/* eslint-disable */
import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { Link } from 'react-router-dom';
import { generateUrl, getElementWidthWithMargin } from '../../../../util/util';
import './style/Carousel.scss';

export default class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerSwiper: null
        }
        this.limitRight = false;
    }

    componentDidMount() {
        this.renderItem()
        this.listenerSwiper();
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        // this.renderItem()
        // this.listenerSwiper();
    }

    listenerSwiper() {
        const { data, pathConfig } = this.props;
        let pathData = {};
        if (pathConfig) {
            pathData = {
                data,
                ...pathConfig
            }
        } else {
            pathData = null;
        }
        if (data) {
            this.removeListenerSwiper();
            // const swipers = document.querySelectorAll(`.swiper-slide${data.id}`);
            const swipers = Array.prototype.slice.call(document.getElementById(`swiperWrapper${this.props.data.id}`).children);
            swipers.forEach((el, index) => {
                el.addEventListener('onFocus', this.swiperFocus.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('left', this.swiperLeft.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('right', this.swiperRight.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('up', this.swiperUp.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('down', this.swiperDown.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('click', this.swiperClick.bind(this, index, Number(el.getAttribute("index"), el)));
                el.addEventListener('mouseenter', this.swiperMouseEnter.bind(this, index, Number(el.getAttribute("index"), el)));

            })
        }
    }

    removeListenerSwiper() {
        const { data, pathConfig } = this.props;
        if (data) {
            // const swipers = document.querySelectorAll(`.swiper-slide${data.id}`);
            const swipers = Array.prototype.slice.call(document.getElementById(`swiperWrapper${this.props.data.id}`).children);
            swipers.forEach((el, index) => {
                el.removeEventListener('onFocus', () => { });
                el.removeEventListener('left', () => { });
                el.removeEventListener('right', () => { });
                el.removeEventListener('up', () => { });
                el.removeEventListener('down', () => { });
                el.removeEventListener('click', () => { });
                el.removeEventListener('mouseenter', () => { });

            })
        }

    }

    swiperFocus(index, realIndex, el) {
        const { data } = this.props
        // let swiperWrapper = document.getElementById(`swiperWrapper${data.id}`);
        // console.log(el, `swiperSlide${this.props.data.id}Index${index}`)
        if (data && data.rContent.length >= 3) {
            this.state.bannerSwiper.slideTo(index, 1000, false)
        }

        if (realIndex != this.props.data.rContent.length - 1) {
            this.limitRight = false;
        }
        // $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
    }

    swiperLeft(index, realIndex, el) {
        this.limitRight = false;
        if (realIndex == 0) {
            if (this.props.data.rContent[realIndex] && this.props.data.rContent[realIndex].id) {
                $tv.requestFocus(document.getElementById(this.props.data.rContent[realIndex].id));
            }
            // console.log(document.getElementById(this.props.data.rContent[realIndex].id))
            // $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
            // 
        }
        // if (index != 0) {
        //     this.state.bannerSwiper.slideTo(index, 1000, false)
        // } else {
        //     $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
        // }

    }

    swiperRight(index, realIndex, el) {
        if (realIndex == this.props.data.rContent.length - 1) {
            if (this.props.data.rContent[realIndex] && this.props.data.rContent[realIndex].id) {
                $tv.requestFocus(document.getElementById(this.props.data.rContent[realIndex].id));
            }
            // console.log(document.getElementById(this.props.data.rContent[realIndex]))
            // $tv.requestFocus(el);
            // $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
        }
        if (this.props.data.rContent.length > 2 && (realIndex == this.props.data.rContent.length - 1) && this.limitRight == false) {
            this.limitRight = true;
            if (document.getElementById(`swiperWrapper${this.props.data.id}`) && document.getElementById(this.props.data.rContent[realIndex].id)) {
                let elWidth = getElementWidthWithMargin(document.getElementById(this.props.data.rContent[realIndex].id));
                console.log(this.getTransformLeftValue(document.getElementById(`swiperWrapper${this.props.data.id}`)) - (elWidth / 2))
                document.getElementById(`swiperWrapper${this.props.data.id}`).style.transform = `translate3d(${this.getTransformLeftValue(document.getElementById(`swiperWrapper${this.props.data.id}`)) - elWidth - (elWidth / 2)}px, 0px, 0px)`
            }
        }
        // console.log(index, realIndex)
        // const { data } = this.props
        // if (realIndex !== this.props.data.rContent.length - 1) {

        //     const swipers = document.querySelectorAll(`.swiper-slide${data.id}`);
        //     let slideToIndex = index;
        //     swipers.forEach((el, elIndex) => {
        //         if (realIndex == Number(el.getAttribute("data-swiper-slide-index")))  {
        //             slideToIndex = elIndex + 1;
        //         }
        //     })
        //     console.log(slideToIndex)
        //     // this.state.bannerSwiper.slideTo(slideToIndex, 1000, false)
        //     // $tv.requestFocus(document.getElementById(`swiperSlide${data.id}Index${slideToIndex}`));
        // } else {
        //     // $tv.requestFocus(document.getElementById(`swiperSlide${data.id}Index${realIndex}`));
        // }

    }

    getTransformLeftValue(element) {
        var style = window.getComputedStyle(element);
        var transformMatrix = style.transform;
        var matrix = transformMatrix.match(/^matrix\(([-.\d]+), ([-.\d]+), ([-.\d]+), ([-.\d]+), ([-.\d]+), ([-.\d]+)\)$/);
        if (matrix) {
            // 对应于 translateX(left) 的值
            return parseFloat(matrix[5]);
        }
        return 0;
    }

    swiperUp(index, realIndex) {
        // console.log(this.props.data.rContent[realIndex])
        if (this.props.carouselUp) {
            this.props.carouselUp(this.props.data.rContent[realIndex]);
        }
    }

    swiperDown(index, realIndex) {
        // console.log(index, this.props.data.rContent[realIndex])
        if (this.props.carouselDown) {
            this.props.carouselDown(this.props.data.rContent[realIndex]);
        }
    }

    swiperClick(index, realIndex) {
        console.log(11111)
        if (this.props.click) {
            this.props.click(this.props.data, this.props.data.rContent[realIndex]);
        }
    }

    swiperMouseEnter() {

    }

    renderItem() {
        const { data } = this.props
        if (data && data.rContent.length >= 3) {
            let bannerSwiper = this.state.bannerSwiper;
            // return
            bannerSwiper = new Swiper(`#swiperContainer${data.id}`, {
                autoplay: false,
                // speed: 1000,
                autoplayDisableOnInteraction: false,
                loop: false,
                initialSlide: 1,
                centeredSlides: true,
                slidesPerView: 2,
                onInit: function (swiper) {
                    swiper.slides[2].className =
                        "swiper-slide swiper-slide-active"; //第一次打开不要动画
                },
                breakpoints: {
                    668: {
                        slidesPerView: 1,
                    }
                },
            })
            this.setState({
                bannerSwiper
            })
        }
    }


    render() {
        const { data, pathConfig } = this.props
        let pathData = {};
        if (pathConfig) {
            pathData = {
                data,
                ...pathConfig
            }
        } else {
            pathData = null;
        }
        return (<div className='carousel-wrapper'>
            <div className={`swiper-container ${data.rContent.length == 1 ? 'swiper-container_single' : (data.rContent.length == 2 ? 'swiper-container_two' : '')}`} id={`swiperContainer${data.id}`}>
                <div className='swiper-wrapper' id={`swiperWrapper${data.id}`}>
                    {
                        data.rContent.map((item, index) => (
                            <div className={`swiper-slide swiper-slide${data.id}`} index={index} id={item.id} key={index} focusable>
                                <div className='swiper-image'>
                                    <Link key={index} to={generateUrl(pathData)}>
                                        <img src={item.poster} />
                                    </Link>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>)
    }
}