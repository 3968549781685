import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getStorage, getElementHeightWithMargin } from '../../../../util/util';
import $request from '../../../../api';
import GLOBAL from '../../../../util/global';
import './style/DeviceMangement.scss';
const deviceTypeIcon = {
    pc: require('../../../../assets/images/icon/icon-pc.svg').default,
    ipad: require('../../../../assets/images/icon/icon-pad.svg').default,
    androidtablet: require('../../../../assets/images/icon/icon-pad.svg').default,
    tv: require('../../../../assets/images/icon/icon-tv.svg').default,
    androidmobile: require('../../../../assets/images/icon/icon-phone.svg').default,
    iphone: require('../../../../assets/images/icon/icon-phone.svg').default,
    android: require('../../../../assets/images/icon/icon-phone.svg').default,
}
let updateFlag = false;
let unbindFlag = false;
class DeviceMangement extends React.PureComponent {
    constructor(props) {
        super(props);
        this.buttonsRef = [];
        this.scrollWrapperRef = React.createRef();
        this.deviceListRef = [];
        this.state = {
            deviceList: [], // 设备列表
            currentDevice: null, // 当前设备
            currentSelectDevice: null, // 当前选择设备
        }
    }

    componentWillMount() {
        this.getDeviceList();
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        this.handleRemoveButtonListenr();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            let deviceList = this.deviceListRef;
            if (deviceList.length) {
                if (nextProps.value) {
                    let hasDevice = false;
                    let deviceListData = this.state.deviceList;
                    deviceListData.forEach((item, index) => {
                        if (item.mac == nextProps.value) {
                            $tv.requestFocus(deviceList[index]);
                            hasDevice = true;
                        }
                    })
                    if (!hasDevice) {
                        $tv.requestFocus(deviceList[0]);
                    }
                } else {
                    $tv.requestFocus(deviceList[0]);
                }

            }
            this.getDeviceList();
        } else {
            this.handleRemoveButtonListenr();
        }
    }

    getDateDiff(dateTimeStamp) {

          var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
          var hour = minute * 60;
          var day = hour * 24;
          var week = day * 7;
          var halfamonth = day * 15;
          var month = day * 30;
          var year = day * 365;
          var now = new Date().getTime(); //获取当前时间毫秒
          // console.log(now);
          var diffValue = Math.abs(now - dateTimeStamp); //时间差
          if (diffValue < 0) {
            return;
          }
          var minC = diffValue / minute; //计算时间差的分，时，天，周，月
          var hourC = diffValue / hour;
          var dayC = diffValue / day;
          var weekC = diffValue / week;
          var monthC = diffValue / month;
          var yearC = diffValue / year;
          var result;
          if (yearC >= 1) {
            result = parseInt(yearC) + ' ' + this.props.t('date.yearsAgo');
          } else if (monthC >= 1 && monthC <= 12) {
            result = parseInt(monthC) + ' ' + this.props.t('date.monthsAgo');
          } else if (weekC >= 1 && weekC <= 4) {
            result = parseInt(weekC) + ' ' + this.props.t('date.weeksAgo');
          } else if (dayC >= 1 && dayC <= 7) {
            result = parseInt(dayC) + ' ' + this.props.t('date.daysAgo');
          } else if (hourC >= 1 && hourC <= 24) {
            result = parseInt(hourC) + ' ' + this.props.t('date.hoursAgo');
          } else if (minC >= 1 && minC <= 60) {
            result = parseInt(minC) + ' ' + this.props.t('date.minutesAgo');
        
          } else if (diffValue >= 0 && diffValue <= minute) {
            result = this.props.t('date.recent');
          } else {
            console.log(8)
            var datetime = new Date();
            datetime.setTime(dateTimeStamp);
            var Nyear = datetime.getFullYear();
            var Nmonth =
              datetime.getMonth() + 1 < 10 ?
                "0" + (datetime.getMonth() + 1) :
                datetime.getMonth() + 1;
            var Ndate =
              datetime.getDate() < 10 ?
                "0" + datetime.getDate() :
                datetime.getDate();
            var Nhour =
              datetime.getHours() < 10 ?
                "0" + datetime.getHours() :
                datetime.getHours();
            var Nminute =
              datetime.getMinutes() < 10 ?
                "0" + datetime.getMinutes() :
                datetime.getMinutes();
            var Nsecond =
              datetime.getSeconds() < 10 ?
                "0" + datetime.getSeconds() :
                datetime.getSeconds();
            result = Nyear + "-" + Nmonth + "-" + Ndate;
          }
          return result;
        }

    /**
     * 获取设备列表
     */
    getDeviceList() {
        if (updateFlag) {
            return 
        }
        updateFlag = true;
        $request.boApi.user.getUserDeviceList({
            mac: getStorage(GLOBAL.STORAGE_KEYS.UUID)
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.devices && res.data.devices.length) {
                    updateFlag = false;
                    unbindFlag = false;
                    let deviceList = res.data.devices;
                    deviceList.forEach(item => {
                        if (item.mac == getStorage(GLOBAL.STORAGE_KEYS.UUID)) {
                            this.setState({
                                currentDevice: item
                            })
                        }
                    });
                    this.setState({
                        deviceList: deviceList.filter(item => {
                            return this.state.currentDevice ? item.mac != this.state.currentDevice.mac : item
                        })
                    }, () => {
                        this.handleButtonListenr();
                    })
                }

            }
        })
    }



    handleButtonListenr() {
        this.handleRemoveButtonListenr();
        let deviceList = this.deviceListRef;
        let scorllEl = this.scrollWrapperRef.current;
        this.handleRemoveButtonListenr();
        deviceList.forEach((el, index) => {
            if (el) {
                el.addEventListener("click", () => {
                    if (this.props.click && unbindFlag == false) {
                        this.props.click(this.state.deviceList[index]);
                    }
                });
                el.addEventListener("left", () => {
                    $tv.requestFocus(el);
                })
                el.addEventListener("right", () => {
                    $tv.requestFocus(el);
                })
                el.addEventListener("up", () => {
                    if (index <= 0) {
                        $tv.requestFocus(el);
                        scorllEl.style.top = 0;
                    } else {
                        $tv.requestFocus(deviceList[index - 1]);
                        let elHeight = getElementHeightWithMargin(el);
                        if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                            scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
                        }
                    }
                })
                el.addEventListener("down", () => {
                    if (index == deviceList.length - 1) {
                        $tv.requestFocus(el);
                    } else {
                        $tv.requestFocus(deviceList[index + 1]);
                    }
                    if (index >= 3) {
                        if (el) {
                            let elHeight = getElementHeightWithMargin(el);
                            scorllEl.style.top = -(index * elHeight) + 'px';
                        }
                    }
                })
            }
        })
    }

    handleRemoveButtonListenr() {
        let deviceList = this.deviceListRef;
        deviceList.forEach(el => {
            if (el) {
                el.removeEventListener("click", () => { });
                el.removeEventListener("left", () => { })
                el.removeEventListener("right", () => { })
                el.removeEventListener("up", () => { })
                el.removeEventListener("down", () => { })
            }

        })
    }

    render() {
        const { show } = this.props;
        const { deviceList, currentDevice } = this.state;
        return (
            <div className='popup' style={{ display: show ? 'flex' : 'none' }}>
                <div className='popup-left'></div>
                <div className='popup-right'>
                    <div className='title'>
                        {this.props.t("setting.deviceManagement")}
                        <div className='desc'>{this.props.t("setting.deviceManagementDesc")}</div>
                    </div>
                    {
                        currentDevice ? (
                            <div className='current-device'>
                                <div className='item-left'>
                                    <div className='icon'>
                                        <img src={deviceTypeIcon[currentDevice.type]} />
                                    </div>
                                    <div className='item-info'>
                                        <div className='item-name'>{currentDevice.type}</div>
                                        <div className='item-value'>{this.props.t("setting.currentDevice")}</div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className='device-box'>
                        <div className='device-wrapper' ref={this.scrollWrapperRef}>
                            {
                                deviceList.map((item, index) => (
                                    <div className='device-item' ref={(el) => { this.deviceListRef[index] = el }} key={index} focusable >
                                        <div className='item-left'>
                                            <div className='icon'>
                                                <img src={deviceTypeIcon[item.type]} />
                                            </div>
                                            <div className='item-info'>
                                                <div className='item-name'>{item.deviceModel}</div>
                                                <div className='item-value'>{this.getDateDiff(item.lastLoginTime.toString().length == 10 ? (item.lastLoginTime * 1000) : item.lastLoginTime)}</div>
                                            </div>
                                        </div>
                                        <div className='item-right'>
                                            <div className='item-button'>{this.props.t("button.delete")}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

DeviceMangement.propTypes = {
    show: PropTypes.bool, // 是否显示
}

export default withTranslation()(DeviceMangement);