import $request from "../api";
import GLOBAL from "./global";
import { rsaDecrypt } from "../util/util"
export function deviceSign() {
    return new Promise((resolve, reject) => {
        let a = 1;
        $request.adApi.getDeviceSign({
            a
        }).then((res) => {
          if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
            let sign = rsaDecrypt(res.data.body)
            sessionStorage.setItem(GLOBAL.STORAGE_KEYS.SIGN, sign);
          }
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
}