/* eslint-disable */
import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { Link } from 'react-router-dom';
import { generateUrl } from '../../../../util/util';
import './style/ScrollBanner.scss';

export default class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerSwiper: null
        }
    }

    componentDidMount() {
        this.renderItem()
        this.listenerSwiper();
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        // this.renderItem()
        // this.listenerSwiper();
    }

    listenerSwiper() {
        const { data } = this.props
        if (data) {
            this.removeListenerSwiper();
            const swipers = document.querySelectorAll(`.swiper-slide${data.id}`);
            swipers.forEach((el, index) => {
                el.addEventListener('onFocus', this.swiperFocus.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('left', this.swiperLeft.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('right', this.swiperRight.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('up', this.swiperUp.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('down', this.swiperDown.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('click', this.swiperClick.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));
                el.addEventListener('mouseenter', this.swiperMouseEnter.bind(this, index, Number(el.getAttribute("data-swiper-slide-index"))));

            })
        }
    }

    removeListenerSwiper() {
        const { data, pathConfig } = this.props;
        if (data) {
            const swipers = document.querySelectorAll(`.swiper-slide${data.id}`);
            swipers.forEach((el, index) => {
                el.removeEventListener('onFocus', () => {});
                el.removeEventListener('left',  () => {});
                el.removeEventListener('right',  () => {});
                el.removeEventListener('up',  () => {});
                el.removeEventListener('down',  () => {});
                el.removeEventListener('click',  () => {});
                el.removeEventListener('mouseenter',  () => {});

            })
        }

    }

    swiperFocus(index, realIndex) {
        this.state.bannerSwiper.slideTo(index, 1000, false)
    }

    swiperLeft(index, realIndex) {
        if (index != 0) {
            this.state.bannerSwiper.slideTo(index, 1000, false)
        } else {
            // $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
        }

    }

    swiperRight(index, realIndex) {
        if (index !== this.props.data.rContent.length - 1) {
            this.state.bannerSwiper.slideTo(index, 1000, false)
        } else {
            // $tv.requestFocus(document.getElementById(`swiperSlide${this.props.data.id}Index${index}`));
        }

    }

    swiperUp(index, realIndex) {
        // console.log(this.props.data.rContent[realIndex])
        if (this.props.scrollBannerUp) {
            this.props.scrollBannerUp(this.props.data.rContent[realIndex]);
        }
    }

    swiperDown(index, realIndex) {
        // console.log(index, this.props.data.rContent[realIndex])
        if (this.props.scrollBannerDown) {
            this.props.scrollBannerDown(this.props.data.rContent[realIndex]);
        }
    }

    swiperClick(index, realIndex) {
        if (this.props.click) {
            this.props.click(this.props.data, this.props.data.rContent[realIndex]);
        }
    }

    swiperMouseEnter() {

    }

    renderItem() {
        const { data } = this.props
        if (data) {
            let bannerSwiper = this.state.bannerSwiper;
            // return
            bannerSwiper = new Swiper(`#swiperContainer${data.id}`, {
                autoplay: false,
                // speed: 1000,
                autoplayDisableOnInteraction: false,
                loop: true,
                centeredSlides: true,
                slidesPerView: 1
            })
            this.setState({
                bannerSwiper
            })
        }
    }


    render() {
        const { data, pathConfig } = this.props;
        let pathData = {};
        if (pathConfig) {
            pathData = {
                data,
                ...pathConfig
            }
        } else {
            pathData = null;
        }
        return (<div className='scroll-banner_wrapper'>
            <div className='swiper-container' id={`swiperContainer${data.id}`}>
                <div className='swiper-wrapper'>
                    {
                        data.rContent.map((item, index) => (
                            <div className={`swiper-slide swiper-slide${data.id}`} key={index} id={item.id} focusable>
                                <div className='swiper-image'>
                                    <Link key={index} to={generateUrl(pathData)}>
                                        <img src={item.poster} />
                                    </Link>
                                </div>
                                {
                                    item.markUrl ? (<div className={`mark-icon ${item.markPosition}`}>
                                        <img src={item.markUrl} />
                                    </div>) : ''
                                }
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>)
    }
}