// import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import enTrans from './react-i18next/en_US.json'; // 英语
import zhCnTrans from './react-i18next/zh_CN.json'; // 简体中文
import idTrans from './react-i18next/id-ID.json'; // 印尼语
import msTrans from './react-i18next/ms-MY.json'; // 马来文
import itTrans from './react-i18next/it-IT.json'; // 意大利语
import { initReactI18next } from 'react-i18next';
import GLOBAL from './util/global';
import { getStorage } from './util/util';
function mergeAndFillTwoLevelObjects(...objects) {
  // 获取所有对象的一级和最后一级 key，并存储在一个集合中
  const allKeys = new Set();

  objects.forEach(obj => {
    collectTwoLevelKeys(obj, allKeys);
  });

  // 对每个对象补充缺失的 key
  objects.forEach(obj => {
    fillMissingTwoLevelKeys(obj, allKeys);
  });

  return objects;
}

// 收集对象的一级和最后一级 key
function collectTwoLevelKeys(obj, keySet) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      for (const subKey in obj[key]) {
        keySet.add(`${key}.${subKey}`);
      }
    }
  }
}

// 填充缺失的一级和最后一级 key
function fillMissingTwoLevelKeys(obj, keySet) {
  keySet.forEach(key => {
    const [parentKey, childKey] = key.split('.');
    if (!obj.hasOwnProperty(parentKey)) {
      obj[parentKey] = {}; // 一级 key 缺失时，先创建空对象
    }
    if (!obj[parentKey].hasOwnProperty(childKey)) {
      obj[parentKey][childKey] = ""; // 最后一级 key 缺失时，设置为空字符串
    }
  });
}
// console.log(mergeAndFillTwoLevelObjects(enTrans,zhCnTrans,idTrans,msTrans,itTrans));

i18n
  .use(initReactI18next) // init i18next
  .init({
    compatibilityJSON: 'v3',
    // 引入资源文件
    resources: {
      en: {
        translation: enTrans,
      },
      zhCn: {
        translation: zhCnTrans,
      },
      id: {
        translation: idTrans,
      },
      ms: {
        translation: msTrans,
      },
      it: {
        translation: itTrans,
      },
    },
    // 选择默认语言，选择内容为上述配置中的key
    fallbackLng: getStorage(GLOBAL.STORAGE_KEYS.LANG_KEY) || 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
