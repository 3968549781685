import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getUUID, keycodeHandle, formatDate, deepClone, getElementHeightWithMargin} from '../../util/util';
import './style/Notification.scss';
import $request from '../../api';
import GLOBAL from '../../util/global';
import Detail from "./components/Detail";
import Empty from '../../components/empty/Empty';

export default withTranslation()(class Notification extends Component {
    constructor(props) {
        super(props);
        this.tabsRef = []; // 菜单列表节点
        this.messageWrapperRef = React.createRef(); // 消息列表盒子节点
        this.pageWheelTimer = null; // 鼠标滚轮监听延时器
        this.state = {
            menuTabs: [ // 菜单列表
                {
                    type: 1,
                    name: this.props.t("message.allMessage")
                }
            ],
            isShowDetail: false, // 是否显示详情
            currentMessage: null, // 当前消息
            messageList: [], // 消息列表
            page: 0, // 当前页
            pageSize: 20, // 页面大小
            totalPage: 0 // 总页数
        }
    }

    componentWillMount() {
        this.getMessageList();
        clearTimeout(this.pageWheelTimer);
    }

    componentDidMount() {
        if (this.tabsRef.length) {
            $tv.requestFocus(this.tabsRef[0]);
        }
        keycodeHandle({
            exit: this.handleExit.bind(this),
            quit: this.handleExit.bind(this),
        });
    }

    componentWillUnmount() {
        this.handleRemoveListenerMessageItem();
    }

    handleExit() {
        if (this.state.isShowDetail && this.state.currentMessage) {
            this.setState({
                isShowDetail: false
            }, () => {
                let messageList = deepClone(this.state.messageList);
                let shouldUpdate = false;
                messageList.forEach(item => {
                    if (item.messageId == this.state.currentMessage.messageId && item.showType != 1) {
                        item.showType = 1;
                        shouldUpdate = true;
                    }
                })
                if (shouldUpdate) {
                    this.setState({
                        messageList
                    })
                }
                $tv.requestFocus(document.getElementById(`messageItem${this.state.currentMessage.messageId}`));
            })
        } else {
            window.history.back(-1);
        }
    }

    /**
     * 获取消息列表
     */
    getMessageList() {
        $request.boApi.user.getMessageList({
            mac: getUUID(),
            page: this.state.page,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.data && res.data.data.messageList) {
                    let messageList = deepClone(this.state.messageList);
                    messageList = messageList.concat(res.data.data.messageList);
                    this.setState({
                        messageList,
                        totalPage: Math.ceil(res.data.data.totalCount / this.state.pageSize)
                    }, () => {
                        this.handleRemoveListenerMessageItem();
                        this.handleListenerMessageItem();
                    })
                }
            }
        })
    }

    /**
     * 移除消息节点事件监听
     */
    handleRemoveListenerMessageItem() {
        const messages = document.querySelectorAll(".message-list_item");
        let scorllEl = this.messageWrapperRef.current;
        messages.forEach((el, index) => {
            el.removeEventListener("click", () => {});
            el.removeEventListener("up", () => {});
            el.removeEventListener("down", () => {});
        })
    }

    /**
     * 消息节点事件监听
     */
    handleListenerMessageItem() {
        const messages = document.querySelectorAll(".message-list_item");
        let scorllEl = this.messageWrapperRef.current;
        messages.forEach((el, index) => {
            el.addEventListener("click", () => {
                this.handleReadMessage(this.state.messageList[index]);
            });
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el);
                    scorllEl.style.top = 0;
                } else {
                    let elHeight = getElementHeightWithMargin(el);
                    if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                        scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
                    }
                }
            });
            el.addEventListener("down", () => {
                if (index >= 3) {
                    if (el) {
                        let elHeight = getElementHeightWithMargin(el);
                        scorllEl.style.top = -(index * elHeight) + 'px';
                    }
                }
                if (index == this.state.messageList.length - 2 && this.state.page < this.state.totalPage - 1) {
                    this.setState({
                        page: this.state.page + 1
                    }, () => {
                        this.getMessageList();
                    })
                }
            });
        })
    }

    /**
     * 阅读消息
     */
    handleReadMessage(data) {
        if (data.showType != 1) {
            $request.boApi.user.updateMessageStatus({
                mac: getUUID(),
                id: data.id,
                messageId: data.messageId
            }).then(res => {
                if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                    let messageList = deepClone(this.state.messageList);
                    messageList.forEach(item => {
                        if (item.id == data.id && item.messageId == data.messageId) {
                            item.showType = 0;
                        }
                    })
                    this.setState({
                        messageList,
                        currentMessage: data,
                        isShowDetail: true
                    })
                }
            })
        } else {
            this.setState({
                currentMessage: data,
                isShowDetail: true
            })
        }
        
    }

    /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
     pageWheel(e) {
        clearTimeout(this.pageWheelTimer);
        this.pageWheelTimer = setTimeout(() => {
            if (e.deltaY > 0) {
                $tv.findFocusType = 0;
                $tv.initDis = 10;
                $tv.setFocus('down');
            } else {
                $tv.findFocusType = 0;
                $tv.initDis = 80;
                $tv.setFocus('up');
            }
        }, 300);
    }

    render() {
        const { menuTabs, messageList, currentMessage } = this.state;
        return (<div className='notification-page'  onWheel={e => this.pageWheel(e)}>
            <div className='tab'>
                {
                    menuTabs.map((item, index) => (
                        <div className='tab-item' key={index} ref={(el) => {
                            this.tabsRef[index] = el;
                        }} focusable>{item.name}</div>
                    ))
                }

            </div>
            <div className='notification-wrapper'>
                <div className='message-wrapper'>
                    <Empty show={messageList.length == 0} />
                    <div className='message-list' ref={this.messageWrapperRef}>
                        {
                            messageList.map((item, index) => (
                                <div className='message-list_item' id={`messageItem${item.messageId}`} focusable key={index}>
                                    <div className='title-box'>
                                        <div className='title-left'>
                                            <div className='readed-icon' style={{display: item.showType == 1 ? 'block' : 'none'}}>
                                                <img className='read-normal' src={require('../../assets/images/icon/atv_messages_icon_read_normal.png').default} />
                                                <img className='read-focused' src={require('../../assets/images/icon/atv_messages_icon_read_focus.png').default} />
                                            </div>
                                        {item.title}
                                        </div>
                                        <div className='title-right'>
                                            <div className='icon'>
                                                <img className='normal-icon' src={require('../../assets/images/icon/atv_messages_icon_time_normal.png').default} />
                                                <img className='focus-icon' src={require('../../assets/images/icon/atv_messages_icon_time_focus.png').default} />
                                            </div>
                                            <div className='date'>{item.createTime ? formatDate(item.createTime, 'yyyy-MM-dd') : ''}</div>
                                        </div>
                                        {/* <div className='un-read' style={{display: item.showType != 1 ? 'block' : 'none'}}>New</div> */}
                                    </div>
                                    <div className='messge-text'>{item.content}</div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
            { currentMessage ?  <Detail show={this.state.isShowDetail} data={currentMessage} /> : null}
            
        </div>)
    }
})