import React, { Component, createRef } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './style/Dialog.scss';

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.confirmElRef = createRef();
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.handleListenerConfirmBtn();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            $tv.requestFocus(this.confirmElRef.current);
        }
    }


    componentWillUnmount() {
        this.handleRemoveListenerConfirm();
    }

    /**
     * 确认按钮监听
     */
    handleListenerConfirmBtn() {
        if (this.confirmElRef && this.confirmElRef.current) {
            let el = this.confirmElRef.current;
            el.addEventListener('click', () => {
                if (this.props.confirm) {
                    this.props.confirm();
                }
            });
            el.addEventListener('left', () => {
                $tv.requestFocus(el);
            });
            el.addEventListener('right', () => {
                $tv.requestFocus(el);
            });
            el.addEventListener('up', () => {
                $tv.requestFocus(el);
            });
            el.addEventListener('down', () => {
                $tv.requestFocus(el);
            });
        }
    }

    /**
     * 移除监听Confirm按键
     */
    handleRemoveListenerConfirm() {
        if (this.confirmElRef && this.confirmElRef.current) {
            let el = this.confirmElRef.current;
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
            el.removeEventListener("up", () => { });
            el.removeEventListener("down", () => { });
        }
    }

    render() {
        const { show, age } = this.props;
        return (
            <div className='dialog' style={{ display: show ? 'flex' : 'none' }}>
                <div className='dialog-body'>
                    <div className='dialog-title'>{this.props.t("adultContent.title")}</div>
                    <div className='dialog-desc'>{this.props.t("adultContent.toAppSetBirthdateTips", { age })}</div>
                    <div className='dialog-btn_group'>
                        <div className='confirm-btn' ref={this.confirmElRef} focusable>{this.props.t("button.ok")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

Dialog.propTypes = {
    show: PropTypes.bool.isRequired, // 必传，是否显示
    text: PropTypes.string, // 弹窗描述
    age: PropTypes.number, // 年龄
}

export default withTranslation()(Dialog);