import { axiosPostRequest } from "../request/http.js";
const collectionApi = {
     /**
     * 数据采集上报接口
     * @param {*} params 
     * @returns 
     */
      event: (params) => {
        return axiosPostRequest({
            url: '/event',
            apiVersion: '/v1',
            apiPrefix: '/collection',
            apiType: 2
        }, params)
    },
    /**
     * 广告浏览、播放、互动上报接口
     * @param {*} params 
     * @returns 
     */
    eventAdPods: (params) => {
        return axiosPostRequest({
            url: '/eventAdPods',
            apiVersion: '/v1',
            apiPrefix: '/collection',
            apiType: 2
        }, params)
    }
}

export default collectionApi