import React from 'react';
import ReactDOM from 'react-dom';
// import Router from './Router';
import './root.css';
import './public.css';
import './util/flexible';
import './swiper.css'
import { $tv } from 'react-tv-focusable';
import { ModalProvider } from './ModalProvider';
import BasicRoute from './BasicRoute';
import axios from 'axios'; // 引入axios
import './i18n';
import md5 from 'js-md5';
React.Component.prototype.$md5 = md5;
axios.defaults.withCredentials = true;
// init的所有配置项可以在最后面的表格中查看
$tv.init({
  initDis: 40,
  KEYS: {
    KEY_LEFT: [37],
    KEY_UP: [38],
    KEY_RIGHT: [39],
    KEY_DOWN: [40],
    KEY_ENTER: [13, 65293, 65376], // ok键， 长按
    KEY_BACK: [640, 65367, 8, 27, 339, 90, 109, 283, 340], // 返回键
  }, // 自定义键值
  offsetDistance: 50,
  findFocusType: 1, // 即默认找最近焦点方式
  longPressTime: 800, // 长按响应时间（单位：毫秒），默认500ms
  distanceToCenter: false, // 是焦点始终在可是范围的中间部分， 默认false
})

// iframe 中监听消息
window.addEventListener("message", (event) => {
  // alert(111)
  console.log(event, '----')
    if (event.data.type === "triggerEvent") {
        console.log("事件触发了！");
    }
});

// function getDeviceInfo () {
//   try {
//     if (webapis && webapis.productinfo.getDuid()) {

//     }
//   }
// }
// ReactDOM.render(<Router />, document.getElementById('root'));
ReactDOM.render(
  <ModalProvider>
    <BasicRoute />
  </ModalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
