import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import './style/popup.scss';

class Popup extends Component {
    constructor(props) {
        super(props);
        this.buttonsRef = [];
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.handleRemoveButtonListenr();
        if (nextProps.show) {
            if (this.buttonsRef.length) {
                setTimeout(() => {
                    $tv.requestFocus(this.buttonsRef[0]);
                }, 300)
            }
            this.buttonsRef.forEach((el, index) => {
                el.addEventListener("click", () => {
                    if (this.props.click) {
                        this.props.click(this.props.buttons[index]);
                    }
                });
                el.addEventListener("left", () => {
                    $tv.requestFocus(el);
                })
                el.addEventListener("right", () => {
                    $tv.requestFocus(el);
                })
                el.addEventListener("up", () => {
                    if (index == 0) {
                        $tv.requestFocus(el);
                    }
                })
                el.addEventListener("down", () => {
                    if (index == this.props.buttons.length - 1) {
                        $tv.requestFocus(el);
                    } else {
                        $tv.requestFocus(this.buttonsRef[index + 1]);
                    }
                })
            })
        } else {
            this.handleRemoveButtonListenr();
        }
    }

    handleRemoveButtonListenr() {
        this.buttonsRef.forEach(el => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { })
            el.removeEventListener("right", () => { })
            el.removeEventListener("up", () => { })
            el.removeEventListener("down", () => { })
        })
    }

    componentWillUnmount() {
        this.handleRemoveButtonListenr();
    }

    render() {
        const { show, title, text, buttons } = this.props;
        return (
            <div className='popup' style={{ display: show ? 'flex' : 'none' }}>
                <div className='popup-left'>
                    <div className='popup-left_wrapper'>
                        <div className='popup-title'>{title || ''}</div>
                        <div className='popup-desc'>{text || ''}</div>
                    </div>
                </div>
                <div className='popup-right'>
                    <div className='btn-group'>
                        {
                            buttons ? buttons.map((item, index) => (
                                <div className='btn-group_item' id={'buttonItem' + item.type} ref={(el) => { this.buttonsRef[index] = el }} key={item.type} focusable>{item.text}</div>
                            )) : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Popup.propTypes = {
    show: PropTypes.bool.isRequired, // 必传，是否显示
    title: PropTypes.string, // 弹窗标题
    text: PropTypes.string, // 弹窗描述
    buttons: PropTypes.array, // 按钮组 例：[{type: 'ok', text: 'Confirm'}]
    click: PropTypes.func // 点击事件
}

export default Popup;