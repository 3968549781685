const projectConfig = {
    // tenantCode: '100063116666', // UAT
    // apiId: 'LMeD5Syc', // UAT
    // apiKey: 'c472edf89984954ffb97c53224a4fa7763d1a650', // UAT
    // boapi: "https://uat-login-molatv.sdmc.tv", // UAT
    // collectionApi: "https://uat-collection-molatv.sdmc.tv", // UAT
    // adapi: '/ad/v1', // UAT
    // adBaseURL: 'https://uat-admsapi-molatv.sdmc.tv/v1', // 三星广告BaseURL UAT

    tenantCode: '100015506555', //  prod
    apiId: 'LMeD5Syc', //  prod
    apiKey: 'c472edf89984954ffb97c53224a4fa7763d1a650', //  prod
    boapi: "https://login.mola.tv",
    collectionApi: "https://collection.mola.tv", // Prod
    adapi: '/v1', // Prod
    adBaseURL: 'https://admsapi.mola.tv/v1', // 三星广告BaseURL Prod
    defaultParentPinCode: "0000", // 默认父母控制PIN码
    paymentDownTime: 900, // 付款倒计时，单位秒
    version: "5.5.2",
    webiste: "https://mola.tv",
    contactEmail: "support@mola.tv",
    defaultLanguage: "en_US",
    lang: [{
        label: 'English',
        value: 'en_US',
        key: 'en',
        shortValue: 'English'
    },
    {
        label: 'Bahasa Indonesia',
        value: 'id-ID',
        key: 'id',
        shortValue: 'Bahasa indonesia'
    },
    {
        label: 'Italiano',
        value: 'it-IT',
        key: 'it',
        shortValue: 'Italiano'
    },
    {
        label: '简体中文',
        value: 'zh_CN',
        key: 'zhCn',
        shortValue: '简体中文'
    },
    {
        label: 'Melayu',
        value: 'ms-MY',
        key: 'ms-MY',
        shortValue: 'Melayu'
    },
    ]
}

export default projectConfig;