import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import './style/alert.scss';

class Alert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, type, show } = this.props;
        return (<div style={{display: show ? 'flex': 'none'}} className={`alert is-${type}`}>
            {text}
        </div>)
    }
}

Alert.propTypes = {
    show: PropTypes.bool.isRequired, // 必传，是否显示
    text: PropTypes.string.isRequired, // 必传，提示语
    type: PropTypes.string.isRequired // 必传，类型error | warning | success
}

export default Alert;