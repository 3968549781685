import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import $request from '../../api';
import { getStorage, getUUID, isObject, keycodeHandle, formatDate, formatDecimals, decodeBase64, getElementHeightWithMargin, getQueryObj } from '../../util/util';
import GLOBAL from '../../util/global';
import Alert from '../../components/alert/alert';
import './style/VoucherCode.scss';

export default withTranslation()(class VoucherCode extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.submitButtonRef = React.createRef();
        this.state = {
            voucherCode: "", // 兑换码
            alertText: "", // 提示文案
            isShowAlert: false, // 是否显示提示
            alertType: '' // 提示类型
        }
    }
    componentWillMount() {

    }

    componentDidMount() {
        keycodeHandle({
            exit: () => { window.history.back(-1) },
            quit: () => { window.history.back(-1) },
        });
        $tv.requestFocus(this.inputRef.current);
        this.handleListenerSubmitButton();
    }

    componentWillUnmount() {
        this.handleRemoveListenerSubmitButton();
    }

    changeInput(e) {
        if (e.target.value !== this.state.inputValue) {
            this.setState({
                voucherCode: e.target.value,
            })
        }
    }

    /**
     * 校验优惠码
     */
    checkVoucherCode() {
        $request.boApi.user.checkVoucherCode({
            ticket: this.state.voucherCode
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    isShowAlert: true,
                    alertType: "success",
                    alertText: this.props.t("toast.voucherCodeActivatedSuccessFully")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.VOUCHER_CODE_NOT_EXIST) {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: this.props.t("toast.errorCode1039")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.VOUCHER_CODE_EXPIRED) {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: this.props.t("toast.errorCode1040")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.VOUCHER_CODE_NOT_YET_EFFECT) {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: this.props.t("toast.errorCode1041")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.VOUCHER_CODE_INVALID) {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: this.props.t("toast.errorCode1042")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SAME_PACKAGE_ACTIVATED) {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: this.props.t("toast.errorCode1069")
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            } else {
                this.setState({
                    isShowAlert: true,
                    alertType: "error",
                    alertText: res.data.description
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false
                        })
                    }, 3000)
                })
            }
        })
    }

    /**
     * 监听提交按钮
     */
    handleListenerSubmitButton() {
        if (this.submitButtonRef && this.submitButtonRef.current) {
            this.submitButtonRef.current.addEventListener("click", () => {
                this.checkVoucherCode();
            })
        }
    }

    /**
     * 移除监听提交按钮
     */
    handleRemoveListenerSubmitButton() {
        if (this.submitButtonRef && this.submitButtonRef.current) {
            this.submitButtonRef.current.removeEventListener("click", () => {})
        }
    }

    render() {
        return (<div className='voucher-code_page'>
            <div className="voucher-title_box">
                <div className='icon'>
                    <img src={require('../../assets/images/icon/atv_me_icon_code.png').default} alt="" />
                </div>
                <div className="voucher-title"> {this.props.t('me.claimTheVoucherCode')}</div>
            </div>
            <div className='input-box'>
                <input placeholder={this.props.t("toast.voucherCodePlaceholder")} value={this.state.voucherCode} onChange={e => this.changeInput(e)} ref={this.inputRef} focusable />
            </div>
            <div className='submit-btn' ref={this.submitButtonRef} focusable>{this.props.t("button.submit")}</div>
            <Alert text={this.state.alertText} type={'error'} show={this.state.isShowAlert} />
        </div>)
    }

}) 