import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, setStorage, removeStorage, keycodeHandle, getUUID } from "../../util/util";
import Column from '../../components/column/Column';
import GLOBAL from "../../util/global"
import Empty from '../../components/empty/Empty';
import $request from '../../api';
import './style/Search.scss';

export default withTranslation()(class NewSearch extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
    this.requestFlag = true; // 请求限制开关
    this.state = {
      searchIcon: require("../../assets/images/icon/atv_search_icon_search_44x44.png"),
      atvSearchIcon: require("../../assets/images/icon/atv_search_icon_search_40x40.png"),
      vodSearchList: [],// 点播搜索结果
      liveSearchList: [], // 直播搜索结果
      liveEventSearchList: [], // 事件直播搜索列表
      seriesSearchList: [],// 系列搜索列表
      isFocus: false,
      currentValue: "",
      inputValue: "",
      hasData: false, // 是否有数据
      isSearch: false, // 是否是搜索
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    this.searchInput.current.addEventListener('onFocus', this.searchInputFocus.bind(this));
    this.searchInput.current.addEventListener('click', this.searchInputClick.bind(this));
    this.searchInput.current.addEventListener('onBlur', this.searchInputBlur.bind(this));
    this.searchInput.current.addEventListener("mouseenter", this.searchInputMouseEnter.bind(this));
    if (getStorage("seriesInputValue")) {
      this.setState({
        inputValue: getStorage("seriesInputValue"),
      }, () => {
        this.querySearch();
        removeStorage("seriesInputValue");
      })
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0); // 滚动条返回顶部
        $tv.requestFocus(this.searchInput.current);
      }, 100);
    }
    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    clearTimeout(this.pageWheelTime);
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  seriesKeyDown = () => {
    // if (!this.state.inputValue) {
      window.history.back();
    // }
  }
  changeInput(e) {
    if (e.target.value !== this.state.inputValue) {
      this.setState({
        inputValue: e.target.value,
      })
    }
  }
  querySearch() {
    $request.boApi.search.getSearch({
      mac: getUUID(),
      keyword: this.state.inputValue,
      page: 0,
      pageSize: 99,
      type: GLOBAL.MEDIA_TYPE.VOD
    }).then(res => {
      this.setState({
        currentValue: this.state.inputValue,
      })
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let contents = res.data.contents
        if (contents.length) {
          this.setState({
            vodSearchList: contents[0].content,
          })
        } else {
          this.setState({
            vodSearchList: []
          })
        }
        this.setState({
          isSearch: true
        })
        this.querySeriesResult();
      }
    });
  };

  // 搜索Series
  querySeriesResult() {
    $request.boApi.search.getSearch({
      mac: getUUID(),
      keyword: this.state.inputValue,
      page: 0,
      pageSize: 99,
      type: GLOBAL.MEDIA_TYPE.SERIES,
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let contents = res.data.contents
        if (contents.length) {
          this.setState({
            seriesSearchList: contents[0].content,
          })
        } else {
          this.setState({
            seriesSearchList: [],
          })
        }
        this.queryLiveEventResult()
      }
    })
  }

  // 搜索liveEvent
  queryLiveEventResult() {
    $request.boApi.search.getSearch({
      mac: getUUID(),
      keyword: this.state.inputValue,
      page: 0,
      pageSize: 99,
      type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let contents = res.data.contents
        if (contents.length) {
          this.setState({
            liveEventSearchList: contents[0].content,
          })
        } else {
          this.setState({
            liveEventSearchList: [],
          })
        }
      }
      this.focusMemory();
    })
  }

  //搜索live结果
  queryLiveResult() {
    $request.boApi.search.getSearch({
      mac: getUUID(),
      keyword: this.state.inputValue,
      page: 0,
      pageSize: 99,
      type: GLOBAL.MEDIA_TYPE.LIVE,
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let contents = res.data.contents
        if (contents.length) {
          this.setState({
            liveSearchList: contents[0].content,
            hasData: true
          })
        } else {
          this.setState({
            liveSearchList: [],
          })
        }
      }
    });
  };
  // 焦点记忆
  focusMemory() {
    if (getStorage("seriesFocalElements") && document.getElementById(getStorage("seriesFocalElements"))) {
      $tv.requestFocus(document.getElementById(getStorage("seriesFocalElements")));
      removeStorage("seriesFocalElements");
    }
  }

  getSeasonsDetail(data) {
    if (this.requestFlag) {
      this.requestFlag = false;
      $request.boApi.vod.getSeasonsDetail({
        mac: getUUID(),
        seasonsAssetId: data.url || data.seasonsAssetId
      }).then(res => {
        if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
          let seasonsDetail = res.data.seasonsDetail;
          if (seasonsDetail.contents) {
            if (seasonsDetail.contents.length > 1) {
              this.props.history.push(`/series/${data.url || data.seasonsAssetId}`);
            } else {
              this.props.history.push(`/vod/${seasonsDetail.contents[0].contentId}`);
            }
          }
        }
        this.requestFlag = true;
      }).catch(err => {
        this.requestFlag = true;
      })
    }
  }
  searchInputMouseEnter() {
    $tv.requestFocus(this.searchInput.current);
  }
  searchInputFocus() {
    this.setState({
      isFocus: true,
    })
    this.input.focus();
  }
  searchInputBlur() {
    this.setState({
      isFocus: false,
    })
    this.input.blur();
    if (this.state.inputValue && this.state.inputValue !== this.state.currentValue) {
      this.querySearch()
    }
  }
  searchInputClick() {
    if (this.state.inputValue && this.state.inputValue !== this.state.currentValue) {
      this.querySearch()
    }
  }
  handleClickItem(propData, childData) {
    setStorage("seriesInputValue", this.state.inputValue);
    if (propData) {
      switch (propData.id) {
        case GLOBAL.MEDIA_TYPE.VOD:
          setStorage("seriesFocalElements", childData.id);
          this.props.history.push(`/vod/${childData.id}`);
          break;
        case GLOBAL.MEDIA_TYPE.SERIES:
          setStorage("seriesFocalElements", childData.seasonsAssetId);
          this.getSeasonsDetail(childData);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
          setStorage("seriesFocalElements", childData.assetId);
          this.props.history.push(`/liveEventDetail/${childData.assetId}`);
          break;
      }
    }
  }
  renderRecommend(data, id, style) {
    return <Column data={data} key={id} id={id} showTitle={true} itemStyle={style} click={(propData, childData) => {
      this.handleClickItem(propData, childData)
    }} />
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }

  render() {
    const { vodSearchList, liveEventSearchList, seriesSearchList, isSearch } = this.state;
    return (
      <div className="search_contenter" onWheel={e => this.pageWheel(e)}>
        <div className="search_title_box">
          <img src={this.state.searchIcon.default} alt="" />
          <div className="search_title"> {this.props.t('Search.title')}</div>
        </div>

        <div className="searchBox" ref={this.searchInput} focusable id='searchInput'>
          <img className="searchIcon" src={this.state.atvSearchIcon.default} alt="" />
          <input className={`searchInput ${this.state.isFocus ? 'searchInput_focus' : ''}`} ref={input => (this.input = input)} placeholder={this.props.t('Search.input')} type="input" onChange={e => this.changeInput(e)} value={this.state.inputValue} />
        </div>
        <div className="emptyText">{this.props.t('Search.resultEmpty')}</div>
        <div className="result_box" style={{ display: vodSearchList.length ? 'block' : 'none' }}>
          <div className='searchResultTitle'> {this.props.t('Search.vod')}</div>
          {
            this.renderRecommend(vodSearchList, 'vod', GLOBAL.RECOMMEND_STYLE.HORIZONTAL)
          }
        </div>
        <div className="result_box" style={{ display: seriesSearchList.length ? 'block' : 'none' }}>
          <div className='searchResultTitle'>{this.props.t('tag.series')}</div>
          {
            this.renderRecommend(seriesSearchList, 'series', GLOBAL.RECOMMEND_STYLE.HORIZONTAL)
          }
        </div>
        <div className="result_box" style={{ display: liveEventSearchList.length ? 'block' : 'none' }}>
          <div className='searchResultTitle'> {this.props.t('tag.liveEvent')}</div>
          {
            this.renderRecommend(liveEventSearchList, 'liveevent', GLOBAL.RECOMMEND_STYLE.HORIZONTAL)
          }
        </div>
        <Empty show={vodSearchList.length == 0 && seriesSearchList.length == 0 && liveEventSearchList.length == 0 && isSearch} />
        {/* <div className="result_box" style={{ display: liveSearchList.length? 'block' : 'none' }}>
          <div className="searchResultTitle">{this.props.t('Search.liveTitle')}</div>
              {
                this.renderRecommend(liveSearchList, 'live', GLOBAL.RECOMMEND_STYLE.HORIZONTAL)
              }
      
        </div> */}
      </div >
    );
  }
});
