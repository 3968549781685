import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style/player.scss';

class ShakaPlayer extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.player = null; // 初始化播放器实例变量
    this.eventListeners = {}; // 用于存储事件监听器引用
    this.state = {
      isLoading: true, // 用于控制加载状态的显示
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    if (shaka.Player.isBrowserSupported()) {
      // eslint-disable-next-line
        shaka.polyfill.installAll();
    }
    this.destroyPlayer().then(() => {
      this.setState({ isLoading: true }); // 显示加载状态
      this.initPlayer();    // 初始化新播放器
    }); // 销毁旧播放器
    
    // if (this.props.src !== nextProps.src) {
    //   this.destroyPlayer(); // 销毁旧播放器
    //   this.setState({ isLoading: true }); // 显示加载状态
    //   this.initPlayer();    // 初始化新播放器
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((this.props.src !== nextProps.src)) {
      // 销毁旧播放器
      this.destroyPlayer().then(() => {
        if (nextProps.src) {
          this.setState({ isLoading: true }, () => {
            this.initPlayer();    // 初始化新播放器
          }); // 显示加载状态
        }
      }); 
      
      
    }
  }

  componentWillUnmount() {
    this.destroyPlayer(); // 在组件卸载时销毁播放器

  }


  initPlayer() {
    if (window.shaka && this.videoRef.current) {
      this.player = new window.shaka.Player(this.videoRef.current);
      
      // 配置自定义请求头
      this.addLicenseRequestHeaders();

      // 配置 DRM license server URL
      console.log(this.props.licenseServerUrl)
      if (this.props.licenseServerUrl) {
        this.player.configure({
          drm: {
            servers: {
              'com.widevine.alpha': this.props.licenseServerUrl,
            },
            retryParameters: {
              baseDelay: 100,
              maxAttempts: 3
            }
          },
          streaming: {
            bufferBehind: 40,
            bufferingGoal: 20,
            retryParameters: {
              maxAttempts: 3,
              baseDelay: 100,
            }
          },
          manifest: {
            retryParameters: {
              baseDelay: 100,
              maxAttempts: 3,
            },
          },
          abr: {
            enabled: true
          }
        });
      }

      // 为所有可监听事件添加监听器
      this.addShakaEventListeners();

      // 加载视频资源
      this.player.load(this.props.src).then(() => {
        this.setState({ isLoading: false }); // 隐藏加载状态
        console.log('The video has now been loaded!');
      }).catch((error) => {
        this.setState({ isLoading: false }); // 隐藏加载状态
        this.onError(error);
      });
    }
  }

  destroyPlayer() {
    
    return new Promise((resolve, reject) => {
      if (this.player) {
        this.player.destroy().then(() => {
          this.player = null;
          console.log(9999999)
          if (this.props.onEvent) {
            this.props.onEvent('destroyed'); // 通过回调函数抛出事件
          }
          resolve();
        }).catch((error) => {
          this.onError(error);
          resolve();
        });
      } else {
        resolve();
      }
    })
  }

  // 添加自定义请求头
  addLicenseRequestHeaders() {
    if (this.player) {
      this.player.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (type === window.shaka.net.NetworkingEngine.RequestType.LICENSE) {
          // 在这里添加自定义请求头
          // request.headers['Custom-Header'] = 'CustomValue';
          // 你可以通过 this.props.customHeaders 来接收外部传入的头部信息
          // console.log(this.props.customHeaders)
          if (this.props.customHeaders) {
            Object.keys(this.props.customHeaders).forEach((key) => {
              request.headers[key] = this.props.customHeaders[key];
            });
          }
        }
      });
    }
  }

  // 获取当前播放时间
  currentTime = (targetTimeInSeconds) => {
    if (targetTimeInSeconds !== undefined) {
      // 获取目标时间范围
      const seekRange = this.player.seekRange();
      console.log(seekRange, targetTimeInSeconds)
      // 将目标时间限制在有效范围内（避免超出媒体总时长）
      const targetTime = Math.min(Math.max(seekRange.start, targetTimeInSeconds), seekRange.end);
      // 使用 seekRange.start 来设置播放位置
      this.videoRef.current.currentTime = targetTime;
    } else {
      return this.videoRef.current ? parseInt(this.videoRef.current.currentTime) : 0;
    }
    // return this.videoRef.current ? this.videoRef.current.currentTime : 0;
  }

  // 获取总时长
  duration = () => {
    if (this.player && this.player.getManifest()) {
        return parseInt(this.player.getManifest().presentationTimeline.getDuration());
    }
    return this.videoRef.current ? parseInt(this.videoRef.current.duration) : 0;
  }

  // 获取分辨率列表
  getResolutionList = () => {
    if (this.player) {
      const variantTracks = this.player.getVariantTracks().filter(track => track.type == 'variant');
      return variantTracks.map(track => ({
        value: track.height,
        label: track.height + 'P',
        active: track.active
      }));
    }
    return [];
  }

  // 获取音轨列表
  getAudioTrackList = () => {
    if (this.player) {
      const audioTracks = this.player.getAudioLanguagesAndRoles();
      return audioTracks.map(track => ({
        language: track.language,
        label: track.language.toUpperCase(),
        active: track.role == "main" ? true : false
      }));
    }
    return [];
  }

  // 切换分辨率
  switchResolution = (height) => {
    if (this.player) {
      const variantTracks = this.player.getVariantTracks();
      const track = variantTracks.find(track => track.height === height);
      if (track) {
        this.player.selectVariantTrack(track, true);
      }
    }
  }

  // 切换音轨
  switchAudioTrack = (language) => {
    if (this.player) {
      const audioTracks = this.player.getAudioLanguagesAndRoles();
      const track = audioTracks.find(track => track.language === language);
      if (track) {
        this.player.selectAudioLanguage(track.language, 'variant');
      }
    }
  }

  // 添加事件监听器，避免重复触发
  addShakaEventListeners() {
    const events = [
      'error', 'buffering', 'adaptation', 'trackschanged', 'abrstatuschanged',
      'emsg', 'manifestparsed', 'loading', 'loaded', 'unloading', 'timeupdate',
      'variantchanged', 'drmSessionUpdate', 'loadeddata'
    ];

    events.forEach((event) => {
      // 移除之前的监听器，避免重复触发
      if (this.eventListeners[event]) {
        this.player.removeEventListener(event, this.eventListeners[event]);
      }

      // 绑定新的监听器
      this.eventListeners[event] = (e) => this.handleShakaEvent(event, e);
      this.player.addEventListener(event, this.eventListeners[event]);
    });
    const nativeEvents = ['timeupdate', 'pause', 'ended', 'loadeddata', 'loaded', 'loading', 'playing'];
    nativeEvents.forEach((event) => {
      if (this.eventListeners[event]) {
        this.videoRef.current.removeEventListener(event, this.eventListeners[event]);
      }
      // 绑定新的监听器
      this.eventListeners[event] = (e) => this.handleShakaEvent(event, e);
      this.videoRef.current.addEventListener(event, this.eventListeners[event]);
    })
  }

  handleShakaEvent = (eventName, event) => {
    // this.setPlaybackRate(1.5)
    // console.log(this.player.getMediaElement().playbackRate)
    // console.log(`Shaka Player Event: ${eventName}`, event);
    switch(eventName) {
      case 'loading':
        this.setState({ isLoading: true }); // 隐藏加载状态
        break;
      case 'loaded':
        this.setState({ isLoading: false }); // 隐藏加载状态
        break;
    }
    if (this.props.onEvent) {
      this.props.onEvent(eventName, event); // 通过回调函数抛出事件
    }
  };

  /**
   * 设置播放速率
   * @param {*} val 0.5,1,1.5,2
   */
  setPlaybackRate = (val) => {
    this.player.getMediaElement().playbackRate = val;
  }

  play = () => {
    console.log(this.videoRef.current)
    try {
      this.videoRef.current.play();
    } catch(err) {
      console.log(err)
    }
  }

  pause = () => {
    this.videoRef.current.pause();
  }

  onErrorEvent = (event) => {
    this.onError(event.detail);
  };

  onError = (error) => {
    console.error('Error code', error.code, 'object', error);
    if (this.props.onEvent) {
      this.props.onEvent('error', error); // 通过回调函数抛出事件
    }
    
  };

  render() {
    return (
      <div className='player-wrapper'>
        {this.state.isLoading && <div className="loading-icon"></div>}
        <video
          ref={this.videoRef}
          className='shaka-player'
          controls={false}
          autoPlay
        />
      </div>
    );
  }
}

// 定义 propTypes 进行参数类型检查
ShakaPlayer.propTypes = {
  src: PropTypes.string.isRequired,              // 必需的字符串类型，视频资源的 URL
  licenseServerUrl: PropTypes.string,            // 可选的字符串类型，DRM 许可证服务器的 URL
  customHeaders: PropTypes.object,               // 可选的对象类型，自定义请求头
  onEvent: PropTypes.func                        // 可选的函数类型，事件回调函数
};

export default ShakaPlayer;
