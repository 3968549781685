import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, setStorage, removeStorage, formatVodDuration, keycodeHandle, calculateAge, getUUID, getFocusedElement } from "../../util/util";
import Column from '../../components/column/Column';
import SignIn from '../../components/signIn/SignIn';
import SendCodeOfBirthday from '../../components/sendCodeOfBirthday/SendCodeOfBirthday';
import SendCodeOfEmail from '../../components/sendCodeOfEmail/SendCodeOfEmail';
import ToolTipBox from '../../components/toolTipBox/ToolTipBox';
import UpdatePin from '../../components/updatePin/UpdatePin';
import PinBox from '../../components/pinBox/PinBox';
import Dialog from '../../components/dialog/Dialog';
import GLOBAL from "../../util/global"
import $request from '../../api';
import './style/Vod.scss';

export default withTranslation()(class NewVod extends Component {
  signInGetMsg = (value) => {
    this.setState({
      isSignIn: value,
    });
    $tv.requestFocus(this.vodSave.current);
  };
  // 生日验证
  SendCodeOfBirthdayGetMsg = (params) => {
    this.setState({
      birthday: params.birthday,
      isSendCodeOfBirthday: params.isShow,
    }, () => {
      this.verifyAge(params.birthday, this.state.vodContent)
    });
  };
  // 发送邮箱验证码
  SendCodeOfEmailGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      isSendCodeOfEmail: params.isShow,
    }, () => {
      this.setState({
        isPinBox: true
      });
    });
  };
  // 18+  PIN 提示
  toolTipBoxGetMsg = (value, isBack) => {
    this.setState({
      isToolTipBox: value,
    }, () => {
      if (this.state.toolTipType == "pin") {
        this.setState({
          isSendCodeOfEmail: true,
        });
      } else {
        if (isBack) {
          window.history.back();
        }
      }
    });
  };
  // 验证pin码
  verifyPinGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      verifyPinPass: params.verifyPinPass,
      isPinBox: params.isShow,
    }, () => {
      if (params.pinBoxStatus == 1) {
        if (this.state.verifyPinPass) {
          if (this.state.isVerifyParent_play) {
            this.props.history.push(`/vodPlayer/${this.state.contentId}/${this.state.playType}/${this.state.playContentId}`);
          } else {
            // location.href = "package.html";
          }
        }
      } else {
        if (params.updateState == "success") {
          this.setState({
            isUpdatePin: true,
            parentControlPin: params.pinNum,
            updateState: params.updateState,
          });
          let userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem(
            "userInfo")) : {};
          userInfo.parentControlPin = params.pinNum;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          setTimeout(() => {
            this.setState({
              isVerifyParent_play: true,
              isUpdatePin: false,
              parentControlPin: "",
              isPinBox: true
            });
          }, 1500)
        } else {
          //warning
          this.setState({
            isUpdatePin: true,
            parentControlPin: "",
            updateState: params.updateState,
          });
          setTimeout(() => {
            this.setState({
              isUpdatePin: false,
            });
          }, 1500)
        }
        this.setState({
          pinBoxStatus: 1,
        });
      }
    });
  };
  constructor(props) {
    super(props);
    this.vodBrief = React.createRef();
    this.vodPlay = React.createRef();
    this.vodSave = React.createRef();
    this.categoryList = React.createRef();
    this.photoFull = React.createRef();
    this.recordFocusEl = null; // 记录聚焦节点
    this.state = {
      vodContentBgImg: require("../../assets/images/icon/home_bgvideo_shadow_bg.png"),
      vodPlay: require("../../assets/images/icon/vod_play.png"),
      vodBrief: require("../../assets/images/icon/vod_brief.png"),
      vodSave: require("../../assets/images/icon/vod_save.png"),
      isVodCollect: true,
      flag: 1,
      contentId: this.props.match.params.contentId, // 请求参数
      vodContent: {},
      categories: [],
      contentGradeDto: [],
      trailersData: [],
      photosData: [],
      episodesData: [],
      titbitsData: [],
      recommondData: [],
      isUnfoldDesc: false,
      videoType: "tag.movie",
      toolTipType: "",
      isSignIn: false,
      isToolTipBox: false,
      isSendCodeOfBirthday: false,
      isPinBox: false,
      verifyPinPass: false, //校验pin码是否通过
      birthday: "",
      isUpdatePin: false,
      updateState: "success",
      parentControlPin: "",
      age: "",
      isVerifyParent_play: false,
      pinBoxStatus: 1,
      isSendCodeOfEmail: false,
      email: "",
      playContentId: "",
      isPhotoFull: false,
      photosDataUrl: "",
      playType: GLOBAL.PLAY_TYPE.VOD,
      showBirthdaySetDialog: false, // 是否显示设置生日弹窗
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    this.getVodInfo();
    // 播放按钮
    this.vodPlay.current.addEventListener('onFocus', this.vodPlayFocus.bind(this));
    this.vodPlay.current.addEventListener('click', this.vodPlayClick.bind(this));
    this.vodPlay.current.addEventListener("mouseenter", this.vodPlayMouseEnter.bind(this));
    // 简介按钮
    this.vodBrief.current.addEventListener('onFocus', this.vodBriefFocus.bind(this));
    this.vodBrief.current.addEventListener('click', this.vodBriefClick.bind(this));
    this.vodBrief.current.addEventListener("mouseenter", this.vodBriefMouseEnter.bind(this));
    // 收藏按钮
    this.vodSave.current.addEventListener('onFocus', this.vodSaveFocus.bind(this));
    this.vodSave.current.addEventListener('click', this.vodSaveClick.bind(this));
    this.vodSave.current.addEventListener("mouseenter", this.vodSaveMouseEnter.bind(this));
    keycodeHandle({ exit: this.vodKeyDown, quit: this.vodKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.match.params.contentId !== this.state.contentId) {
      this.setState({
        contentId: newProps.match.params.contentId
      }, () => {
        window.location.reload()
      });
    }
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    $tv.distanceToCenter = true;
    clearTimeout(this.pageWheelTime);
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  vodKeyDown = () => {
    if (this.state.isVerifyParent_play) {
      this.setState({
        isVerifyParent_play: false,
      })
    } else if (this.state.isUnfoldDesc) {
      this.setState({
        isUnfoldDesc: false,
      }, () => {
        $tv.requestFocus(this.vodBrief.current);
      });
    } else if (this.state.isPinBox) {
      this.setState({
        isPinBox: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isSignIn) {
      this.setState({
        isSignIn: false,
      }, () => {
        if (this.state.isLivePlay) {
          $tv.requestFocus(this.vodPlay.current);
        } else {
          $tv.requestFocus(this.vodSave.current);
        }
      });
    } else if (this.state.isSendCodeOfBirthday) {
      this.setState({
        isSendCodeOfBirthday: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isSendCodeOfEmail) {
      this.setState({
        isSendCodeOfEmail: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isToolTipBox) {
      this.setState({
        isToolTipBox: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isUpdatePin) {
      this.setState({
        isUpdatePin: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isPhotoFull) {
      if (document.getElementById(this.state.photosDataUrl)) {
        $tv.requestFocus(document.getElementById(this.state.photosDataUrl));
      }
      this.setState({
        photosDataUrl: "",
        isPhotoFull: false
      });
    } else {
      window.history.back();
    }
  }
  // 获取点播详情
  getVodInfo() {
    $request.boApi.vod.getVodInfo({
      mac: getUUID(),
      contentId: this.state.contentId
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let vodContent = res.data.contentInfo;
        let playContentId = vodContent.contentId;
        if (vodContent.episodes) {
          vodContent.episodes.forEach(item => {
            if (vodContent.playProgress && vodContent.playSort && (item.sort == vodContent.playSort)) {
              playContentId = item.contentId;
            }
          })
        }
        this.setState({
          vodContent: vodContent,
          categories: vodContent.categories,
          playContentId: playContentId,

        }, () => {
          this.getCategoryItemEls();
        });
        if (vodContent.type === GLOBAL.MEDIA_TYPE.SERIES) {
          this.setState({
            videoType: "tag.series"
          })
        }
        if (vodContent.contentGradeDto && vodContent.contentGradeDto.name) {
          this.setState({
            contentGradeDto: vodContent.contentGradeDto.name.split(",")
          })
        }
        //判断是否收藏
        if (vodContent.isFavorites) {
          this.setState({
            flag: 0,
            vodSave: require("../../assets/images/icon/vod_saved.png"),
          });
        } else {
          this.setState({
            flag: 1,
            vodSave: require("../../assets/images/icon/vod_save.png"),
          });
        }
        //连续剧  播放 contentId 获取详情的ID type： vod， auth： 点击单个里面的contentId
        if (vodContent.episodes && vodContent.episodes.length > 0) {
          this.setState({
            episodesData: vodContent.episodes,
          })
        }
        //有预告片 播放 contentId 获取详情的ID type： trailer， auth： 点击单个里面的contentId
        if (vodContent.trailers && vodContent.trailers.length > 0) {
          this.setState({
            trailersData: vodContent.trailers,
          })
        }
        //花絮 播放 contentId 获取详情的ID type： titbit， auth： 点击单个里面的contentId
        if (vodContent.titbits && vodContent.titbits.length > 0) {
          this.setState({
            titbitsData: vodContent.titbits,
          })
        }
        //有剧照 预览
        if (vodContent.screenShot && vodContent.screenShot.length > 0) {
          this.setState({
            photosData: vodContent.screenShot,
          }, () => {
            this.getPhotoFullEls();
          })
        }
        this.getVodRecommend(vodContent.guid);
      }
    })
  }
  // 焦点记忆
  focusMemory() {
    if (getStorage("vodFocalElements") && document.getElementById(getStorage("vodFocalElements"))) {
      $tv.requestFocus(document.getElementById(getStorage("vodFocalElements")));
      removeStorage("vodFocalElements");
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0); // 滚动条返回顶部
        if (this.vodPlay && this.vodPlay.current) {
          $tv.requestFocus(this.vodPlay.current);
        }
        
      }, 100);
    }
  }

  //有剧照 预览方法添加
  getPhotoFullEls() {
    this.photoFull.current.addEventListener('click', this.photoFullClick.bind(this));
    this.photoFull.current.addEventListener('right', () => {
      $tv.requestFocus(this.photoFull.current);
      let currentIndex = 0;
      this.state.photosData.forEach((item, index) => {
        if (item.url == this.state.photosDataUrl) {
          currentIndex = index;
        }
      })
      if (currentIndex < this.state.photosData.length - 1) {
        this.setState({
          photosDataUrl: this.state.photosData[currentIndex + 1].url
        })
      }
    });
    this.photoFull.current.addEventListener('left', () => {
      $tv.requestFocus(this.photoFull.current);
      let currentIndex = 0;
      this.state.photosData.forEach((item, index) => {
        if (item.url == this.state.photosDataUrl) {
          currentIndex = index;
        }
      })
      if (currentIndex != 0) {
        this.setState({
          photosDataUrl: this.state.photosData[currentIndex - 1].url
        })
      }
    });
    this.photoFull.current.addEventListener('up', () => {
      $tv.requestFocus(this.photoFull.current);
    });
    this.photoFull.current.addEventListener('down', () => {
      $tv.requestFocus(this.photoFull.current);
    });
  }
  // 获取点播推荐 点播详情、系列详情 、事件直播详情
  getVodRecommend(contentId) {
    $request.boApi.content.getRelated({
      mac: getUUID(),
      contentId: contentId
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        this.setState({
          recommondData: res.data.contents,
        })
      }
      this.focusMemory();
    })
  }

  // 分类方法添加
  getCategoryItemEls() {
    // 分类按钮
    this.categoryList.current.childNodes.forEach((el, index) => {
      el.addEventListener('down', this.categoryItemDown.bind(this));
      el.addEventListener('click', this.categoryItemClick.bind(this, index));
      el.addEventListener("mouseenter", this.categoryItemMouseEnter.bind(this, index));
    });
  }
  // 收藏
  clickFavorites() {
    // 1 收藏  0 取消收藏
    $request.boApi.user.updataFavorites({
      assetId: this.state.contentId,
      type: GLOBAL.MEDIA_TYPE.VOD,
      action: this.state.flag
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.USER_NOT_LOGIN) {
        this.setState({
          isSignIn: true,
        });
        return;
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (this.state.flag == 1) {
          this.setState({
            flag: 0,
            vodSave: require("../../assets/images/icon/vod_saved.png"),
          });
        } else {
          this.setState({
            flag: 1,
            vodSave: require("../../assets/images/icon/vod_save.png"),
          });
        }
      }
    })
  };
  // 校验年龄方法
  verifyAge(time, contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!contentDetail.contentGradeDto) {
      return
    }
    if (calculateAge(time) < contentDetail.contentGradeDto.age) {
      this.setState({
        isToolTipBox: true,
        toolTipType: "mature"
      });
      return
    }
    if (contentDetail.contentGradeDto.parentControl == "Y") {
      this.setState({
        email: user.email,
      });
      if (user.parentControlPin == GLOBAL.CONFIG.DEFAULT_PIN) {
        this.setState({
          isToolTipBox: true,
          toolTipType: "pin"
        });
      } else {
        this.setState({
          isVerifyParent_play: true,
          isToolTipBox: false,
          isPinBox: true
        });
      }
      return
    }
    this.setState({
      isVerifyParent_play: true,
    });
    this.props.history.push(`/vodPlayer/${this.state.contentId}/${this.state.playType}/${this.state.playContentId}`);
  }

  verfiyContentGradeDto(contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (contentDetail.loginLimit == "N") {
      return true;
    }
    if (contentDetail.loginLimit == "Y" && !user) {
      this.setState({
        isSignIn: true,
      });
      return
    }
    if (contentDetail.contentGradeDto) {
      if (user.birthday) {
        this.verifyAge(user.birthday, contentDetail)
        return false
      }
      this.recordFocusEl = getFocusedElement();
      this.setState({
        isSendCodeOfBirthday: true,
        // showBirthdaySetDialog: true,
        age: contentDetail.contentGradeDto.age,
      });
      return false
    }
    return true
  }

  /**
   * 设置生日
   */
  handleSetBirthday() {
    $request.boApi.user.getMemberInfo({
      mac: getUUID()
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data) {
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, res.data.userInfo);
          this.setState({
            showBirthdaySetDialog: false
          }, () => {
            if (this.recordFocusEl) {
              $tv.requestFocus(this.recordFocusEl);
            }
          })
        }
      }
    })
  }

  categoryItemMouseEnter(params) {
    $tv.requestFocus(this.categoryList.current.childNodes[params]);
  }
  // 分类方法点击
  categoryItemClick(params) {
    // console.log(111111, this.state.categories[params])
    
    // return
    setStorage("vodFocalElements", `category${params}`);
    this.props.history.push(`/category/${this.state.categories[params].id}/0/${GLOBAL.MEDIA_TYPE.VOD}/Y/${this.state.categories[params].name}`);
  }
  categoryItemDown() {
    $tv.requestFocus(this.vodPlay.current);
  }
  // 简介获焦
  vodBriefFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  vodBriefMouseEnter() {
    $tv.requestFocus(this.vodBrief.current);
  }

  vodBriefClick() {
    this.setState({
      isUnfoldDesc: true,
    });
  }

  // 播放获焦
  vodPlayFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  vodPlayMouseEnter() {
    $tv.requestFocus(this.vodPlay.current);
  }
  // 点击播放
  vodPlayClick() {
    if (!this.state.isVerifyParent_play) {
      if (!this.verfiyContentGradeDto(this.state.vodContent)) {
        return
      } else {
        this.props.history.push(`/vodPlayer/${this.state.contentId}/${this.state.playType}/${this.state.playContentId}`);
      }
    }
  }

  // 收藏获焦
  vodSaveFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  vodSaveMouseEnter() {
    $tv.requestFocus(this.vodSave.current);
  }
  vodSaveClick() {
    // 收藏
    this.clickFavorites();
  }
  // 剧照全屏
  photoFullClick() {
    if (document.getElementById(this.state.photosDataUrl)) {
      $tv.requestFocus(document.getElementById(this.state.photosDataUrl));
    }
    this.setState({
      photosDataUrl: "",
      isPhotoFull: false
    });

  }
  columnClick(propData, childData, id) {
    if (id === "photos") {
      this.setState({
        photosDataUrl: childData.url,
        isPhotoFull: true
      });
      $tv.requestFocus(this.photoFull.current);
      return
    } else if (id === "recommend") {
      if (childData.type === GLOBAL.MEDIA_TYPE.LIVE_EVENT) {
        setStorage("vodFocalElements", childData.url || childData.contentId);
        this.props.history.push(`/liveEventDetail/${childData.url || childData.contentId}`);
      } else {
        setStorage("vodFocalElements", childData.contentId);
        this.props.history.push(`/vod/${childData.contentId}`);
      }
      return
    } else {
      setStorage("vodFocalElements", childData.contentId);
      this.setState({
        playContentId: childData.contentId,
        playType: id
      }, () => {
        this.vodPlayClick()
      });
    }
  }
  renderRecommend(data, id, style, isDisplayContentTitle) {
    return <Column data={data} key={id} id={id} showTitle={isDisplayContentTitle} itemStyle={style} click={(propData, childData) => {
      this.columnClick(propData, childData, id)
    }} />
  }

  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }


  render() {
    const { contentGradeDto, categories, episodesData, trailersData, titbitsData, photosData, recommondData } = this.state;
    return (
      <div className='vod_content' onWheel={e => this.pageWheel(e)}>
        <img className="root_bg_img" style={{ display: !this.state.isUnfoldDesc ? 'block' : 'none' }} src={this.state.vodContent.poster} alt="" />
        <div className="root_bg" style={{ display: this.state.isUnfoldDesc ? 'block' : 'none' }}></div>
        <img className="vod_content_bg" src={this.state.vodContentBgImg.default} alt="" />
        <div className="vod_container">
          <div className="vod_title">{this.state.vodContent.name}</div>
          <div className="type-age_tag--list">
            <div className="type-tag">{this.props.t(this.state.videoType)}</div>
            <div className="age-tag_list">
              {
                contentGradeDto.map((item, index) => (
                  <div className="age-tag" key={index}>{item}</div>
                ))
              }
            </div>
          </div>
          <div className="vod_frist_info">
            <div className="titleInfo" style={{ display: this.state.vodContent.onlineyear ? 'flex' : 'none' }}>
              <div className="vod_frist_info_item">{this.state.vodContent.onlineyear}</div>
              <div className="vod_frist_info_item_line"></div>
            </div>
            <div className="titleInfo" style={{ display: this.state.vodContent.area ? 'flex' : 'none' }}>
              <div className="vod_frist_info_item">{this.state.vodContent.area}</div>
              <div className="vod_frist_info_item_line"></div>
            </div>
            <div className="titleInfo" style={{ display: this.state.vodContent.duration ? 'flex' : 'none' }}>
              <div className="vod_frist_info_item">{formatVodDuration(this.state.vodContent.duration)}</div>
            </div>
            <div className="category-list" ref={this.categoryList} >
              {
                categories.map((item, index) => (
                  <div className="category-item" id={`category${index}`} key={item.id} focusable>{item.name}</div>
                ))
              }
            </div>
          </div>
          <div className="vod_genre" style={{ display: this.state.vodContent.director ? 'flex' : 'none' }}>
            <div className="vod_genre_title">{this.props.t('vod.Director')}</div>
            <div className="vod_genre_text">{this.state.vodContent.director ? this.state.vodContent.director.split(',').join(", ") : ""}</div>
          </div>
          <div className="vod_genre" style={{ display: this.state.vodContent.actor ? 'flex' : 'none' }}>
            <div className="vod_genre_title">{this.props.t('vod.Starring')}</div>
            <div className="vod_genre_text">{this.state.vodContent.actor ? this.state.vodContent.actor.split(',').join(", ") : ""}</div>
          </div>
          <div className={`vod_intro ${this.state.isUnfoldDesc ? 'vod_intro_fullScreen' : ''}`}>{this.state.vodContent.description}</div>
          <div className="vod_control" style={{ display: !this.state.isUnfoldDesc ? 'inline-block' : 'none' }} >
            <div ref={this.vodPlay} className="vod_play" id='vodPlayBtn' focusable>
              <img src={this.state.vodPlay.default} alt="" className="vod_play_icon" />
              <div className="vod_play_text">{this.props.t('vod.Play')}</div>
            </div>
            <div ref={this.vodBrief} className="unfold-desc" id='unfoldDesc' focusable>
              <img src={this.state.vodBrief.default} alt="" />
            </div>
            <div ref={this.vodSave} className="vod_collect" id='vodCollectBtn' style={{ display: this.state.isVodCollect ? 'flex' : 'none' }} focusable>
              <img src={this.state.vodSave.default} alt="" className="vod_play_icon" />
            </div>
          </div>
        </div>
        <div className="wrapper" style={{ display: !this.state.isUnfoldDesc ? 'block' : 'none' }}>
          <div className="content detail-list_content" style={{ display: episodesData.length ? 'block' : 'none' }} >
            {
              this.renderRecommend(episodesData, GLOBAL.PLAY_TYPE.VOD, GLOBAL.RECOMMEND_STYLE.HORIZONTAL, 'Y')
            }
          </div>
          <div className="content detail-list_content" style={{ display: trailersData.length ? 'block' : 'none' }}>
            <div className='category_title'> {this.props.t('vod.Trailers')}</div>
            {
              this.renderRecommend(trailersData, GLOBAL.PLAY_TYPE.TRAILER, GLOBAL.RECOMMEND_STYLE.HORIZONTAL, 'N')
            }
          </div>
          <div className="content detail-list_content" style={{ display: titbitsData.length ? 'block' : 'none' }}>
            <div className='category_title'> {this.props.t('vod.Titbits')}</div>
            {
              this.renderRecommend(titbitsData, GLOBAL.PLAY_TYPE.TIDBITS, GLOBAL.RECOMMEND_STYLE.HORIZONTAL, 'N')
            }
          </div>
          <div className="content detail-list_content" style={{ display: photosData.length ? 'block' : 'none' }}>
            <div className='category_title'> {this.props.t('vod.Photos')}</div>
            {
              this.renderRecommend(photosData, 'photos', GLOBAL.RECOMMEND_STYLE.HORIZONTAL, 'N')
            }
          </div>
          <div className="content" style={{ display: recommondData.length ? 'block' : 'none' }}>
            <div className='category_title'> {this.props.t('vod.Recommend')}</div>
            {
              this.renderRecommend(recommondData, 'recommend', GLOBAL.RECOMMEND_STYLE.VERTICAL)
            }
          </div>
        </div>
        <div className="photoFull" style={{ display: this.state.isPhotoFull ? 'block' : 'none' }} ref={this.photoFull} focusable  >
          <div className="photoBox"  focusable>
            <img src={this.state.photosDataUrl} alt="" />
          </div>
        </div>
        <SignIn isSignIn={this.state.isSignIn} signInGetMsg={this.signInGetMsg} />
        <SendCodeOfBirthday isSendCodeOfBirthday={this.state.isSendCodeOfBirthday} age={this.state.age} SendCodeOfBirthdayGetMsg={this.SendCodeOfBirthdayGetMsg} />
        <SendCodeOfEmail isSendCodeOfEmail={this.state.isSendCodeOfEmail} email={this.state.email} SendCodeOfEmailGetMsg={this.SendCodeOfEmailGetMsg} />
        <ToolTipBox isToolTipBox={this.state.isToolTipBox} toolTipType={this.state.toolTipType} toolTipBoxGetMsg={this.toolTipBoxGetMsg} />
        <UpdatePin isUpdatePin={this.state.isUpdatePin} updateState={this.state.updateState} parentControlPin={this.state.parentControlPin} />
        <PinBox isPinBox={this.state.isPinBox} pinBoxStatus={this.state.pinBoxStatus} verifyPinGetMsg={this.verifyPinGetMsg} />
        <Dialog show={ this.state.showBirthdaySetDialog } age={Number(this.state.age)} confirm={() => {
          this.handleSetBirthday()
        }} />
      </div>
    );
  }
});
