import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './style/SelectorPopup.scss';
import GLOBAL from '../../../../util/global';
import projectConfig from '../../../../util/config'
import { getStorage, getElementHeightWithMargin } from '../../../../util/util'

class SelectorPopup extends Component {
    constructor(props) {
        super(props);
        this.buttonsRef = [];
        this.scrollWrapperRef = React.createRef();
        this.selectorListRef = [];
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.handleButtonListenr();
    }

    componentWillUnmount() {
        this.handleRemoveButtonListenr();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.show) {
            let selectorList = this.selectorListRef;
            if (nextProps.data && nextProps.data.length) {
                if (nextProps.value) {
                    nextProps.data.forEach(item => {
                        if (nextProps.value == item.value) {
                            $tv.requestFocus(this.selectorListRef[0]);
                        }
                    })
                } else {
                    $tv.requestFocus(selectorList[0]);
                }
            }
        }
    }

    handleButtonListenr() {
        let selectorList = this.selectorListRef;
        let scorllEl = this.scrollWrapperRef.current;
        selectorList.forEach((el, index) => {
            el.addEventListener("click", () => {
                if (this.props.click) {
                    console.log(this.props.data[index])
                    this.props.click(this.props.data[index]);
                }
            });
            el.addEventListener("left", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("up", () => {
                if (index <= 0) {
                    $tv.requestFocus(el);
                    scorllEl.style.top = 0;
                } else {
                    $tv.requestFocus(selectorList[index - 1]);
                    let elHeight = getElementHeightWithMargin(el);
                    if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                        scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
                    }
                }
            })
            el.addEventListener("down", () => {
                if (index == selectorList.length - 1) {
                    $tv.requestFocus(el);
                } else {
                    $tv.requestFocus(selectorList[index + 1]);
                }
                if (index >= 3) {
                    if (el) {
                        let elHeight = getElementHeightWithMargin(el);
                        scorllEl.style.top = -(index * elHeight) + 'px';
                    }
                }
            })
        })
    }

    handleRemoveButtonListenr() {
        let selectorList = this.selectorListRef;
        selectorList.forEach(el => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { })
            el.removeEventListener("right", () => { })
            el.removeEventListener("up", () => { })
            el.removeEventListener("down", () => { })
        })
    }


    render() {
        const { show, title, data, value, isSelected } = this.props;
        return (
            <div className='selector-popup' style={{ display: show ? 'flex' : 'none' }}>
                <div className='popup-left'>

                </div>
                <div className='popup-right'>
                    <div className='title'>{title}</div>
                    <div className='selector-box'>
                        <div className='selector-wrapper' ref={this.scrollWrapperRef}>
                            {
                                data && data.length ? (
                                    data.map((item, index) => (
                                        <div className='selector-item' id={'selectorItem' + item.value} ref={(el) => {this.selectorListRef[index] = el}} key={index} focusable>
                                            <div className='icon' style={{display: isSelected ? 'block' : 'none'}}>
                                                {
                                                    (value == item.value && isSelected)  ? (<img src={require('../../../../assets/images/icon/atv_setting_icon_select_blue.png').default} />) : null
                                                }
                                            </div>
                                            <div className='item-info'>
                                                <div className='item-name'>{item.label}</div>
                                            </div>
                                        </div>
                                    ))
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SelectorPopup.propTypes = {
    click: PropTypes.func, // 点击事件
    show: PropTypes.bool, // 是否显示
    title: PropTypes.string, // 标题
    value: PropTypes.any, // 选中值
    isSelected: PropTypes.bool, // 是否是选择器
    data: PropTypes.array // 选择项数据列表 [{label: 'On', value: '1'}]
}

export default withTranslation()(SelectorPopup);