import axios from 'axios'; 
import { getRequestToken, getStorage, removeStorage, getSecondTime, getLocalTimeZone, sortAscii, deepClone, getSign, getUUID } from "../util/util.js";
import projectConfig from "../util/config.js";
import GLOBAL from '../util/global.js';
import md5 from 'js-md5';

// 创建axios实例
const service = axios.create({
    timeout: 60000, 
});

service.defaults.withCredentials = false;

let boapi = "/boapi/v1"; 
let collection = "/collection/v1"; 
let boapiAuth = projectConfig.adapi;
let tenantCode = projectConfig.tenantCode; 
let apiId = projectConfig.apiId; 
let apiKey = projectConfig.apiKey; 
let requestToken = getRequestToken();

let token = getStorage(GLOBAL.STORAGE_KEYS.TOKEN) || "";
let mac = getStorage(GLOBAL.STORAGE_KEYS.UUID) || getUUID();
let lang = getStorage(GLOBAL.STORAGE_KEYS.LANG) || "en_US";
let timeStamp;
let deviceModel = getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) ? getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) : GLOBAL.DEVICE_TYPE.LG_TV;
let deviceType = "tv";
let boapiSign;
let adBaseURL = projectConfig.adapi;
if (getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) && getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) == GLOBAL.DEVICE_TYPE.SAMSUNG_TV) {
    adBaseURL = projectConfig.adBaseURL;
}
const requestCache = new Map(); // 用于缓存请求

// 缓存有效时长，单位为毫秒
const CACHE_EXPIRY_TIME = 10000; // 10秒

service.interceptors.request.use(config => {
    const cacheKey = `${config.url}_${JSON.stringify(config.data)}`; // 根据请求URL和参数生成缓存Key

    if (requestCache.has(cacheKey)) {
        const cached = requestCache.get(cacheKey);
        const now = Date.now();

        // 如果缓存存在且未过期，则使用缓存
        if (now - cached.timestamp < CACHE_EXPIRY_TIME) {
            const source = axios.CancelToken.source();
            config.cancelToken = source.token;
            source.cancel(`Request is duplicate within ${CACHE_EXPIRY_TIME / 1000} seconds: ${config.url}`);
            return Promise.resolve(cached.response);
        }
    }

    timeStamp = getSecondTime();
    if (getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO)) {
        let sysInfomation = getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO);
        config.headers['X-areaCode'] = sysInfomation.ADAreaCode ? sysInfomation.ADAreaCode : 1643084;
    } else {
        config.headers['X-areaCode'] = timeStamp;
    }
    config.headers['X-version'] = projectConfig.version;
    config.headers['X-mac'] = mac;
    config.headers['X-languageCode'] = lang;
    config.headers['X-tenantCode'] = tenantCode;
    config.headers['X-apiId'] = apiId;
    config.headers['X-apiKey'] = apiKey;
    config.headers['X-requestToken'] = requestToken;
    config.headers['X-deviceType'] = deviceType;
    config.headers['X-timestamp'] = timeStamp;
    config.headers['X-timeZone'] = "GMT" + getLocalTimeZone();
    config.headers['X-deviceModel'] = deviceModel;
    config.headers['Access-Control-Allow-Origin'] = "*";
    
    if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
        token = getStorage(GLOBAL.STORAGE_KEYS.TOKEN);
        config.headers['X-token'] = token;
        config.headers['X-profileId'] = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).profileId;
    }

    let paramsTemp = {
        'X-version': projectConfig.version,
        'X-deviceType': deviceType,
        'X-languageCode': lang,
        'X-tenantCode': tenantCode,
        'X-apiId': apiId,
        'X-requestToken': requestToken,
        'X-timestamp': timeStamp,
        "X-mac": mac
    };

    let ascParams = sortAscii(paramsTemp);
    let bodyData = deepClone(config.data);
    let requestUrl = boapi + config.url;
    if (config.baseURL.indexOf(collection) != -1) {
        requestUrl = collection + config.url;
    }
    let noSign = requestUrl + "?" + ascParams + `&X-apiKey=${apiKey}` + "&bodySign=" + md5(sortAscii(bodyData)).toUpperCase();
    let newSign2 = md5(noSign).toUpperCase();

    if (config.baseURL == adBaseURL) {
        if (getSign()) {
            config.headers['X-sign'] = getSign();
        }
    } else {
        config.headers['X-sign'] = newSign2;
    }
    boapiSign = newSign2;
    return config;
});

service.interceptors.response.use(res => {
    const cacheKey = `${res.config.url}_${JSON.stringify(res.config.data)}`;
    const now = Date.now();

    // 缓存请求结果及当前时间
    requestCache.set(cacheKey, { response: res, timestamp: now });

    if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.USER_EXPIRATION) {
        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
            removeStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
            removeStorage(GLOBAL.STORAGE_KEYS.TOKEN);
        }
    }
    return Promise.resolve(res);
}, error => {
    if (axios.isCancel(error)) {
        console.log(error.message); // 输出取消原因
    } else {
        console.log("http发生错误～～", error);
    }
    return Promise.reject(error);
});

/**
 * Post请求公用方法
 * @param { url } options API地址
 * @param { apiType } options 0、boapi 1、广告api 2、统计api
 * @param { isUploadFile } options  是否是上传文件
 * @param { apiVersion } options API版本
 * @param { apiPrefix } options API前缀
 * @param { params }  上传参数
 */
export function axiosPostRequest(options, params) {
    var data = params ? deepClone(params) : {};
    return new Promise((resolve, reject) => {
        if (options.apiType && options.apiType == 1) {
            service.defaults.baseURL = adBaseURL;
        } else if (options.apiType && options.apiType == 2) {
            service.defaults.baseURL = projectConfig.collectionApi + collection;
        } else {
            boapi = options.apiPrefix ? `${options.apiPrefix}${options.apiVersion}` : `/boapi/v1`;
            service.defaults.baseURL = projectConfig.boapi + boapi;
            if (boapiSign) {
                service.defaults.headers['X-sign'] = boapiSign;
            }
        }
        service({
            method: 'post',
            url: options.url,
            data: data,
        }).then(resp => {
            resolve(resp);
        }).catch(error => {
            reject(error);
        });
    });
}
