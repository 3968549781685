import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, keycodeHandle, formatDate, formatDecimals } from "../../util/util";
import GLOBAL from "../../util/global";
import Empty from '../../components/empty/Empty';
import $request from '../../api';
import './style/TransactionHistory.scss';


export default withTranslation()(class NewTransactionHistory extends Component {

  constructor(props) {
    super(props);
    this.tranListBox = React.createRef();
    this.tranMenu = React.createRef();
    this.state = {
      subscriptionHistory: require("../../assets/images/icon/atv_subscription_history.png"),
      addedFocus: require("../../assets/images/icon/payment_icon_added_focus.png"),
      addedNormal: require("../../assets/images/icon/payment_icon_added_normal.png"),
      iconRight: require("../../assets/images/icon/right.png"),
      down: require("../../assets/images/icon/down.png"),
      up: require("../../assets/images/icon/up.png"),
      currentTab: 0,
      isEmptyText: false,
      orderList: [],
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    this.getOrderList();
    // 订单类型方法
    this.tranMenu.current.childNodes.forEach((item, index) => {
      item.addEventListener("mouseenter", this.tranMenuMouseEnter.bind(this, index));
    });
    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    // const currentIsScreen = nextProps.isScreen;
    // const currentErrorFullscreen = nextProps.errorFullscreen;
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    clearTimeout(this.pageWheelTime);
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  seriesKeyDown = () => {
    window.history.back();
  }
  // 订单栏目方法添加
  getTranListBoxEls() {
    // 订单栏目方法
    this.tranListBox.current.childNodes.forEach((item, index) => {
      item.addEventListener('click', this.tranListItemClick.bind(this, index));
      item.addEventListener('onFocus', this.tranListItemFocus.bind(this, index));
      item.addEventListener('onBlur', this.tranListItemBlur.bind(this, index));
      item.addEventListener("mouseenter", this.tranListItemMouseEnter.bind(this, index));
    });
  }
  //订单列表
  getOrderList() {
    $request.boApi.user.order({
      mac: getStorage("uuid"),
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let orderList = res.data.orders;
        if (orderList.length) {
          orderList.forEach((item) => {
            item.isExpand = false;
            item.isFocus = false;
          })
          this.setState({
            isEmptyText: false,
            orderList: orderList,
          }, () => {
            this.getTranListBoxEls();
          });
        } else {
          this.setState({
            isEmptyText: true,
          });
        }
      }
    })
  };
  tranMenuMouseEnter(params) {
    $tv.requestFocus(this.tranMenu.current.childNodes[params]);
  }
  tranListItemFocus(params) {
    const currentOrderList = this.state.orderList;
    if (currentOrderList[params].isFocus === false) {
      currentOrderList[params].isFocus = true;
      this.setState({
        orderList: currentOrderList,
      });
    }
  }
  tranListItemBlur(params) {
    const currentOrderList = this.state.orderList;
    if (currentOrderList[params].isFocus === true) {
      currentOrderList[params].isFocus = false;
      this.setState({
        orderList: currentOrderList,
      });
    }
  }
  tranListItemMouseEnter(params) {
    const currentOrderList = this.state.orderList;
    if (currentOrderList[params].isFocus === false) {
      currentOrderList[params].isFocus = true;
      this.setState({
        orderList: currentOrderList,
      });
    }
    $tv.requestFocus(this.tranListBox.current.childNodes[params]);
  }
  tranListItemClick(params) {
    const currentOrderList = this.state.orderList;
    if (currentOrderList[params].isExpand === true) {
      currentOrderList[params].isExpand = false;
      this.setState({
        orderList: currentOrderList,
      });
    } else {
      currentOrderList[params].isExpand = true;
      this.setState({
        orderList: currentOrderList,
      });
    }
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }
  render() {
    const { orderList } = this.state;
    return (
      <div className="tran-content" onWheel={e => this.pageWheel(e)}>
        <div className="tran-header">
          <img src={this.state.subscriptionHistory.default} alt="" />
          <div className="tran_title" name="Transaction.History">{this.props.t('Transaction.History')}</div>
        </div>
        <div className="tran_contenter">
          <div className="tran_menu" ref={this.tranMenu}>
            <div className={`tran_menu_item ${this.state.currentTab === 0 ? 'tran_item_current' : ''}`} focusable>
              <span>{this.props.t('Transaction.currentPackage')}</span>
              <span className="menu-icon">
                <img src={this.state.iconRight.default} alt="" />
              </span>
            </div>
            {/* <div className={`tran_menu_item ${this.state.currentTab === 1 ? 'tran_item_current' : ''}`} focusable>
              <span >{this.props.t('Transaction.tvod')}</span>
              <span className="menu-icon">
                <img src={this.state.iconRight.default} alt="" />
              </span>
            </div> */}
          </div>
          <div className="tran_content">
            <Empty show={this.state.isEmptyText} />
            {/* <div className="emptyText" style={{ display: this.state.isEmptyText ? 'block' : 'none' }} >{this.props.t('Transaction.empty')}</div> */}
            <div className="tran_list_wrap">
              <div className="tran_list_box" ref={this.tranListBox}>
                {
                  orderList.map((item, index) => (
                    <div className={`tran-list_item ${item.isExpand ? 'current' : ''} ${item.isFocus ? 'tran-list_item_focus' : ''}`} focusable key={index}>
                      <div className="tran-item_header">
                        <div className="tran-item_header--left">
                          <span className="icon-vip">
                            <img src={item.isCurrent || !item.isExpired ? this.state.addedFocus.default : this.state.addedNormal.default} alt="" />
                          </span>
                          <span className="product-name">{item.productName}</span>
                        </div>
                        <div className="tran-item_header--right">
                          <span className="price">{item.currency} {formatDecimals(item.price)}</span>
                          <span className="icon-arrow">
                            <img src={this.state.down.default} className="icon-down" alt="" />
                            <img src={this.state.up.default} className="icon-up" alt="" />
                          </span>
                        </div>
                      </div>
                      <div className="tran-list_body">
                        <div className="tran-body_item">
                          <span className="tran-body_item--label" name="Transaction.effectiveFrom">{this.props.t('Transaction.effectiveFrom')}</span>
                          <span className="tran-body_item--text">{formatDate(item.effectiveTimeUTC, 'yyyy-MM-dd hh:mm')}</span>
                        </div>
                        <div className="tran-body_item">
                          <span className="tran-body_item--label" name="Transaction.validUntil"> {this.props.t('Transaction.validUntil')}</span>
                          <span className="tran-body_item--text">{formatDate(item.expiredTimeUTC, 'yyyy-MM-dd hh:mm')}</span>
                        </div>
                        <div className="tran-body_item">
                          <span className="tran-body_item--label" name="Transaction.purchaseTime"> {this.props.t('Transaction.purchaseTime')}</span>
                          <span className="tran-body_item--text">{formatDate(item.orderedTimeUTC, 'yyyy-MM-dd hh:mm')}</span>
                        </div>
                        <div className="tran-body_item">
                          <span className="tran-body_item--label" name="Transaction.orderNumber"> {this.props.t('Transaction.orderNumber')}</span>
                          <span className="tran-body_item--text">{item.orderNo}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
