// BasicRoute.js
import React from 'react';
import { HashRouter } from 'react-router-dom';
import App from './App';

const BasicRoute = () => (
  <HashRouter>
    <App />
  </HashRouter>
);

export default BasicRoute;
