import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import './style/Empty.scss';

class Empty extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        
    }


    componentWillUnmount() {
       
    }

    render() {
        const { show } = this.props;
        return (
            <div className='empty' style={{ display: show ? 'flex' : 'none' }}>
               <div className='empty-wrapper'>
                    <img src={require('../../assets/images/icon/empty.png').default} />
               </div>
               {/* <div className='empty-desc'>sdf</div> */}
            </div>
        )
    }
}

Empty.propTypes = {
    show: PropTypes.bool.isRequired, // 必传，是否显示
    text: PropTypes.string, // 弹窗描述
}

export default Empty;