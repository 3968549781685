import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { $tv } from 'react-tv-focusable';
import $request from '../../api';
import './style/SendCodeOfEmail.scss';
import { getStorage, keycodeHandle } from "../../util/util";
import GLOBAL from '../../util/global';
import projectConfig from '../../util/config';

export default withTranslation()(class SendCodeOfEmail extends Component {
    constructor(props) {
        super(props);
        this.sendCode = React.createRef();
        this.numKeyBoard = React.createRef();
        this.state = {
            isShow: false,  //是否显示弹框 
            numKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'del'], // 键盘数字
            iconClearImg: require("../../assets/images/icon/atv_search_icon_clear.png"),
            iconDeleteImg: require("../../assets/images/icon/atv_search_icon_delete.png"),
            warning: "",
            email: "",
            inputValue: "",
            isHasSend: false,
            isSendEmail: true,
            user: getStorage("userInfo"),
            sendEmailCodeBtnText: this.props.t("login.sendCode")
        };
    }

    componentDidMount() {
        this.selectNum();
        // 检查按钮
        this.sendCode.current.addEventListener('click', this.sendCodeClick.bind(this));
        this.sendCode.current.addEventListener('right', this.sendCodeRight.bind(this));
        this.sendCode.current.addEventListener('left', this.sendCodeMove.bind(this));
        this.sendCode.current.addEventListener('up', this.sendCodeMove.bind(this));
        this.sendCode.current.addEventListener('down', this.sendCodeMove.bind(this));
        this.sendCode.current.addEventListener("mouseenter", this.sendCodeMove.bind(this));
        this.keyDownHandle();
        
        // this.sendCode.current.addEventListener("keydown", this.keyDownHandle.bind(this));
    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isSendCodeOfEmail = nextProps.isSendCodeOfEmail;
        const email = nextProps.email;
        if (isSendCodeOfEmail !== this.props.isSendCodeOfEmail) {
            this.setState({
                email: email,
                isShow: isSendCodeOfEmail,
                isHasSend: false,
                isSendEmail: true,
                inputValue: "",
                warning: "",
                sendEmailCodeBtnText: this.props.t("login.sendCode")
            });

            if (this.pinCodeVerfCodeTimer) {
                clearInterval(this.pinCodeVerfCodeTimer);
                this.pinCodeVerfCodeTimer = null;

            }
            if (isSendCodeOfEmail) {
                $tv.requestFocus(this.sendCode.current);
            }
            
        }
    }
    componentWillUnmount() {
        if (this.pinCodeVerfCodeTimer) {
            clearInterval(this.pinCodeVerfCodeTimer)
        }
        this.setState = () => false;
    }
    // 数字按钮绑定事件
    selectNum() {
        this.numKeyBoard.current.childNodes.forEach((item, index) => {
            item.addEventListener('click', this.numKeyBoardClick.bind(this, index));
            item.addEventListener('right', this.numKeyBoardRight.bind(this, index));
            item.addEventListener('left', this.numKeyBoardLeft.bind(this, index));
            item.addEventListener('up', this.numKeyBoardUp.bind(this, index));
            item.addEventListener('down', this.numKeyBoardDown.bind(this, index));
            item.addEventListener("mouseenter", this.numKeyBoardMouseEnter.bind(this, index));
        });
    }
    // 数字按钮点击
    numKeyBoardClick(params) {
        let inputValue = "";
        if (params !== 9 && params !== 11) {
            if (params === 10) {
                params = -1;
            }
            if (this.state.inputValue.length < 6) {
                inputValue = this.state.inputValue + (params + 1);
                this.setState({
                    inputValue: inputValue,
                });
                if (this.state.inputValue.length == 6) {
                    this.changePinNumber(this.state.inputValue)
                }
            }

        } else {
            if (params === 9) {
                this.setState({
                    inputValue: "",
                });
            } else if (params === 11) {
                if (this.state.inputValue.length) {
                    inputValue = this.state.inputValue.slice(0, -1);
                    this.setState({
                        inputValue: inputValue,
                    });
                    return
                }
            }
        }

    }

    numKeyBoardMouseEnter(params) {
        $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
    }
    numKeyBoardRight(params) {
        if (params % 3 == 2) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 1]);
        }
    }
    numKeyBoardLeft(params) {
        if (params % 3 == 0) {
            $tv.requestFocus(this.sendCode.current);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 1]);
        }
    }
    numKeyBoardUp(params) {
        if (params < 3) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 3]);
        }
    }
    numKeyBoardDown(params) {
        if (params > 8) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 3]);
        }
    }
    // 发送点击
    sendCodeClick() {
        if (this.state.isSendEmail) {
            this.getCodeBtn()
            $tv.requestFocus(this.sendCode.current);
        }
    }
    sendCodeMove() {
        $tv.requestFocus(this.sendCode.current);
    }
    sendCodeMove() {
        $tv.requestFocus(this.sendCode.current);
    }
    sendCodeMove() {
        $tv.requestFocus(this.sendCode.current);
    }
    sendCodeRight() {
        $tv.requestFocus(this.numKeyBoard.current.childNodes[0])
    }
    /**
     * 监听遥控数字按键
     */
    keyDownHandle() {
        document.addEventListener('keydown', (e) => {
            if (this.props.isSendCodeOfEmail) {
                if (document.getElementById(`key${GLOBAL.KEY_CODE[e.keyCode]}`)) {
                    $tv.requestFocus(document.getElementById(`key${GLOBAL.KEY_CODE[e.keyCode]}`));
                    document.getElementById(`key${GLOBAL.KEY_CODE[e.keyCode]}`).click();
                }
            }
        }, true)
    }
    // 发送验证码
    getCodeBtn() {
        this.setState({
            isSendEmail: false,
        });
        if (this.pinCodeVerfCodeTimer) {
            return
        }
        $request.boApi.user.getVcode({
            account: this.state.email,
            useType: 2,
            gatewayType: "",
        }).then(res => {
            if (res.data.resultCode === 0) {
                var count = 60;
                this.setState({
                    warning: "",
                    isHasSend: true,
                    sendEmailCodeBtnText: count + 's'
                });
                if (this.pinCodeVerfCodeTimer) {
                    clearInterval(this.pinCodeVerfCodeTimer);
                    this.pinCodeVerfCodeTimer = null;
                }
                this.pinCodeVerfCodeTimer = setInterval(() => {
                    count = count - 1;
                    if (count == 0) {
                        clearInterval(this.pinCodeVerfCodeTimer);
                        this.pinCodeVerfCodeTimer = null;
                        this.setState({
                            isSendEmail: true,
                            sendEmailCodeBtnText: this.props.t("login.sendCode")
                        });
                    } else {
                        this.setState({
                            isSendEmail: false,
                            sendEmailCodeBtnText: count + 's'
                        });
                    }
                }, 1000);
            } else {
                //防止连续点击发送
                setTimeout(() => {
                    this.setState({
                        isSendEmail: true,
                    });
                }, 3000)
            }
        });
    }

    // 验证验证码
    changePinNumber(verCode) {
        if (!verCode) {
            this.setState({
                warning: this.props.t("toast.haveSend"),
            });
            return
        }
        $request.boApi.user.checkVcode({
            account: this.state.email,
            code: verCode,
            gatewayType: "",
        }).then(res => {
            if (res.data.resultCode === 0) {
                this.setState({
                    isHasSend: false,
                    isShow: false,
                    inputValue: "",
                    sendEmailCodeBtnText: this.props.t("login.sendCode")
                });
                if (this.pinCodeVerfCodeTimer) {
                    clearInterval(this.pinCodeVerfCodeTimer);
                    this.pinCodeVerfCodeTimer = null;
                }
                this.props.SendCodeOfEmailGetMsg({ pinBoxStatus: 2, isShow: false });
            } else if (res.data.resultCode == 1021) {
                this.setState({
                    warning: this.props.t("toast.WrongCode"),
                });
            } else if (res.data.resultCode == 1035) {
                this.setState({
                    warning: this.props.t("toast.codeExpired"),
                });
            } else if (res.data.resultCode == 1036) {
                this.setState({
                    warning: this.props.t("toast.codeInvalid"),
                });
            } else if (res.data.resultCode == 1065) {
                this.setState({
                    warning: this.props.t("toast.wrongCode5"),
                });
            } else {
                this.setState({
                    warning: this.props.t("toast.WrongCode"),
                });
            }
        })
    }
    render() {
        return (
            <div className="sendCodeOfEmail" id='sendEmailBox' style={{
                display: this.state.isShow ? 'block' : 'none'
            }}>
                <div className="sendCodeOfEmail_title">
                    {this.props.t("pin.phoneVerificationCode")}
                </div>
                <div className="email_div">
                    <div className="email_content">
                        <div className="pin_content_phone">
                            <div className="emailTile"> {this.props.t("user.Email")}</div>
                            <div className="emailNumber userEmail" >{this.state.email}</div>
                        </div>
                        <div className="codeHint">{this.props.t("login.inputVerification")}</div>
                        <div className="sendEmailCode">
                            <span className="sendEmailCode_btn" focusable id='sendEmailCodeBtn' ref={this.sendCode} >
                                <span className="sendEmailCode_btn_text">{this.state.sendEmailCodeBtnText}</span>
                            </span>
                            <input value={this.state.inputValue} disabled type="input" />
                            <div style={{
                                display: this.state.warning ? 'block' : 'none'
                            }} className="codeWarning">{this.state.warning}</div>
                        </div>
                        <div className="hasSend" style={{
                            display: this.state.isHasSend ? 'block' : 'none'
                        }}> {this.props.t("toast.codeSendEmail")}</div>
                    </div>
                    <div className='email_pin_div'>
                        <div className="email_pin_right" ref={this.numKeyBoard}>
                            {this.state.numKey.map((item) => (
                                <div key={item} id={'key' + item} focusable>
                                    {item <= 9 ? item : ''}
                                    <img style={{ display: item === "clear" ? 'block' : 'none' }} src={this.state.iconClearImg.default} alt="" />
                                    <img style={{ display: item === "del" ? 'block' : 'none' }} src={this.state.iconDeleteImg.default} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
})