import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { $tv } from 'react-tv-focusable';
import './style/ToolTipBox.scss';

export default withTranslation()(class ToolTipBox extends Component {
    constructor(props) {
        super(props);
        this.cancel = React.createRef();
        this.state = {
            isShow: false, //是否显示弹框
            toolTipType: "",// 提示类型 18+ :mature  PIN :pin
            warningImg: require("../../assets/images/icon/Warning.png"),
        };

    }

    componentDidMount() {
        this.cancel.current.addEventListener('click', this.cancelClick.bind(this));
        this.cancel.current.addEventListener('right', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('left', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('up', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('down', this.cancelMove.bind(this));
        this.cancel.current.addEventListener("mouseenter", this.cancelMove.bind(this));
    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isToolTipBox = nextProps.isToolTipBox;
        const toolTipType = nextProps.toolTipType;
        if (isToolTipBox !== this.props.isToolTipBox) {
            this.setState({
                isShow: isToolTipBox,
            });
            if (isToolTipBox) {
                $tv.requestFocus(this.cancel.current);
            }
        }
        if (toolTipType !== this.props.toolTipType) {
            this.setState({
                toolTipType: toolTipType,
            });
        }

    }
    // 取消点击
    cancelClick() {
        this.setState({
            isShow: false,
        });
        this.props.toolTipBoxGetMsg(false, this.state.toolTipType === 'mature' ? 1 : 0);
    }
    cancelMove() {
        $tv.requestFocus(this.cancel.current);
    }
    render() {
        return (
            <div className="mature_Dialog" style={{ display: this.state.isShow ? 'block' : 'none' }} >
                <div className="mature_warning">
                    <img src={this.state.warningImg.default} alt="" />
                </div>
                <div className="mature_title">
                    {/* <span id="limitAage"></span> */}
                    <span> {this.state.toolTipType === 'mature' ? this.props.t("contentWarning.subtitle") : this.props.t("adultContent.initialPINCode")}</span>
                </div>
                <div className="matureHint"> {this.state.toolTipType === 'mature' ? this.props.t("contentWarning.prompt") : this.props.t("adultContent.initialPINCodeDesc")}</div>
                <div className="mature_btn">
                    <div className="mature_btn_Item" ref={this.cancel} focusable>
                        {this.state.toolTipType === 'mature' ? this.props.t("contentWarning.goBack") : this.props.t("user.setNewPIN")}
                    </div>
                </div>
            </div>
        )
    }
})