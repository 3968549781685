
import { axiosPostRequest } from "../request/http.js";
const boApi = {
    recommend: {
        /**
        * 据推荐位Id获取内容列表
        * @param { recommendId } data 推荐位Id
        * @param { page } data 当前页，第一页从0开始
        * @param { pageSize } data 页数量显示最大数
        */
        getRecommendContentsById: (data) => {
            return axiosPostRequest({
                url: `/recommend/contents`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取推荐位
         * @param { mac } data 
         * @param { menuId } data
         * @returns 
         */
        getRecommend: (data) => {
            return axiosPostRequest({
                url: `/menu/recommend`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    menu: {
        /**
         * 获取菜单列表
         * @param { isRequiredChildNode } data 
         * @param { language } data 
         * @param { mac } data 
         * @param { menuId } data
         * @returns 
         */
        getMenuList: (data) => {
            return axiosPostRequest({
                url: `/menu/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    user: {
        /**
         * 获取用户信息
         * @param { mac } data mac地址
         * @param { sessionId } data 当前会话的sessionId
         */
        getMemberInfo: (data) => {
            return axiosPostRequest({
                url: `/member/info`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 账号注销
         * @param { memberId } data Y 会员ID
         * @param { password } data M 密码
         */
        cancelledMember: (data) => {
            return axiosPostRequest({
                url: `/member/cancelled`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 退出登录
         * @param { mac } data 
         * @param { version } data 
         * @returns 
         */
        logout: (data) => {
            return axiosPostRequest({
                url: `/auth/logout`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取二维码登录扫码结果
         */
        getInteractiveResult(data) {
            return axiosPostRequest({
                url: `/getInteractive/result`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取收藏列表
         * @param { page } data 
         * @param { pageSize } data
         * @param { type } data 
         * @returns 
         */
        getFavoriteList: (data) => {
            return axiosPostRequest({
                url: `/favorites/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取播放历史
         * @param { page } data 
         * @param { pageSize } data 
         * @param { type } data
         * @returns 
         */
        getPlayList: (data) => {
            return axiosPostRequest({
                url: `/play/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /** 
        * 删除播放记录
        * @param { type } data  媒体类型
        * @param { assetId } data 以contentId拼接的字符串
        * @returns 
        */
        deleteWatchHistory: (data) => {
            return axiosPostRequest({
                url: `/play2/delete`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
        * 取消收藏
        * @param { type } data  媒体类型
        * @param { assetId } data 以contentId拼接的字符串
        * @param { action } data  1 收藏  0 取消收藏(多个以逗号分隔)
        * @returns 
        */
        updataFavorites: (data) => {
            return axiosPostRequest({
                url: `/favorites/record`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 订单查询
         * @param { mac } data 
         * @returns 
         */
        order: (data) => {
            return axiosPostRequest({
                url: `/product/order`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取节目单预约列表
         * @param { type } data 类型 vod点播，live直播, liveevent,all 全部 
         * @param { pageSize } data 
         * @param { page } data 
         */
        getReservationList: (data) => {
            return axiosPostRequest({
                url: `/playlist/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 兑换码核销
         * @param { ticket } data 
         * @returns 
         */
        checkVoucherCode: (data) => {
            return axiosPostRequest({
                url: `/member/redemption`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /* 更新用户信息
         * @param { nickName } data 
         * @param { sex } data 
         * @param { birthday } data
         * @returns 
         */
        updateUserInfo: (data) => {
            return axiosPostRequest({
                url: `/member/update`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取消息列表
         * @param { mac } data 
         * @param { page } data 
         * @param { pageSize } data 
         * @returns 
         */
        getMessageList: (data) => {
            return axiosPostRequest({
                url: `/message/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /* 
         * 获取验证码
         *Usertype
        /*0 注册或绑定手机时
        *1 修改密码时
        *2 修改pin时
        *3 登录时超过5次
        * **/
        getVcode: (data) => {
            return axiosPostRequest({
                url: `/generate/verificationCode`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 更新消息状态
         * @param { mac } data 
         * @param { id } data 
         * @param { messageId } data 
         * @returns 
         */
        updateMessageStatus: (data) => {
            return axiosPostRequest({
                url: `/message/status/update`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
        * 校验验证码
        * account
        * code
        * /checkCode
        * **/
        checkVcode: (data) => {
            return axiosPostRequest({
                url: `/checkCode`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 更新所有消息状态
         * @param { mac } data 
         * @returns 
         */
        updateAllMessageStatus: (data) => {
            return axiosPostRequest({
                url: `/message/status/updateAll`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取未读消息数量
         * @param { mac } data 
         * @returns 
         */
        getUnreadMessageCount: (data) => {
            return axiosPostRequest({
                url: `/message/unread/count`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取用户设备列表
         * @param { mac } data 
         * @returns 
         */
        getUserDeviceList: (data) => {
            return axiosPostRequest({
                url: `/user/device/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 解除绑定设备
         * @param { unbindMac } data 需要解绑的Mac地址
         */
        unbindDevice: (data) => {
            return axiosPostRequest({
                url: `/device/unbinding`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    liveEvent: {
        /**
        * 获取当前事件直播列表
        * @param { date } data 查询日期（格式yyyy-MM-dd）
        * @param { categoryId } data 分类Id，多个逗号分隔传递
        */
        getLiveEventCurrentList: (data) => {
            return axiosPostRequest({
                url: `/liveevent/current/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 根据分类获取所有事件直播
         * @param { categoryId } data 分类Id
         * @param { startDate } data 开始日期（yyyy-MM-dd）
         * @param { endDate } data 结束日期（yyyy-MM-dd）
         * @param { page } data 当前页，第一页为1
         * @param { pageSize } data 页面大小
         */
        getLiveEventCategoryList: (data) => {
            return axiosPostRequest({
                url: `/liveevent/category/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取事件直播详情
         * @param { assetId } data 事件直播资产Id
         */
        getLiveEventDetail: (data) => {
            return axiosPostRequest({
                url: `/liveevent/detail`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取事件直播推荐列表
         * @param { categoryId } data 分类Id
         * @param { page } data 当前页，第一页为1
         * @param { pageSize } data 页面大小
         */
        getLiveEventRecommendList: (data) => {
            return axiosPostRequest({
                url: `/liveevent/recommend/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取事件直播时间
         * @param { assetId } data 事件直播资产ID
         */
        getLiveEventTime: (data) => {
            return axiosPostRequest({
                url: `/liveevent/time`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取直播分类列表
         * @param { categoryId } data 
         * @param { mediaType } data 
         * @returns 
         */
        getCategoryList: (data) => {
            return axiosPostRequest({
                url: `/content/category`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    vod: {
        /**
         * 获取系列详情
         * @param { mac } data Mac地址
         * @param { seasonsAssetId } data 系列assetId
         * @returns 
         */
        getSeasonsDetail: (data) => {
            return axiosPostRequest({
                url: `/seasons/detail`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取点播详情
         * @param { mac } data Mac地址
         * @param { contentId } data 内容contentId
         * @returns 
         */
        getVodInfo: (data) => {
            return axiosPostRequest({
                url: `/content/detail`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
    },
    content: {
        /**
         * 获取点播推荐内容
         * @param { mac } data Mac地址
         * @param { contentId } data 内容contentId
         * @returns 
         */
        getRelated: (data) => {
            return axiosPostRequest({
                url: `/recommend/related`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取直播分类菜单
         * @param { mac } data Mac地址
         * @param { categoryId } data 分类Id
         * @param { mediaType } data  媒体类型
         * @returns 
         */
        getLiveContent: (data) => {
            return axiosPostRequest({
                url: `/content/category`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * vod二级菜单下的推荐分类内容
         * @param { mac } data Mac地址
         * @param { categoryId } data 分类Id
         * @param { mediaType } data  媒体类型
         * @param { page } data 页数
         * @param { pageSize } data  条数
         * @returns 
         */
        getVodContentList: (data) => {
            return axiosPostRequest({
                url: `/content/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    system: {
        /**
         * 获取水印详细信息
         * @param { watermarkId } data 水印Id，在直播、点播、事件直播详情接口已经返回
         */
        getWatermarkInfo: (data) => {
            return axiosPostRequest({
                url: `/sys/watermark/info`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取系统信息
         * @param {*} data 
         */
        getSystemInfo: (data) => {
            return axiosPostRequest({
                url: `/sys/info`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取系统链接信息
         */
        getSysLinkInfo: (data) => {
            return axiosPostRequest({
                url: `/sys/linkInfo`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    search: {
        /**
         * 搜索
         * @param { watermarkId } data 水印Id，在直播、点播、事件直播详情接口已经返回
         */
        getSearch: (data) => {
            return axiosPostRequest({
                url: `/global/search`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    commont: {
        /**
         * 点播、直播节目单、事件直播预约接口
         * @param {*} data 
         */
        subscriptionReminder: (data) => {
            return axiosPostRequest({
                url: `/playlist/record`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    common: {
        /**
         * 播放鉴权
         * @param { type } data 
         * @param { playType } data 
         * @param { mac } data
         * @param { contentId } data  
         * @returns 
         */
        getPlayAuth: (data) => {
            return axiosPostRequest({
                url: `/play/auth`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
        * 获取播放鉴权Drm-token   
        * @param { contentId } data 
        * @param { contentType } data 
        * @param { drmType } data 
        * @returns 
        */
        getDrmToken: (data) => {
            return axiosPostRequest({
                url: `/play/auth/drm/token`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 播放记录上报
         * @param { assetId } data
         * @param { type } data 
         * @param { playId } data  
         * @param { playName } data 
         * @param { playSort } data
         * @param { playProgress } data
         */
        playRecord: (data) => {
            return axiosPostRequest({
                url: `/play/record`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 播放时长上报
         * @param { contentId } data 
         * @param { type } data 
         * @param { playCode } data 
         * @param { playDuration } data 
         * @returns 
         */
        playDuration: (data) => {
            return axiosPostRequest({
                url: `/play/duration`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 心跳检测
         * @param { mac } data 
         * @returns 
         */
        checkHeart: (data) => {
            return axiosPostRequest({
                url: `/play/duration`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 应用启动
         * @param { mac } data 
         * @param { type } data startupLogo
         * @returns 
         */
        startup: (data) => {
            return axiosPostRequest({
                url: `/startup/animation`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        }
    },
    product: {
        /**
         * 获取基础包
         * @param { mac } data 
         * @param { page } data
         * @param { pageSize } data
         * @returns 
         */
        getProductBaseList: (data) => {
            return axiosPostRequest({
                url: `/product/base/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取增值包
         * @param { mac } data 
         * @param { page } data
         * @param { pageSize } data
         * @returns 
         */
        getProductConsumeList: (data) => {
            return axiosPostRequest({
                url: `/product/consume/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 获取当前订阅包
         * @param { mac } data 
         * @returns 
         */
        getCurrentSubscribe: (data) => {
            return axiosPostRequest({
                url: `/product/current/subscribe`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 根据contentId获取产品包列表
         * @param { contentId } data 
         * @param { mac } data 
         * @param { type } data 
         * @param { eventAssetId } data 事件直播传
         * @returns 
         */
        getContentProductList: (data) => {
            return axiosPostRequest({
                url: `/content/product/list`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 产品包详情
         * @param { mac } data 
         * @param { productId } data 
         */
        getProductDetail: (data) => {
            return axiosPostRequest({
                url: `/product/detail`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
        /**
         * 根据产品ID获取内容
         * @param { productId } data 
         * @param { type } data
         * @param { pageSize } data
         * @param { page } data
         * @param { mac } data
         */
        getProductContentById: (data) => {
            return axiosPostRequest({
                url: `/product/content/byId`,
                apiVersion: '/v1',
                apiPrefix: '/boapi'
            }, data)
        },
    },
    payment: {
        /**
         * 取消自定续订
         * @param { orderId } data 
         * @param { payType } data 
         * @param { subscribeId } data 
         * @returns 
         */
        paymentUnsubPackage: (data) => {
            return axiosPostRequest({
                url: `/payment/unsub/package`,
                apiVersion: '/v1',
                apiPrefix: '/payment-gw'
            }, data)
        },
        /**
         * 创建订单
         * @param { packageId } data 
         * @param { payType } data 
         * @param { assetId } data 
         * @param { contentType } data 
         * @returns 
         */
        paymentOrderCheckout: (data) => {
            return axiosPostRequest({
                url: `/payment/order/checkout`,
                apiVersion: '/v1',
                apiPrefix: '/payment-gw'
            }, data)
        },
        /**
         * 获取订单结果
         * @param { orderId } data 
         * @returns 
         */
        paymentOrderCheck: (data) => {
            return axiosPostRequest({
                url: `/payment/order/check/${data.orderId}`,
                apiVersion: '/v1',
                apiPrefix: '/payment-gw'
            }, data)
        }
    }

}
export default boApi