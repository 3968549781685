/* eslint-disable */
// 节流
export function throttle(fn, delay) {
  let valid = true;
  return function () {
    if (!valid) {
      // 休息时间 暂不接客
      return false;
    }
    // 工作时间，执行函数并且在间隔期内把状态位设为无效
    valid = false;
    setTimeout(() => {
      console.log(fn)
      fn();
      valid = true;
    }, delay);
  };
}


/**
 * forEach for object
 */
export function forEachValue(obj, fn) {
  Object.keys(obj).forEach(key => fn(obj[key], key));
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}


export function isPromise(val) {
  return val && typeof val.then === 'function';
}

export function assert(condition, msg) {
  if (!condition) throw new Error(`[vuex] ${msg}`);
}



/**
 * @description 清理数组中的空元素，返回新数组
 * @param originalArr
 * @returns {Array}
 */
export function cleanArray(originalArr) {
  const newArray = [];
  for (let i = 0; i < originalArr.length; i++) {
    if (originalArr[i]) {
      newArray.push(originalArr[i]);
    }
  }
  return newArray;
}

/**
 * @description json对象转换成字符串
 * @param json
 * @returns {*}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map((key) => {
    if (json[key] === undefined) return '';
    // return encodeURIComponent(key) + '=' +
    //   encodeURIComponent(json[key])
    return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
  })).join('&');
}

/**
 * @description 获取url 后面的参数
 * @param url
 * @returns {{}}
 */
export function getQueryVariable(letiable) {
  let str = window.location.href; // 取得整个地址栏
  const num = str.indexOf('?');
  str = str.substr(num + 1); // 取得所有参数   stringlet.substr(start [, length ]
  const arr = str.split('&'); // 各个参数放到数组里
  for (let i = 0; i < arr.length; i++) {
    const pair = arr[i].split('=');
    if (pair[0] === letiable) {
      return pair[1];
    }
  }
  return false;
}

export function keycodeHandle(params) {
  window.document.onkeydown = eventHandler;
  try {
    let value = tizen.tvinputdevice.getSupportedKeys();
    console.log(value);
    tizen.tvinputdevice.registerKeyBatch(['MediaPlayPause', 'MediaRewind', 'MediaFastForward', 'MediaPlay', 'MediaPause', 'MediaStop', 'MediaTrackPrevious', 'MediaTrackNext']);
  } catch (error) {
    console.log("not support registerKeyBatch")
  }
  // eslint-disable-next-line
  try {
    if (webapis.appcommon && webapis.appcommon.registerKey) {
      webapis.appcommon.registerKey(['MediaPlayPause', 'MediaRewind', 'MediaFastForward', 'MediaPlay', 'MediaPause', 'MediaStop', 'MediaTrackPrevious', 'MediaTrackNext'])
    }
  } catch (error) {
    console.log("not support registerKey")
  }
  function eventHandler(e) {
    // eslint-disable-next-line
    params.up = params.up ? params.up : () => { };
    // eslint-disable-next-line
    params.down = params.down ? params.down : () => { };
    // eslint-disable-next-line
    params.left = params.left ? params.left : () => { };
    // eslint-disable-next-line
    params.right = params.right ? params.right : () => { };
    // eslint-disable-next-line
    params.click = params.click ? params.click : () => { };
    // 快进
    params.mediaFastForward = params.mediaFastForward ? params.mediaFastForward : () => { };
    // 快退
    params.mediaRewind = params.mediaRewind ? params.mediaRewind : () => { };
    // 上一集
    params.prev = params.prev ? params.prev : () => { };
    // 下一集
    params.next = params.next ? params.next : () => { };
    params.other = params.other ? params.other : () => { };
    params.exit = params.exit ? params.exit : () => { }
    params.quit = params.quit ? params.quit : () => { }
    console.log(e.keyCode);
    switch (e.keyCode) {
      case 10009:
      case 461:
      case 27:
        params.quit();
        break;
      // case 8:
      //   params.exit();
      // break;
      case 38:
        params.up();
        break;
      case 40:
        params.down();
        break;
      case 37:
      case 227:
        params.left();
        break;
      case 39:
      case 228:
        params.right();
        break;
      case 19:
      case 169:
      case 413:
        params.pause();
        break;
      case 415:
      case 179:
        params.play();
        break;
      case 13:
        params.click();
        break;
      case 412:
        params.mediaRewind();
        break;
      case 417:
        params.mediaFastForward();
        break;
      case 10232:
        params.prev();
        break;
      case 10233:
        params.next();
        break;
      default:
        params.other(e.keyCode);
        break;
    }
  }
}


//时间戳转换成日期
export function changeTime(time) {
  var date = new Date(time);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  return `${year}-${addZero(month)}-${addZero(day)}`
};

export function htmlDecodeByRegExp(str) {
  let temp = "";
  if (str.length == 0) { }
  temp = str.replace(/&amp;/g, "&");
  temp = temp.replace(/&lt;/g, "<");
  temp = temp.replace(/&gt;/g, ">");
  temp = temp.replace(/&nbsp;/g, " ");
  temp = temp.replace(/&#39;/g, "'");
  temp = temp.replace(/&quot;/g, '"');
  return temp;
}

export function addZero(num) {
  if (num <= 9) {
    return '0' + num
  }
  return num
};

//图片延迟加载
// function onLoad() {
//   $('img').lazyload();
// }
//背景图
function getBgImg() {
  // let backaground = localStorage.getItem('backgroundColor') || 'default';
  // if(backaground == "blue"){
  // 	$(".root_bg").css({
  // 		"background":"url(images/pageBg/blue.png) no-repeat"
  // 	})
  // }else if(backaground == "purple"){
  // 	$(".root_bg").css({
  // 		"background":"url(images/pageBg/purple.png) no-repeat"
  // 	})
  // }else{
  // 	$(".root_bg").css({
  // 		"background":"url(images/pageBg/default.png) no-repeat"
  // 	})
  // }
}
//背景图
// export function getBgImgHasInput() {
//   let backaground = localStorage.getItem('backgroundColor') || 'default';
//   if (backaground == "blue") {
//     $(".root_bg").css({
//       "background": "url(images/pageBg/blue.png) no-repeat"
//     })
//   } else if (backaground == "purple") {
//     $(".root_bg").css({
//       "background": "url(images/pageBg/purple.png) no-repeat"
//     })
//   } else {
//     $(".root_bg").css({
//       "background": "url(images/pageBg/default.png) no-repeat"
//     })
//   }
// }

// export function changeBgImg(imgUrl) {

//   if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(imgUrl)) {
//     $(".root").css({
//       "background": 'none'
//     });
//     // $(".root_bg").css({
//     //     "background": `url(${imgUrl})  no-repeat`
//     // });
//   } else {
//     $(".root").css({
//       "background": '#303030'
//     });
//     $(".root_bg").css({
//       "background": '#303030'
//     });
//   }

// }

//是否开启父母锁
export function isCheckParentalLock() {
  return true;
  //默认需要验证
  var parentalLock = localStorage.getItem('parentalLock') || 'on';
  return parentalLock == "on" ? true : false
}

export function sortAscii(obj) {
  let arr = new Array();
  let num = 0;
  for (let i in obj) {
    arr[num] = i;
    num++;
  }
  let sortArr = arr.sort();
  console.log()
  //let sortObj = {};    //完成排序值
  let str = ''; //自定义排序字符串
  for (let i in sortArr) {
    if (obj[sortArr[i]] !== '') {
      let data = '';
      if (typeof obj[sortArr[i]] == "object") {
        data = JSON.stringify(obj[sortArr[i]]).replace(/":/g, '=').replace(/"/g, '').replace(/,/g, ', ');
        // data = JSON.stringify(obj[sortArr[i]], null, 2).replace(/"([^"]+)":/g, '$1=');
        // data = JSON.stringify(obj[sortArr[i]]);
      } else {
        data = obj[sortArr[i]]
      }
      str += sortArr[i] + '=' + data + '&';
      //sortObj[sortArr[i]] = obj[sortArr[i]];
    }
  }
  //去除两侧字符串
  let char = '&'
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
  return str;
  //return sortObj;
}

//生成32位随机数
export function getRequestToken() {
  var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e',
    'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
  ];
  var nums = "";
  for (var i = 0; i < 32; i++) {
    var id = Math.round((Math.random() * 61));
    nums += chars[id];
  }
  return nums;
}
//排序的函数
export function Sort(obj) {
  var newkey = Object.keys(obj).sort();
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) { //遍历newkey数组
    newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对

  }
  return newObj; //返回排好序的新对象
}
//生成加密的参数x-sign
// export function createSign(path, bodyJson, headerList) {
//   let that = this;
//   var sb = '';
//   let signUrl = '';
//   let b = Sort(headerList);
//   for (let i in b) {
//     if (b[i] && i !== "X-apiKey" && "bodySign" !== i && i !== "X-sign") {
//       sb += i + "=" + b[i] + "&";
//     }
//   }
//   var link = '';
//   let c = Sort(bodyJson);
//   for (let i in c) {
//     if (c[i] !== '') {
//       link += i + "=" + c[i] + "&";
//     }
//   }
//   var index = link.lastIndexOf('&');
//   var link1 = link.substring(0, index);
//   // console.log('接口名称------', path)
//   // console.log('前端传参------', bodyJson)

//   var linkurl = $.md5(link1)

//   signUrl = path + "?" + sb + "X-apiKey=e1e499e5746f90191ec5261947055e0c" + '&bodySign=' + linkurl.toUpperCase();
//   // console.log('signUrl------', signUrl);
//   var sign = $.md5(signUrl)
//   let signlink = sign.toUpperCase();
//   return signlink;
// }

export function getSecondTime() {
  var timeStamp = getUTCTime(); //获取到毫秒的时间戳，精确到毫秒
  // timeStamp = timeStamp.substr(0, 10); //精确到秒
  return timeStamp;
}

export function getStorage(key) {
  if (localStorage.getItem(key)) {
    if (localStorage.getItem(key).indexOf('{') > -1 || localStorage.getItem(key).indexOf('[') > -1 || localStorage
      .getItem(key) == 'false' || localStorage.getItem(key) == 'true') {
      return JSON.parse(localStorage.getItem(key))
    } else {
      return localStorage.getItem(key)

    }
  } else {
    return ''
  }
}

/**
 * 删除localStorage统一方法封装
 * @param { 键名 } key
 */
export function removeStorage(key) {
  if (getStorage(key)) {
    localStorage.removeItem(key)
  }
}

export function formatUctDate(date) {
  return new Date(date).getUTCFullYear() + '-' + (new Date(date).getUTCMonth() + 1 >= 10 ? new Date(date)
    .getUTCMonth() + 1 : '0' + (new Date(date).getUTCMonth() + 1)) + '-' + (new Date(date).getUTCDate() >= 10 ?
      new Date(date).getUTCDate() : '0' + new Date(date).getUTCDate()) + ' ' + (new Date(date).getHours() >= 10 ?
        new Date(date).getHours() : '0' + new Date(date).getHours()) + ':' + (new Date(date).getMinutes() >= 10 ?
          new Date(date).getMinutes() : '0' + new Date(date).getMinutes())
}

export function formatUctDateDay(date) {
  return new Date(date).getUTCFullYear() + '-' + (new Date(date).getUTCMonth() + 1 >= 10 ? new Date(date)
    .getUTCMonth() + 1 : '0' + (new Date(date).getUTCMonth() + 1)) + '-' + (new Date(date).getUTCDate() >= 10 ?
      new Date(date).getUTCDate() : '0' + new Date(date).getUTCDate())
}

export function setStorage(key, value) {
  if (typeof value == "boolean" || typeof value != "string" || JSON.stringify(value).indexOf('{') > -1 || JSON
    .stringify(value).indexOf('[') > -1) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.setItem(key, value)
  }
}

export function getSign() {
  var sign = sessionStorage.getItem("sign");
  if (sign) {
    return sign
  } else {
    return "";
  }
}

//参考jsencrypt.js源码
var BI_RM = "0123456789abcdefghijklmnopqrstuvwxyz";

function int2char(n) {
  return BI_RM.charAt(n);
}

export function rsaDecrypt(PUBLIC_KEY) {
  let decrypt = new JSEncrypt();
  let PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIICegIBADANBgkqhkiG9w0BAQEFAASCAmQwggJgAgEAAoGBAJIcsQbnUNzE7Mcw\n22inz9gvEQTXjQxoYDV9qtEOSu4ObTbYPenF34iVzfZI2UuEmjd+ZAxBbLXNwKzp\nw194jVeBYAbRwP+QKAsSfDDI1zhkHf2qeY3EZfXjYBfAKVaKDO+CAyof5JTtK//J\nxny/JaeknQ8jTk6/0Kb8xwY/9owhAgMBAAECgYBpfuvWtzesX0cyvzT8SIYKallY\nbKX7unUUyiqGjVJOtf3nJpT1cJhoTvmKBKlYHGMZBFoKe0aZsfiXIKUMdCp6cVzM\n3ybqFGzJjrrobgwRnD4aSehWfE6anrF8b0DWDI5fgtcm/8fcHIrEmcu7QB37J2Sa\nfbhgytgL5QvrdnFhQQJFAMsLQKD90RBSg94iHKGZS5wpZQ0lbGwAMiHSUytyff8s\nyQEYNaZgJBKH3xGVod7us2eiOl8tnUoSZQAkfVznVyRPCagpAj0AuDg8qXknicac\nv8HB3kJFpH1Cgpc2Ec0tO3vohnQC8v8q6sLAXbi4JSxuZ5KvD0wgjtvBN+6Wer+q\njqM5AkUAjc18A3OAMNAP/HECYfG3loZqZnX+dKbmXCyRnURGrpHOh48q2NlIGBEA\nPz2rYRCABx6LebFXqkWSeDkDvIBsoZT5f5ECPAogtV0P1xeg/q8gJNtmQ1PmuwhY\n7dUOtaTmjAMQT7UySBa9/Dtq01zf03YIP22U3tlbxYSgrk8zSFotKQJEVu7wtV5q\nDrMOIJhzYwh62S/1M7I0JMoCz+/rS6TSN1UN7vmU+tF/TNaOmxzLOeYea/L2sGNS\nM7lcUCc5x+G2bkIbF4E=\n-----END PRIVATE KEY-----"
  decrypt.setPrivateKey(PRIVATE_KEY);
  let uncrypted = decrypt.decrypt(PUBLIC_KEY);
  uncrypted = decodeBase64(uncrypted);
  uncrypted = JSON.parse(uncrypted).sign;
  return uncrypted;
}

export function decodeBase64(input) {
  var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  var output = "";
  var chr1, chr2, chr3;
  var enc1, enc2, enc3, enc4;
  var i = 0;
  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
  while (i < input.length) {
    enc1 = _keyStr.indexOf(input.charAt(i++));
    enc2 = _keyStr.indexOf(input.charAt(i++));
    enc3 = _keyStr.indexOf(input.charAt(i++));
    enc4 = _keyStr.indexOf(input.charAt(i++));
    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;
    output = output + String.fromCharCode(chr1);
    if (enc3 != 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 != 64) {
      output = output + String.fromCharCode(chr3);
    }
  }
  return output;
}



export function rsaDecryptContent(encryContent) {
  JSEncrypt.prototype.decryptLong2 = function (string) {
    var k = this.getKey();
    // 字符串转化为16进制之后，两个16进制字符即表示一个字节，对应128个字节
    var MAX_DECRYPT_BLOCK = 256;
    try {
      var ct = "";
      var t1;
      var hexTmp;
      // 转化为16进制
      var str = b64tohex(string);
      var inputLen = str.length;
      //开始长度
      var offSet = 0;
      //结束长度
      var endOffSet = MAX_DECRYPT_BLOCK;
      //分段加密
      while (inputLen - offSet > 0) {
        if (inputLen - offSet > 256) {
          hexTmp = str.slice(offSet, endOffSet);
          t1 = k.decrypt(hexTmp);
          ct += t1;
        } else {
          hexTmp = str.slice(offSet, inputLen);
          t1 = k.decrypt(hexTmp);
          ct += t1;
        }
        offSet += MAX_DECRYPT_BLOCK;
        endOffSet += MAX_DECRYPT_BLOCK;
      }
      return ct;
    } catch (ex) {
      return false;
    }
  };
  var decrypt = new JSEncrypt();
  let PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIICegIBADANBgkqhkiG9w0BAQEFAASCAmQwggJgAgEAAoGBAJIcsQbnUNzE7Mcw\n22inz9gvEQTXjQxoYDV9qtEOSu4ObTbYPenF34iVzfZI2UuEmjd+ZAxBbLXNwKzp\nw194jVeBYAbRwP+QKAsSfDDI1zhkHf2qeY3EZfXjYBfAKVaKDO+CAyof5JTtK//J\nxny/JaeknQ8jTk6/0Kb8xwY/9owhAgMBAAECgYBpfuvWtzesX0cyvzT8SIYKallY\nbKX7unUUyiqGjVJOtf3nJpT1cJhoTvmKBKlYHGMZBFoKe0aZsfiXIKUMdCp6cVzM\n3ybqFGzJjrrobgwRnD4aSehWfE6anrF8b0DWDI5fgtcm/8fcHIrEmcu7QB37J2Sa\nfbhgytgL5QvrdnFhQQJFAMsLQKD90RBSg94iHKGZS5wpZQ0lbGwAMiHSUytyff8s\nyQEYNaZgJBKH3xGVod7us2eiOl8tnUoSZQAkfVznVyRPCagpAj0AuDg8qXknicac\nv8HB3kJFpH1Cgpc2Ec0tO3vohnQC8v8q6sLAXbi4JSxuZ5KvD0wgjtvBN+6Wer+q\njqM5AkUAjc18A3OAMNAP/HECYfG3loZqZnX+dKbmXCyRnURGrpHOh48q2NlIGBEA\nPz2rYRCABx6LebFXqkWSeDkDvIBsoZT5f5ECPAogtV0P1xeg/q8gJNtmQ1PmuwhY\n7dUOtaTmjAMQT7UySBa9/Dtq01zf03YIP22U3tlbxYSgrk8zSFotKQJEVu7wtV5q\nDrMOIJhzYwh62S/1M7I0JMoCz+/rS6TSN1UN7vmU+tF/TNaOmxzLOeYea/L2sGNS\nM7lcUCc5x+G2bkIbF4E=\n-----END PRIVATE KEY-----"
  // let PRIVATE_KEY = "myMw6qPt&3AD"
  decrypt.setPrivateKey(PRIVATE_KEY);
  var uncrypted = decrypt.decryptLong2(encryContent);
  uncrypted = decodeBase64(uncrypted);
  try {
    uncrypted = JSON.parse(uncrypted);
    return uncrypted;
  } catch (error) {
    return "-1";
  }
}

var b64map = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
var b64pad = "=";

function b64tohex(s) {
  var ret = "";
  var i;
  var k = 0; // b64 state, 0-3
  var slop = 0;
  for (i = 0; i < s.length; ++i) {
    if (s.charAt(i) == b64pad) {
      break;
    }
    var v = b64map.indexOf(s.charAt(i));
    if (v < 0) {
      continue;
    }
    if (k == 0) {
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 1;
    } else if (k == 1) {
      ret += int2char((slop << 2) | (v >> 4));
      slop = v & 0xf;
      k = 2;
    } else if (k == 2) {
      ret += int2char(slop);
      ret += int2char(v >> 2);
      slop = v & 3;
      k = 3;
    } else {
      ret += int2char((slop << 2) | (v >> 4));
      ret += int2char(v & 0xf);
      k = 0;
    }
  }
  if (k == 1) {
    ret += int2char(slop << 2);
  }
  return ret;
}

export function getUTCTime(date) {
  // if (date) {
  // 	return new Date(
  // 		new Date(date).getUTCFullYear(),
  // 		new Date(date).getUTCMonth(),
  // 		new Date(date).getUTCDate(),
  // 		new Date(date).getUTCHours(),
  // 		new Date(date).getUTCMinutes(),
  // 		new Date(date).getUTCSeconds()
  // 	).getTime();
  // } else {
  // 	return new Date(
  // 		new Date().getUTCFullYear(),
  // 		new Date().getUTCMonth(),
  // 		new Date().getUTCDate(),
  // 		new Date().getUTCHours(),
  // 		new Date().getUTCMinutes(),
  // 		new Date().getUTCSeconds()
  // 	).getTime();
  // }
  if (date) {
    // console.log(new Date(date).getUTCFullYear(),new Date(date).getUTCMonth(),new Date(date).getUTCDate())
    return new Date(date).getTime();
  } else {
    return new Date().getTime();
  }
}

export function getUTCDateFn(dateTime) {
  var date = new Date(dateTime);
  var year = date.getUTCFullYear();
  var month = date.getUTCMonth() + 1;
  month = month < 10 ? '0' + month : month;
  var day = date.getUTCDate();
  day = day < 10 ? '0' + day : day;
  return year + '-' + month + '-' + day
};

// 用户行为唯一标识
export function algorithm() {
  let abc = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'g', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u',
    'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];
  let [max, min] = [Math.floor(Math.random() * (10 - 7 + 1) + 1), Math.floor(Math.random() * (17 - 10 + 1) + 17)];
  abc = abc.sort(() => 0.4 - Math.random()).slice(max, min).slice(0, 8).join("");
  var a = new Date().getTime() + abc;
  return a
};

function getFirebaseConfig() {
  return {
    apiKey: "AIzaSyASYnKB8scQaNxg5XOR_ZCDuYdPMJNg8pI",
    authDomain: "digital-tv-8c042.firebaseapp.com",
    projectId: "digital-tv-8c042",
    storageBucket: "digital-tv-8c042.appspot.com",
    messagingSenderId: "590013021381",
    appId: "1:590013021381:web:81b87550941018bb8644ac",
    measurementId: "G-WKHJZ0Q5HH",
  }
}
//默认加载图片
export function loadDefaultImg(ele, img) {
  if (ele.getAttribute('retryTimes')) {
    if (ele.getAttribute('retryTimes') > 3) {
      if (ele.parentNode) {
        ele.parentNode.removeChild(ele);
      }
      return img
    } else {
      ele.setAttribute('retryTimes', Number(ele.getAttribute('retryTimes')) + 1);
      return img
    }
  } else {
    ele.setAttribute('retryTimes', 1);
    return img
  }

}

export function formatDate(date, style = "yyyy-MM-dd hh:mm:ss") {
  Date.prototype.Format = function (fmt) { //author: meizz   
    var o = {
      "M+": this.getMonth() + 1, //月份   
      "d+": this.getDate(), //日   
      "h+": this.getHours(), //小时   
      "m+": this.getMinutes(), //分   
      "s+": this.getSeconds(), //秒   
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度   
      "S": this.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
          .length)));
    return fmt;
  }
  return date ? new Date(date).Format(style) : new Date().Format(style);
}

// function formatDate(time) {
// 	var date = new Date(time)

// 	var year = date.getFullYear();
// 	var month = date.getMonth() + 1; // 月份是从0开始的
// 	var day = date.getDate();
// 	var hour = date.getHours();
// 	var min = date.getMinutes();
// 	var sec = date.getSeconds();
// 	var ms = date.getMilliseconds();
// 	var newTime =
// 		year +
// 		'-' +
// 		(month < 10 ? '0' + month : month) +
// 		'-' +
// 		(day < 10 ? '0' + day : day) +
// 		' ' +
// 		(hour < 10 ? '0' + hour : hour) +
// 		':' +
// 		(min < 10 ? '0' + min : min) +
// 		':' +
// 		(sec < 10 ? '0' + sec : sec) + ' ' + ms

// 	return newTime
// }

/**
 * 获取本地时区
 * @returns 
 */
export function getLocalTimeZone() {
  let localTimeZone = "0";
  if (new Date().getTimezoneOffset() / 60 > 0) {
    localTimeZone =
      "-" + parseInt(Math.abs(new Date().getTimezoneOffset() / 60));
  } else if (new Date().getTimezoneOffset() / 60 < 0) {
    localTimeZone =
      "+" + parseInt(Math.abs(new Date().getTimezoneOffset() / 60));
  }
  return localTimeZone
}

function roundShift(arr, count) {
  for (var i = 0; i < count; i++) {
    arr.unshift(arr.pop())
  }
}

export function generateDays(daysLength, offset, isFormatObject) {
  let date = new Date();
  date.setDate(date.getDate() - (offset || 4));
  let days = new Array();

  for (let i = 0; i < (daysLength || 7); i++) {
    date.setDate(date.getDate() + 1);
    if (isFormatObject) {
      days[i] = {
        date: date.getDate() + "/" + (date.getMonth() + 1),
        timestamp: new Date(date).getTime(),
        day: new Date(date).getDay(),
        formatDate: formatDate(new Date(date).getTime(), "yyyy-MM-dd")
      }
    } else {
      days[i] = date.getDate() + "/" + (date.getMonth() + 1)
    }
  }
  return days;
}

/**
 * 判断两个日期是否是同一天
 * @param {*} date1 
 * @param {*} date2 
 * @returns 
 */
export function isSameDay(date1, date2) {
  // console.log(date2)
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

export function downTime(endTime) {
  var EndTime = new Date(endTime);
  var NowTime = new Date();
  var t = EndTime.getTime() - NowTime.getTime();
  var d = Math.floor(t / 1000 / 60 / 60 / 24);
  var h = Math.floor(t / 1000 / 60 / 60 % 24);
  var m = Math.floor(t / 1000 / 60 % 60);
  var s = Math.floor(t / 1000 % 60);
  if (t <= 0) {
    return 0
  }
  // if (d) {
  return d + '  ' + (d > 1 ? 'Days' : 'Day') + " " + ((h < 10) ? ('0' + h) : h) + ':' + ((m < 10) ? ('0' + m) : m) + ':' + (s < 10 ? ('0' + s) : s);
  // }
  // return ((h < 10) ? ('0' + h) : h) + ':' + ((m < 10) ? ('0' + m) : m) + ':' + (s < 10 ? ('0' + s) : s);
}

function getExplorerInfo() {
  try {
    let explorer = window.navigator.userAgent.toLowerCase();
    if (explorer.indexOf("msie") >= 0) {
      var ver = explorer.match(/msie ([\d.]+)/)[1];
      return "IE " + ver;
    } else if ("ActiveXObject" in window || window.ActiveXObject) {
      return "IE 11.0"
    }

    //firefox
    else if (explorer.indexOf("firefox") >= 0) {
      var ver = explorer.match(/firefox\/([\d.]+)/)[1];
      return "Firefox " + ver;
    }

    if (explorer.indexOf("edg") >= 0) {
      return "Edge"
    }

    //Chrome
    else if (explorer.indexOf("chrome") >= 0) {
      var ver = explorer.match(/chrome\/([\d.]+)/)[1];
      return "Chrome " + ver;
    }



    //Opera
    else if (explorer.indexOf("opera") >= 0) {
      var ver = explorer.match(/opera.([\d.]+)/)[1];
      return "Opera " + ver;
    }
    //Safari
    else if (explorer.indexOf("safari") >= 0) {
      // if (explorer.match(/crios\/([\d.]+)/)) {
      //     var ver = explorer.match(/crios\/([\d.]+)/)[1];
      //     // return "Chrome" + ver;
      //     return "safari:" + ver;
      // } else {
      //     return "unknow brower";
      // }

      try {
        var ver = explorer.match(/version\/([\d.]+)/)[1];
        return "safari:" + ver;
      } catch (error) {
        var ver = explorer.match(/crios\/([\d.]+)/)[1];
        // return "Chrome" + ver;
        return "safari:" + ver;
      }

    } else {
      return "unknow brower";
    }
  } catch {

  }

}
// 获取IE版本
function IEVersion() {
  // 取得浏览器的userAgent字符串
  var userAgent = navigator.userAgent;
  // 判断是否为小于IE11的浏览器
  var isLessIE11 = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
  // 判断是否为IE的Edge浏览器
  var isEdge = (userAgent.indexOf('Edge') > -1 && !isLessIE11 || userAgent.toLowerCase().indexOf("edg") >= 0);
  // 判断是否为IE11浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  if (isLessIE11) {
    var IEReg = new RegExp('MSIE (\\d+\\.\\d+);');
    // 正则表达式匹配浏览器的userAgent字符串中MSIE后的数字部分，，这一步不可省略！！！
    IEReg.test(userAgent);
    // 取正则表达式中第一个小括号里匹配到的值
    var IEVersionNum = parseFloat(RegExp['$1']);
    if (IEVersionNum === 7) {
      // IE7
      return 7
    } else if (IEVersionNum === 8) {
      // IE8
      return 8
    } else if (IEVersionNum === 9) {
      // IE9
      return 9
    } else if (IEVersionNum === 10) {
      // IE10
      return 10
    } else {
      // IE版本<7
      return 6
    }
  } else if (isEdge) {
    // edge
    return "Edge"
  } else if (isIE11) {
    // IE11
    return 11
  } else {
    // 不是ie浏览器
    return -1
  }
}


// export function getDateDiff(dateTimeStamp) {

//   var minute = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
//   var hour = minute * 60;
//   var day = hour * 24;
//   var week = day * 7;
//   var halfamonth = day * 15;
//   var month = day * 30;
//   var year = day * 365;
//   var now = new Date().getTime(); //获取当前时间毫秒
//   // console.log(now);
//   var diffValue = Math.abs(now - dateTimeStamp); //时间差
//   if (diffValue < 0) {
//     return;
//   }
//   var minC = diffValue / minute; //计算时间差的分，时，天，周，月
//   var hourC = diffValue / hour;
//   var dayC = diffValue / day;
//   var weekC = diffValue / week;
//   var monthC = diffValue / month;
//   var yearC = diffValue / year;
//   var result;
//   if (yearC >= 1) {
//     result = parseInt(yearC) + ' ' + $.i18n.prop('date.yearsAgo');
//   } else if (monthC >= 1 && monthC <= 12) {
//     result = parseInt(monthC) + ' ' + $.i18n.prop('date.monthsAgo');
//   } else if (weekC >= 1 && weekC <= 4) {
//     result = parseInt(weekC) + ' ' + $.i18n.prop('date.weeksAgo');
//   } else if (dayC >= 1 && dayC <= 7) {
//     result = parseInt(dayC) + ' ' + $.i18n.prop('date.daysAgo');
//   } else if (hourC >= 1 && hourC <= 24) {
//     result = parseInt(hourC) + ' ' + $.i18n.prop('date.hoursAgo');
//   } else if (minC >= 1 && minC <= 60) {
//     result = parseInt(minC) + ' ' + $.i18n.prop('date.minutesAgo');

//   } else if (diffValue >= 0 && diffValue <= minute) {
//     result = $.i18n.prop('date.recent');
//   } else {
//     console.log(8)
//     var datetime = new Date();
//     datetime.setTime(dateTimeStamp);
//     var Nyear = datetime.getFullYear();
//     var Nmonth =
//       datetime.getMonth() + 1 < 10 ?
//         "0" + (datetime.getMonth() + 1) :
//         datetime.getMonth() + 1;
//     var Ndate =
//       datetime.getDate() < 10 ?
//         "0" + datetime.getDate() :
//         datetime.getDate();
//     var Nhour =
//       datetime.getHours() < 10 ?
//         "0" + datetime.getHours() :
//         datetime.getHours();
//     var Nminute =
//       datetime.getMinutes() < 10 ?
//         "0" + datetime.getMinutes() :
//         datetime.getMinutes();
//     var Nsecond =
//       datetime.getSeconds() < 10 ?
//         "0" + datetime.getSeconds() :
//         datetime.getSeconds();
//     result = Nyear + "-" + Nmonth + "-" + Ndate;
//   }
//   return result;
// }

/**
 * 消息提醒弹窗
 * @param { title } options 
 * @param { desc } options
 * @param { index } options
 * @param { sure } options 按钮点击callback
 */
// function createAlertDialog(options) {
//   let dialog = $(`<div class="sign_out" style="display: block;" id='toast_content${options.index}'>
// 			<div class="sign_out_logo">
// 				<img src="images/user/xmedia_logo_en.png" alt="">
// 			</div>
// 			<div class="sign_out_title">
// 				<div id="toastText" style="color: #0472B3">${options.title}</div>
// 				<div>${options.desc}</div>
// 			</div>
// 			<div class="sign_out_btn">
// 				<div class="sign_out_btn_Item i18n" data-focusable-next-focus-up='null' data-focusable-next-focus-right='null' data-focusable-next-focus-down='null' data-focusable-next-focus-left='null' id="affirmToast${options.index}"
// 					name="toast.sure">${$.i18n.prop("toast.sure")}</div>
// 			</div>
// 		</div>`);

//   $('body').append(dialog);
//   $(`#affirmToast${options.index}`).caphButton({
//     focusOption: {
//       name: `affirmToast${options.index}`,
//       depth: 0,

//     },
//     onSelected: function () {
//       options.sure(options.index);
//       $(`#toast_content${options.index}`).hide();
//     }
//   })
//   // setTimeout(() => {
//   // 	$(`#toast_content${options.index}`).hide();
//   // }, 3000)
// }
// 获取视频流类型
export function getVideoStreamType(url) {
  var type = "";
  if (url.indexOf(".m3u8") > -1) {
    type = "HLS";
  } else if (url.indexOf(".mpd") > -1) {
    type = "DASH";
  } else if (url.indexOf(".mp4") > -1) {
    type = "HLS";
  }
  return type;
}
/**
 * 格式化数字
 * @param number
 * @param decimals
 */
export function formatDecimals(number, decimals = 0) {
  return formatMoney(number || 0, decimals, '.', ',')
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
export function formatMoney(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
  let str = (number || 0).toString();
  let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, "$1,");
}

/**
 * 数组对象排序通过某个字段
 * @param {*} arr 数组
 * @param {*} key 键值 
 */
export function sortByKey(arr, key, type) {
  let newArr = null;
  if (type) {
    newArr = arr.sort(
      (a, b) => parseInt(a[key]) - parseInt(b[key])
    );
  } else {
    newArr = arr.sort(
      (a, b) => parseInt(b[key]) - parseInt(a[key])
    );
  }

  return newArr;
}

// ;
// (function () {
//   /**
//    * 弱提示弹窗
//    * @param { message } options // 提示文案
//    * @param { type } options // 提示类型  success、warning、error
//    * @param { duration } options // 显示时长
//    */
//   var Alert = function (options) {
//     var html = $(`<div class="alert i18n is-${options.type ? options.type : 'success'}" name="${options.message}">${$.i18n.prop(options.message) ? $.i18n.prop(options.message) : options.message}</div>`)
//     if (!document.getElementsByClassName('alert').length) {
//       if (document.getElementsByClassName("main_page").length) {
//         html.insertAfter('.main_page')
//       } else {
//         $("body").append(html);
//         // document.getElementsByName("body")[0].appendChild(html)
//       }

//       // window.document.body.appendChild(html)
//     }

//     this.show = () => {
//       $('.alert').show();
//       setTimeout(() => {
//         $('.alert').hide();
//       }, options.duration ? options.duration : 3000);
//     }
//   }
//   window.Alert = Alert;
// })();

// if (typeof (module) !== 'undefined') {
//   module.exports = window.Alert;
// } else if (typeof define === 'function' && define.amd) {
//   define([], function () {
//     'use strict';
//     return window.Alert;
//   });
// }
export function getAllAttributes(element) {
  var attributes = element.attributes;
  var attrs = {};
  for (var i = 0; i < attributes.length; i++) {
    attrs[attributes[i].name] = attributes[i].value;
  }
  return attrs;
}


/**
 * 检测元素是否在可视区域
 * @param {*} el 
 * @returns 
 */
export function isElementInViewport(el) {
  if (!el) {
    return
  }
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


/**
 * 计算年龄
 * @param {*} birthTimestamp 
 * @returns 
 */
export function calculateAge(birthTimestamp) {
  var now = new Date(); // 当前时间
  var birthDate = new Date(birthTimestamp); // 出生日期
  var age = now.getFullYear() - birthDate.getFullYear(); // 计算年龄
  var m = now.getMonth() - birthDate.getMonth(); // 计算月份差值

  // 如果当前月份没有超过出生月份，或者月份相同但当前日期没有超过出生日期，则年龄减1
  if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}


// export function scrollText(el, position, speed) {
//   const scrollingContainer = document.getElementById(el);
//   position += speed;
//   scrollingContainer.scrollTop = position;

//   // 当滚动到底部时，将滚动位置重置到顶部，实现循环滚动效果
//   if (scrollingContainer.scrollTop >= scrollingText.offsetHeight - scrollingContainer.clientHeight) {
//     position = 0;
//     scrollingContainer.scrollTop = position;
//   }

//   requestAnimationFrame(scrollText);
// }


//秒转时：分：秒
export function exchangeHMS(_s) {
  if (_s.toString().indexOf(":") != -1) {
    return _s
  }
  var s = Number(_s);
  var t = "";
  if (s >= 0) {
    var hour = Math.floor(s / 3600);
    var min = Math.floor(s / 60) % 60;
    var sec = s % 60;
    if (hour < 10 && hour > 0) {
      t = '0' + hour + ":"
    } else if (hour > 10) {
      t = hour + ":";
    }
    if (min < 10) {
      t += "0"
    }
    t += min + ":";
    if (sec < 10) {
      t += "0";
    }
    t += sec.toFixed(0);
  }
  return t
};


export function findClosestBelowElement(currentElement) {
  const focusableElements = Array.from(document.querySelectorAll('[focusable]'));
  const currentRect = currentElement.getBoundingClientRect();

  let closestElement = null;
  let closestDistance = Infinity;

  focusableElements.forEach(element => {
    const rect = element.getBoundingClientRect();
    const isBelow = rect.top > currentRect.top;
    const distance = rect.top - currentRect.bottom;

    if (isBelow && distance < closestDistance) {
      closestDistance = distance;
      closestElement = element;
    }
  });

  return closestElement;
}

/**
 * 获取图片主题色
 * @param {*} imageUrl 图片地址
 * @returns 
 */
async function getImagePalette(imageUrl) {
  const img = new Image();
  img.crossOrigin = 'Anonymous'; // 处理跨域问题
  img.onload = function () {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    const imageData = ctx.getImageData(0, 0, img.width, img.height);
    const data = imageData.data;
    const colorCount = {};
    let maxCount = 0;
    let dominantColor = '';

    for (let i = 0; i < data.length; i += 4) {
      const rgb = `${data[i]},${data[i + 1]},${data[i + 2]}`;
      if (colorCount[rgb]) {
        colorCount[rgb]++;
      } else {
        colorCount[rgb] = 1;
      }

      if (colorCount[rgb] > maxCount) {
        maxCount = colorCount[rgb];
        dominantColor = rgb;
      }
    }
    console.log(dominantColor)
    return `rgb(${dominantColor})`
    // resolve(`rgb(${dominantColor})`);
  };

  img.onerror = function () {
    console.log('无法加载图片');
  };

  img.src = imageUrl;
  // const img = await createImageBitmap(await fetch(imageUrl).then(r => r.blob()));
  // const canvas = document.createElement('canvas');
  // const ctx = canvas.getContext('2d');
  // canvas.width = img.width;
  // canvas.height = img.height;
  // ctx.drawImage(img, 0, 0);
  // const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  // const colorThief = new ColorThief();
  // const dominantColor = colorThief.getColor(imageData);
  // return dominantColor;
}

export function splitArray(arr, size) {
  return arr.reduce((result, item, index) => {
    if (index % size === 0) {
      result.push([item]);
    } else {
      result[result.length - 1].push(item);
    }
    return result;
  }, []);
}


/**
 * 处理精度丢失公用方法
 * @param {*} num1 
 * @param {*} num2 
 * @param {*} symbol 
 * @returns 
 */
//num1 num2传入两个值  symbol +-*/符号
export function amend(num1, num2, symbol) {
  console.log(num1, num2)
  var str1 = num1.toString(), str2 = num2.toString(), result, str1Length, str2Length
  //解决整数没有小数点方法
  try { str1Length = str1.split('.')[1].length } catch (error) { str1Length = 0 }
  try { str2Length = str2.split('.')[1].length } catch (error) { str2Length = 0 }
  var step = Math.pow(10, Math.max(str1Length, str2Length))
  // 
  console.log(step);
  switch (symbol) {
    case "+":
      result = (num1 * step + num2 * step) / step
      break;
    case "-":
      result = (num1 * step - num2 * step) / step
      break;
    case "*":
      result = ((num1 * step) * (num2 * step)) / step / step
      break;
    case "/":
      result = (num1 * step) / (num2 * step)
      break;
    default:
      break;
  }
  return result

}


/**
 * 数组对象深拷贝方法
 * @param {*} source 
 * @returns 
 */
export function deepClone(source) {
  if (!source || typeof source !== 'object') {
    return source
    // throw new Error('Error in deepClone: source is not an object.');
  }

  const targetClass = Object.prototype.toString.call(source).slice(8, -1);
  let target;
  if (targetClass === 'Object' || targetClass === 'Array') {
    target = targetClass === 'Object' ? {} : [];
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        const value = source[key];
        if (value && typeof value === 'object') {
          target[key] = deepClone(value);
        } else {
          target[key] = value;
        }
      }
    }
  } else {
    return source
    // throw new Error('Error in deepClone: source is not a plain object or array.');
  }

  return target;
}


/**
* 计算两个时间戳相差的天数
* @param {*} timestamp1 
* @param {*} timestamp2 
* @returns 
*/
export function daysBetween(timestamp1, timestamp2) {
  const oneDay = 86400000; // 一天的毫秒数
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  const timeDiff = Math.abs(date2.getTime() - date1.getTime()); // 时间差的绝对值
  const daysDiff = timeDiff / oneDay; // 相差的天数
  return daysDiff; // 返回相差的天数（向下取整）
}

/**
 * 传入时间戳，判断年龄
 * @param {*} timestamp 
 * @returns 
 */
export function getAge(timestamp) {
  // 将时间戳转换为日期对象
  var birthDate = new Date(timestamp);
  // 获取当前日期
  var currentDate = new Date();
  // 计算年龄
  var age = currentDate.getFullYear() - birthDate.getFullYear();
  // 调整月份和日期以确保计算结果准确
  var monthDiff = currentDate.getMonth() - birthDate.getMonth();
  var dayDiff = currentDate.getDate() - birthDate.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }
  return age;
}

export function getUUID() {
  // || "63047b93-9f57-4072-8fb5-8eef9d6f79d9"
  var mac = getStorage("uuid");
  if (mac) {
    setStorage("uuid", mac)
    return mac;
  } else {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = "-"

    var uuid = s.join("")
    setStorage("uuid", uuid);
    return uuid;
  }
}

// 获取系统当前时间
export function getSystemTime(isToSec) {
  var date = new Date();
  var hh = date.getHours();
  hh = hh < 10 ? '0' + hh : hh;
  var mm = date.getMinutes();
  mm = mm < 10 ? '0' + mm : mm;
  var ss = date.getSeconds();
  ss = ss < 10 ? '0' + ss : ss;
  if (isToSec) {
    return hh + ':' + mm + ':' + ss
  } else {
    return hh + ':' + mm
  }

}


//剧集总时长时间转换
export function formatVodDuration(time_distance, colon) {
  if (!time_distance) {
    return
  }
  // 天时分秒换算 
  var int_day = Math.floor(time_distance / 86400)
  time_distance = time_distance - int_day * 86400;

  var int_hour = Math.floor(time_distance / 3600)
  time_distance = time_distance - int_hour * 3600;

  var int_minute = Math.floor(time_distance / 60)
  time_distance = time_distance - int_minute * 60;

  var int_second = Math.floor(time_distance)
  // 时分秒为单数时、前面加零 
  if (int_day < 10) {
    int_day = "0" + int_day;
  }
  if (int_hour < 10) {
    int_hour = "0" + int_hour;
  }
  if (int_minute < 10) {
    int_minute = "0" + int_minute;
  }
  if (int_second < 10) {
    int_second = "0" + int_second;
  }
  if (!colon) {
    if (int_day == "00" && int_hour != "00") {
      // return int_hour + 'h' + int_minute + 'min' + int_second + 's';
      return int_hour + 'h' + int_minute + 'min'; //不需要秒
    } else if (int_hour == "00") {
      return int_minute + 'min'; //不需要秒
    } else {
      // return int_day + 'day' + int_hour + 'h' + int_minute + 'min' + int_second + 's';
      return int_day + 'day' + int_hour + 'h' + int_minute + 'min'; //不需要秒
    }
  } else {
    //剧集时长分：秒
    if (int_hour != "00") {
      return int_hour + ':' + int_minute + ':' + int_second;
    } else {
      return int_minute + ':' + int_second;
    }
  }

}
export function getElementWidthWithMargin(element) {
  const style = window.getComputedStyle(element);
  const width = element.clientWidth; // 元素的宽度
  const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight); // 左右边距的和
  return width + margin;
}

export function getElementHeightWithMargin(element) {
  const style = window.getComputedStyle(element);
  const width = element.clientHeight; // 元素的宽度
  const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom); // 左右边距的和
  return width + margin;
}

/**
 * 参数合并生成链接
 * @param { path } options 
 * @param { data } options 取值目标对象
 * @param { param } options 取值目标对象
 * @param { query } options 取值目标对象
 * @returns 
 */
export function generateUrl(options) {
  if (options == null) {
    return ''
  }
  const { path, data, param = [], query = {} } = options;

  // 生成路径部分
  let url = `/${path}`;

  // 检查并生成路径参数
  if (Array.isArray(param) && param.length > 0) {
    param.forEach(key => {
      if (data[key] !== undefined) {
        url += `/${data[key]}`;
      } else {
        throw new Error(`Key '${key}' not found in data object`);
      }
    });
  }

  // 检查并生成查询参数
  const queryStrings = [];
  if (typeof query === 'object' && Object.keys(query).length > 0) {
    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        const valueKey = query[key];
        if (data[valueKey] !== undefined) {
          queryStrings.push(`${key}=${data[valueKey]}`);
        } else {
          throw new Error(`Query value '${valueKey}' not found in data object`);
        }
      }
    }
  }

  // 拼接查询参数部分
  if (queryStrings.length > 0) {
    url += `?${queryStrings.join('&')}`;
  }

  return url;
}


/**
 * 事件直播排序
 * @param {*} data 
 * @param { timestampKey } options 
 * @param { nameKey } options 
 */
export function sortingAlgorithm(data, options) {
  let copyData = deepClone(data);
  // 自定义排序函数
  copyData.sort((a, b) => {
    // 按时间戳倒序排序
    if (a[options.timestampKey] > b[options.timestampKey]) {
      return 1;
    }
    if (a[options.timestampKey] < b[options.timestampKey]) {
      return -1;
    }
    // 如果时间戳相同，按名称的 ASCII 码顺序排序
    return a[options.nameKey].localeCompare(b[options.nameKey]);
  });
  return copyData
}

//秒钟转换成时:分:秒
export function formatTimeToHMS(second) {
  // let result = parseInt(second/1000);
  let result = second
  let h = addZero(Math.floor((result / 3600)));
  let m = addZero(Math.floor((result / 60 % 60)));
  let s = addZero(Math.floor((result % 60)));

  let res = `${h}:${m}:${s}`;
  return res;
}

// 获取url后的参数obj对象
export function getQueryObj(url) {
  let ItemArr = [];
  let ItemObj = {};
  url.substring(url.lastIndexOf("?") + 1, url.length).split("&").map((item) => {
    ItemArr.push(item.split("="));
  });
  ItemArr.map((item) => {
    ItemObj[item[0]] = item[1];
  });
  return ItemObj;
}

/**
 * 数组根据指定字段去重
 * @param {*} arr 原数组
 * @param {*} t 字段名
 * @returns 
 */
export function deduplicate(arr, t = "") {
  const newArr = [],
    assignList = []
  if (t) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i][t] && assignList.lastIndexOf(arr[i][t]) === -1) {
        assignList.push(arr[i][t])
        newArr.push(arr[i])
      }
    }
  }
  return newArr
}

/**
 * 找当前节点指定class名最近的元素
 * @param {*} element 当前节点
 * @param {*} className 名称
 * @param {*} direction 向上 或者 向下 previous next
 * @returns 
 */
export function findClosestSiblingWithClass(element, className, direction = 'previous') {
  let sibling = (direction === 'previous') ? element.previousElementSibling : element.nextElementSibling;

  // 循环查找指定方向的同级元素
  while (sibling) {
    if (sibling.classList.contains(className) && window.getComputedStyle(sibling).display === 'block') {
      return sibling;
    }
    sibling = (direction === 'previous') ? sibling.previousElementSibling : sibling.nextElementSibling;
  }

  return null; // 如果没有找到符合条件的同级元素
}

/**
 * 返回获焦节点
 * @returns 
 */
export function getFocusedElement() {
  const focusableElements = document.querySelectorAll('[focused]');
  if (focusableElements && focusableElements.length) {
    return focusableElements[0];
  }
  // 如果没有找到，则返回null
  return null;
}

/**
 * 返回能获焦的节点
 * @returns 
 */
export function getCanFocusElement() {
  const focusableElements = document.querySelectorAll('[focusable]');
    if (focusableElements && focusableElements.length) {
      for (let element of focusableElements) {
        // Check if the element is visible: not display: none, visibility: hidden, or opacity: 0
        const style = window.getComputedStyle(element);
        if (element.offsetParent !== null && 
            style.display !== 'none' && 
            style.visibility !== 'hidden' && 
            parseFloat(style.opacity) > 0) {
          return element;
        }
      }
    }
    // 如果没有找到，则返回null
    return null;
}



export function scrollTextOnFocus(target, options = {}) {
  // 默认配置
  const settings = {
    duration: 2, // 滚动持续时间（秒）
    autoScroll: true, // 是否在聚焦时自动滚动
    ...options // 覆盖默认配置
  };

  // 确保传入的是 DOM 元素
  const container = target;
  if (!container) return; // 若未找到元素则返回
  let interval = null;

  // 定义滚动函数
  function startScroll() {
    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    container.classList.add("auto-scroll");
    // 确保文字不换行
    // container.style.whiteSpace = "nowrap";
    // 设置横向滚动
    // container.scrollLeft = 0;
    // container.scrollIntoView({ behavior: "smooth", inline: "start" });
    // console.log(scrollWidth, clientWidth)
    // // 仅当内容超出容器宽度时滚动
    // if (scrollWidth > clientWidth) {
    //     container.style.transition = `transform ${settings.duration}s linear`;
    //     container.style.transform = `translateX(-${scrollWidth - clientWidth}px)`;

    //     // 重置滚动，持续循环
    //     interval = setTimeout(() => {
    //         container.style.transition = 'none';
    //         container.style.transform = 'translateX(0)';
    //         startScroll(); // 重新开始滚动
    //     }, settings.duration * 1000);
    // }
  }

  // 定义重置滚动函数
  function resetScroll() {
    container.classList.remove("auto-scroll");
    // container.style.transition = 'none';
    // container.style.transform = 'translateX(0)';
    // if (interval) {
    //     clearTimeout(interval);
    //     interval = null;
    // }
  }



  // 将方法暴露给外部
  return {
    startScroll: startScroll,
    resetScroll: resetScroll
  };
}


/**
 * 递归查找符合条件的子节点
 * @param {Element} parentElement - 父节点元素
 * @param {string} selector - CSS 选择器，如 '.className' 或 '#id'
 * @param {boolean} all - 是否获取所有符合条件的子节点，默认为 false 仅获取第一个
 * @returns {Element|Element[]|null} - 返回符合条件的子节点或节点列表，如果未找到则返回 null
 */
 export function findChildNodeDeep(parentElement, selector, all = false) {
  if (!parentElement || !(parentElement instanceof Element)) {
    console.error("无效的父节点元素");
    return null;
  }

  const results = [];

  function recursiveSearch(element) {
    // 检查当前节点是否符合条件
    if (element.matches(selector)) {
      results.push(element);
      if (!all) return true; // 如果不获取所有节点，找到一个后直接返回
    }

    // 遍历子节点
    for (const child of element.children) {
      if (recursiveSearch(child) && !all) return true;
    }
  }

  recursiveSearch(parentElement);

  // 根据 all 参数决定返回值：若为 true 返回所有结果数组，若为 false 返回第一个符合条件的节点或 null
  return all ? (results.length ? results : null) : (results[0] || null);
}