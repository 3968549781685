import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import axios from 'axios'; // 引入axios
import QRCode from 'qrcode.react';
import $request from "../../api"
import projectConfig from '../../util/config';
import GLOBAL from '../../util/global';
import Popup from '../../components/popup/popup';
import { getStorage, setStorage, removeStorage, deepClone, findClosestSiblingWithClass, rsaDecryptContent, keycodeHandle, getUUID, getElementWidthWithMargin, formatTimeToHMS, exchangeHMS, amend, getUTCTime, getAge, getVideoStreamType } from '../../util/util';
import { deviceSign } from '../../util/commonRequest';
import ShakaPlayer from '../../components/player/player';
import ColumnItem from '../../components/columnItem/ColumnItem';
import AdPlayer from '../../components/adPlayer/adPlay'
import './style/VodPlayer.scss';

let flag = false;
let checkTimeUpdate = new Date().getTime();
export default withTranslation()(class VodPlayer extends Component {
    constructor(props) {
        super(props);
        this.playerRef = React.createRef(); // 播放器节点
        this.progressBarRef = React.createRef(); // 进度条节点
        this.progressFocusDot = React.createRef(); // 进度条聚焦点
        this.currentTimeRef = React.createRef(); // 进度当前时间节点
        this.durationRef = React.createRef(); // 进度条总时长节点
        this.forwardRef = React.createRef(); // 快进节点
        this.playOrPauseRef = React.createRef(); // 播放暂停节点
        this.rewindRef = React.createRef(); // 快退节点
        this.playbackRateRef = React.createRef(); // 切换速率节点
        this.settingRef = React.createRef(); // 设置节点
        this.subtitlesSettingRef = React.createRef(); // 字幕设置节点
        this.qualitySettingRef = React.createRef(); // 分辨率设置节点
        this.audioSettingRef = React.createRef(); // 音轨设置节点
        this.subtitleWrapperRef = React.createRef(); // 字幕显示节点
        this.episodesRef = React.createRef(); // 剧集列表元素
        this.selectionRef = React.createRef(); // 剧集点击按钮
        this.contentGradecontainerRef = React.createRef();
        this.contentGradeSkipRef = React.createRef(); // 内容评级面板跳过按钮
        this.settingPannelList = [];
        this.contentGradeTimer = null; // 内容评级面板倒计时
        this.scrollSpeed = 1; // 每次滚动的像素值，较小值会更平滑
        this.scrollDelay = 300; // 16ms 大约是每帧的时间 (60帧每秒)
        this.continueButtonGroupRef = []; // 继续播放按钮组
        this.purchaseButtonGroupRef = []; // 订阅按钮组
        this.audioListRef = []; // 音轨设置列表
        this.qualityListRef = []; // 分辨率设置列表
        this.subtitleListRef = []; // 字幕设置列表
        this.checkHeartInterval = null; // 心跳定时器
        this.adImageParentMapRef = {}; // 图片广告素材父节点
        this.adImageMapRef = {}; // 图片广告素材节点
        this.adPodsReportQueue = []; // 请求队列
		this.isRequestProcessing = false; // 请求处理状态标志
        this.playStatusReportInterval = null; // 播放状态定时器
        this.playBufferReportInterval = null; // 播放buffer定时器
        this.behaviorRecords = 0; // 操作行为记录
        this.reportCycleConfig = { // 数据采集上报频次
            vodEventCycle: 60,
            liveEventCycle: 60,
            bufferEventCycle: 60,
            maxBufferCount: 10,
            qualityEventCycle: 60,
            errorEventCycle: 60,
            adEventCount: 10
        }
        this.adImagesLoadedSuccess= {}; // 图片广告加载成功记录
        this.adImagesLoadedFailed = {}; // 图片广告加载失败记录
        this.updateTime = getUTCTime();
        this.state = {
            contentId: this.props.match.params.contentId, // 详情ID
            playType: this.props.match.params.type,
            authId: this.props.match.params.authId, // 鉴权ID
            playTypeTagText: "", // 播放类型标签
            vodDetail: null, // 点播详情
            watermarkInfo: null, // 水印信息
            ageGrade: null, // 年龄评级
            playInfo: null, // 播放信息
            currentPlaySource: null, // 当前播放的源信息
            customPlayerHeaders: {}, // 自定义license请求头
            isPause: false, // 是否是暂停
            isShowControls: false, // 是否显示控制栏
            isShowSettingPannel: false, // 是否显示设置面板
            isShowSubtitlesPannel: false, // 是否显示字幕设置面板
            isShowQualityPannel: false, // 是否显示分辨率设置面板
            isShowAudioPannel: false, // 是否显示音轨设置面板
            currentProgressWidth: 0, // 当前进度
            isFocusProgressBar: false, // 是否聚焦到进度条
            progressBarTime: { // 进度条时间
                currentTime: "00:00:00", // 剩余时间
                duration: "00:00:00" // 总时间
            },
            playbackRateList: [ // 速率列表
                {
                    value: 0.5,
                    label: '0.5X',
                    active: false
                },
                {
                    value: 1,
                    label: '1.0X',
                    active: true
                },
                {
                    value: 1.5,
                    label: '1.5X',
                    active: false
                },
                {
                    value: 2,
                    label: '2.0X',
                    active: false
                }
            ],
            playbackRateLabel: '1.0X', // 播放速率
            resolutionList: [], // 分辨率列表
            currentQuality: null, // 当前分辨率
            audioTrackList: [], // 音轨列表
            currentAudio: null, // 当前音轨
            subtitlesList: [], // 字幕列表
            currentSubtitle: null, // 当前字幕
            subtitlesArr: [], // 字幕
            subtitleStr: "", // 字幕文字
            episodes: [], // 剧集列表
            isShowEpisode: false, // 是否显示剧集
            currentEpisode: null, // 当前剧集
            isShowContinueWatching: false, // 是否显示继续播放按钮
            isContinueWatchingShow: false, // 是否有显示过继续播放 
            playList: [], // 播放列表
            isShowPlayList: false, // 是否显示播放列表
            currentPlayContent: null, // 当前播放内容
            continueButtonGroup: [
                {
                    value: 1,
                    label: this.props.t("player.continueWatching")
                },
                {
                    value: 2,
                    label: this.props.t("player.startFromTheBeginning")
                },
                {
                    value: 3,
                    label: this.props.t("player.exitPlay")
                }
            ],
            isShowPlayerTips: false, // 是否显示播放器提示
            playerTips: "", // 播放器提示语
            purchaseButtonGroup: [],
            isShowPurchase: false, // 是否显示订阅
            showPurchase: true, // 显示订阅还是提示订阅
            isAdShow: false, // 广告是否显示
            isSupportAd: true, // 是否支持广告
            isPlayAd: false, // 是否正在播放广告
            isShowContentGradePanel: false, // 是否显示年龄评级面板
            contentGradePannelCountingDown: 0, // 内容等级倒计时
            isShowContentGragePannelSkip: false, // 是否显示年龄评级跳过按钮
            allImageAdPodsList: [], // 所有图片广告荚
            waitRequestImageAdPodsList: [], // 待请求图片广告荚
            allVideoAdPodsList: [], // 所有视频广告荚
            waitRequestVideoAdPodsList: [], // 待请求视频广告荚
            gradeShowEnded: false, // 年龄评级面板是否有显示
            waitPlayVideoMaterialsList: [], // 待播放视频素材列表
            currentPlayVideoAdMaterial: {}, // 当前播放视频广告素材
            currentPlayVideoAdPods: {}, // 当前播放视频广告荚
            isShowSignIn: false, // 是否显示登录
            bufferCount: 0, // 加载次数
            videoStartPlayTime: 0, // 视频开始播放时间
            buffers: [], // buffers数据
            isShowTypeTag: true, // 是否显示类型标签
            user: null // 用户信息
        }
    }

    componentWillMount() {
        
        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
            this.setState({
                user: getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)
            })
            if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).isAD == 'N') {
                this.setState({
                    isSupportAd: false
                })
            }
        }
        if (getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG)) {
            let reportCycleConfig = getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG);
            Object.keys(this.reportCycleConfig).forEach(key => {
                this.reportCycleConfig[key] = reportCycleConfig[key];
            });
        }
        

    }
    componentDidMount() {
        this.checkPurchaseBtn();
        this.getDetail()
        this.handleProgressBar();
        this.handleForward();
        this.handleRewind();
        this.handlePlayOrPause();
        this.handlePlaybackRate();
        this.handleControlKey();
        this.handleSubtitleSetting();
        this.handleQulitySetting();
        this.handleAudioSetting();
        this.handleListenerSettingPannel();
        this.handleSetting();
        this.handleContinueButtonGroup();
        this.startAutoScroll();

    }
    componentWillUnmount() {
        this.stopAutoScroll();
    }

    startAutoScroll = () => {
        const scroll = () => {
            const container = this.contentGradecontainerRef.current;
            if (container && container.scrollTop >= container.scrollHeight - container.clientHeight) {
                container.scrollTop = 0; // 回到顶部，形成循环
            } else {
                if (container) {
                    container.scrollTop += this.scrollSpeed;
                }

            }
            // 使用 requestAnimationFrame 使滚动更加平滑
            this.scrollAnimationFrame = requestAnimationFrame(scroll);
        };

        this.scrollAnimationFrame = requestAnimationFrame(scroll);
    };

    stopAutoScroll = () => {
        cancelAnimationFrame(this.scrollAnimationFrame); // 清除动画帧
    };

    handleSignInBtn(data) {
        switch(data.type) {
            case 'ok':
                this.props.history.push('/qrLogin');
                break;
            case 'cancel':
                window.history.back(-1);
                break;
        }
    }

    checkPurchaseBtn() {
        if (getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO)) {
            if (getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) == GLOBAL.DEVICE_TYPE.LG_TV) {
                this.setState({
                    showPurchase: getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).lgTVBuySwitch
                }, () => {
                    if (!this.state.showPurchase) {
                        this.setState({
                            purchaseButtonGroup: [
                                {
                                    value: 2,
                                    label: this.props.t("button.cancel")
                                }
                            ]
                        })
                    } else {
                        this.setState({
                            purchaseButtonGroup: [
                                {
                                    value: 1,
                                    label: this.props.t("button.purchase")
                                },
                                {
                                    value: 2,
                                    label: this.props.t("button.cancel")
                                }

                            ]
                        })
                    }
                })
            } else if (getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) == GLOBAL.DEVICE_TYPE.SAMSUNG_TV) {
                this.setState({
                    showPurchase: getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).samsungTVBuySwitch
                }, () => {
                    if (!this.state.showPurchase) {
                        this.setState({
                            purchaseButtonGroup: [
                                {
                                    value: 2,
                                    label: this.props.t("button.cancel")
                                }
                            ]
                        })
                    } else {
                        this.setState({
                            purchaseButtonGroup: [
                                {
                                    value: 1,
                                    label: this.props.t("button.purchase")
                                },
                                {
                                    value: 2,
                                    label: this.props.t("button.cancel")
                                }

                            ]
                        })
                    }
                })
            }
        }

    }

    /**
     * 初始化播放器UI
     * @param {*} callback 
     */
    initPlayUI(callback) {
        this.setState({
            isShowControls: true,
            isShowSettingPannel: false,
            isShowSubtitlesPannel: false,
            isShowQualityPannel: false,
            isShowAudioPannel: false,
            isShowPlayList: false
        }, () => {
            if (callback) {
                this.behaviorRecords = 0;
                callback();
            }
        })
    }

    handleControlKey() {
        keycodeHandle({
            exit: this.handleExitKey,
            quit: this.handleExitKey,
            up: this.handleUpKey,
            down: this.handleDownKey,
            left: this.handleLeftKey,
            right: this.handleRightKey,
            pause: this.handlePauseKey,
            play: this.handlePlayKey,
            mediaRewind: this.handleMediaRewindKey,
            mediaFastForward: this.handleMediaFastForwardKey,
            prev: this.handlePrevKey,
            next: this.handleNextKey,
            click: this.handleOkKey
        });
    }

    handlePlayKey = () => {
        this.behaviorRecords = 0;
        if (this.state.isPause && !this.state.isShowContentGradePanel && !this.state.isPlayAd) {
            if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
                this.initPlayUI(() => {
                    $tv.requestFocus(this.playOrPauseRef.current);
                })
                this.playerRef.current.play();
            }
        }
    }

    handlePauseKey = () => {
        this.behaviorRecords = 0;
        if (!this.state.isPause && !this.state.isShowContentGradePanel && !this.state.isPlayAd) {
            if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
                this.initPlayUI(() => {
                    $tv.requestFocus(this.playOrPauseRef.current);
                })
                this.playerRef.current.pause();
            }
        }
    }

    handleMediaRewindKey = () => {
        if (!this.state.isShowContentGradePanel && !this.state.isPlayAd) {
            if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
                this.initPlayUI(() => {
                    $tv.requestFocus(this.rewindRef.current);
                })
                this.mediaRewind();
            }
        }
    }

    handleMediaFastForwardKey = () => {
        if (!this.state.isShowContentGradePanel && !this.state.isPlayAd) {
            if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
                this.initPlayUI(() => {
                    $tv.requestFocus(this.forwardRef.current);
                })
                this.mediaFastForward();
            }
        }
    }

    handlePrevKey = () => {
        this.behaviorRecords = 0;
        const { currentEpisode, playList, currentPlayContent, isShowContentGradePanel, isPlayAd } = this.state;
        if (!isShowContentGradePanel && !isPlayAd) {
            this.initPlayUI();
            if (playList && playList.length) {
                playList.forEach((item, index) => {
                    if (currentEpisode && currentPlayContent.contentId == item.contentId) {
                        if (index != 0) {
                            this.playEndReportToServer();
                            this.videoPlayRecordReport(0);
                            setTimeout(() => {
                                this.playNextEpisode(playList[index - 1]);
                            }, 2000)
                        }
                    }
                })
            }
        }
    }

    handleNextKey = () => {
        this.behaviorRecords = 0;
        const { currentEpisode, playList, currentPlayContent, isShowContentGradePanel, isPlayAd } = this.state;
        if (!isShowContentGradePanel && !isPlayAd) {
            this.initPlayUI();
            if (playList && playList.length) {
                playList.forEach((item, index) => {
                    if (currentEpisode && currentPlayContent.contentId == item.contentId) {
                        if (index != playList.length - 1) {
                            this.playEndReportToServer();
                            this.videoPlayRecordReport(0);
                            setTimeout(() => {
                                this.playNextEpisode(playList[index + 1]);
                            }, 2000)
                        }
                    }
                })
            }
        }
    }

    handleExitKey = () => {
        const { isShowControls, isShowSettingPannel, isShowQualityPannel, isShowAudioPannel, isShowSubtitlesPannel, isShowPlayList } = this.state;
        if (isShowControls) {
            this.setState({
                isShowControls: false
            });
            return
        }
        if (isShowAudioPannel) {
            this.setState({
                isShowAudioPannel: false
            });
            return
        }
        if (isShowQualityPannel) {
            this.setState({
                isShowQualityPannel: false
            });
            return
        }
        if (isShowSubtitlesPannel) {
            this.setState({
                isShowSubtitlesPannel: false
            });
            return
        }
        if (isShowPlayList) {
            this.setState({
                isShowEpisode: false,
                isShowPlayList: false
            });
            return
        }
        if (isShowSettingPannel) {
            this.setState({
                isShowSettingPannel: false
            });
            return
        }
        if (this.checkIsAdImageShow()) {
            this.hideImageAd();
            return
        }
        if (this.playStatusReportInterval) {
            clearInterval(this.playStatusReportInterval);
        }
        if (this.playBufferReportInterval) {
            clearInterval(this.playBufferReportInterval);
        }
        this.videoPlayRecordReport(0);
        this.playEndReportToServer(1);
       
    }
    
    handleOkKey = () => {
        const { isShowControls, isShowPlayList, isShowContinueWatching, isShowPurchase, isShowContentGradePanel, isShowSettingPannel, isShowAudioPannel, isShowSubtitlesPannel, isShowQualityPannel } = this.state;
        if (!isShowControls && !isShowPlayList && !isShowContinueWatching && !isShowPurchase && !isShowContentGradePanel && !isShowSettingPannel && !isShowAudioPannel && !isShowSubtitlesPannel && !isShowQualityPannel) {
            this.setState({
                isShowControls: true
            }, () => {
                this.behaviorRecords = 0;
                $tv.requestFocus(this.progressBarRef.current);
            })
        }
    }


    handleUpKey = () => {
        this.behaviorRecords = 0;
        if (!this.state.isShowControls && !this.state.isShowSettingPannel && !this.state.isShowSubtitlesPannel && !this.state.isShowQualityPannel && !this.state.isShowAudioPannel && !this.state.isShowPlayList && !this.state.isShowContinueWatching && !this.state.isShowPurchase && !this.state.isShowContentGradePanel && !this.state.isShowSettingPannel && !this.state.isShowAudioPannel && !this.state.isShowSubtitlesPannel && !this.state.isShowQualityPannel) {
            if (this.state.episodes.length && !this.state.isShowPlayList) {
                this.setState({
                    isShowEpisode: true,
                    isShowPlayList: true,
                }, () => {
                    // this.episodesRef.current.style.display = "block";
                    if (this.state.currentPlayContent) {
                        $tv.requestFocus(document.getElementById(this.state.currentEpisode.contentId));
                    }
                })
            } else {

                if (!this.state.isShowPurchase && !this.state.isShowContentGradePanel) {
                    this.setState({
                        isShowControls: true
                    }, () => {
                        this.behaviorRecords = 0;
                        $tv.requestFocus(this.progressBarRef.current);
                    })
                }
            }
        }
    }

    handleDownKey = () => {
        this.behaviorRecords = 0;
        const { isShowControls, isShowPlayList, isShowContinueWatching, isShowPurchase, isShowContentGradePanel, isShowSettingPannel, isShowAudioPannel, isShowSubtitlesPannel, isShowQualityPannel } = this.state;
        if (!isShowControls && !isShowPlayList && !isShowContinueWatching && !isShowPurchase && !isShowContentGradePanel && !isShowSettingPannel && !isShowAudioPannel && !isShowSubtitlesPannel && !isShowQualityPannel) {
            this.setState({
                isShowControls: true
            }, () => {
                this.behaviorRecords = 0;
                $tv.requestFocus(this.progressBarRef.current);
            })
        }
    }

    handleLeftKey = () => {
        this.behaviorRecords = 0;
        const { isShowControls, isShowPlayList, isShowContinueWatching, isShowPurchase, isShowContentGradePanel, isShowSettingPannel, isShowAudioPannel, isShowSubtitlesPannel, isShowQualityPannel } = this.state;
        if (!isShowControls && !isShowPlayList && !isShowContinueWatching && !isShowPurchase && !isShowContentGradePanel && !isShowSettingPannel && !isShowAudioPannel && !isShowSubtitlesPannel && !isShowQualityPannel) {
            this.setState({
                isShowControls: true
            }, () => {
                this.behaviorRecords = 0;
                $tv.requestFocus(this.progressBarRef.current);
            })
        }
    }

    handleRightKey = () => {
        this.behaviorRecords = 0;
        const { isShowControls, isShowPlayList, isShowContinueWatching, isShowPurchase, isShowContentGradePanel, isShowSettingPannel, isShowAudioPannel, isShowSubtitlesPannel, isShowQualityPannel } = this.state;
        if (!isShowControls && !isShowPlayList && !isShowContinueWatching && !isShowPurchase && !isShowContentGradePanel && !isShowSettingPannel && !isShowAudioPannel && !isShowSubtitlesPannel && !isShowQualityPannel) {
            this.setState({
                isShowControls: true
            }, () => {
                this.behaviorRecords = 0;
                $tv.requestFocus(this.progressBarRef.current);
            })
        }
    }

    /**
     * 广告隐藏
     */
    hideImageAd() {
        let adImageEls = document.querySelectorAll(".ad-images_container");
        adImageEls.forEach(item => {
            item.style.display = "none";
        });
    }

    /**
     * 检测是否有图片广告显示
     */
    checkIsAdImageShow() {
        let adImageEls = document.querySelectorAll(".ad-images_container");
        let hasAdShow = false;
        adImageEls.forEach(item => {
            if (item.style.display == 'block') {
                hasAdShow = true;
            }
        })
        return hasAdShow
    }

    /**
     * 获取点播详情
     */
    getDetail() {
        $request.boApi.vod.getVodInfo({
            mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
            contentId: this.state.contentId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    vodDetail: res.data.contentInfo,
                    playTypeTagText: this.props.t("tag.movie"),
                }, () => {
                    if (this.state.isSupportAd) {
                        this.formatAdPods();
                    }

                    if (this.state.vodDetail.contentGradeDto && this.state.vodDetail.contentGradeDto.icon) {
                        this.setState({
                            ageGrade: this.state.vodDetail.contentGradeDto
                        })
                    }
                    // 字幕处理
                    if (this.state.vodDetail.captions && this.state.vodDetail.captions && (!this.state.vodDetail.episodes || !this.state.vodDetail.episodes.length) && this.state.playType != GLOBAL.PLAY_TYPE.TRAILER && this.state.playType != GLOBAL.PLAY_TYPE.TIDBITS) {
                        let subtitlesList = deepClone(this.state.vodDetail.captions);
                        subtitlesList.unshift({
                            languageCode: this.props.t("player.close"),
                            active: true
                        })
                        this.setState({
                            subtitlesList: subtitlesList.map((item) => {
                                return {
                                    ...item,
                                    active: item.active ? item.active : false
                                }
                            })
                        })
                    } else if (this.state.playType == GLOBAL.PLAY_TYPE.TIDBITS) {
                        this.setState({
                            playList: this.state.vodDetail.titbits
                        }, () => {
                            this.renderPlayListSubtitle();
                        })

                    } else if (this.state.playType == GLOBAL.PLAY_TYPE.TRAILER) {
                        this.setState({
                            playList: this.state.vodDetail.trailers
                        }, () => {
                            this.renderPlayListSubtitle();
                        })

                    }
                    // 剧集处理
                    if (this.state.vodDetail.episodes && this.state.vodDetail.episodes.length && this.state.playType != GLOBAL.PLAY_TYPE.TRAILER && this.state.playType != GLOBAL.PLAY_TYPE.TIDBITS) {
                        this.setState({
                            playTypeTagText: this.props.t("tag.series"),
                            episodes: this.state.vodDetail.episodes,
                            playList: this.state.vodDetail.episodes
                        }, () => {
                            this.state.episodes.forEach(item => {
                                if (this.state.authId == item.contentId) {
                                    this.setState({
                                        currentEpisode: item,
                                        currentPlayContent: item
                                    })
                                    this.renderPlayListSubtitle();

                                }
                            })
                        })
                    } else {
                        // this.selectionRef.current.style.display = "none";
                        // if (this.state.playType != GLOBAL.PLAY_TYPE.TRAILER && this.state.playType != GLOBAL.PLAY_TYPE.TIDBITS) {
                        //     if (this.state.vodDetail.playProgress && this.state.vodDetail.gradeShow == "N") {
                        //         this.setState({
                        //             isShowContinueWatching: true
                        //         }, () => {
                        //             this.handleContinueButtonGroup();
                        //         })
                        //     }
                        // }
                    }

                });
                if (res.data.contentInfo.watermarkId) {
                    this.getWatermarkInfo(res.data.contentInfo.watermarkId);
                }
                this.getPlayAuth(this.state.authId ? this.state.authId : this.state.vodDetail.contentId);
                this.checkHeart();
            }
        })
    }

    /**
     * 播放中状态上报
     */
    playStatusReport() {
        const { contentId, authId, playType, playInfo, currentQuality, currentPlaySource, bufferCount, videoStartPlayTime } = this.state;
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_PLAY_EVENT,
            contentId: authId,
            type: playType,
            quality: currentQuality.label,
            playCode: recordPlayInfo.playCode,
            duration: this.reportCycleConfig.vodEventCycle,
            drmType: currentPlaySource.drmAuthUrl ? 2 : 0,
        })
    }

    /**
     * 播放记录上报
     */
    videoPlayRecordReport(isComplete) {
        const { contentId, authId, playType, playInfo, currentQuality, currentPlaySource, bufferCount, videoStartPlayTime } = this.state;
        if (this.playerRef && this.playerRef.current) {
            let recordPlayInfo = deepClone(playInfo);
            $request.collectionApi.event({
                eventId: GLOBAL.COLLECTION_EVENT.PLAYER_PLAY_RECORD,
                contentId: authId,
                type: playType,
                duration: parseInt(this.playerRef.current.currentTime()),
                playCode: recordPlayInfo.playCode,
                quality: currentQuality.label,
                complete: isComplete,
                drmType: currentPlaySource.drmAuthUrl ? 2 : 0,
                streamType: getVideoStreamType(currentPlaySource.playUrl),
                bufferCount,
                startTime: videoStartPlayTime,
                endTime: getUTCTime()
            })
        }
    }

    /**
     * 视频缓存情况上报
     */
    playBufferReport() {
        const { contentId, authId, playType, playInfo, currentQuality, currentPlaySource, bufferCount, videoStartPlayTime, buffers } = this.state;
        if (!buffers.length) {
            return
        }
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_BUFFER_EVENT,
            playBuffers: [{
                totalBufferCount: bufferCount,
                playCode: recordPlayInfo.playCode,
                buffers
            }]
        })
    }

    /**
     * 切换分辨率操作记录上报
     */
    playQualitysReport() {
        const { contentId, authId, playType, playInfo, currentQuality, videoStartPlayTime } = this.state;
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_QUALITY_EVENT,
            playQualitys: [{
                qualitys: {
                    vodChangeTime: parseInt((getUTCTime() - videoStartPlayTime) / 1000),
                    quality: currentQuality.label,
                },
                playCode: recordPlayInfo.playCode
            }]
        })
    }

    /**
     * 播放视频错误上报
     */
    playErrorReport(errorData) {
        const { contentId, authId, playType, playInfo, currentQuality, currentPlaySource, bufferCount, videoStartPlayTime } = this.state;
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_ERROR_EVENT,
            playErrors: [{
                ...errorData,
                playCode: recordPlayInfo.playCode,
                quality: currentQuality.label,
                type: playType,
                drmType: currentPlaySource.drmAuthUrl ? 2 : 0,
            }]
        })
    }

    formatAdPods() {
        
        if (this.state.isSupportAd && this.state.vodDetail.adPods && this.state.vodDetail.adPods.length && this.state.playType == GLOBAL.PLAY_TYPE.VOD) {

            // 过滤出banner广告和轨迹广告
            let allImageAdPodsList = deepClone(this.state.vodDetail.adPods).filter(item => {
                return item.podsType == GLOBAL.AD_MATERIAL_TYPE.BANNER || item.podsType == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY
            });
            // 过滤出视频广告
            let allVideoAdPodsList = deepClone(this.state.vodDetail.adPods).filter(item => {
                return item.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL || item.podsType == GLOBAL.AD_MATERIAL_TYPE.MID_ROLL || item.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL
            });
            let waitRequestImageAdPodsList = deepClone(allImageAdPodsList);
            let waitRequestVideoAdPodsList = deepClone(allVideoAdPodsList);
            allImageAdPodsList.forEach(item => {
                // 计算出广告荚的播放结束时间
                item.endDuration = amend(item.duration, item.displayDuration, "+");
            })
            allVideoAdPodsList.forEach(item => {
                item.status = GLOBAL.AD_PLAY_STATUS.NOT_PLAYED;
                // 计算出广告荚的播放结束时间
                item.endDuration = amend(item.duration, item.displayDuration, "+");
            })
            this.setState({
                allImageAdPodsList,
                allVideoAdPodsList,
                waitRequestImageAdPodsList,
                waitRequestVideoAdPodsList
            }, () => {
                if (this.state.waitRequestImageAdPodsList.length) {
                    this.getAdPods({
                        podsId: this.state.waitRequestImageAdPodsList[0].podsId
                    }, GLOBAL.AD_TYPE.IMAGE);
                }
                if (this.state.waitRequestVideoAdPodsList.length) {
                    this.getAdPods({
                        podsId: this.state.waitRequestVideoAdPodsList[0].podsId
                    }, GLOBAL.AD_TYPE.VIDEO);
                }
            })
        }
    }

    /**
     * 获取广告荚素材
     */
    getAdPods(params, adPodsType) {
        let promise = new Promise((resolve, reject) => {
            resolve();
        });
        if (!sessionStorage.getItem(GLOBAL.STORAGE_KEYS.SIGN)) {
            promise = deviceSign()
        }
        params.memberId = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) ? getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).memberId : 0;
        promise.then(() => {
            $request.adApi.getAdPods(params).then(res => {
                // console.log(res)
                if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                    if (res.data.body) {
                        let pods = rsaDecryptContent(res.data.body);
                        // console.log(pods)
                        if (pods && pods.materials && pods.materials.length) {

                            if (adPodsType == GLOBAL.AD_TYPE.IMAGE) {
                                // console.log(pods)
                                let allImageAdPodsList = deepClone(this.state.allImageAdPodsList);
                                allImageAdPodsList.forEach(item => {
                                    if (item.podsId == pods.podsId) {
                                        item.podsType = pods.podsType;
                                        item.materials = this.getMaterialDuration(item.duration, pods.materials, item);
                                    }
                                })
                                this.setState({
                                    allImageAdPodsList
                                })
                            } else if (adPodsType == GLOBAL.AD_TYPE.VIDEO) {
                                let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
                                allVideoAdPodsList.forEach(item => {
                                    if (item.podsId == pods.podsId) {
                                        item.podsType = pods.podsType;
                                        item.materials = this.getMaterialDuration(item.duration, pods.materials, item);
                                    }
                                })
                                this.setState({
                                    allVideoAdPodsList
                                })
                                console.log(allVideoAdPodsList)
                            }
                        }

                    }
                }
                // 获取到广告后，继续获取下一个
                this.getNextPods(adPodsType);
            }).catch(() => {
                // this.setState({
                //     isSupportAd: false
                // })
            })
        })

    }
    
    /**
     * 图片广告展现上报
     */
    adPodsImpressReport(item, materialItem) {
        const { user, playInfo } = this.state;
        if (flag) {
            return
        }
        flag = true;
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_IMPRESS_EVENT,
            materialName: materialItem.materialName,
            materialId: materialItem.materialId,
            adPodsName: item.podsName,
            adPodsId: item.podsId,
            type: materialItem.type
        }).then(() => {
            flag = false;
        });
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_VIEW_EVENT,
            materialId: materialItem.materialId,
            materialUrl: materialItem.url,
            adPodId: item.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[materialItem.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            // assetId: contentId,
            // contentId: (currentPlayContent && currentPlayContent.contentId) ? currentPlayContent.contentId : authId
        }
        if (playInfo) {
            let recordPlayInfo = deepClone(playInfo);
            collectionData.playCode = recordPlayInfo.playCode;
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        let adPodsBehavior = [];
        if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
            adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            adPodsBehavior.push(collectionData);
        } else {
            adPodsBehavior.push(collectionData);
        }
        setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
        this.reportAdPodsBehavior();
    }

    getNextPods(adPodsType) {
        if (adPodsType == GLOBAL.AD_TYPE.IMAGE) {
            let waitRequestImageAdPodsList = deepClone(this.state.waitRequestImageAdPodsList);
            waitRequestImageAdPodsList.shift(waitRequestImageAdPodsList[0]);
            this.setState({
                waitRequestImageAdPodsList
            }, () => {
                if (waitRequestImageAdPodsList.length) {
                    setTimeout(() => {
                        this.getAdPods({
                            podsId: waitRequestImageAdPodsList[0].podsId
                        }, GLOBAL.AD_TYPE.IMAGE);
                    }, 300);
                }
            })
        } else if (adPodsType == GLOBAL.AD_TYPE.VIDEO) {
            let waitRequestVideoAdPodsList = deepClone(this.state.waitRequestVideoAdPodsList);
            waitRequestVideoAdPodsList.shift(waitRequestVideoAdPodsList[0]);
            this.setState({
                waitRequestVideoAdPodsList
            }, () => {
                if (waitRequestVideoAdPodsList.length) {
                    setTimeout(() => {
                        this.getAdPods({
                            podsId: waitRequestVideoAdPodsList[0].podsId
                        }, GLOBAL.AD_TYPE.VIDEO);
                    }, 300)
                }
            })
        }
    }

    /**
     * 计算广告素材显示的开始时间点和结束时间点
     * @param {*} startDuration 广告荚开始显示时间点
     * @param {*} materials 广告荚素材列表
     */
    getMaterialDuration(startDuration, materials, pods) {
        let materialList = deepClone(materials);
        materialList.forEach((item, index) => {
            item.isShow = false;
            item.podsId = pods.podsId;
            if (index == 0 || materialList.length == 1) {
                item.startDuration = parseInt(startDuration);
                item.endDuration = amend(parseInt(amend(item.startDuration, item.displayDuration, "+")), 1, '-');
            } else {
                item.startDuration = amend(parseInt(materialList[index - 1].endDuration), 1, '+');
                item.endDuration = amend(parseInt(amend(materialList[index - 1].endDuration, item.displayDuration, "+")), 1, '-');
            }
        })
        return materialList
    }

    /**
     * 渲染播放列表字幕
     */
    renderPlayListSubtitle() {
        this.state.playList.forEach(item => {
            if (this.state.authId == item.contentId) {
                this.setState({
                    currentPlayContent: item
                })
                let subtitlesList = deepClone(item.captions);
                subtitlesList.unshift({
                    languageCode: this.props.t("player.close"),
                    active: true
                })
                this.setState({
                    subtitlesList: subtitlesList.map((item) => {
                        return {
                            ...item,
                            active: item.active ? item.active : false
                        }
                    })
                })
            }
        })
        this.selectionRef.current.style.display = "flex";
        this.handleSelection();
    }

    /**
     * 获取水印
     */
    getWatermarkInfo(watermarkId) {
        $request.boApi.system.getWatermarkInfo({
            watermarkId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    watermarkInfo: res.data.data
                })
            }
        })
    }

    /**
     * 获取DRM Token
     * @param {*} contentId 
     */
    getDrmToken(contentId) {
        $request.boApi.common.getDrmToken({
            contentId,
            contentType: GLOBAL.MEDIA_TYPE.VOD,
            drmType: GLOBAL.DRM_CODE.VMX
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    customPlayerHeaders: {
                        "Authorization": res.data.token
                    }
                })
            }
        })
    }

    checkContentGrade(callback) {
        const { vodDetail, contentGradePannelCountingDown, isShowContentGragePannelSkip, gradeShowEnded } = this.state;
        this.contentGradeCallback = callback;
        console.log('_)(_)(_)(_)(_)(_)(');
        if (vodDetail.gradeShow == "Y" && vodDetail.contentGradeDto && vodDetail.contentGradeDto.countingDown && !gradeShowEnded) {
            this.setState({
                isShowContentGradePanel: true,
                isShowContentGragePannelSkip: false,
                contentGradePannelCountingDown: deepClone(vodDetail.contentGradeDto).countingDown
            }, () => {
                if (this.playerRef && this.playerRef.current) {
                    this.playerRef.current.pause();
                }
                if (this.contentGradeTimer) {
                    clearInterval(this.contentGradeTimer);
                    this.contentGradeTimer = null;
                }
                let countingDown = deepClone(vodDetail.contentGradeDto).countingDown;
                this.contentGradeTimer = setInterval(() => {
                    if (countingDown > 0) {
                        if (this.playerRef && this.playerRef.current) {
                            this.playerRef.current.pause();
                        }
                        countingDown--;
                        this.setState({
                            contentGradePannelCountingDown: countingDown
                        })
                        if (vodDetail.contentGradeDto.skip) {
                            if ((vodDetail.contentGradeDto.skipDuration > countingDown) || (vodDetail.contentGradeDto.countingDown - countingDown >= vodDetail.contentGradeDto.skipDuration)) {
                                this.setState({
                                    isShowContentGragePannelSkip: true
                                }, () => {
                                    $tv.requestFocus(this.contentGradeSkipRef.current);
                                    this.handleListenerSkipContentGrade();
                                })
                            }
                        }
                    } else {
                        clearInterval(this.contentGradeTimer);
                        this.contentGradeTimer = null;
                        this.handleSkipContentGrade();
                        // callback();
                    }
                }, 1000)
            })
        } else {
            this.handleSkipContentGrade();
        }
    }

    /**
     * 点击跳过内容评级
     */
    handleSkipContentGrade() {
        if (this.contentGradeTimer) {
            clearInterval(this.contentGradeTimer);
            this.contentGradeTimer = null;
        }
        this.setState({
            isShowContentGradePanel: false,
            isShowContentGragePannelSkip: false,
            gradeShowEnded: true
        }, () => {
            if (this.playerRef && this.playerRef.current) {
                if (this.contentGradeCallback) {
                    this.contentGradeCallback();
                }
                // this.playerRef.current.play();
            }
        })
    }

    handleListenerSkipContentGrade() {
        this.contentGradeSkipRef.current.addEventListener("click", () => {
            this.handleSkipContentGrade();
        })
        this.contentGradeSkipRef.current.addEventListener("left", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("right", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("up", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("down", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
    }

    /**
     * 播放鉴权
     */
    getPlayAuth(contentId, isPreview) {
        this.resetPlayerStatus();
        $request.boApi.common.getPlayAuth({
            mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
            contentId,
            type: GLOBAL.MEDIA_TYPE.VOD,
            playType: isPreview ? GLOBAL.PLAY_TYPE.PREVIEW : ""
        }).then(res => {
            console.log(res.data)
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.settingPannelList = [this.subtitlesSettingRef.current];
                if (this.state.vodDetail && this.state.vodDetail.contentGradeDto) {
                    this.setState({
                        contentGradePannelCountingDown: this.state.vodDetail.contentGradeDto.countingDown,
                    })
                }
                this.updateTime = getUTCTime();
                let playInfo = res.data;
                // 判断是否是streamKeeper
                if (playInfo.streamKeeperDto) {
                    let source = {
                        drmAuthUrl: playInfo.streamKeeperDto.drmAuthUrl,
                        playUrl: ""
                    }
                    playInfo.streamKeeperDto.playUrlList.forEach(item => {
                        if (item.isDefault) {
                            source.playUrl = item.playUrl;
                        }
                    })
                    if (!source.playUrl) {
                        if (playInfo.streamKeeperDto.playUrlList.length) {
                            source.playUrl = playInfo.streamKeeperDto.playUrlList[0].playUrl;
                        }
                    }
                    setTimeout(() => {
                        this.setState({
                            gradeShowEnded: false,
                            currentPlaySource: source,
                            bufferCount: 0,
                            playInfo: {
                                ...playInfo.streamKeeperDto,
                                playCode: playInfo.playCode
                            },
                            customPlayerHeaders: {
                                "Authorization": playInfo.streamKeeperDto.drmToken
                            }
                        }, () => {
                            // if (!this.state.isSupportAd) {
                            //     this.checkContentGrade();
                            // }
    
                        })
                    }, 300)
                } else {
                    let source = {
                        drmAuthUrl: playInfo.drmAuthUrl,
                        playUrl: ""
                    }
                    playInfo.playUrlList.forEach(item => {
                        if (item.isDefault) {
                            source.playUrl = item.playUrl;
                        }
                    })
                    setTimeout(() => {
                        this.setState({
                            currentPlaySource: source,
                            playInfo,
                            customPlayerHeaders: null,
                            gradeShowEnded: false
                        }, () => {
                            // if (!this.state.isSupportAd) {
                            //     this.checkContentGrade();
                            // }
                        })
                    }, 300)
                }
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.NEED_BY) {
                this.setState({
                    isShowPurchase: true
                }, () => {
                    if (this.purchaseButtonGroupRef && this.purchaseButtonGroupRef.length) {
                        $tv.requestFocus(this.purchaseButtonGroupRef[0]);
                        this.handlePurchaseButtonGroup();
                    }
                    // if (!this.state.isSupportAd) {
                    //     this.checkContentGrade();
                    // }
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.USER_NOT_LOGIN) {
                this.setState({
                    isShowSignIn: true
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
                setTimeout(() => {
                    this.setState({
                        isShowPlayerTips: true,
                        playerTips: this.props.t("toast.deviceLimit")
                    })
                }, 300)
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.DRM_TOKEN_ERROR) {
                setTimeout(() => {
                    this.setState({
                        isShowPlayerTips: true,
                        playerTips: this.props.t("toast.drmTokenError")
                    })
                }, 300)
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.NOT_EXIST) {
                setTimeout(() => {
                    this.setState({
                        isShowPlayerTips: true,
                        playerTips: this.props.t("toast.addressExist")
                    })
                },300)
            }
        })
    }


    /**
     * 播放器状态重置
     */
    resetPlayerStatus() {
        this.hideImageAd();
        let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
        allVideoAdPodsList.forEach(item => {
            item.status = GLOBAL.AD_PLAY_STATUS.NOT_PLAYED;
        })
        this.setState({
            currentProgressWidth: 0,
            allVideoAdPodsList,
            gradeShowEnded: false,
            isShowContinueWatching: false,
            isContinueWatchingShow: false
        })
    }

    /**
     * 心跳检测
     */
    checkHeart() {
        $request.boApi.common.checkHeart({
            mac: getUUID()
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    isShowPlayerTips: false
                })
                this.checkHeartInterval = setTimeout(() => {
                    this.checkHeart();
                }, 3 * 60 * 1000)
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
                this.setState({
                    isShowPlayerTips: true,
                    playerTips: this.props.t("toast.deviceLimit")
                })
            }
        })
    }

    /**
     * 按钮组监听
     */
    handleContinueButtonGroup() {
        $tv.requestFocus(this.continueButtonGroupRef[0]);
        this.continueButtonGroupRef.forEach((el, index) => {
            el.addEventListener("click", () => {
                switch (this.state.continueButtonGroup[index].value) {
                    case 1:
                        this.setState({
                            isShowContinueWatching: false,
                            isContinueWatchingShow: true
                        })
                        if (this.state.vodDetail && this.state.vodDetail.contentGradeDto && this.state.vodDetail.gradeShow == "Y") {
                            this.checkContentGrade(() => {
                                this.setState({
                                    gradeShowEnded: true
                                }, () => {
                                    if (this.playerRef && this.playerRef.current) {
                                        // 处理如果记录时长与结束时长差不多，就直接重播
                                        let isLastEpisode = true;
                                        if (this.state.episodes.length) {
                                            this.state.episodes.forEach((item, index) => {
                                                if (item.contentId == this.state.currentEpisode.contentId) {
                                                    if (this.state.vodDetail.playProgress && this.state.vodDetail.playSort && item.sort == this.state.vodDetail.playSort) {
                                                       if (index != this.state.episodes.length - 1) {
                                                            isLastEpisode = false;
                                                       }
                                                    }
                                                }
                                            })
                                        }
                                        if (isLastEpisode) {
                                            if (this.playerRef.current.duration() - Number(this.state.vodDetail.playProgress) < 5) {
                                                this.playerRef.current.currentTime(0);
                                            } else {
                                                this.playerRef.current.currentTime(this.state.vodDetail.playProgress);
                                            }
                                            
                                        } else {
                                            this.playerRef.current.currentTime(this.state.vodDetail.playProgress);
                                        }
                                        this.playerRef.current.play();
                                    }
                                    setTimeout(() => {
                                        this.setState({
                                            isShowTypeTag: false
                                        });
                                    }, 10 * 1000)
                                })
                            })
                        } else {
                            if (this.playerRef && this.playerRef.current) {
                                this.playerRef.current.currentTime(this.state.vodDetail.playProgress);
                                this.playerRef.current.play();
                            }
                        }
                        
                        break;
                    case 2:
                        let vodDetail = deepClone(this.state.vodDetail);
                        vodDetail.playProgress = 0;
                        this.setState({
                            isShowContinueWatching: false,
                            isContinueWatchingShow: true,
                            vodDetail
                        })
                        this.playerRef.current.currentTime(0);
                        this.playerRef.current.play();
                        break;
                    case 3:
                        window.history.back();
                        break;
                }
            });
            el.addEventListener("left", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el);
                }
            })
            el.addEventListener("down", () => {
                if (index == this.state.continueButtonGroup.length - 1) {
                    $tv.requestFocus(el);
                }
            })
        })
    }

    /**
     * 购买按钮组监听
     */
    handlePurchaseButtonGroup() {
        this.purchaseButtonGroupRef.forEach((el, index) => {
            el.addEventListener("click", () => {
                switch (this.state.purchaseButtonGroup[index].value) {
                    case 1:
                        setStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD, window.location.href);
                        this.props.history.push(`/package?contentId=${this.state.currentPlayContent ? this.state.currentPlayContent.contentId : (this.state.authId ? this.state.authId : this.state.vodDetail.contentId)}&type=${GLOBAL.MEDIA_TYPE.VOD}`)
                        break;
                    case 2:
                        window.history.back();
                        break;
                }
            });
            el.addEventListener("left", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el);
                }
            })
            el.addEventListener("down", () => {
                if (index == this.state.purchaseButtonGroup.length - 1) {
                    $tv.requestFocus(el);
                }
            })
        })
    }

    playerEvent(eventName, data) {
        let buffersStart = getUTCTime();
        console.log(eventName);
        switch (eventName) {
            case 'timeupdate':
                if (this.state.isShowContinueWatching) {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.pause();
                    }
                    
                }
                this.timeUpdate();
                break;
            case 'ended':
                this.playEnd();
                break;
            case 'loading':
                buffersStart = getUTCTime();
                break;
            case 'loaded':
                
                this.setState({
                    resolutionList: this.playerRef && this.playerRef.current ? this.playerRef.current.getResolutionList() : [],
                    audioTrackList: this.playerRef && this.playerRef.current ? this.playerRef.current.getAudioTrackList() : [],
                    videoStartPlayTime: getUTCTime(),

                }, () => {
                    let currentQuality = this.state.resolutionList.filter(item => {
                        return item.active == true
                    })
                    let currentAudio = this.state.audioTrackList.filter(item => {
                        return item.active == true
                    })
                    if (this.playStatusReportInterval) {
                        clearInterval(this.playStatusReportInterval);
                    }
                    if (this.playBufferReportInterval) {
                        clearInterval(this.playBufferReportInterval);
                    }
                    this.playStatusReportInterval = setInterval(() => {
                        this.playStatusReport();
                    }, this.reportCycleConfig.vodEventCycle * 1000);
                    this.playBufferReportInterval = setInterval(() => {
                        this.playBufferReport();
                    }, this.reportCycleConfig.bufferEventCycle * 1000)
                    // if (this.state.resolutionList.length) {
                    //     this.settingPannelList.push(this.qualitySettingRef.current);
                    // }
                    // if (this.state.audioTrackList.length) {
                    //     this.settingPannelList.push(this.audioSettingRef.current);
                    // }
                    this.setState({
                        currentAudio: currentAudio.length ? currentAudio[0] : null,
                        currentQuality: currentQuality.length ? currentQuality[0] : null
                    }, () => {
                        console.log(this.state.currentQuality);
                        let buffers = deepClone(this.state.buffers);
                        buffers.push({
                            vodStartTime: buffersStart,
                            bufferTime: parseInt((getUTCTime() - buffersStart) / 1000),
                            quality: this.state.currentQuality ? this.state.currentQuality.label : "auto",
                            drmType: this.state.currentPlaySource.drmAuthUrl ? 2 : 0,
                        });
                        this.setState({
                            buffers
                        })
                    })
                })
                console.log(this.state.isShowContinueWatching, '000000000-22222222222')
                if (this.state.isShowContinueWatching) {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.pause();
                    }
                    
                }
                if (!this.state.isContinueWatchingShow && !this.state.isShowContinueWatching) {
                    console.log('320943-2094-2092938402~~~~~~~')
                    this.checkShowContinueOrContentGrade();
                }
                setTimeout(() => {
                    this.setState({
                        isShowTypeTag: false
                    })
                }, 10 * 1000)
                break;
            case 'pause':
                this.setState({
                    isPause: true
                })
                break;
            case 'playing':
                this.setState({
                    isPause: false
                })
                break;
            case 'adaptation':
                this.setState({
                    bufferCount: this.state.bufferCount + 1
                })
                break;
        }
    }

    /**
     * 设置进度到指定时间
     */
    setPlayToTime() {
        // if (this.state.isSupportAd && this.state.allVideoAdPodsList.length) {
        // if (this.state.playType == GLOBAL.PLAY_TYPE.VOD) {
        //     if (this.state.episodes.length) {
        //         let allowSkip = getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP);
        //         let isCurrentEpisode = false;
        //         this.state.episodes.forEach(item => {
        //             if (item.contentId == this.state.currentEpisode.contentId) {
        //                 if (this.state.vodDetail.playProgress && this.state.vodDetail.playSort && item.sort == this.state.vodDetail.playSort) {
        //                     isCurrentEpisode = true;
        //                 }
        //             }
        //         })
        //     } else {
        //         if (this.state.vodDetail.playProgress) {
        //             this.setState({
        //                 isShowContinueWatching: true
        //             }, () => {
        //                 this.handleContinueButtonGroup();
        //             })
        //         } 
        //     }
        // }
        // } else {
        // if (this.state.playType == GLOBAL.PLAY_TYPE.VOD) {
        //     if (this.state.episodes.length) {
        //         // let allowSkip = getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP);
        //         // let isCurrentEpisode = false;
        //         // this.state.episodes.forEach(item => {
        //         //     if (item.contentId == this.state.currentEpisode.contentId) {
        //         //         if (this.state.vodDetail.playProgress && this.state.vodDetail.playSort && item.sort == this.state.vodDetail.playSort) {
        //         //             isCurrentEpisode = true;
        //         //         }
        //         //     }
        //         // })
        //         if (this.state.vodDetail.gradeShow == "N") {

        //         }
        //         if (isCurrentEpisode) {

        //         } else {
        //             if (allowSkip) {
        //                 this.playerRef.current.currentTime(this.state.currentEpisode.titleDuration);
        //             }
        //         }

        //     } else {
        //         if (this.state.vodDetail.playProgress && this.state.vodDetail.gradeShow == "N") {
        //             this.setState({
        //                 isShowContinueWatching: true
        //             }, () => {
        //                 this.handleContinueButtonGroup();
        //             })
        //         }
        //     }
        // }
        // }
    }

    checkShowContinueOrContentGrade() {
        // if (!this.state.isContinueWatchingShow) {

        // }
        if (this.state.isSupportAd && this.state.allVideoAdPodsList.length) {
            let hasPreRoll = false;
            this.state.allVideoAdPodsList.forEach(item => {
                if (item.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL) {
                    hasPreRoll = true;
                }
            });
            if (hasPreRoll) {
                this.playSkipToTime();
            } else {
                this.checkContentGrade(() => {
                    this.playSkipToTime();
                })
            }
            
        } else {
            this.checkContentGrade(() => {
                this.playSkipToTime();
            })
        }
    }

    playSkipToTime() {
        if (this.state.playType == GLOBAL.PLAY_TYPE.VOD) {
            if (this.state.episodes.length) {
                let allowSkip = getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP);
                let isCurrentEpisode = false;
                this.state.episodes.forEach(item => {
                    if (item.contentId == this.state.currentEpisode.contentId) {
                        if (this.state.vodDetail.playProgress && this.state.vodDetail.playSort && item.sort == this.state.vodDetail.playSort) {
                            isCurrentEpisode = true;
                        }
                    }
                })
                if (isCurrentEpisode) {
                    if (allowSkip) {
                        if (this.state.vodDetail.playProgress) {
                            this.setState({
                                isShowContinueWatching: true
                            }, () => {
                                this.handleContinueButtonGroup();
                            })
                        } else {
                            if (!this.state.isSupportAd || !this.state.allVideoAdPodsList.length) {
                                if (this.playerRef && this.playerRef.current) {
                                    this.playerRef.current.currentTime(this.state.currentEpisode.titleDuration);
                                    this.playerRef.current.play();
                                }
                            }
                        }

                    } else {
                        if (this.state.vodDetail.playProgress) {
                            this.setState({
                                isShowContinueWatching: true
                            }, () => {
                                this.handleContinueButtonGroup();
                            })
                        } else {
                            if (this.playerRef && this.playerRef.current) {
                                this.playerRef.current.play();
                            }
                            
                        }
                    }
                } else {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.play();
                    }
                }
            } else {
                if (this.state.vodDetail.playProgress) {
                    this.setState({
                        isShowContinueWatching: true
                    }, () => {
                        this.handleContinueButtonGroup();
                    })
                } else {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.play();
                    }
                    
                }
            }
        } else {
            if (this.playerRef && this.playerRef.current) {
                this.playerRef.current.play();
            }
        }
    }

    playEnd() {
        const { isSupportAd, isPlayAd, episodes, playType, currentEpisode } = this.state;
        // if (isSupportAd) {

        // } else {
        if (!isPlayAd) {
            this.playEndChecked();

        }
        // }
    }

    playEndChecked() {
        const { episodes, currentEpisode, playList, currentPlayContent } = this.state;
        this.videoPlayRecordReport(1);
        this.playEndReportToServer();
        if (playList && playList.length) {
            playList.forEach((item, index) => {
                if (currentEpisode && currentPlayContent.contentId == item.contentId) {
                    
                    if (index != playList.length - 1) {
                        setTimeout(() => {
                            this.playNextEpisode(playList[index + 1]);
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            window.history.back();
                        }, 2000)
                    }
                }
            })
        } else {
            setTimeout(() => {
                window.history.back();
            }, 2000)
        }
    }

    /**
     * 播放下一集
     * @param {*} data 
     */
    playNextEpisode(data) {
        let subtitlesList = data.captions;
        subtitlesList.unshift({
            languageCode: this.props.t("player.close"),
            active: true
        })
        let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
        allVideoAdPodsList.forEach(item => {
            item.status = GLOBAL.AD_PLAY_STATUS.NOT_PLAYED;
        })
        this.setState({
            currentEpisode: data,
            currentPlayContent: data,
            currentProgressWidth: 0,
            allVideoAdPodsList,
            subtitlesList: subtitlesList.map((item) => {
                return {
                    ...item,
                    active: item.active ? item.active : false
                }
            })
        }, () => {
            this.getPlayAuth(this.state.currentPlayContent.contentId);
        })
    }

    /**
     * 数据上报
     */
    playEndReportToServer(isBack) {
        if (this.state.playType != GLOBAL.PLAY_TYPE.VOD) {
            window.history.back();
            return
        }
        if (!this.playerRef || !this.playerRef.current || this.state.playType != GLOBAL.PLAY_TYPE.VOD) {
            window.history.back();
            return
        }
        if (this.checkHeartInterval) {
            clearInterval(this.checkHeartInterval);
            this.checkHeartInterval = null;
        }
        const { episodes, currentEpisode, playInfo, vodDetail, contentId, authId } = this.state;
        let recordPlayContent = currentEpisode ? deepClone(currentEpisode) : vodDetail;
        let recordPlayInfo = deepClone(playInfo);
        let recordData = {
            type: GLOBAL.MEDIA_TYPE.VOD,
            assetId: contentId,
            playId: recordPlayInfo.playCode,
            playName: vodDetail.name,
            playSort: 0,
            playProgress: parseInt(this.playerRef.current.currentTime())
        }
        let durationData = {
            contentId: authId,
            type: GLOBAL.MEDIA_TYPE.VOD,
            playCode: recordPlayInfo.playCode,
            playDuration: parseInt(this.playerRef.current.currentTime())
        }
        if (episodes.length) {
            recordData.playSort = recordPlayContent.sort;
            durationData.contentId = currentEpisode.contentId
        }
        $request.boApi.common.playRecord(recordData).then(res => {
            if (isBack) {
                window.history.back();
            }
        });
        $request.boApi.common.playDuration(durationData);
    }

    timeUpdate() {
        if (this.playerRef && this.playerRef.current) {
            this.changeTime(this.playerRef.current.currentTime());
            // console.log(this.state.allVideoAdPodsList)
            if (!this.state.isShowContinueWatching) {
                if (checkTimeUpdate == 0) {
                    checkTimeUpdate = getUTCTime();
                }
                // console.log(getUTCTime() - checkTimeUpdate >= 1000, this.playerRef.current.currentTime(), this.playerRef.current.duration())
                if ((getUTCTime() - checkTimeUpdate >= 1000) && checkTimeUpdate != 0) {
                    this.behaviorRecords++;
                    if (this.behaviorRecords >= 10) {
                        this.setState({
                            isShowControls: false
                        }, () => {
                            this.behaviorRecords = 0;
                        })
                    }
                    this.checkImageAdShow(parseInt(this.playerRef.current.currentTime()));
                    if (this.state.allVideoAdPodsList && this.state.allVideoAdPodsList.length) {
                        this.checkVideoAdShow(this.playerRef.current.currentTime());
                    }
                    
                    checkTimeUpdate = 0; 
                }
            }

        }

        setTimeout(() => {
            this.setProgress();
        }, 1000)
    }

    /**
     * 检测图片广告是否显示
     * @param {*} duration 
     */
    checkImageAdShow(currentTime) {
        const { allImageAdPodsList, playInfo } = this.state;
        //    console.log(allImageAdPodsList)
        allImageAdPodsList.forEach(item => {
            if (currentTime >= item.duration && currentTime <= item.endDuration) {
                if (item.materials) {
                    item.materials.forEach(materialItem => {
                        // console.log(materialItem)
                        let adMaterialElParent = this.adImageParentMapRef[`adImagesContainer${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                        let adMaterialEl = this.adImageMapRef[`adItem${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                        if (currentTime >= materialItem.startDuration && currentTime < materialItem.endDuration && materialItem.isShow == false) {
                            // console.log(1111)
                            if (materialItem.isShow == false) {
                                materialItem.isShow = true;
                                materialItem.timeCode = currentTime;
                                // console.log(adMaterialElParent)
                                adMaterialElParent.style.display = 'block';
                                if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                    setTimeout(() => {
                                        Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                            adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                        });
                                        setTimeout(() => {
                                            Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                                adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                            });
                                        }, 300)
                                    }, 300)
                                }
                                this.adPodsImpressReport(item, materialItem);
                                if (this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]) {
                                    let adPodsBehavior = [];
                                    if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                        adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                        adPodsBehavior.push(this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    } else {
                                        adPodsBehavior.push(this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    }
                                    setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                                }
                                if (this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]) {
                                    let adPodsBehavior = [];
                                    if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                        adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                        adPodsBehavior.push(this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    } else {
                                        adPodsBehavior.push(this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    }
                                    setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                                }
                            }
                        } else if (currentTime >= materialItem.endDuration) {
                            if (materialItem.isShow) {
                                if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                    Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                        adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                    });
                                    setTimeout(() => {
                                        Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                            adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                        });
                                    }, 300)
                                    setTimeout(() => {
                                        materialItem.isShow = false;
                                        adMaterialElParent.style.display = 'none';
                                    }, 600)
                                } else {
                                    materialItem.isShow = false;
                                    adMaterialElParent.style.display = 'none';
                                }
                                let collectionData = {
                                    eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_PLAY_RECORD,
                                    materialId: materialItem.materialId,
                                    materialUrl: materialItem.url,
                                    adPodId: materialItem.podsId,
                                    adType: GLOBAL.COLLECTION_AD_TYPE[materialItem.type],
                                    videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
                                    timeCode: materialItem.timeCode ? materialItem.timeCode : materialItem.startDuration,
                                    skipAd: 0,
                                    loadTime: 0,
                                    loadError: 0,
                                    loadErrorMessage: "",
                                    playDuration: materialItem.endDuration - materialItem.startDuration,
                                    // assetId: contentId,
                                    // contentId: (currentPlayContent && currentPlayContent.contentId) ? currentPlayContent.contentId : authId
                                }
                                if (playInfo) {
                                    let recordPlayInfo = deepClone(playInfo);
                                    collectionData.playCode = recordPlayInfo.playCode;
                                }
                                if (collectionData.playDuration >= materialItem.displayDuration) {
                                    collectionData.fullPlay = 1;
                                } else {
                                    collectionData.fullPlay = 1;
                                }
                                let adPodsBehavior = [];
                                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                    adPodsBehavior.push(collectionData);
                                } else {
                                    adPodsBehavior.push(collectionData);
                                }
                                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                            }

                        } else if (currentTime < materialItem.startDuration) {
                            if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                    adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                });
                                setTimeout(() => {
                                    Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                        adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                    });
                                }, 300)
                                setTimeout(() => {
                                    materialItem.isShow = false;
                                    adMaterialElParent.style.display = 'none';
                                }, 600)
                            } else {
                                materialItem.isShow = false;
                                adMaterialElParent.style.display = 'none';
                            }
                            materialItem.isShow = false;
                            adMaterialElParent.style.display = 'none';
                        }
                    })
                }
            } else {
                if (currentTime < item.duration) {
                    if (item.materials) {
                        item.materials.forEach(materialItem => {
                            // console.log(materialItem)
                            let adMaterialElParent = this.adImageParentMapRef[`adImagesContainer${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                            let adMaterialEl = this.adImageMapRef[`adItem${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                            if (currentTime < materialItem.startDuration) {
                                if (materialItem.isShow) {
                                    if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                        Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                            adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                        });
                                        setTimeout(() => {
                                            Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                                adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                            });
                                        }, 300)
                                        setTimeout(() => {
                                            materialItem.isShow = false;
                                            adMaterialElParent.style.display = 'none';
                                        }, 600)
                                    } else {
                                        materialItem.isShow = false;
                                        adMaterialElParent.style.display = 'none';
                                    }
                                }
                            }
                        })
                    }
                }
            }
        })
    }

    /**
     * 检测视频广告是否播放
     * @param {*} duration 
     */
    checkVideoAdShow(duration) {
        let currentTime = parseInt(duration);
        let totalDuration = 0;
        if (this.playerRef && this.playerRef.current) {
            totalDuration = parseInt(this.playerRef.current.duration());
        }
        if (currentTime == 0) {
            let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
            let waitPlayAdPodsList = []; // 待播视频广告
            waitPlayAdPodsList = allVideoAdPodsList.filter((item, index) => {
                return item.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED
            })
            // console.log(waitPlayAdPodsList, '-----')
            if (waitPlayAdPodsList.length) {
                this.checkWaitPlayAdVideoPods([deepClone(waitPlayAdPodsList)[0]]);
                this.changeOtherAdStatus(waitPlayAdPodsList);
            } else {
                this.preRollAdVideoPlayEnded();
            }
        } else if (currentTime == totalDuration && currentTime != 0) {
            let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
            let waitPlayAdPodsList = []; // 待播视频广告
            waitPlayAdPodsList = allVideoAdPodsList.filter((item, index) => {
                return item.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED
            })
            if (waitPlayAdPodsList.length) {
                this.checkWaitPlayAdVideoPods([deepClone(waitPlayAdPodsList)[0]]);
                this.changeOtherAdStatus(waitPlayAdPodsList);
            }
        } else {
            let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
            let waitPlayAdPodsList = []; // 待播视频广告
            waitPlayAdPodsList = allVideoAdPodsList.filter((item, index) => {
                return item.podsType == GLOBAL.AD_MATERIAL_TYPE.MID_ROLL && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED && currentTime == item.duration
            })
            if (waitPlayAdPodsList.length) {
                this.checkWaitPlayAdVideoPods([deepClone(waitPlayAdPodsList)[0]]);
                this.changeOtherAdStatus(waitPlayAdPodsList);
            }
        }
    }

    /**
     * 改变除第一个的其他广告状态
     * @param {*} waitPlayAdPodsList 
     */
    changeOtherAdStatus(waitPlayAdPodsList) {
        if (waitPlayAdPodsList.length > 1) {
            let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
            allVideoAdPodsList.forEach(item => {
                waitPlayAdPodsList.forEach((child, childIndex) => {
                    if (childIndex != 0) {
                        if (item.podsId == child.podsId) {
                            item.status = GLOBAL.AD_PLAY_STATUS.END_ROLL;
                        }
                    }
                })
            })
            this.setState({
                allVideoAdPodsList
            })
        }
    }

    /**
     * 片头播放结束
     */
    preRollAdVideoPlayEnded() {
        let allowSkip = getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP);
        if (allowSkip) {
            if (this.state.episodes.length) {
                if (this.state.currentEpisode.titleDuration) {
                    this.playerRef.current.currentTime(this.state.currentEpisode.titleDuration);
                } else {
                    this.playerRef.current.currentTime(1);
                }
                
            }
        }
    }

    /**
     * 检测当前要播放的素材
     * @param {*} waitPlayAdPodsList 
     */
    checkWaitPlayAdVideoPods(waitPlayAdPodsList) {
        let currentTime = 0;
        let totalDuration = 0;
        if (this.playerRef && this.playerRef.current) {
            totalDuration = this.playerRef.current.duration();
        }
        if (this.playerRef && this.playerRef.current) {
            currentTime = parseInt(this.playerRef.current.currentTime());
        }
        waitPlayAdPodsList.forEach(item => {
            if (item.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL && currentTime == 0 && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED) {
                item.status = GLOBAL.AD_PLAY_STATUS.PLAYING;
                // console.log(item.materials)
                if (item.materials && item.materials.length) {
                    this.playVideoAd(item.materials, item);
                } else {
                    item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
                    allVideoAdPodsList.forEach(podsItem => {
                        if (podsItem.podsId == item.podsId) {
                            podsItem.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                        }
                    })
                    this.setState({
                        allVideoAdPodsList
                    })
                    if (this.state.vodDetail && this.state.vodDetail.contentGradeDto && this.state.vodDetail.gradeShow == "Y") {
                        this.playerRef.current.pause();
                        this.checkContentGrade(() => {
                            this.setState({
                                gradeShowEnded: true
                            }, () => {
                                this.playerRef.current.play();
                                setTimeout(() => {
                                    this.setState({
                                        isShowTypeTag: false
                                    });
                                }, 10 * 1000)
                            })
                        })
                    }
                }
            } else if (item.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL && currentTime != 0 && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED && currentTime == parseInt(totalDuration)) {
                item.status = GLOBAL.AD_PLAY_STATUS.PLAYING;
                if (item.materials && item.materials.length) {
                    this.playVideoAd(item.materials, item);
                } else {
                    item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
                    allVideoAdPodsList.forEach(podsItem => {
                        if (podsItem.podsId == item.podsId) {
                            podsItem.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                        }
                    })
                    this.setState({
                        allVideoAdPodsList
                    })
                }
            } else if (currentTime == item.duration && currentTime != 0 && item.status == GLOBAL.AD_PLAY_STATUS.NOT_PLAYED) {
                item.status = GLOBAL.AD_PLAY_STATUS.PLAYING;
                if (item.materials && item.materials.length) {
                    this.playVideoAd(item.materials, item);
                } else {
                    item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
                    allVideoAdPodsList.forEach(podsItem => {
                        if (podsItem.podsId == item.podsId) {
                            podsItem.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                        }
                    })
                    this.setState({
                        allVideoAdPodsList
                    })
                }
            }
        })
    }

    /**
     * 播放视频广告
     */
    playVideoAd(playList, pods) {
        if (playList && playList.length) {
            this.playerRef.current.pause();
            this.setState({
                isShowControls: false
            })
        }
        this.setState({
            isPlayAd: true,
            waitPlayVideoMaterialsList: playList,
            currentPlayVideoAdPods: pods
        }, () => {
            let waitPlayAdList = deepClone(playList);

            if (waitPlayAdList.length) {
                this.setState({
                    currentPlayVideoAdMaterial: waitPlayAdList[0]
                })
            }
        })
    }

    /**
     * 设置进度条
     */
    setProgress() {
        if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
            let duration = this.playerRef.current.duration();
            let currentTime = this.playerRef.current.currentTime();
            let currentProgressWidth = (Math.floor((currentTime / duration) * 10000)) / 100;
            this.setState({
                progressBarTime: {
                    currentTime: formatTimeToHMS(currentTime),
                    duration: formatTimeToHMS(duration)
                }
            }, () => {
                // if (!this.state.isFocusProgressBar) {
                this.progressBarRef.current.style.width = currentProgressWidth + '%';
                // console.log(getElementWidthWithMargin(this.progressBarRef.current), getElementWidthWithMargin(this.progressFocusDot.current) / 2, this.progressFocusDot.current.clientWidth)
                this.progressFocusDot.current.style.left = (parseInt(getElementWidthWithMargin(this.progressBarRef.current)) - parseInt(getElementWidthWithMargin(this.progressFocusDot.current)) / 2) + 'px';
                // }
            })
        }
    }

    /**
     * 播放器控制台监听
     */
    handleProgressBar() {
        const { currentProgressWidth } = this.state;
        this.progressBarRef.current.addEventListener('onFocus', () => {
            this.progressFocusDot.current.style.display = "flex";
            this.setState({
                isFocusProgressBar: true
            })
        })
        this.progressBarRef.current.addEventListener('onBlur', () => {
            this.progressFocusDot.current.style.display = "none";
            this.setState({
                isFocusProgressBar: false
            })
        })
        this.progressBarRef.current.addEventListener('left', () => {
            $tv.requestFocus(this.progressBarRef.current);
            if (this.state.currentProgressWidth > 0) {
                this.setState({
                    currentProgressWidth: this.state.currentProgressWidth - 1,
                    isFocusProgressBar: true
                }, () => {
                    this.progressBarRef.current.style.width = this.state.currentProgressWidth + '%';
                    this.progressFocusDot.current.style.left = (parseInt(getElementWidthWithMargin(this.progressBarRef.current)) - parseInt(getElementWidthWithMargin(this.progressFocusDot.current)) / 2) + 'px';
                    setTimeout(() => {
                        let duration = this.playerRef.current.duration();
                        // console.log(duration * this.state.currentProgressWidth / 100)
                        this.playerRef.current.currentTime(duration * this.state.currentProgressWidth / 100);

                    }, 300);
                    setTimeout(() => {
                        this.setState({
                            isFocusProgressBar: false
                        })
                    }, 3000)
                })
            }
        })
        this.progressBarRef.current.addEventListener('right', () => {
            $tv.requestFocus(this.progressBarRef.current);
            if (this.state.currentProgressWidth < 100) {
                this.setState({
                    currentProgressWidth: this.state.currentProgressWidth + 1,
                    isFocusProgressBar: true
                }, () => {
                    this.progressBarRef.current.style.width = this.state.currentProgressWidth + '%';
                    this.progressFocusDot.current.style.left = (parseInt(getElementWidthWithMargin(this.progressBarRef.current)) - parseInt(getElementWidthWithMargin(this.progressFocusDot.current)) / 2) + 'px';
                    setTimeout(() => {
                        let duration = this.playerRef.current.duration();
                        this.playerRef.current.currentTime(duration * this.state.currentProgressWidth / 100);
                    }, 300);
                    setTimeout(() => {
                        this.setState({
                            isFocusProgressBar: false
                        })
                    }, 3000)
                })
            }
        })
    }

    /**
     * 快进
     */
    handleForward() {
        this.forwardRef.current.addEventListener("click", () => {
            this.mediaFastForward();
        })
    }

    mediaFastForward() {
        if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
            let currentTime = parseInt(this.playerRef.current.currentTime());
            if (currentTime - 10 <= 0) {
                this.playerRef.current.currentTime(0);
            } else {
                this.playerRef.current.currentTime(currentTime - 10);
            }
        }
    }

    /**
    * 快退
    */
    handleRewind() {
        this.rewindRef.current.addEventListener("click", () => {
            this.mediaRewind();
        })
    }

    mediaRewind() {
        if (this.playerRef && this.playerRef.current && this.playerRef.current.duration()) {
            let currentTime = parseInt(this.playerRef.current.currentTime());
            let duration = parseInt(this.playerRef.current.duration());
            if (currentTime + 10 >= duration) {
                this.playerRef.current.currentTime(duration);
            } else {
                this.playerRef.current.currentTime(currentTime + 10);
            }
        }
    }

    /**
     * 暂停或播放
     */
    handlePlayOrPause() {
        this.playOrPauseRef.current.addEventListener("click", () => {
            if (this.state.isPause) {
                this.playerRef.current.play();
                this.setState({
                    isPause: false
                })
            } else {
                this.playerRef.current.pause();
                this.setState({
                    isPause: true
                })
            }
        })
    }

    /**
     * 播放速率监听
     */
    handlePlaybackRate() {
        this.playbackRateRef.current.addEventListener("click", () => {
            let { playbackRateLabel, playbackRateList } = this.state;
            playbackRateList.forEach((item, index) => {
                if (item.label == playbackRateLabel) {
                    if (index == playbackRateList.length - 1) {
                        this.setState({
                            playbackRateLabel: playbackRateList[0].label
                        });
                        this.playerRef.current.setPlaybackRate(playbackRateList[0].value);
                    } else {
                        this.setState({
                            playbackRateLabel: playbackRateList[index + 1].label
                        });
                        this.playerRef.current.setPlaybackRate(playbackRateList[index + 1].value);
                    }
                }
            })

        })
    }

    /**
     * 设置
     */
    handleSetting() {
        this.settingRef.current.addEventListener("click", () => {
            this.setState({
                isShowControls: false,
                isShowSettingPannel: true
            }, () => {
                if (this.state.subtitlesList.length) {
                    $tv.requestFocus(this.subtitlesSettingRef.current);
                    return
                }
                if (this.state.resolutionList.length) {
                    $tv.requestFocus(this.qualitySettingRef.current);
                    return
                }
                if (this.state.audioTrackList.length) {
                    $tv.requestFocus(this.audioSettingRef.current);
                    return
                }
            })
        })
        this.settingRef.current.addEventListener("left", () => {
            $tv.requestFocus(this.settingRef.current);
        })
    }

    /**
     * 设置面板监听
     */
    handleListenerSettingPannel() {
        const items = document.querySelectorAll('.setting-pannel_item');
        items.forEach((el, index) => {
            el.addEventListener('up', () => {
                if (index == 0) {
                    $tv.requestFocus(el);
                } else {
                    if (findClosestSiblingWithClass(el, 'setting-pannel_item', 'previous')) {
                        $tv.requestFocus(findClosestSiblingWithClass(el, 'setting-pannel_item', 'previous'));
                    } else {
                        $tv.requestFocus(el);
                    }
                }
            })
            el.addEventListener('down', () => {
                if (index == items.length - 1) {
                    $tv.requestFocus(el);
                } else {
                    console.log(findClosestSiblingWithClass(el, 'setting-pannel_item', 'next'))
                    if (findClosestSiblingWithClass(el, 'setting-pannel_item', 'next')) {
                        $tv.requestFocus(findClosestSiblingWithClass(el, 'setting-pannel_item', 'next'));
                    } else {
                        $tv.requestFocus(el);
                    }
                }

            })
            el.addEventListener('left', () => {
                $tv.requestFocus(el);
            })
            el.addEventListener('right', () => {
                $tv.requestFocus(el);
            })
        })
    }

    /**
     * 剧集按钮点击监听
     */
    handleSelection() {
        this.selectionRef.current.addEventListener("click", () => {
            this.setState({
                isShowEpisode: true,
                isShowControls: false,
                isShowPlayList: true
            }, () => {
                // this.episodesRef.current.style.display = "block";
                if (this.state.currentPlayContent) {
                    let scorllEl = this.episodesRef.current;
                    let elWidth = getElementWidthWithMargin(document.getElementById(this.state.currentPlayContent.contentId));
                    this.state.playList.forEach((item, index) => {
                        if (item.contentId == this.state.currentPlayContent.contentId) {
                            if (index >= 2 && index <= this.state.playList.length - 1) {
                                scorllEl.style.left = -((index - 3) * elWidth) + 'px';
                            }
                        }
                    })
                    $tv.requestFocus(document.getElementById(this.state.currentPlayContent.contentId));
                }
            })
        })
    }

    /**
     * 设置字幕
     */
    handleSubtitleSetting() {
        this.subtitlesSettingRef.current.addEventListener("click", () => {
            this.setState({
                isShowSettingPannel: false,
                isShowSubtitlesPannel: true
            }, () => {
                if (this.state.subtitlesList.length) {
                    this.handleListenerSubtitleItem();
                    $tv.requestFocus(document.getElementById(`subtitleItem0`));
                }
            })
        })
    }

    /**
     * 字幕监听
     */
    handleListenerSubtitleItem() {
        this.handleRemoveListenerSubtitleItem();
        this.subtitleListRef.forEach((el, index) => {
            el.addEventListener("click", () => {
                if (index != 0) {
                    if (this.state.subtitlesList[index] && this.state.subtitlesList[index].url) {
                        this.getSubtitleFile(this.state.subtitlesList[index].url);
                    } else {
                        this.setState({
                            subtitlesArr: []
                        })
                    }

                } else {
                    this.setState({
                        subtitlesArr: []
                    })
                }
                let subtitlesList = deepClone(this.state.subtitlesList);
                subtitlesList.forEach((item, subtitleIndex) => {
                    item.active = false;
                    if (index == subtitleIndex) {
                        item.active = true;
                    }
                })
                this.setState({
                    subtitlesList,
                    currentSubtitle: this.state.subtitlesList[index]
                })
            })
            el.addEventListener("left", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.subtitleListRef[index - 1]);
                }
            })
            el.addEventListener("down", () => {
                if (index == this.state.subtitlesList.length - 1) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.subtitleListRef[index + 1]);
                }
            })
        })
    }


    /**
     * 获取字幕
     * @param {*} url 
     */
    getSubtitleFile(url) {
        axios({
            method: 'get',
            url,
            withCredentials: false
        }).then(res => {
            let srtItem = this.createSrtArr(res.data);
            this.setState({
                subtitlesArr: srtItem
            })
        })
    }

    /**
     * 二分法拆分字幕
     * @param {*} currentTime 
     * @param {*} arr 
     * @returns 
     */
    getSubtitle(currentTime, arr) {
        let low = 0,
            high = arr.length;
        let mid, htmlText;
        while (low <= high) {
            mid = parseInt((low + high) / 2);
            if (mid !== arr.length) {
                if (arr[mid].from <= currentTime && arr[mid].to >= currentTime) {
                    htmlText = arr[mid].htmlText;
                    break;
                }
                if (arr[mid].from > currentTime) {
                    high = mid - 1;
                }
                if (arr[mid].to < currentTime) {
                    low = mid + 1;
                }
            } else {
                return;
            }
        }
        // console.log('二分法拆分字幕', htmlText);
        return htmlText;
    }

    changeTime(time) {
        let timeList = [];
        let listData = [];
        if (!this.state.subtitlesArr) {
            this.subtitleWrapperRef.current.innerText = '';
            return
        }
        this.state.subtitlesArr.forEach(item => {
            timeList.push(item.to);
            listData.push(item.from);
        })
        let minTime = Math.min(...listData);
        let maxTime = Math.max(...timeList);
        if (parseInt(time) < maxTime && parseInt(time) > minTime) {
            let subtitleStr = this.getSubtitle(time, this.state.subtitlesArr);
            // console.log(subtitleStr)
            if (subtitleStr) {
                this.subtitleWrapperRef.current.innerText = subtitleStr.replace(/\{.*?\}/g, '');
                this.setState({
                    subtitleStr: subtitleStr.replace(/\{.*?\}/g, '')
                })
            }
        } else {
            this.subtitleWrapperRef.current.innerText = '';
            this.setState({
                subtitleStr: ""
            })
        }
    }

    createSrtArr(srt) {
        let arr = srt.split(/\n\n|\r\r|\r\n\r\n/);
        let result = [];
        arr.forEach(item => {
            let srtObj = {};
            let containArr = item.split(/\n/);
            // console.log('containArr',containArr)
            if (containArr[1]) {
                //此处正则用于取出00:00:00，213 --> 00:00:00，213这种结构，防止可能存在于此行的x，y坐标对后续处理造成影响
                var reg = /^(\d{2}):(\d{2}):(\d{2})[\.,](\d{1,3}) --\> (\d{2}):(\d{2}):(\d{2})[\.,](\d{1,3})/g;
                let regResult = reg.exec(containArr[1]);
                if (regResult) {
                    let timeSplit = regResult[0].split(' --> ');
                    srtObj.from = this.getSeconds(timeSplit[0]);
                    srtObj.to = this.getSeconds(timeSplit[1]);
                    //这里的两个replace主要是将srt中可能携带的font标签转化为易用的span，当时遇到了如果是font标签的话没办法继承父元素的字体大小的问题，题外话：本次字母字体大小采用了媒体查询进行设置
                    srtObj.htmlText = containArr
                        .slice(2)
                        .join(`\r`)
                        .replace(/\r\r/g, '\r')
                        .replace(/\<font/g, '<span')
                        .replace(/\<\/font/g, '</span');
                    result.push(srtObj);
                }
            }
        });
        return result;
    }

    /**
     * 将srt中的字符串时间转化为所需要的格式，这里转化成了秒数
     * @returns 
     */
    getSeconds(val) {
        let arr = val.split(/\,|\./);
        let sec = 0;
        let a = arr[0].split(':');
        sec = Number(a[0]) * 60 * 60 + Number(a[1]) * 60 + Number(a[2]) + arr[1] / 1000;
        return sec;
    }


    /**
     * 设置分辨率
     */
    handleQulitySetting() {
        this.qualitySettingRef.current.addEventListener("click", () => {
            this.setState({
                isShowSettingPannel: false,
                isShowQualityPannel: true
            }, () => {
                if (this.state.resolutionList.length) {
                    this.handleListenerQualityItem();
                    $tv.requestFocus(document.getElementById(`qualityItem0`));
                }

            })
        })
    }


    /**
     * 移除分辨率监听
     */
    handleRemoveListenerSubtitleItem() {
        this.qualityListRef.forEach((el, index) => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
            el.removeEventListener("up", () => { });
            el.removeEventListener("down", () => { });
        })
    }


    /**
     * 分辨率监听
     */
    handleListenerQualityItem() {
        this.handleRemoveListenerQualityItem();
        this.qualityListRef.forEach((el, index) => {
            el.addEventListener("click", () => {
                let resolutionList = deepClone(this.state.resolutionList);
                resolutionList.forEach((item, qualityIndex) => {
                    item.active = false;
                    if (index == qualityIndex) {
                        item.active = true;
                    }
                })
                this.setState({
                    resolutionList,
                    currentQuality: this.state.resolutionList[index]
                }, () => {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.switchResolution(this.state.resolutionList[index].value);
                        this.playQualitysReport();
                    }
                })
            })
            el.addEventListener("left", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.qualityListRef[index - 1]);
                }
            })
            el.addEventListener("down", () => {
                if (index == this.state.resolutionList.length - 1) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.qualityListRef[index + 1]);
                }
            })
        })
    }


    /**
     * 移除分辨率监听
     */
    handleRemoveListenerQualityItem() {
        this.subtitleListRef.forEach((el, index) => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
            el.removeEventListener("up", () => { });
            el.removeEventListener("down", () => { });
        })
    }


    /**
     * 设置音轨
     */
    handleAudioSetting() {
        this.audioSettingRef.current.addEventListener("click", () => {
            this.setState({
                isShowSettingPannel: false,
                isShowAudioPannel: true
            }, () => {
                if (this.state.audioTrackList.length) {
                    $tv.requestFocus(document.getElementById(`audioItem0`));
                }
            })
        })
    }

    /**
     * 分辨率监听
     */
    handleListenerAudioItem() {
        this.handleRemoveListenerAudioItem();
        this.audioListRef.forEach((el, index) => {
            el.addEventListener("click", () => {
                let audioTrackList = deepClone(this.state.audioTrackList);
                audioTrackList.forEach((item, audioIndex) => {
                    item.active = false;
                    if (index == audioIndex) {
                        item.active = true;
                    }
                })
                this.setState({
                    audioTrackList,
                    currentAudio: this.state.audioTrackList[index]
                }, () => {
                    if (this.playerRef && this.playerRef.current) {
                        this.playerRef.current.switchAudioTrack(this.state.audioTrackList[index].language);
                    }
                })
            })
            el.addEventListener("left", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el)
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.audioListRef[index - 1]);
                }
            })
            el.addEventListener("down", () => {
                if (index == this.state.audioTrackList.length - 1) {
                    $tv.requestFocus(el)
                } else {
                    $tv.requestFocus(this.audioListRef[index + 1]);
                }
            })
        })
    }


    /**
     * 移除字幕监听
     */
    handleRemoveListenerAudioItem() {
        this.audioListRef.forEach((el, index) => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
            el.removeEventListener("up", () => { });
            el.removeEventListener("down", () => { });
        })
    }

    /**
     * 剧集点击
     * @param {*} data 
     */
    handleClickEpisode(data) {
        this.playEndReportToServer();
        this.videoPlayRecordReport(0)
        let subtitlesList = [];
        if (data && data.data) {
            subtitlesList = data.data.captions
        }
        subtitlesList.unshift({
            languageCode: this.props.t("player.close"),
            active: true
        })
        this.setState({
            currentEpisode: data.data,
            currentPlayContent: data.data,
            isShowEpisode: false,
            isShowPlayList: false,
            subtitlesList: subtitlesList.map((item) => {
                return {
                    ...item,
                    active: item.active ? item.active : false
                }
            })
        }, () => {
            this.getPlayAuth(this.state.currentPlayContent.contentId);
        })
    }

    /**
     * 剧集列表左移
     * @param {*} data 
     */
    handleEpisodeLeft(data) {
        let index = data.index;
        let scorllEl = this.episodesRef.current;
        let elWidth = getElementWidthWithMargin(document.getElementById(data.id));
        if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
            scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
        } else {
            scorllEl.style.left = "0px"
        }
        if (index == 0) {
            $tv.requestFocus(document.getElementById(data.id));
            document.getElementById(data.id).classList.add("levelShake");
            setTimeout(() => {
                document.getElementById(data.id).classList.remove("levelShake");
            }, 1000)
        }
    }

    /**
     * 剧集列表右移
     * @param {*} data 
     */
    handleEpisodeRight(data) {
        let index = data.index;
        console.log(index)
        let scorllEl = this.episodesRef.current;
        let elWidth = getElementWidthWithMargin(document.getElementById(data.id));
        if (index >= 3 && index < this.state.episodes.length - 1) {
            scorllEl.style.left = -((index - 2) * elWidth) + 'px'
        } else {
            if (index == this.state.episodes.length - 1) {
                $tv.requestFocus(document.getElementById(data.id));
                document.getElementById(data.id).classList.add("levelShake");
                setTimeout(() => {
                    document.getElementById(data.id).classList.remove("levelShake");
                }, 1000)
            }
        }
    }

    /**
     * 广告播放事件监听
     * @param {*} eventName 
     * @param {*} data 
     */
    handlerAdPlayerEventListener(eventName, data) {
        const { currentPlayVideoAdMaterial, currentPlayVideoAdPods, user, playInfo } = this.state;
        let allVideoAdPodsList = deepClone(this.state.allVideoAdPodsList);
        let waitPlayAdList = deepClone(this.state.waitPlayVideoMaterialsList);
        let loadTime = 0; // 广告加载时间
        let collectionData = {
            materialId: currentPlayVideoAdMaterial.materialId,
            materialUrl: currentPlayVideoAdMaterial.url,
            adPodId: currentPlayVideoAdPods.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[currentPlayVideoAdMaterial.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            // assetId: contentId,
            // contentId: (currentPlayContent && currentPlayContent.contentId) ? currentPlayContent.contentId : authId
        }
        if (playInfo) {
            let recordPlayInfo = deepClone(playInfo);
            collectionData.playCode = recordPlayInfo.playCode;
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        switch (eventName) {
            case 'loading':
                loadTime = getUTCTime();
                var copyCollectionData = deepClone(collectionData);
                copyCollectionData.eventId = GLOBAL.COLLECTION_EVENT.AD_PODS_VIEW_EVENT;
                var adPodsBehavior = [];
				if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
					adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
					adPodsBehavior.push(copyCollectionData);
				} else {
					adPodsBehavior.push(copyCollectionData);
				}
				setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                this.reportAdPodsBehavior();
                break;
            case 'loaded':
                var copyCollectionData = deepClone(collectionData);
                copyCollectionData.eventId = GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD;
                if (loadTime) {
                    copyCollectionData.loadTime = getUTCTime() - loadTime;
                } else {
                    copyCollectionData.loadTime = 0;
                }
                copyCollectionData.loadError = 0;
                copyCollectionData.loadErrorMessage = "";
                adPodsBehavior = [];
                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                    adPodsBehavior.push(copyCollectionData);
                } else {
                    adPodsBehavior.push(copyCollectionData);
                }
                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                this.reportAdPodsBehavior();
                break;
            case 'completed':
                var copyCollectionData = deepClone(collectionData);
                var timeCode = data.duration || 0;
                if (currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL || currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL) {
                    timeCode = 0;
                }
                copyCollectionData.eventId = GLOBAL.COLLECTION_EVENT.AD_PODS_PLAY_RECORD;
                copyCollectionData.timeCode = timeCode;
                copyCollectionData.playDuration = data.currentTime;
                copyCollectionData.fullPlay = 1;
                copyCollectionData.skipAd = 0;
                adPodsBehavior = [];
                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                    adPodsBehavior.push(copyCollectionData);
                } else {
                    adPodsBehavior.push(copyCollectionData);
                }
                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
				this.reportAdPodsBehavior();
                allVideoAdPodsList.forEach(item => {
                    if (item.podsId == this.state.currentPlayVideoAdPods.podsId) {
                        item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    }
                })
                waitPlayAdList.shift(waitPlayAdList[0]);
                this.setState({
                    allVideoAdPodsList,
                    waitPlayVideoMaterialsList: waitPlayAdList
                })
                break;
            case 'skiped':
                var copyCollectionData = deepClone(collectionData);
                var timeCode = data.duration || 0;
                if (currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL || currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL) {
                    timeCode = 0;
                }
                copyCollectionData.eventId = GLOBAL.COLLECTION_EVENT.AD_PODS_PLAY_RECORD;
                copyCollectionData.timeCode = timeCode;
                copyCollectionData.playDuration = data.currentTime;
                copyCollectionData.fullPlay = 0;
                copyCollectionData.skipAd = 1;
                adPodsBehavior = [];
                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                    adPodsBehavior.push(copyCollectionData);
                } else {
                    adPodsBehavior.push(copyCollectionData);
                }
                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                this.reportAdPodsBehavior();
                allVideoAdPodsList.forEach(item => {
                    if (item.podsId == this.state.currentPlayVideoAdPods.podsId) {
                        item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    }
                })
                waitPlayAdList.shift(waitPlayAdList[0]);
                this.setState({
                    allVideoAdPodsList,
                    waitPlayVideoMaterialsList: waitPlayAdList,
                    // currentPlayVideoAdMaterial: {},
                })
                break;
            case 'destroyed':
                this.setState({
                    isPlayAd: false,
                    currentPlayVideoAdMaterial: {},
                }, () => {
                    if (waitPlayAdList.length) {
                        this.playVideoAd(waitPlayAdList, this.state.currentPlayVideoAdPods);
                    } else {
                        if (this.state.currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.PRE_ROLL) {
                            // this.playerRef.current.play();
                           
                            this.checkContentGrade(() => {
                                this.playerRef.current.play();
                            });
                        } else {
                            this.playerRef.current.play();
                        }
                        if (this.state.currentPlayVideoAdPods.podsType == GLOBAL.AD_MATERIAL_TYPE.END_ROLL) {
                            this.playerRef.current.pause();
                            console.log(1111111)
                            this.playEnd();
                            
                        }

                    }
                })
                break;
            case 'error':
                var copyCollectionData = deepClone(collectionData);
                copyCollectionData.eventId = GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD;
                if (loadTime) {
                    copyCollectionData.loadTime = getUTCTime() - loadTime;
                }
                copyCollectionData.loadTime = loadTime || 0;
                copyCollectionData.loadError = 1;
                copyCollectionData.loadErrorMessage = "";
                console.log(data)
                if (data && data.detail && data.detail.message) {
                    copyCollectionData.loadErrorMessage = data.detail.message.indexOf("(") > -1 ? data.detail.message.split("(")[0] : data.detail.message;
                    this.playErrorReport({
                        errorCode: data.code,
                        errorDescription: copyCollectionData.loadErrorMessage
                    });
                } else {
                    if (data.message && data.code) {
                        copyCollectionData.loadErrorMessage = data.message.indexOf("(") > -1 ? data.message.split("(")[0] : data.message;
                        this.playErrorReport({
                            errorCode: data.code,
                            errorDescription: copyCollectionData.loadErrorMessage
                        });
                    }
                }
                adPodsBehavior = [];
                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                    adPodsBehavior.push(copyCollectionData);
                } else {
                    adPodsBehavior.push(copyCollectionData);
                }
                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                this.reportAdPodsBehavior();
                allVideoAdPodsList.forEach(item => {
                    if (item.podsId == this.state.currentPlayVideoAdPods.podsId) {
                        item.status = GLOBAL.AD_PLAY_STATUS.PLAY_ENDED;
                    }
                })
                waitPlayAdList.shift(waitPlayAdList[0]);
                this.setState({
                    allVideoAdPodsList,
                    waitPlayVideoMaterialsList: waitPlayAdList,
                    // currentPlayVideoAdMaterial: {},
                })
                break;
        }
    }

    /**
     * 广告图片加载错误
     * @param {*} data 
     */
    handleAdImageError(data) {
        const { user, playInfo } = this.state;
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materialId,
            materialUrl: data.url,
            adPodId: data.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            loadTime: 0,
            loadError: 0,
            loadError: 1,
			loadErrorMessage: "network error",
            // assetId: contentId,
            // contentId: (currentPlayContent && currentPlayContent.contentId) ? currentPlayContent.contentId : authId
        }
        if (playInfo) {
            let recordPlayInfo = deepClone(playInfo);
            collectionData.playCode = recordPlayInfo.playCode;
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        this.adImagesLoadedFailed[`adImage${data.podsId}And${data.materialId}Duration${data.startDuration}`] = collectionData;
    }

    /**
     * 广告图片加载成功
     * @param {*} data 
     */
    handleAdImageLoad(data) {
        const { user, playInfo  } = this.state;
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materialId,
            materialUrl: data.url,
            adPodId: data.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            loadTime: 0,
            loadError: 0,
            loadErrorMessage: "",
            // assetId: contentId,
            // contentId: (currentPlayContent && currentPlayContent.contentId) ? currentPlayContent.contentId : authId
        }
        if (playInfo) {
            let recordPlayInfo = deepClone(playInfo);
            collectionData.playCode = recordPlayInfo.playCode;
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        this.adImagesLoadedSuccess[`adImage${data.podsId}And${data.materialId}Duration${data.startDuration}`] = collectionData;
    }

    // renderImageAdPods(podsItem) {
    //     if (!podsItem.materials) {
    //         return null
    //     }
    //     podsItem.materials.forEach((item, index) => {
    //         console.log(this.renderImageAdMaterial(item, index))
    //     })
    // }

    /**
     * 渲染广告素材
     * @param {*} materialItem 
     */
    renderImageAdMaterial(materialInfo, index) {
        if (!materialInfo) {
            return
        }
        if (materialInfo.displayLocation == GLOBAL.AD_POSITION.TOP) {
            return (
                <div className='ad-images_container horizontal' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-top horizontal " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                            onLoad={() => {
                                this.handleAdImageLoad(materialInfo)
                            }}
                            />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.BOTTOM) {
            return (
                <div className='ad-images_container horizontal' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-bottom horizontal " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                            onLoad={() => {
                                this.handleAdImageLoad(materialInfo)
                            }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.LEFT) {
            return (
                <div className='ad-images_container vertical' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-left vertical " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                            onLoad={() => {
                                this.handleAdImageLoad(materialInfo)
                            }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.RIGHT) {
            return (
                <div className='ad-images_container vertical' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-right vertical " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                            onLoad={() => {
                                this.handleAdImageLoad(materialInfo)
                            }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.CENTER) {
            return (
                <div className='ad-images_container center' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className="ad-item center">
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                            onLoad={() => {
                                this.handleAdImageLoad(materialInfo)
                            }} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    /**
     * 广告荚数据上报
     */
    reportAdPodsBehavior() {
        // 将当前任务添加到队列
        this.adPodsReportQueue.push(this.processAdPodsBehavior);
        // 如果当前没有请求在处理，则开始处理队列
        if (!this.isRequestProcessing) {
            this.processQueue();
        }
    }

    processQueue() {
        this.isRequestProcessing = true; // 标记为正在处理请求

        // 处理队列中的请求
        if (this.adPodsReportQueue.length > 0) {
            const currentTask = this.adPodsReportQueue.shift(); // 获取队列中的第一个任务
            currentTask().then(() => {
                this.processQueue(); // 当前任务完成后继续处理下一个
            });
        } else {
            this.isRequestProcessing = false; // 标记为请求处理完成
        }
    }

    /**
    * 上报行为请求
    * @returns 
    */
    processAdPodsBehavior = () => {
        let _this = this;
        return new Promise((resolve) => {
            if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                let adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                console.log(this.reportCycleConfig.adEventCount, adPodsBehavior.length)
                if (adPodsBehavior.length >= (this.reportCycleConfig.adEventCount || 10)) {
                    adPodsBehavior.forEach(item => {
                        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) && getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).birthday) {
                            item.age = getAge(getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).birthday);
                        } else {
                            item.age = -1;
                        }
                        if (item.recordTime) {
                            delete item.recordTime; // 删除记录时间
                        }
                    });

                    $request.collectionApi.eventAdPods({ data: adPodsBehavior })
                        .then(res => {
                            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                                removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO); // 成功后清除存储
                            }
                            resolve(); // 请求完成，调用 resolve
                        })
                        .catch(error => {
                            console.error('报告广告行为失败:', error); // 错误处理
                            resolve(); // 失败时也要调用 resolve
                        });
                } else {
                    resolve(); // 如果不满足条件，直接调用 resolve
                }

                if (adPodsBehavior.length > GLOBAL.CONFIG.AD_REPORT_LIMIT_SIZE) {
                    removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO); // 超过限制时清除存储
                }
            } else {
                resolve(); // 如果没有数据，直接调用 resolve
            }
        });
    }


    render() {
        const { showPurchase, isShowSignIn, watermarkInfo, ageGrade, currentPlaySource, customPlayerHeaders, playTypeTagText, isShowTypeTag, vodDetail, isPause, isShowControls, playbackRateLabel, progressBarTime, isShowSettingPannel, isShowQualityPannel, isShowSubtitlesPannel, isShowAudioPannel, resolutionList, audioTrackList, currentQuality, currentAudio, subtitlesList, currentSubtitle, episodes, isShowPlayList, currentEpisode, isShowContinueWatching, continueButtonGroup, isShowPurchase, purchaseButtonGroup, isShowContentGragePannelSkip, contentGradePannelCountingDown, isShowContentGradePanel, currentPlayContent, playList, currentPlayVideoAdMaterial, allImageAdPodsList, isShowPlayerTips, playerTips } = this.state;
        const { url, allowSecondsJump, isAllowJump } = currentPlayVideoAdMaterial;
        let imageMaterialList = [];
        if (allImageAdPodsList.length) {
            deepClone(allImageAdPodsList).forEach(item => {
                if (item.materials && item.materials) {
                    item.materials.forEach(childItem => {
                        childItem.podsId = item.podsId;
                        childItem.podsType = item.podsType;
                        childItem.isShow = false;
                        imageMaterialList.push(childItem)
                    })
                }
            })
        }
        // console.log(allImageAdPodsList, '----')
        return (
            <div className='vod-player'>
                {
                    watermarkInfo ? (<div className='watermark' style={{ ...GLOBAL.WATERMARK_POSITION_MAP[watermarkInfo.coordinateOrigin] }}>
                        <img src={watermarkInfo.filePath} />
                    </div>) : null
                }
                {
                    ageGrade ? (
                        <div className='age-grade'>
                            <img src={ageGrade.icon} />
                        </div>
                    ) : null
                }
                <div className='player-tips' style={{ display: isShowPlayerTips ? 'flex' : 'none' }}>{playerTips}</div>
                <div className='play-type_tag' style={{display: isShowTypeTag ? 'flex' : 'none'}}>{playTypeTagText ? playTypeTagText.toLocaleUpperCase() : ""}</div>
                <div className='player-main'>
                    {
                        currentPlaySource ? <ShakaPlayer
                            src={currentPlaySource.playUrl}
                            ref={this.playerRef}
                            licenseServerUrl={currentPlaySource.drmAuthUrl}
                            customHeaders={customPlayerHeaders}
                            onEvent={(eventName, event) => {
                                this.playerEvent(eventName, event)
                            }}
                        /> : null
                    }

                </div>
                <div className='subtitle-wrapper' ref={this.subtitleWrapperRef}>
                </div>
                <div className='controls' style={{ display: isShowControls ? 'block' : 'none' }}>
                    <div className='video-name'>{currentEpisode ? currentEpisode.name : (vodDetail ? vodDetail.name : '')}</div>
                    <div className='time-bar'>
                        <div className='current-time' ref={this.currentTimeRef}>{progressBarTime.currentTime}</div>
                        <div className='progress-track'>
                            <div className='progress-bar' ref={this.progressBarRef} focusable></div>
                            <div className='dot-box' ref={this.progressFocusDot}>
                                <div className='dot-highlight'></div>
                            </div>
                        </div>
                        <div className='total-time' ref={this.durationRef}>{progressBarTime.duration}</div>
                    </div>
                    <div className='setting-group'>
                        <div className='setting-btn' ref={this.settingRef} focusable>
                            <span className='icon'>
                                <img src={require('../../assets/images/icon/live_icon_settings.png').default} />
                            </span>
                            <span>{this.props.t("player.setting")}</span>
                        </div>
                        <div className='playback-rate_btn' ref={this.playbackRateRef} focusable>{playbackRateLabel}</div>
                        <div className='play-btn_group'>
                            <div className='forward-btn' ref={this.forwardRef} focusable>
                                <img src={require('../../assets/images/icon/atv_vod_play_rewind_default.png').default} />
                            </div>
                            <div className='play-pause_btn' ref={this.playOrPauseRef} focusable>
                                <img src={require('../../assets/images/icon/audio_play.png').default} style={{ display: isPause ? 'block' : 'none', position: "relative", left: '2px' }} />
                                <img src={require('../../assets/images/icon/audio_pause.png').default} style={{ display: isPause ? 'none' : 'block' }} />
                                {/* {
                                    isPause ? <img src={require('../../assets/images/icon/audio_play.png').default} /> : 
                                } */}
                            </div>
                            <div className='rewind' ref={this.rewindRef} focusable>
                                <img src={require('../../assets/images/icon/atv_vod_play_speed_default.png').default} />
                            </div>
                        </div>
                        <div className='selection-btn' ref={this.selectionRef} focusable>
                            <span className='icon'>
                                <img src={require('../../assets/images/icon/atv_vod_play_selection_44x44.png').default} />
                            </span>
                            <span>{this.props.t("player.selection")}</span>
                        </div>
                    </div>
                </div>
                <div className='setting-pannel pannel' style={{ display: isShowSettingPannel ? 'block' : 'none' }}>
                    <div className='pannel-title'>{this.props.t("player.setting")}</div>
                    <div className='option'>
                        <div className='option-item setting-pannel_item' style={{ display: subtitlesList.length ? 'block' : 'none' }} ref={this.subtitlesSettingRef} focusable>
                            <div className='option-label'>{this.props.t("player.subtitles")}</div>
                            <div className='option-value'>{currentSubtitle ? currentSubtitle.languageCode : this.props.t("player.close")}</div>
                        </div>
                        <div className='option-item setting-pannel_item' style={{ display: resolutionList.length > 1 ? 'block' : 'none' }} ref={this.qualitySettingRef} focusable>
                            <div className='option-label'>{this.props.t("player.quality")}</div>
                            <div className='option-value'>{currentQuality ? currentQuality.label : ""}</div>
                        </div>
                        <div className='option-item setting-pannel_item' style={{ display: audioTrackList.length > 1 ? 'block' : 'none' }} ref={this.audioSettingRef} focusable>
                            <div className='option-label'>{this.props.t("player.audio")}</div>
                            <div className='option-value'>{currentAudio ? currentAudio.label : ""}</div>
                        </div>
                    </div>
                </div>
                <div className='pannel' style={{ display: isShowSubtitlesPannel ? 'block' : 'none' }}>
                    <div className='pannel-title'>{this.props.t("player.subtitles")}</div>
                    <div className='option'>
                        {/* <div className='option-item subtitle-item' focusable>
                            <div className='option-label'>{this.props.t("player.close")}</div>
                            <div className='option-value'></div>
                        </div> */}
                        {
                            subtitlesList.map((item, index) => (
                                <div className='option-list-item' id={'subtitleItem' + index} ref={(el) => (this.subtitleListRef[index] = el)} key={index} index={index} focusable>
                                    {/* <div className='option-label'>{item.languageCode}</div>
                                    <div className='option-value'></div> */}
                                    <span className='icon'>
                                        <img style={{ display: item.active ? 'block' : 'none' }} src={require("../../assets/images/icon/vod_setting_selected.png").default} />
                                    </span>
                                    <span>{item.languageCode}</span>
                                </div>
                            ))
                        }
                        {/* <div className='option-item' focusable>
                            <div className='option-label'></div>
                            <div className='option-value'></div>
                        </div> */}
                    </div>
                </div>
                <div className='pannel' style={{ display: isShowQualityPannel ? 'block' : 'none' }}>
                    <div className='pannel-title'>{this.props.t("player.quality")}</div>
                    <div className='option'>
                        {
                            resolutionList.map((item, index) => (
                                <div className='option-list-item quality-item' id={'qualityItem' + index} ref={(el) => (this.qualityListRef[index] = el)} key={index} index={index} focusable>
                                    <span className='icon'>
                                        <img style={{ display: item.active ? 'block' : 'none' }} src={require("../../assets/images/icon/vod_setting_selected.png").default} />
                                    </span>
                                    <span>{item.label}</span>
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className='pannel' style={{ display: isShowAudioPannel ? 'block' : 'none' }}>
                    <div className='pannel-title'>{this.props.t("player.audio")}</div>
                    <div className='option'>
                        {
                            audioTrackList.map((item, index) => (
                                <div className='option-list-item audio-item' id={'audioItem' + index} ref={(el) => (this.audioListRef[index] = el)} key={index} index={index} focusable>
                                    <span className='icon'>
                                        <img style={{ display: item.active ? 'block' : 'none' }} src={require("../../assets/images/icon/vod_setting_selected.png").default} />
                                    </span>
                                    <span>{item.label}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='episodes-box' style={{ display: isShowPlayList ? 'block' : 'none' }}>
                    <div className='episodes-box_title'>
                        <span className='episodes-icon'>
                            <img src={require('../../assets/images/icon/atv_vod_play_selection_44x44.png').default} />
                        </span>
                        {this.props.t("vod.Episodes")}
                    </div>
                    <div className='episodes-wrapper' ref={this.episodesRef}>
                        {
                            playList.map((item, index) => (
                                <ColumnItem key={index} click={(propData) => {
                                    this.handleClickEpisode({ ...propData, index })
                                }} scorllLeft={(propData) => {
                                    this.handleEpisodeLeft({ ...propData, index })
                                }}
                                    scorllRight={(propData) => {
                                        this.handleEpisodeRight({ ...propData, index })
                                    }}
                                    data={item} itemStyle={GLOBAL.RECOMMEND_STYLE.HORIZONTAL} name={item.name} id={item.contentId}>
                                    <div className='column-item_children'>
                                        <div className='total-time'>{item.duration ? exchangeHMS(item.duration) : ''}</div>
                                        {
                                            currentPlayContent && currentPlayContent.contentId == item.contentId ? (
                                                <div className='icon'>
                                                    <img src={require('../../assets/images/icon/atv_live_icon_playgif.gif').default} />
                                                </div>
                                            ) : null
                                        }

                                    </div>
                                </ColumnItem>
                            ))
                        }
                    </div>
                </div>
                <div className='dialog' style={{ display: isShowContinueWatching ? 'flex' : 'none' }}>
                    <div className='dialog-left'>
                        <div className='dialog-left_wrapper'>
                            <div className='dialog-title'>{vodDetail ? vodDetail.name : ''}</div>
                            <div className='dialog-desc'>{this.props.t("player.playbakWillStartTip")} {formatTimeToHMS(vodDetail ? vodDetail.playProgress : 0)}</div>
                        </div>
                    </div>
                    <div className='dialog-right'>
                        <div className='btn-group'>
                            {
                                continueButtonGroup.map((item, index) => (
                                    <div className='btn-group_item' id={'continueBtnItem' + item.value} ref={(el) => { this.continueButtonGroupRef[index] = el }} key={item.value} focusable>{item.label}</div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <div className='dialog' style={{ display: isShowPurchase ? 'flex' : 'none' }}>
                    <div className='dialog-left'>
                        <div className='dialog-left_wrapper'>
                            <div className='dialog-title'>{vodDetail ? vodDetail.name : ''}</div>
                            <div className='dialog-desc'>{showPurchase ? this.props.t("purchase.purchaseAfterView") : this.props.t("purchase.purchaseTipsToApp")}</div>
                        </div>
                    </div>
                    <div className='dialog-right'>
                        <div className='btn-group'>
                            {
                                purchaseButtonGroup.map((item, index) => (
                                    <div className='btn-group_item' id={'purchaseBtnItem' + item.value} ref={(el) => { this.purchaseButtonGroupRef[index] = el }} key={item.value} focusable>{item.label}</div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                {
                    <Popup show={isShowSignIn} 
                    click={(data) => {
                        this.handleSignInBtn(data)
                    }}
                    title={this.props.t("user.signIn")} text={this.props.t("user.notLogin") + ',' + this.props.t("user.afterLogin")}
                    buttons={
                        [
                          {
                            type: 'ok',
                            text: this.props.t("user.signIn")
                          },
                          {
                            type: 'cancel',
                            text: this.props.t("user.cancel")
                          }
                        ]
                      }
                    />
                }
                <div className='content-grade_pannel' style={{ display: isShowContentGradePanel ? 'block' : 'none' }}>
                    <div className='grade-pannel_wrapper'>
                        <div className='icon-wrapper'>
                            <div className='logo'>
                                <img src={require('../../assets/images/logo.png').default} />
                            </div>
                            <div className='line'></div>
                            <div className='age-icon' style={{ display: (vodDetail && vodDetail.contentGradeDto && vodDetail.contentGradeDto.icon) ? 'block' : 'none' }}>
                                <img src={(vodDetail && vodDetail.contentGradeDto && vodDetail.contentGradeDto.icon) ? vodDetail.contentGradeDto.icon : ''} />
                            </div>
                        </div>
                        {
                            vodDetail && vodDetail.contentGradeDto ? (
                                <div className='grade-pannel_main' ref={this.contentGradecontainerRef}>
                                    <div className='rating-desc'>{vodDetail.contentGradeDto.ratingDesc}</div>
                                    <div className='warning-desc'>{vodDetail.contentGradeDto.warningDesc}</div>
                                </div>
                            ) : null
                        }
                        <div className='grade-pannel_footer'>
                            <div className='grade-pannel_skip' ref={this.contentGradeSkipRef} style={{ display: isShowContentGragePannelSkip ? 'flex' : 'none' }} focusable>
                                <div className='skip-icon'>
                                    <img src={require('../../assets/images/icon/npvr_play.png').default} />
                                </div>
                                <div className='skip-text'>{this.props.t("button.skip")}</div>
                            </div>
                            <div className='down-time_text'>{this.props.t("player.contentGrageDownTimeTips")} {contentGradePannelCountingDown}</div>
                        </div>
                    </div>
                </div>
                <div className='ad-contenter'>
                    {
                        imageMaterialList.map((item, index) => (
                            this.renderImageAdMaterial(item, index)
                        ))
                    }
                </div>
                {
                    url ? (<AdPlayer src={url + `?rt=${this.updateTime}`} showSkipDuration={allowSecondsJump} isAllowSkip={isAllowJump} onEvent={(eventName, event) => {
                        this.handlerAdPlayerEventListener(eventName, event)
                    }} />) : null
                }
            </div>
        )
    }
});