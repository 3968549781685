import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { keycodeHandle } from "../../util/util";
import projectConfig from '../../util/config';
import './style/ContactUs.scss'

export default withTranslation()(class ContactUs extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        keycodeHandle({ exit: this.handleExit.bind(this), quit: this.handleExit.bind(this) });
    }

    handleExit() {
        window.history.back(-1);
    }

    render() {
        return <div className='connect-us_page'>
            <div className='page-header'>
                {this.props.t("connectUs.connectUs")}
            </div>
            <div className='wrapper'>
                <div className='wrapper-left'>
                    <div className='wrapper-left-icon'>
                        <img src={require('../../assets/images/icon/contactUs.png').default} />
                    </div>
                </div>
                <div className='wrapper-right'>
                    <div className="contact-info">
                        <div className="info-item">
                            <div className="info-item_title">{this.props.t("connectUs.visitWebsite")}</div>
                            <div className="info-item_label">{projectConfig.webiste}</div>
                        </div>
                        <div className="info-item">
                            <div className="info-item_title">{this.props.t("user.email")}</div>
                            <div className="info-item_label">{projectConfig.contactEmail}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
})