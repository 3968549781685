import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, setStorage, removeStorage, keycodeHandle, calculateAge, getUUID, getFocusedElement } from "../../util/util";
import Column from '../../components/column/Column';
import SignIn from '../../components/signIn/SignIn';
import SendCodeOfBirthday from '../../components/sendCodeOfBirthday/SendCodeOfBirthday';
import SendCodeOfEmail from '../../components/sendCodeOfEmail/SendCodeOfEmail';
import ToolTipBox from '../../components/toolTipBox/ToolTipBox';
import UpdatePin from '../../components/updatePin/UpdatePin';
import PinBox from '../../components/pinBox/PinBox';
import Dialog from '../../components/dialog/Dialog';
import GLOBAL from "../../util/global"
import $request from '../../api';
import './style/Series.scss';

export default withTranslation()(class NewSeries extends Component {
  signInGetMsg = (value) => {
    this.setState({
      isSignIn: value,
    });
    $tv.requestFocus(this.liveCollect.current);
  };
  // 生日验证
  SendCodeOfBirthdayGetMsg = (params) => {
    this.setState({
      birthday: params.birthday,
      isSendCodeOfBirthday: params.isShow,
    }, () => {
      this.verifyAge(params.birthday, this.state.vodContent)
    });
  };
  // 发送邮箱验证码
  SendCodeOfEmailGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      isSendCodeOfEmail: params.isShow,
    }, () => {
      this.setState({
        isPinBox: true
      });
    });
  };
  // 18+  PIN 提示
  toolTipBoxGetMsg = (value) => {
    this.setState({
      isToolTipBox: value,
    }, () => {
      if (this.state.toolTipType == "pin") {
        this.setState({
          isSendCodeOfEmail: true,
        });
      }
    });
  };
  // 验证pin码
  verifyPinGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      verifyPinPass: params.verifyPinPass,
      isPinBox: params.isShow,
    }, () => {
      if (params.pinBoxStatus == 1) {
        if (this.state.verifyPinPass) {
          if (this.state.isVerifyParent_play) {
            this.props.history.push(`/vodPlayer/${this.state.contentId}/${GLOBAL.MEDIA_TYPE.VOD}/${this.state.playContentId}`);
          } else {
            // location.href = "package.html";
          }
        }
      } else {
        if (params.updateState == "success") {
          this.setState({
            isUpdatePin: true,
            parentControlPin: params.pinNum,
            updateState: params.updateState,
          });
          let userInfo = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) || {};
          userInfo.parentControlPin = params.pinNum;
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, userInfo);
          setTimeout(() => {
            this.setState({
              isVerifyParent_play: true,
              isUpdatePin: false,
              parentControlPin: "",
              isPinBox: true
            });
          }, 1500)
        } else {
          //warning
          this.setState({
            isUpdatePin: true,
            parentControlPin: "",
            updateState: params.updateState,
          });
          setTimeout(() => {
            this.setState({
              isUpdatePin: false,
            });
          }, 1500)
        }
        this.setState({
          pinBoxStatus: 1,
        });
      }
    });
  };
  constructor(props) {
    super(props);
    this.recordFocusEl = null; // 记录聚焦节点
    this.state = {
      seasonsDetailBgImg: require("../../assets/images/icon/home_bgvideo_shadow_bg.png"),
      seasonsAssetId: this.props.match.params.seasonsAssetId, // 请求参数
      seasonsDetail: {},
      vodContent: {},
      seasonsList: [],
      recommondData: [],
      toolTipType: "",
      isSignIn: false,
      isToolTipBox: false,
      isSendCodeOfBirthday: false,
      isPinBox: false,
      verifyPinPass: false, //校验pin码是否通过
      birthday: "",
      isUpdatePin: false,
      updateState: "success",
      parentControlPin: "",
      age: "",
      isVerifyParent_play: false,
      pinBoxStatus: 1,
      isSendCodeOfEmail: false,
      email: "",
      playContentId: "",
      isPhotoFull: false,
      photosDataUrl: "",
      playType: GLOBAL.MEDIA_TYPE.SERIES,
      contentId: "",
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    this.getSeasonsDetail();
    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    clearTimeout(this.pageWheelTime);
    $tv.distanceToCenter = true;
    this.setState = () => false;
  }
  seriesKeyDown() {
    window.history.back();
  }
  // 监听esc和Backspace事件
  vodKeyDown = () => {
    if (this.state.isVerifyParent_play) {
      this.setState({
        isVerifyParent_play: false,
      })
    } else if (this.state.isUnfoldDesc) {
      this.setState({
        isUnfoldDesc: false,
      }, () => {
        $tv.requestFocus(this.vodBrief.current);
      });
    } else if (this.state.isPinBox) {
      this.setState({
        isPinBox: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isSignIn) {
      this.setState({
        isSignIn: false,
      }, () => {
        if (this.state.isLivePlay) {
          $tv.requestFocus(this.vodPlay.current);
        } else {
          $tv.requestFocus(this.liveCollect.current);
        }
      });
    } else if (this.state.isSendCodeOfBirthday) {
      this.setState({
        isSendCodeOfBirthday: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isSendCodeOfEmail) {
      this.setState({
        isSendCodeOfEmail: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isToolTipBox) {
      this.setState({
        isToolTipBox: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else if (this.state.isUpdatePin) {
      this.setState({
        isUpdatePin: false,
      }, () => {
        $tv.requestFocus(this.vodPlay.current);
      });
    } else {
      window.history.back();
    }
  }
  // 获取系列详情
  getSeasonsDetail() {
    $request.boApi.vod.getSeasonsDetail({
      mac: getUUID,
      seasonsAssetId: this.state.seasonsAssetId
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let seasonsDetail = res.data.seasonsDetail;
        let seasonsList = seasonsDetail.contents;
        seasonsList.forEach((item, parentIndex) => {
          item.index = parentIndex;
          item.episodes.forEach(childItem => {
            childItem.index = parentIndex;
          })
        })
        this.setState({
          seasonsDetail: seasonsDetail,
          seasonsList: seasonsList,
        }, () => {
          if (seasonsList.length) {
            this.getViewAllItem();
          }
        });
      }
    })
  }
  // 焦点记忆
  focusMemory() {
    if (getStorage("seriesFocalElements") && document.getElementById(getStorage("seriesFocalElements"))) {
      $tv.requestFocus(document.getElementById(getStorage("seriesFocalElements")));
      removeStorage("seriesFocalElements");
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0); // 滚动条返回顶部
        if (document.getElementById(this.state.seasonsList[0].episodes[0].assetId)) {
          $tv.requestFocus(document.getElementById(this.state.seasonsList[0].episodes[0].assetId));
        }
      }, 100);
    }
  }

  // 获取点播详情
  getVodInfo(contentId) {
    $request.boApi.vod.getVodInfo({
      mac: getUUID(),
      contentId: contentId
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let vodContent = res.data.contentInfo;
        this.setState({
          vodContent: vodContent,
        }, () => {
          this.playClick()
        });
      }
    })
  }
  // 查看所有按钮方法添加
  getViewAllItem() {
    // 查看所有按钮
    const viewAllItem = document.querySelectorAll('.series_details');
    viewAllItem.forEach((el, index) => {
      el.addEventListener('right', this.viewAllItemRight.bind(this, index));
      el.addEventListener('click', this.viewAllItemClick.bind(this, index));
      el.addEventListener("mouseenter", this.viewAllItemMouseEnter.bind(this, index));
    });
    this.focusMemory();
  }
  // 校验年龄方法
  verifyAge(time, contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!contentDetail.contentGradeDto) {
      return
    }
    if (calculateAge(time) < contentDetail.contentGradeDto.age) {
      this.setState({
        isToolTipBox: true,
        toolTipType: "mature"
      });
      return
    }
    if (contentDetail.contentGradeDto.parentControl == "Y") {
      this.setState({
        email: user.email,
      });
      if (user.parentControlPin == GLOBAL.CONFIG.DEFAULT_PIN) {
        this.setState({
          isToolTipBox: true,
          toolTipType: "pin"
        });
      } else {
        this.setState({
          isVerifyParent_play: true,
          isToolTipBox: false,
          isPinBox: true
        });
      }
      return
    }
    this.setState({
      isVerifyParent_play: true,
    });
    this.props.history.push(`/vodPlayer/${this.state.contentId}/${GLOBAL.MEDIA_TYPE.VOD}/${this.state.playContentId}`);
  }

  verfiyContentGradeDto(contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (contentDetail.loginLimit == "N") {
      return true;
    }
    if (contentDetail.loginLimit == "Y" && !user) {
      this.setState({
        isSignIn: true,
      });
      return
    }
    if (contentDetail.contentGradeDto) {
      if (user.birthday) {
        this.verifyAge(user.birthday, contentDetail)
        return false
      }
      this.recordFocusEl = getFocusedElement();
      this.setState({
        isSendCodeOfBirthday: true,
        // showBirthdaySetDialog: true,
        age: contentDetail.contentGradeDto.age,
      });
      return false
    }
    return true
  }

  /**
   * 设置生日
   */
   handleSetBirthday() {
    $request.boApi.user.getMemberInfo({
      mac: getUUID()
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data) {
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, res.data.userInfo);
          this.setState({
            showBirthdaySetDialog: false
          }, () => {
            if (this.recordFocusEl) {
              $tv.requestFocus(this.recordFocusEl);
            }
          })
        }
      }
    })
  }

  viewAllItemMouseEnter(params) {
    $tv.requestFocus(this.refs[`viewAll${params}`]);
  }
  viewAllItemClick(params) {
    setStorage("seriesFocalElements", `viewAll${params}`);
    this.props.history.push(`/vod/${this.state.seasonsList[params].contentId}`)
  }
  viewAllItemRight(params) {
    $tv.requestFocus(this.refs[`viewAll${params}`]);
  }
  // 播放
  playClick() {
    if (!this.state.isVerifyParent_play) {
      if (!this.verfiyContentGradeDto(this.state.vodContent)) {
        return
      } else {
        this.props.history.push(`/vodPlayer/${this.state.contentId}/${GLOBAL.MEDIA_TYPE.VOD}/${this.state.playContentId}`);
      }
    }
  }
  columnClick(propData, childData) {
    setStorage("seriesFocalElements", childData.assetId);
    this.setState({
      contentId: propData.id,
      playContentId: childData.assetId,
    }, () => {
      this.getVodInfo(propData.id)
    });
  }
  columnScorllUp(data, childData) {
    if (data.index == 1) {
      window.scrollTo(0, 0); // 滚动条返回顶部
    }
  }
  columnScorllDown(data, childData) {
    let index = data.index;
    if (index === this.state.seasonsList[this.state.seasonsList.length - 1].index) {
      if (document.getElementById(childData.id)) {
        $tv.requestFocus(document.getElementById(childData.id));
        document.getElementById(childData.id).classList.add("verticalShake");
        setTimeout(() => {
          if (childData.id && document.getElementById(childData.id)) {
            document.getElementById(childData.id).classList.remove("verticalShake");
          }
        }, 1000)
      }
    }
  }
  renderRecommend(data, index, id, style) {
    return <Column data={data} key={id} index={index} id={id} showTitle={true} itemStyle={style}
      scorllDown={(propData, index) => {
        this.columnScorllDown(propData, index)
      }} scorllUp={(propData, index) => {
        this.columnScorllUp(propData, index)
      }} click={(propData, childData) => {
        this.columnClick(propData, childData)
      }} />
  }

  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }

  render() {
    const { seasonsList } = this.state;
    return (
      <div className='series_content' onWheel={e => this.pageWheel(e)}>
        <img className="root_bg_img" src={this.state.seasonsDetail.poster} alt="" />
        <img className="series_content_bg" src={this.state.seasonsDetailBgImg.default} alt="" />
        <div className="series_container">
          <div className="series_title">{this.state.seasonsDetail.seasonsName}</div>
          <div className="tag_list">
            <div className="type-tag"><span>{this.state.seasonsList.length}</span>&nbsp;<span> {this.props.t('vod.seasons')}</span>
            </div>
          </div>
          <div className="series_intro">{this.state.seasonsDetail.description}</div>
        </div>
        <div className="wrapper">
          <div className="series-content" >
            {
              seasonsList.map((item, index) => (
                <div key={index}>
                  <div className="series-header">
                    <div className="seriesCategory_title">{item.title}</div>
                    <div className="series_details" ref={`viewAll${index}`} id={`viewAll${index}`} focusable>{this.props.t('liveEvent.viewAll')}</div>
                  </div>
                  {this.renderRecommend(item.episodes, index, item.contentId, GLOBAL.RECOMMEND_STYLE.HORIZONTAL)}
                </div>
              ))
            }
          </div>
        </div>
        <SignIn isSignIn={this.state.isSignIn} signInGetMsg={this.signInGetMsg} />
        <SendCodeOfBirthday isSendCodeOfBirthday={this.state.isSendCodeOfBirthday} age={this.state.age} SendCodeOfBirthdayGetMsg={this.SendCodeOfBirthdayGetMsg} />
        <SendCodeOfEmail isSendCodeOfEmail={this.state.isSendCodeOfEmail} email={this.state.email} SendCodeOfEmailGetMsg={this.SendCodeOfEmailGetMsg} />
        <ToolTipBox isToolTipBox={this.state.isToolTipBox} toolTipType={this.state.toolTipType} toolTipBoxGetMsg={this.toolTipBoxGetMsg} />
        <UpdatePin isUpdatePin={this.state.isUpdatePin} updateState={this.state.updateState} parentControlPin={this.state.parentControlPin} />
        <PinBox isPinBox={this.state.isPinBox} pinBoxStatus={this.state.pinBoxStatus} verifyPinGetMsg={this.verifyPinGetMsg} />
        <Dialog show={ this.state.showBirthdaySetDialog } age={Number(this.state.age)} confirm={() => {
          this.handleSetBirthday()
        }} />
      </div>
    );
  }
});
