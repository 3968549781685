// 
import { axiosPostRequest } from "../request/http.js";
const adApi = {
    /**
    * 获取广告荚素材列表
    * @param { podsId } params 
    */
    getAdPods: (params) => {
        return axiosPostRequest({
            url: '/ad/pods',
            apiType: 1
        }, params);
    },
    /**
     * 广告签名
     * @param {*} params 
     * @returns 
     */
    getDeviceSign: (params) => {
        return axiosPostRequest({
            url: '/device/sign',
            apiType: 1
        }, params);
    }
}

export default adApi;