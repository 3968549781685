import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, keycodeHandle, deepClone, getUUID, setStorage, removeStorage, getElementHeightWithMargin } from "../../util/util";
import ColumnItem from '../../components/columnItem/ColumnItem';
import GLOBAL from "../../util/global"
import $request from '../../api';
import './style/AllRecommend.scss';

export default withTranslation()(class NewAllRecommend extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recommendId: this.props.match.params.id,
      recommendList: [],
      recommendTitle: this.props.match.params.name,
      isDisplayContentTitle: this.props.match.params.isDisplayTitle,
      itemStyle: this.props.match.params.style,
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    this.getRecommendList();
    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    // const currentIsScreen = nextProps.isScreen;
    // const currentErrorFullscreen = nextProps.errorFullscreen;
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    clearTimeout(this.pageWheelTime);
    $tv.distanceToCenter = true;
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  seriesKeyDown = () => {
    window.history.back();
  }
  //推荐列表
  getRecommendList() {
    $request.boApi.recommend.getRecommendContentsById({
      recommendId: this.state.recommendId,
      page: 0,
      pageSize: 500
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data && res.data.data && res.data.data.contents) {
          let recommendList = res.data.data.contents;
          if (recommendList.length) {
            this.setState({
              recommendList: recommendList,
            }, () => {
              if (getStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO)) {
                let focusInfo = getStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO);
                removeStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO);
                if (focusInfo.y) {
                  window.scrollTo(focusInfo.y, 0);
                }
                if (focusInfo.focusId) {
                  if (document.getElementById(focusInfo.focusId)) {
                    $tv.requestFocus(document.getElementById(focusInfo.focusId));
                  } else {
                    $tv.requestFocus(document.getElementById(this.state.recommendList[0].id));
                  }
                }
              } else {
                if (this.state.recommendList) {
                  $tv.requestFocus(document.getElementById(this.state.recommendList[0].id));
                }
                
              }
            });
          }
        }
      }
    })
  };
  columnItemUp(propData) {
    let el = document.getElementById(propData.id);
    if (el) {
      let elHeight = getElementHeightWithMargin(el);
      console.log(window.scrollY, elHeight)
      if (window.scrollY - elHeight <= 0) {
        window.scrollTo(0, 0);
      }
    }
    
  }
  columnItemLeft(propData) {
    let index = propData.index;
    let condition = false;
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.HORIZONTAL) {
      condition = index % 4 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.VERTICAL) {
      condition = index % 6 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.BIG_VERTICAL) {
      condition = index % 4 === 0;
    }
    if (condition) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("levelShake");
        setTimeout(() => {
          if (document.getElementById(propData.id)) {
            document.getElementById(propData.id).classList.remove("levelShake");
          }
        }, 1000)
      }
    }
  }

  columnItemRight(propData) {
    let index = propData.index;
    let condition = false;
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.HORIZONTAL) {
      condition = (index + 1) % 4 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.VERTICAL) {
      condition = (index + 1) % 6 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.BIG_VERTICAL) {
      condition = (index + 1) % 4 === 0;
    }
    if (condition || index === (this.state.recommendList.length - 1)) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("levelShake");
        setTimeout(() => {
          if (document.getElementById(propData.id)) {
            document.getElementById(propData.id).classList.remove("levelShake");
          }
        }, 1000)
      }
    }
  }
  /**
   * 跳转详情
   * @param {*} parentData 
   * @param {*} index 
   */
  handleLinkToDetail(parentData, index) {
    if (parentData && parentData.data) {
      let data = parentData.data;
      var scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      setStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO, {
        y: scrollTop,
        focusId: data.id
      });
      switch (data.type) {
        case GLOBAL.MEDIA_TYPE.VOD:
          this.props.history.push(`/vod/${data.url}`);
          break;
        case GLOBAL.MEDIA_TYPE.EPISODE:
          this.getSeasonsDetail(data);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
          this.props.history.push(`/liveEventDetail/${data.url || data.contentId}`);
          break;
        case GLOBAL.MEDIA_TYPE.CATEGORY:
          this.props.history.push(`/category/${data.url || data.contentId}/${data.resAssetId}/${data.categoryType}/${this.state.isDisplayContentTitle}/${data.title}`);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE:
          break;
      }
    }
  }
  /**
   * 获取系列详情
   * @param {*} data 
   */
  getSeasonsDetail(data) {
    $request.boApi.vod.getSeasonsDetail({
      mac: getUUID(),
      seasonsAssetId: data.url || data.contentId
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let seasonsDetail = res.data.seasonsDetail;
        if (seasonsDetail.contents) {
          if (seasonsDetail.contents.length > 1) {
            this.props.history.push(`/series/${data.url || data.contentId}`);
          } else {
            this.props.history.push(`/vod/${seasonsDetail.contents[0].contentId}`);
          }
        }
      }
    }).catch(err => {
    })
  }
  renderRecommend(data, index, style) {
    return <ColumnItem itemStyle={style} name={data.name || data.title} click={(propData, index) => {
      this.handleLinkToDetail(propData, index)
    }} showTitle={this.state.isDisplayContentTitle} key={index} data={data} index={index} id={data.id}
    scorllUp={(propData) => {
      this.columnItemUp(propData)
    }}
    scorllLeft={(propData) => {
      this.columnItemLeft(propData)
    }} scorllRight={(propData) => {
      this.columnItemRight(propData, index);
    }} />
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }

  render() {
    const { recommendList, itemStyle } = this.state;
    return (
      <div className="contanierHome" onWheel={e => this.pageWheel(e)}>
        <div className="recommend-position_title">{this.state.recommendTitle}</div>
        <div className="recommend-wrap">
          <div className="recommend-list">
            {
              recommendList.map((item, index) => (
                <div className='recommend_content_item' key={index}>
                  {this.renderRecommend(item, index, itemStyle)}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
});
