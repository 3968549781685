import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, keycodeHandle, deepClone, setStorage, removeStorage, getUUID, getElementHeightWithMargin } from "../../util/util";
import ColumnItem from '../../components/columnItem/ColumnItem';
import GLOBAL from "../../util/global"
import $request from '../../api';
import './style/Category.scss';

export default withTranslation()(class NewCategory extends Component {

  constructor(props) {
    super(props);
    this.categoryMenuEl = React.createRef();
    this.state = {
      seasonsAssetId: getStorage("category_tab_id"),
      parentId: Number(this.props.match.params.parentId), // 父级分类ID
      categoryId: Number(this.props.match.params.categoryId), // 分类ID
      parentCategoryName: this.props.match.params.name, // 父分类
      isDisplayTitle: this.props.match.params.isDisplayTitle || "", // 是否显示标题
      categorysList: [],
      childContentList: [],
      recommondData: [],
      categoryName: "",
      isFirst: true,
      itemStyle: "",
      categoryfocusItem: null,
      isHideTabBar: false, // 是否隐藏tab切换
      page: 0, // 当前页
      pageSize: 20, // 页面大小
      totalPage: 0, // 总页数
      totalCount: 0, // 总条数
      currentTab: null, // 当前服分类Tab
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    if (this.state.categoryId && !this.state.parentId) {
      this.setState({
        isHideTabBar: true
      })
      this.getContentList(this.state.categoryId);
    } else {
      this.getLiveContent();
    }

    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    // const currentIsScreen = nextProps.isScreen;
    // const currentErrorFullscreen = nextProps.errorFullscreen;
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    $tv.distanceToCenter = true;
    clearInterval(this.intervalTime);
    clearTimeout(this.pageWheelTime);
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  seriesKeyDown = () => {
    window.history.back();
  }

  //获取vod分类菜单
  getLiveContent() {
    $request.boApi.content.getLiveContent({
      categoryId: this.state.parentId,
      mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
      mediaType: GLOBAL.MEDIA_TYPE.VOD
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let categorysList = res.data.categorys;
        if (res.data.categoryName) {
          this.setState({
            parentCategoryName: res.data.categoryName
          })
        }
        if (categorysList.length) {
          this.setState({
            categorysList: categorysList,
            categoryName: categorysList[0].categoryName
          }, () => {
            this.getcategoryMenuEls();
            if (!getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO)) {
              categorysList.forEach((item, index) => {
                if (item.categoryId == this.state.categoryId) {
                  let el = document.getElementById(`categoryItem${this.state.categoryId}`);
                  $tv.requestFocus(el);
                  let scorllEl = this.categoryMenuEl.current;
                  if (categorysList.length >= 10 && index >= 9) {
                    let elHeight = getElementHeightWithMargin(el);
                    scorllEl.style.top = -((index - 9) * elHeight) + 'px';
                  } else {
                    scorllEl.style.top = '0px'
                  }
                }
              })
             
            }
          });;
        }
       
      }
    })
  }
  // 分类菜单方法添加
  getcategoryMenuEls() {
    // 分类按钮
    const categoryMenu = document.querySelectorAll('.category_item_left');
    categoryMenu.forEach((el, index) => {
      el.addEventListener('onFocus', this.categoryMenuFocus.bind(this, index));
      el.addEventListener('up', this.categoryMenuUp.bind(this, index));
      el.addEventListener('down', this.categoryMenuDown.bind(this, index));
      el.addEventListener('mouseenter', this.categoryMenuMouseEnter.bind(this, index));
    });
    if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO)) {
      $tv.requestFocus(this.refs[`categoryItem${getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO).focusTabIndex}`]);
    }
    // $tv.requestFocus(this.refs[`categoryItem0`]);
  }
  categoryMenuFocus(params) {
    this.setState({
      categoryName: this.state.categorysList[params].categoryName,
      itemStyle: this.state.categorysList[params].style,
    });
    if (this.state.categoryfocusItem !== params) {
      this.setState({
        childContentList: [],
        totalCount: 0,
        page: 0,
        categoryfocusItem: params
      })
      if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO)) {
        let focusInfo = getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
        if (focusInfo.hasOwnProperty('focusTabIndex')) {
          this.getContentList(this.state.categorysList[focusInfo.focusTabIndex].categoryId, focusInfo.focusTabIndex);
        } else {
          this.getContentList(this.state.categorysList[focusInfo.focusTabIndex].categoryId, focusInfo.focusTabIndex);
        }
      } else {
        this.getContentList(this.state.categorysList[params].categoryId, params)
      }

    }

  }
  categoryMenuMouseEnter(params) {
    this.setState({
      isFirst: true
    });
    $tv.requestFocus(this.refs[`categoryItem${params}`]);
  }
  categoryMenuUp(index) {
    this.setState({
      isFirst: true
    });
    const menus = document.querySelectorAll('.category_item_left');
    let scorllEl = this.categoryMenuEl.current;
    let el = menus[index];
    if (el) {
      if (index <= 0) {
        $tv.requestFocus(el);
        scorllEl.style.top = 0;
      } else {
        if (menus.length >= 10 & index >= 9) {
          $tv.requestFocus(menus[index - 1]);
          let elHeight = getElementHeightWithMargin(el);
          if (Math.abs(scorllEl.offsetTop) >= elHeight) {
            scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
          }
        }
      }
    }
  }
  categoryMenuDown(index) {
    this.setState({
      isFirst: true
    });
    const menus = document.querySelectorAll('.category_item_left');
    let el = menus[index];
    let scorllEl = this.categoryMenuEl.current;
    if (el) {
      if (index == menus.length - 1) {
        $tv.requestFocus(el);
      } else {
        $tv.requestFocus(menus[index + 1]);
      }
      if (index >= 10 && index > 9) {
        if (el) {
          let elHeight = getElementHeightWithMargin(el);
          scorllEl.style.top = -((index - 9) * elHeight) + 'px';
        }
      }
    }
  }
  //分类内容列表
  getContentList(id, params) {
    if (this.timeoutTime) {
      clearInterval(this.timeoutTime);
    }
    this.setState({
      currentTab: {
        id,
        index: params
      }
    })
    $request.boApi.content.getVodContentList({
      categoryId: id,
      mediaType: GLOBAL.MEDIA_TYPE.VOD,
      mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
      page: this.state.page,
      pageSize: this.state.pageSize
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let childContentList = res.data.contents;
        if (childContentList.length) {
          this.setState({
            childContentList: this.state.childContentList.concat(childContentList),
            totalPage: Math.ceil(res.data.pageCount / this.state.pageSize),
            totalCount: res.data.pageCount
          });
          if (this.state.isHideTabBar == false) {
            if (this.state.isFirst) {
              this.timeoutTime = setTimeout(() => {
                this.setState({
                  categoryfocusItem: params,
                  isFirst: false
                });
                // $tv.requestFocus(document.getElementById(childContentList[0].contentId));
              }, 200);
            }
            if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO)) {
              let focusInfo = getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
              if (focusInfo.hasOwnProperty('focusTabIndex')) {
                if (this.state.page < focusInfo.page) {
                  this.setState({
                    page: this.state.page + 1
                  }, () => {
                    this.getContentList(id, params);
                  });
                } else {
                  removeStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
                  if (focusInfo.y) {
                    window.scrollTo(focusInfo.y, 0);
                  }
                  if (focusInfo.focusId) {
                    if (document.getElementById(focusInfo.focusId)) {
                      $tv.requestFocus(document.getElementById(focusInfo.focusId));
                    }
                  }
                  this.setState({
                    categoryfocusItem: focusInfo.focusTabIndex,
                  });
                  if (focusInfo.scorllElY) {
                    this.categoryMenuEl.current.style.top = focusInfo.scorllElY + 'px';
                  }
                }
              }
            }
          } else {
            
            // if (this.state.isHideTabBar == true) {
            if (this.state.page == 0) {
              $tv.requestFocus(document.getElementById(childContentList[0].contentId));
            }
            if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO) && (!getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO).hasOwnProperty('focusTabIndex') || getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO).focusTabIndex === null)) {
              let focusInfo = getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
              if (this.state.page < focusInfo.page) {
                this.setState({
                  page: this.state.page + 1
                }, () => {
                  this.getContentList(this.state.categoryId);
                });

              } else {
                removeStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
                if (focusInfo.y) {
                  window.scrollTo(focusInfo.y, 0);
                }
                if (focusInfo.focusId) {
                  if (document.getElementById(focusInfo.focusId)) {
                    $tv.requestFocus(document.getElementById(focusInfo.focusId));
                  }
                }
              }
            }

            // }
          }
        } else {
          if (this.state.isHideTabBar == false) {
            this.setState({
              categoryfocusItem: params,
              totalCount: 0
            })
          }
        }
      }
    })
  };
  columnItemLeft(propData) {
    let index = propData.index;
    let condition = false;
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.HORIZONTAL) {
      condition = index % 3 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.VERTICAL) {
      condition = index % 4 === 0;
    }
    if (condition) {
      $tv.requestFocus(this.refs[`categoryItem${this.state.categoryfocusItem === null ? (this.state.categoryId ? this.state.categoryId : 0) : this.state.categoryfocusItem}`]);
    }
  }

  columnItemRight(propData) {
    let index = propData.index;
    let condition = false;
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.HORIZONTAL) {
      condition = (index + 1) % 3 === 0;
    }
    if (this.state.itemStyle === GLOBAL.RECOMMEND_STYLE.VERTICAL) {
      condition = (index + 1) % 4 === 0;
    }
    if (condition || index === (this.state.childContentList.length - 1)) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("levelShake");
        setTimeout(() => {
          if (document.getElementById(propData.id)) {
            document.getElementById(propData.id).classList.remove("levelShake");
          }
        }, 1000)
      }
    }
  }
  columnItemDown(propData) {
    if (this.state.page < this.state.totalPage - 1) {
      this.setState({
        page: this.state.page + 1
      }, () => {
        if (this.state.categoryId && !this.state.parentId) {
          this.getContentList(this.state.categoryId);
        } else {
          this.getContentList(this.state.currentTab.id, this.state.currentTab.index);
        }
      })
    }
    // 判断是否为最后一行，如果是，强制聚焦到当前节点
    let style = this.state.itemStyle;
    let totalCount = this.state.totalCount;
    let limitDownIndex = 0;
    let columnSize = (style === GLOBAL.RECOMMEND_STYLE.VERTICAL) ? 4 : 3; 
    if (totalCount % columnSize === 0) {
      limitDownIndex = totalCount - columnSize;
    } else {
      limitDownIndex = totalCount - (totalCount % columnSize);
    }
    
    if (propData.index >= limitDownIndex) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("verticalShake");
        setTimeout(() => {
          if (document.getElementById(propData.id)) {
            document.getElementById(propData.id).classList.remove("verticalShake");
          }
        }, 1000)
      }
    }
  }
  /**
   * 跳转详情
   * @param {*} parentData 
   * @param {*} index 
   */
  handleLinkToDetail(parentData, index) {
    if (parentData && parentData.data) {
      let data = parentData.data;
      var scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
      let categoryMenuItemHeight = 0;
      let scorllElY = 0;
      if (this.state.categorysList.length) {
        categoryMenuItemHeight = getElementHeightWithMargin(document.getElementById(`categoryItem${this.state.categorysList[0].categoryId}`));
        if (this.state.categoryfocusItem >= 9) {
          scorllElY = (this.state.categoryfocusItem - 9) > 0 ? -((this.state.categoryfocusItem - 9) * categoryMenuItemHeight) : 0;
        }
      }
      // return
      setStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO, {
        y: scrollTop,
        focusId: data.contentId || data.url,
        page: this.state.page,
        focusTabIndex: this.state.categoryfocusItem,
        scorllElY: this.state.categoryfocusItem >= 9 ? scorllElY : 0
      });
      // console.log(this.categoryMenuEl.current.offsetTop)
      // return
      switch (data.mediaType) {
        case GLOBAL.MEDIA_TYPE.VOD:
          this.props.history.push(`/vod/${data.contentId || data.url}`);
          break;
        case GLOBAL.MEDIA_TYPE.EPISODE:
        case GLOBAL.MEDIA_TYPE.SERIES:
          this.getSeasonsDetail(data);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
          this.props.history.push(`/liveEventDetail/${data.contentId || data.url}`);
          break;
        case GLOBAL.MEDIA_TYPE.CATEGORY:
          this.props.history.push(`/category/${data.contentId || data.url}/${data.resAssetId}/${data.categoryType || data.type}/${this.state.isDisplayContentTitle}/${data.name || data.title}`);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE:
          break;
      }
    }
  }
  /**
   * 获取系列详情
   * @param {*} data 
   */
  getSeasonsDetail(data) {
    $request.boApi.vod.getSeasonsDetail({
      mac: getUUID(),
      seasonsAssetId: data.contentId || data.url
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let seasonsDetail = res.data.seasonsDetail;
        if (seasonsDetail.contents) {
          if (seasonsDetail.contents.length > 1) {
            this.props.history.push(`/series/${data.contentId || data.url}`);
          } else {
            this.props.history.push(`/vod/${seasonsDetail.contents[0].contentId}`);
          }
        }
      }
    }).catch(err => {
    })
  }
  renderRecommend(data, index, style) {
    return <ColumnItem itemStyle={style} name={data.name} showTitle={this.state.isDisplayTitle} key={index} data={data} index={index} id={data.contentId} click={(propData, index) => {
      this.handleLinkToDetail(propData, index)
    }} scorllDown={(propData) => {
      this.columnItemDown(propData)
    }} scorllLeft={(propData) => {
      this.columnItemLeft(propData)
    }} scorllRight={(propData) => {
      this.columnItemRight(propData, index);
    }} />
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }
  render() {
    const { categorysList, childContentList, itemStyle, isHideTabBar, parentCategoryName, totalCount } = this.state;
    return (
      <div className={isHideTabBar ? 'category_contenter hide-tab_bar' : 'category_contenter'} onWheel={e => this.pageWheel(e)}>
        <div className="category__box_left" style={{ display: isHideTabBar ? 'none' : 'block' }}>
          <div className="left_list">
            <div className='left-list_wrapper' ref={this.categoryMenuEl}>
              {
                categorysList.map((item, index) => (
                  <div className='category_item_left' id={'categoryItem' + item.categoryId} ref={`categoryItem${index}`} key={index} focusable>
                    <div className='tab_child_item_name' >{item.categoryName}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="right_list">
          <div className="right_list_title">
            <span id="category_focused_name" style={{ display: this.state.categoryName ? 'block' : 'none' }}>{this.state.categoryName}:</span>
            <span>{totalCount}{this.props.t('vod.part')}</span>
            <span className="lineV"></span>
            <span className="category__name_current">{parentCategoryName}</span>
          </div>
          <div className="right_content_wrap">
            <div className="right_content_box" id="right_content_box">
              {
                childContentList.map((item, index) => (
                  <div className='right_content_item' key={index}>
                    {this.renderRecommend(item, index, itemStyle)}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
});
