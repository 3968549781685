import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import './style/SignIn.scss';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.signIn = React.createRef();
        this.cancel = React.createRef();
        this.state = {
            isShow: false,  //是否显示弹框
            xmediaLogoImg: require("../../assets/images/icon/xmedia_logo_en.png"),
        };

    }

    componentDidMount() {
        // 登录按钮
        this.signIn.current.addEventListener('click', this.signInClick.bind(this));
        this.signIn.current.addEventListener('right', this.signInRight.bind(this));
        this.signIn.current.addEventListener('left', this.signInMove.bind(this));
        this.signIn.current.addEventListener('up', this.signInMove.bind(this));
        this.signIn.current.addEventListener('down', this.signInMove.bind(this));
        this.signIn.current.addEventListener("mouseenter", this.signInMove.bind(this));
        // 取消按钮
        this.cancel.current.addEventListener('click', this.cancelClick.bind(this));
        this.cancel.current.addEventListener('right', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('left', this.cancelLeft.bind(this));
        this.cancel.current.addEventListener('up', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('down', this.cancelMove.bind(this));
        this.cancel.current.addEventListener("mouseenter", this.cancelMove.bind(this));

    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isSignIn = nextProps.isSignIn;
        if (isSignIn !== this.props.isSignIn) {
            this.setState({
                isShow: isSignIn,
            }, () => {
                if (isSignIn) {
                    setTimeout(() => {
                        $tv.requestFocus(this.signIn.current);
                    }, 300)
                }
            });
        }
    }
    // 登录点击
    signInClick() {
        this.props.history.push('/qrLogin');
    }
    signInRight() {
        $tv.requestFocus(this.cancel.current);
    }
    signInMove() {
        $tv.requestFocus(this.signIn.current);
    }

    // 取消点击
    cancelClick() {
        this.setState({
            isShow: false,
        });
        this.props.signInGetMsg(false);
    }
    cancelMove() {
        $tv.requestFocus(this.cancel.current);
    }
    cancelLeft() {
        $tv.requestFocus(this.signIn.current);
    }
    render() {
        return (
            <div className="sign_out" style={{ display: this.state.isShow ? 'block' : 'none' }}>
                <div className="sign_out_logo">
                    <img src={this.state.xmediaLogoImg.default} alt="" />
                </div>
                <div className="sign_out_title">
                    <div>{this.props.t("user.notLogin")}</div>
                    <div>{this.props.t("user.afterLogin")}</div>
                </div>
                <div className="sign_out_btn">
                    <div className="sign_out_btn_Item" ref={this.signIn} focusable >{this.props.t("user.signIn")}
                    </div>
                    <div className="sign_out_btn_Item" ref={this.cancel} focusable >{this.props.t("user.cancel")}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(SignIn));