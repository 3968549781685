import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './style/Detail.scss';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    componentWillMount() {

    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            $tv.requestFocus(this.contentRef.current);
        }
    }

    render() {
        const { show, data } = this.props;
        return (
            <div className='message-detail_page' style={{ display: show ? 'block' : 'none' }}>
                <div className='page-title'>{this.props.t("message.messages")}</div>
                <div className='message-main'>
                    <div className='message-title'>{data.title}</div>
                    <div className='message-content'>
                        <div className='message-wrapper' ref={this.contentRef} focusable>{data.content}</div>
                    </div>
                </div>
            </div>
        )
    }
}

Detail.propTypes = {
    show: PropTypes.bool.isRequired, // 必传，是否显示
    data: PropTypes.object.isRequired // 必传， 数据
}

export default withTranslation()(Detail);