import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, getUUID, isObject, formatDate, deepClone, formatDecimals, getElementWidthWithMargin, getQueryObj, keycodeHandle } from '../../util/util';
import './style/PackageDetail.scss';
import $request from '../../api';
import GLOBAL from '../../util/global';
import ColumnItem from '../../components/columnItem/ColumnItem';

export default withTranslation()(class PackageDetail extends Component {
    constructor(props) {
        super(props);
        this.buyButtonRef = React.createRef(); // 购买按钮节点
        this.contentWrapperRef = React.createRef(); // 内容盒子节点
        this.pageWheelTimer = null; // 鼠标滚轮监听延时器
        this.state = {
            productId: this.props.match.params.productId, // 产品ID
            productDetail: null, // 产品包详情
            pageSize: 20, // 分页大小
            page: 0, // 当前页
            totalPage: 0, // 总页数
            contentList: [], // 内容列表
            pathUrlQuery: null, // 路径参数
        }
    }

    componentWillMount() {
        this.getProductDetail();
        this.getProductContentById();
        let pathUrlQuery = getQueryObj(window.location.href);
        if (pathUrlQuery.contentId) {
            this.setState({
                pathUrlQuery
            })
        }
    }

    componentDidMount() {
        keycodeHandle({
            exit: () => { window.history.back(-1) },
            quit: () => { window.history.back(-1) },
        })
    }

    componentWillUnmount() {
        this.handleRemoveListenerBuyButton();
        this.handleRemoveListenerContentItem();
        clearTimeout(this.pageWheelTimer);
    }

    /**
     * 获取产品包详情
     */
    getProductDetail() {
        $request.boApi.product.getProductDetail({
            mac: getUUID(),
            productId: this.state.productId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.productInfo) {
                    this.setState({
                        productDetail: res.data.productInfo || null
                    }, () => {
                        if (this.buyButtonRef && this.buyButtonRef.current) {
                            $tv.requestFocus(this.buyButtonRef.current);
                            this.handleListenerBuyButton();
                        }
                    })
                }
            }
        })
    }

    /**
     * 获取产品包内容
     */
    getProductContentById() {
        $request.boApi.product.getProductContentById({
            mac: getUUID(),
            productId: this.state.productId,
            type: "all",
            pageSize: this.state.pageSize,
            page: this.state.page
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                // console.log(res.data.contents)
                if (res.data.contents) {
                    let contentList = deepClone(this.state.contentList);
                    contentList = contentList.concat(res.data.contents);
                    this.setState({
                        contentList,
                        totalPage: Math.ceil(res.data.contentPageCount / this.state.pageSize)
                    }, () => {
                        this.handleRemoveListenerContentItem();
                        this.handleListenerContentItem();
                    })
                }
            }
        })
    }

    /**
     * 购买按钮监听
     */
    handleListenerBuyButton() {
        this.buyButtonRef.current.addEventListener("click", () => { 
            if (this.state.pathUrlQuery) {
                this.props.history.push(`/payment/${this.state.productId}?contentId=${this.state.pathUrlQuery.contentId}&type=${this.state.pathUrlQuery.type}&purchaseType=${this.state.productDetail.purchaseType}`);
            } else {
                this.props.history.push(`/payment/${this.state.productId}`);
            }
            
        });
        this.buyButtonRef.current.addEventListener("down", () => {
            if (this.state.contentList.length) {
                $tv.requestFocus(document.getElementById(this.state.contentList[0].contentId));
            }
        });
    }

    /**
     * 移除购买按钮监听
     */
    handleRemoveListenerBuyButton() {
        this.buyButtonRef.current.removeEventListener("click", () => { });
        this.buyButtonRef.current.removeEventListener("down", () => { });
    }

    /**
     * 内容事件监听
     */
    handleListenerContentItem() {
        const contents = document.querySelectorAll(".recommend-item");
        let scorllEl = this.contentWrapperRef.current;
        contents.forEach((el, index) => {
            el.addEventListener("click", () => {
                switch (this.state.contentList[index].type) {
                    case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
                        this.props.history.push(`/liveEventDetail/${this.state.contentList[index].contentId}`);
                        break;
                    case GLOBAL.MEDIA_TYPE.LIVE:
                        break;
                    default:
                    case GLOBAL.MEDIA_TYPE.VOD:
                        this.props.history.push(`/vod/${this.state.contentList[index].contentId}`);
                        break;
                }
            });
            el.addEventListener("down", () => {
                $tv.requestFocus(el);
                el.classList.add("verticalShake");
                setTimeout(() => {
                    if (el) {
                        el.classList.remove("verticalShake");
                    }
                }, 1000)
            });
            el.addEventListener("left", () => {
                let elWidth = getElementWidthWithMargin(el);
                if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
                    scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
                } else {
                    scorllEl.style.left = "0px"
                }
                if (index == 0 && el) {
                    $tv.requestFocus(el);
                    el.classList.add("levelShake");
                    setTimeout(() => {
                        if (el) {
                            el.classList.remove("levelShake");
                        }
                    }, 1000)
                }
            });
            el.addEventListener("right", () => {
                let elWidth = getElementWidthWithMargin(el);
                if (index >= 1 && index < this.state.contentList.length - 1) {
                    scorllEl.style.left = -(((index - 1) || 1) * elWidth) + 'px';
                } else {
                    if (index == this.state.contentList.length - 1 && el) {
                        $tv.requestFocus(el);
                        el.classList.add("levelShake");
                        setTimeout(() => {
                            if (el) {
                                el.classList.remove("levelShake");
                            }
                        }, 1000)
                    }
                }
            });
        })
    }

    /**
     * 移除内容事件监听
     */
    handleRemoveListenerContentItem() {
        const contents = document.querySelectorAll(".recommend-item");
        contents.forEach(el => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("down", () => { });
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
        })
    }

    /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
     pageWheel(e) {
        clearTimeout(this.pageWheelTimer);
        this.pageWheelTimer = setTimeout(() => {
            if (e.deltaY > 0) {
                $tv.findFocusType = 0;
                $tv.initDis = 10;
                $tv.setFocus('down');
            } else {
                $tv.findFocusType = 0;
                $tv.initDis = 80;
                $tv.setFocus('up');
            }
        }, 300);
    }
    

    render() {
        const { productDetail, contentList } = this.state;
        return (
            <div className='package-detail_page' onWheel={e => this.pageWheel(e)}>
                <div className='package-info'>
                    <div className='poster'>
                        {productDetail && productDetail.poster ? (<img src={productDetail.poster} />) : null}
                    </div>
                    {
                        productDetail ? (
                            <div className='info'>
                                <div className='product-name'>{productDetail.productName}</div>
                                <div className='price'>{productDetail.currency} {productDetail.price ? formatDecimals(productDetail.price) : 0} / {productDetail.cycle} {productDetail.cycleUnit}</div>
                                <div className='available-payment'>{this.props.t("purchase.availablePayment")} {productDetail.payplan}</div>
                                <div className='buy-btn' ref={this.buyButtonRef} focusable>{this.props.t("button.buy")}</div>
                            </div>
                        ) : null
                    }

                </div>
                <div className='product-content'>
                    <div className='title'>{this.props.t("purchase.contentList")}</div>
                    <div className='content-list'>
                        <div className='content-list_wrapper' ref={this.contentWrapperRef}>
                            {
                                contentList.map((item, index) => (
                                    <ColumnItem key={index} itemStyle={GLOBAL.RECOMMEND_STYLE.VERTICAL} name={item.name} id={item.contentId} showTitle="Y" data={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})