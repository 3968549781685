import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { $tv } from 'react-tv-focusable';
import $request from '../../api';
import './style/SendCodeOfBirthday.scss';
import { getStorage, setStorage } from "../../util/util";

export default withTranslation()(class SendCodeOfBirthday extends Component {
    constructor(props) {
        super(props);
        this.check = React.createRef();
        this.cancel = React.createRef();
        this.numKeyBoard = React.createRef();
        this.state = {
            isShow: false,  //是否显示弹框 
            numKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'del'], // 键盘数字
            iconClearImg: require("../../assets/images/icon/atv_search_icon_clear.png"),
            iconDeleteImg: require("../../assets/images/icon/atv_search_icon_delete.png"),
            inputDate: "",
            inputMonth: "",
            inputYear: "",
            focusInput: "",
            prompt: "",
            age: "",
            user: getStorage("userInfo"),
        };
    }

    componentDidMount() {
        this.selectNum();
        // 检查按钮
        this.check.current.addEventListener('click', this.checkClick.bind(this));
        this.check.current.addEventListener('left', this.checkLeft.bind(this));
        this.check.current.addEventListener('right', this.checkRight.bind(this));
        this.check.current.addEventListener('up', this.checkMove.bind(this));
        this.check.current.addEventListener('down', this.checkMove.bind(this));
        this.check.current.addEventListener("mouseenter", this.checkMove.bind(this));
        // 取消按钮
        this.cancel.current.addEventListener('click', this.cancelClick.bind(this));
        this.cancel.current.addEventListener('right', this.cancelRight.bind(this));
        this.cancel.current.addEventListener('left', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('up', this.cancelMove.bind(this));
        this.cancel.current.addEventListener('down', this.cancelMove.bind(this));
        this.cancel.current.addEventListener("mouseenter", this.cancelMove.bind(this));
    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isSendCodeOfBirthday = nextProps.isSendCodeOfBirthday;
        const age = nextProps.age;
        if (isSendCodeOfBirthday !== this.props.isSendCodeOfBirthday) {
            this.setState({
                age: age,
                isShow: isSendCodeOfBirthday,
            });
            if (isSendCodeOfBirthday) {
                $tv.requestFocus(this.check.current);
            }
        }
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    // 数字按钮绑定事件
    selectNum() {
        this.numKeyBoard.current.childNodes.forEach((item, index) => {
            item.addEventListener('click', this.numKeyBoardClick.bind(this, index));
            item.addEventListener('right', this.numKeyBoardRight.bind(this, index));
            item.addEventListener('left', this.numKeyBoardLeft.bind(this, index));
            item.addEventListener('up', this.numKeyBoardUp.bind(this, index));
            item.addEventListener('down', this.numKeyBoardDown.bind(this, index));
            item.addEventListener("mouseenter", this.numKeyBoardMouseEnter.bind(this, index));
        });
    }
    // 数字按钮点击
    numKeyBoardClick(params) {
        let inputDate = "";
        let inputMonth = "";
        let inputYear = "";
        if (params !== 9 && params !== 11) {
            if (params === 10) {
                params = -1;
            }
            if (this.state.inputDate.length < 2) {
                inputDate = this.state.inputDate + (params + 1);
                this.setState({
                    inputDate: inputDate,
                    focusInput: "inputDate"
                });
            } else if (this.state.inputMonth.length < 2) {
                inputMonth = this.state.inputMonth + (params + 1);
                this.setState({
                    inputMonth: inputMonth,
                    focusInput: "inputMonth"
                });
            } else if (this.state.inputYear.length < 4) {
                inputYear = this.state.inputYear + (params + 1);
                this.setState({
                    inputYear: inputYear,
                    focusInput: "inputYear"
                });
            }
        } else {
            if (params === 9) {
                this.setState({
                    inputDate: "",
                    inputMonth: "",
                    inputYear: "",
                    focusInput: "",
                });
            } else if (params === 11) {
                if (this.state.inputYear.length) {
                    inputYear = this.state.inputYear.slice(0, -1);
                    this.setState({
                        inputYear: inputYear,
                        focusInput: "inputYear"
                    });
                    return
                } else if (this.state.inputMonth.length) {
                    inputMonth = this.state.inputMonth.slice(0, -1);
                    this.setState({
                        inputMonth: inputMonth,
                        focusInput: "inputMonth"
                    });
                    return
                } else if (this.state.inputDate.length) {
                    inputDate = this.state.inputDate.slice(0, -1);
                    this.setState({
                        inputDate: inputDate,
                    });
                    if (inputDate.length === 0) {
                        this.setState({
                            focusInput: ""
                        });
                    } else {
                        this.setState({
                            focusInput: "inputDate"
                        });
                    }
                    return
                } else {
                    this.setState({
                        focusInput: "",
                    });
                }
            }
        }

    }
    numKeyBoardMouseEnter(params) {
        $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
    }
    numKeyBoardRight(params) {
        if (params % 3 == 2) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 1]);
        }
    }
    numKeyBoardLeft(params) {
        if (params % 3 == 0) {
            $tv.requestFocus(this.check.current);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 1]);
        }
    }
    numKeyBoardUp(params) {
        if (params < 3) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 3]);
        }
    }
    numKeyBoardDown(params) {
        if (params > 8) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 3]);
        }
    }
    // 检查点击
    checkClick() {
        this.affirmBirthdayCheck();
    }
    checkLeft() {
        $tv.requestFocus(this.cancel.current);
    }
    checkRight() {
        $tv.requestFocus(document.getElementById('keyclear'));
    }
    checkMove() {
        $tv.requestFocus(this.check.current);
    }
    cancelMove() {
        $tv.requestFocus(this.cancel.current);
    }
    // 判断输入的年月日是否正确
    dateValidation(year, month, day) {
        // 1 判断年
        if (year >= 1000 && year <= 3000 && year % 1 === 0) {
            // 2 年份对了  就判断月份
            if (month >= 1 && month <= 12 && month % 1 === 0) {
                // 先把maxDay 算出来
                var maxDay = 31;   // 默认等于31   后面就不需要写1 3 5 7 8 10 12
                switch (month) {
                    case '4':
                    case '6':
                    case '9':
                    case '11': maxDay = 30; break;
                    case '2':
                        if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0) {
                            // 给maxDay 重新赋值    赋值用一个等号
                            maxDay = 29;
                        }
                        else {
                            maxDay = 28;
                        }
                }
                // 3 月份正确就判断天数
                if (day >= 1 && day <= maxDay && day % 1 === 0) {
                    this.setState({
                        prompt: '',
                    });
                    return year + '-' + month + '-' + day
                } else {

                    this.setState({
                        prompt: 'contentWarning.day',
                    });
                }
            } else {
                this.setState({
                    prompt: 'contentWarning.monthly',
                });
            }
        } else {
            this.setState({
                prompt: 'contentWarning.year',
            });
        }
    }

    // 生日提交
    affirmBirthdayCheck() {
        let birthday = this.dateValidation(this.state.inputYear, this.state.inputMonth, this.state.inputDate)
        if (birthday) {
            let user = this.state.user;
            $request.boApi.user.updateUserInfo({
                birthday: new Date(birthday).getTime(),
                sex: user.sex,
                nickName: user.nickName
            }).then(res => {
                if (res.data.resultCode == 0) {
                    user.birthday = new Date(birthday).getTime();
                    user.birth = birthday;
                    setStorage("userInfo", user);
                    this.setState({
                        user: user,
                        inputDate: "",
                        inputMonth: "",
                        inputYear: "",
                        focusInput: "",
                    });
                    this.props.SendCodeOfBirthdayGetMsg({ birthday: user.birthday, isShow: false });
                }
            })
        }
    }

    // 取消点击
    cancelClick() {
        this.setState({
            isShow: false,
            inputDate: "",
            inputMonth: "",
            inputYear: "",
            focusInput: "",
        });
        this.props.SendCodeOfBirthdayGetMsg({ birthday: "", isShow: false });
    }
    cancelRight() {
        $tv.requestFocus(this.check.current);
    }
    cancelMove() {
        $tv.requestFocus(this.cancel.current);
    }
    render() {
        return (
            <div className="sendCodeOfBirthday" style={{
                display: this.state.isShow ? 'block' : 'none'
            }}>
                <div className="sendCodeOfBirthday_title">
                    {this.props.t("adultContent.title")}
                </div>
                <div className="forgetPin_div">
                    <div className="forgetPin_content">
                        <div className="birthdayHint">{this.props.t("adultContent.prompt", this.state.age)}</div>
                        <div className="sendBirthdayCode">
                            <input className={`${this.state.focusInput === "inputDate" ? 'input_code_focus' : ''} input_code`} placeholder="DD" value={this.state.inputDate} disabled type="input" />
                            <input className={`${this.state.focusInput === "inputMonth" ? 'input_code_focus' : ''} input_code`} placeholder="MM" value={this.state.inputMonth} disabled type="input" />
                            <input className={`${this.state.focusInput === "inputYear" ? 'input_code_focus' : ''} input_code`} placeholder="YYYY" value={this.state.inputYear} disabled type="input" />
                            <div className="birthdayWarning">{this.props.t(this.state.prompt)}</div>
                        </div>
                        <div className="birthday_btn">
                            <div className="birthday_btn_Item" ref={this.cancel} focusable>{this.props.t("adultContent.cancel")}</div>
                            <div className="birthday_btn_Item" ref={this.check} focusable>{this.props.t("adultContent.check")}</div>
                        </div>
                    </div>
                    <div className='birthday_pin_div'>
                        <div className="birthday_pin_right" ref={this.numKeyBoard}>
                            {this.state.numKey.map((item) => (
                                <div key={item} focusable id={`key${item}`} >
                                    {item <= 9 ? item : ''}
                                    <img style={{ display: item === "clear" ? 'block' : 'none' }} src={this.state.iconClearImg.default} alt="" />
                                    <img style={{ display: item === "del" ? 'block' : 'none' }} src={this.state.iconDeleteImg.default} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
})