import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { keycodeHandle, getStorage } from '../../util/util';
import GLOBAL from '../../util/global';
import axios from 'axios'; // 引入axios
import './style/TermsOfService.scss';

export default withTranslation()(class TermsOfService extends Component {
    constructor(props) {
        super(props);
        this.contentWrapperRef = React.createRef(); // 内容盒子节点
        this.downRef = React.createRef(); // 上键节点
        this.upRef = React.createRef(); // 下键节点
        this.iframeRef = React.createRef(); // iframe盒子
        this.state = {
            type: 2, // 链接类型 1 内部链接 2 外部链接
            linkInfo: null, // 链接信息
        }

    }

    componentWillMount() {
        this.setState({
            linkInfo: getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_LINK_INFO)
        }, () => {
            if (this.state.linkInfo.termsOfService.indexOf("https://mola.tv") != -1) {
                this.setState({
                    type: 1
                })
            } else {
                axios({
                    method: 'get',
                    url: this.state.linkInfo.termsOfService,
                    // url: "https://img.mola.tv/public/upload/100015506555/20241115/html/termsService_ms-MY_Malaysia_20241115075906746.html",
                    withCredentials: false
                }).then(res => {
                    this.contentWrapperRef.current.innerHTML = res.data;
                })
            }
        })
    }

    componentDidMount() {

        keycodeHandle({
            exit: this.handleExit.bind(this),
            quit: this.handleExit.bind(this),
        });
        $tv.requestFocus(this.downRef.current);
        this.upRef.current.addEventListener("click", () => {
            if (this.state.type == 1) {
                window.frames['iframeContainer'].window.postMessage({
                    type: 'up',
                    key: 'MolaTV'
                }, this.state.linkInfo.termsOfService);
            } else {
                let scorllEl = this.contentWrapperRef.current;
                if (parseInt(scorllEl.offsetTop) + 100 < 0) {
                    scorllEl.style.top = (parseInt(scorllEl.offsetTop) + 100) + 'px';
                } else {
                    scorllEl.style.top = '0px';
                }
            }
        })
        this.downRef.current.addEventListener("click", () => {
            if (this.state.type == 1) {
                window.frames['iframeContainer'].window.postMessage({
                    type: 'down',
                    key: 'MolaTV'
                }, this.state.linkInfo.termsOfService);
            } else {
                let scorllEl = this.contentWrapperRef.current;
                console.log(scorllEl.scrollHeight, scorllEl.offsetTop)
                if (Math.abs(scorllEl.offsetTop) < scorllEl.scrollHeight - 800) {
                    scorllEl.style.top = (-100 + scorllEl.offsetTop) + 'px';
                }
                
            }
        })
    }

    componentWillUnmount() {

    }

    handleExit() {
        window.history.back(-1);
    }

    render() {
        const { linkInfo, type } = this.state;
        return (<div className='rich-text'>

            <iframe frameborder={0} scroll={'no'} name="iframeContainer" style={{display: type == 1 ? 'block' : 'none'}} src={linkInfo.termsOfService} ref={this.iframeRef}></iframe>
            <div className='rich-text_wrapper' ref={this.contentWrapperRef}></div>
            <div className='more-box'>
                <div className='more-button' ref={this.downRef} focusable>
                    <span>
                        <img src={require('../../assets/images/icon/down.png').default} />
                    </span>
                </div>
                <div className='more-button' ref={this.upRef} focusable>
                    <span>
                        <img src={require('../../assets/images/icon/up.png').default} />
                    </span>
                </div>
            </div>
        </div>)
    }
})
