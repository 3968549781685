import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { $tv } from 'react-tv-focusable';
import './style/UpdatePin.scss';

export default withTranslation()(class UpdatePin extends Component {
    constructor(props) {
        super(props);
        this.cancel = React.createRef();
        this.state = {
            isShow: false, //是否显示弹框
            parentControlPin: "",
            updateState: "success",
            warningImg: require("../../assets/images/icon/icon_pin_change_success.png"),
        };

    }

    componentDidMount() {


    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isUpdatePin = nextProps.isUpdatePin;
        const updateState = nextProps.updateState;
        const parentControlPin = nextProps.parentControlPin;
        if (isUpdatePin !== this.props.isUpdatePin) {
            this.setState({
                isShow: isUpdatePin,
            });
            if (updateState == "success") {
                this.setState({
                    warningImg: require("../../assets/images/icon/icon_pin_change_success.png"),
                });
            } else {
                this.setState({
                    warningImg: require("../../assets/images/icon/Warning.png"),
                });
            }

        }
        if (parentControlPin !== this.props.parentControlPin) {
            this.setState({
                parentControlPin: parentControlPin,
            });
        }


    }

    render() {
        return (
            <div className="updatePin_Dialog" style={{ display: this.state.isShow ? 'block' : 'none' }}>
                <div className="updatePin_content">
                    <div className="updatePin_content_img updatePin_img">
                        <img src={this.state.warningImg.default} alt="" />
                    </div>
                    <div className="updatePinSuccess">{this.state.updateState === 'success' ? this.props.t("pin.pinUpdateSuccess") : this.props.t("pin.pinUpdateFailure")}</div>
                    <div className="updatePin_num">{this.state.parentControlPin}</div>
                    <div className="updatePinSuccess_hint">{this.props.t("pin.rememberPin")}</div>
                </div>
            </div>
        )
    }
})