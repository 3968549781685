/* eslint-disable */
import React from 'react';
import { HashRouter, Route, Switch, Router, withRouter } from 'react-router-dom';
import { ModalProvider, ModalContext } from './ModalProvider';
import ModalContainer from './ModalContainer';
import Home from './views/Home/Home'; // 首页
import QRLogin from './views/QRLogin/QRLogin';
import Vod from './views/Vod/Vod'; // 点播详情
import Series from './views/Series/Series'; // 系列详情
import Category from './views/Category/Category'; // 分类页面
import AllRecommend from './views/AllRecommend/AllRecommend'; // 所有推荐
import WatchHistory from './views/WatchHistory/WatchHistory'; // 观看历史
import Favorite from './views/Favorite/Favorite'; // 收藏记录
import TransactionHistory from './views/TransactionHistory/TransactionHistory'; // 订阅记录
import Search from './views/Search/Search'; // 搜索功能
import LiveEventDetail from './views/LiveEventDetail/LiveEventDetail'; // 事件直播详情
import Subscription from './views/Subscription/Subscription';
import Package from './views/Package/Package';
import PackageDetail from './views/PackageDetail/PackageDetail';
import Payment from './views/Payment/Payment';
import VodPlayer from './views/VodPlayer/VodPlayer';
import EventPlayer from './views/EventPlayer/EventPlayer';
import VoucherCode from './views/VoucherCode/VoucherCode';
import Notification from './views/Notification/Notification';
import LiveEventAll from './views/LiveEventAll/LiveEventAll';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './views/TermsOfService/TermsOfService';
import FAQ from './views/FAQ/FAQ';
import contactUs from './views/ContactUs/ContactUs';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import $request from './api';
import GLOBAL from './util/global';
import { setStorage, getStorage, deepClone } from './util/util';

class App extends React.Component {
    static contextType = ModalContext;

    componentDidMount() {
        // console.log(this.props)
        this.getReservationList();
        setTimeout(() => {
            this.startPolling();
        }, 3000);
        // this.props.history.listen(() => {
        //     // 延迟执行的函数
        //     this.startPolling();
        //   });
    }

    componentWillUnmount() {
        clearInterval(this.pollingInterval);
    }

    startPolling = () => {
        // return
        this.pollingInterval = setInterval(async () => {
            const data = await this.fetchData();
            data.forEach((item) => {
                const { id, title, description } = item;
                console.log('Attempting to add modal:', title); // 调试输出
                this.context.addModal(id, title, description); // 使用实例方法
            });
        }, 5000);
    };

    handleAfterPageLoad = () => {
        this.startPolling();
    }

    fetchData = async () => {
        let pathName = this.props.history.location.pathname;
        if (pathName == '/' || pathName.indexOf("/vod/") != -1 || pathName.indexOf("series") != -1 || pathName.indexOf("category") != -1 || pathName.indexOf("allRecommend") != -1 || pathName.indexOf("search") != -1 || pathName.indexOf("liveEventAll") != -1 || pathName.indexOf("liveEventDetail") != -1) {
            // console.log(this.props.history.location, this.props.history.location.pathname)
            if (getStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST)) {
                let reservationList = deepClone(getStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST));
                let eventsPlayList = reservationList.map(item => {
                    return {
                        id: item.assetId,
                        title: item.title,
                        reservationTime: item.reservationTime,
                        assetId: item.assetId,
                        contentId: item.contentId,
                        description: 'The program you reserved has started playing'
                    }
                })
                let playList = eventsPlayList.filter(item => {
                    return item.reservationTime <= new Date().getTime();
                })
                let storagePlayList = getStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST) || [];
                storagePlayList.forEach((item, index) => {
                    playList.forEach(child => {
                        if (item.assetId == child.assetId) {
                            storagePlayList.splice(index, 1);
                        }
                    })
                })
                setStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST, storagePlayList);
                return playList || [];
            }

        }
        return []
    };

    /**
     * 获取预约播放列表
     */
    getReservationList = () => {
        $request.boApi.user.getReservationList({
            type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
            page: 0,
            pageSize: -1
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                let eventsPlayList = res.data.contents || [];
                setStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST, eventsPlayList);
            }
        })
    }

    render() {
        return (
            <>
                <ModalContainer />
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/qrLogin" component={QRLogin} />
                    <Route exact path="/vod/:contentId" component={Vod} />
                    <Route exact path="/series/:seasonsAssetId" component={Series} />
                    <Route exact path="/category/:categoryId/:parentId/:type/:isDisplayTitle/:name" component={Category} />
                    <Route exact path="/allRecommend/:id/:style/:name/:isDisplayTitle" component={AllRecommend} />
                    <Route exact path="/watchHistory" component={WatchHistory} />
                    <Route exact path="/favorite" component={Favorite} />
                    <Route exact path="/transactionHistory" component={TransactionHistory} />
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/liveEventDetail/:contentId" component={LiveEventDetail} />
                    {/* <Route exact path="/series/:seasonsAssetId" component={Series} /> */}
                    {/* contentId：详情ID type: 常量PLAY_TYPE authId：播放鉴权ID */}
                    <Route exact path="/vodPlayer/:contentId/:type/:authId" component={VodPlayer} />
                    <Route exact path="/eventPlayer/:contentId" component={EventPlayer} />
                    <Route exact path="/subscription" component={Subscription} />
                    <Route exact path="/package" component={Package} />
                    <Route exact path="/packageDetail/:productId" component={PackageDetail} />
                    <Route exact path="/payment/:productId" component={Payment} />
                    <Route exact path="/voucherCode" component={VoucherCode} />
                    <Route exact path="/notification" component={Notification} />
                    <Route exact path="/liveEventAll/:categoryId/:name" component={LiveEventAll} />
                    <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
                    <Route exact path="/termsOfService" component={TermsOfService} />
                    <Route exact path="/faq" component={FAQ} />
                    <Route exact path="/contactUS" component={contactUs} />
                </Switch>
            </>
        )
    }
}

// const BasicRoute = () => (
//   <HashRouter history={Router}>
//     <ScrollToTop />
//     <Switch>
//       <Route exact path="/" component={Home} />
//       <Route exact path="/qrLogin" component={QRLogin} />
//       <Route exact path="/vod/:contentId" component={Vod} />
//       <Route exact path="/series/:seasonsAssetId" component={Series} />
//       <Route exact path="/category/:categoryId/:parentId/:type/:isDisplayTitle/:name" component={Category} />
//       <Route exact path="/allRecommend/:id/:style/:name/:isDisplayTitle" component={AllRecommend} />
//       <Route exact path="/watchHistory" component={WatchHistory} />
//       <Route exact path="/favorite" component={Favorite} />
//       <Route exact path="/transactionHistory" component={TransactionHistory} />
//       <Route exact path="/search" component={Search} />
//       <Route exact path="/liveEventDetail/:contentId" component={LiveEventDetail} />
//       {/* <Route exact path="/series/:seasonsAssetId" component={Series} /> */}
//       {/* contentId：详情ID type: 常量PLAY_TYPE authId：播放鉴权ID */}
//       <Route exact path="/vodPlayer/:contentId/:type/:authId" component={VodPlayer} />
//       <Route exact path="/eventPlayer/:contentId" component={EventPlayer} />
//       <Route exact path="/subscription" component={Subscription} />
//       <Route exact path="/package" component={Package} />
//       <Route exact path="/packageDetail/:productId" component={PackageDetail} />
//       <Route exact path="/payment/:productId" component={Payment} />
//       <Route exact path="/voucherCode" component={VoucherCode} />
//       <Route exact path="/notification" component={Notification} />
//       <Route exact path="/liveEventAll/:categoryId/:name" component={LiveEventAll} />
//       <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
//       <Route exact path="/termsOfService" component={TermsOfService} />
//       <Route exact path="/faq" component={FAQ} />
//       <Route exact path="/contactUS" component={contactUs} />
//     </Switch>
//   </HashRouter>
// );

export default withRouter(App);
