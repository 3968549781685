import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import $request from "../../api";
import QRCode from 'qrcode.react';
import { getStorage, setStorage, removeStorage, getAge, deepClone, rsaDecryptContent, keycodeHandle, getElementWidthWithMargin, formatTimeToHMS, exchangeHMS, amend, getSystemTime, getUUID, getUTCTime, getVideoStreamType, formatDate } from '../../util/util';
import { deviceSign } from '../../util/commonRequest';
import ShakaPlayer from '../../components/player/player';
import './style/EventPlayer.scss';
import GLOBAL from '../../util/global';

let flag = false;
export default withTranslation()(class EventPlayer extends Component {
    constructor(props) {
        super(props);
        this.playerRef = React.createRef(); // 播放器节点
        this.currentSystemInterval = null;  // 当前系统时间定时器
        this.contentGradecontainerRef = React.createRef();
        this.contentGradeSkipRef = React.createRef(); // 内容评级面板跳过按钮
        this.contentGradeTimer = null; // 内容评级面板倒计时
        this.scrollSpeed = 1; // 每次滚动的像素值，较小值会更平滑
        this.scrollDelay = 300; // 16ms 大约是每帧的时间 (60帧每秒)
        this.startTimeUpdateTimer = null; // 定时请求获取直播开始时间及结束时间
        this.eventPlayEndInterval = null; // 事件播放结束定时器
        this.checkHeartInterval = null; // 心跳定时器
        this.adImageParentMapRef = {}; // 图片广告素材父节点
        this.adImageMapRef = {}; // 图片广告素材节点
        this.adCheckInterval = null;
        this.adImageParentMapRef = {}; // 图片广告素材父节点
        this.adImageMapRef = {}; // 图片广告素材节点
        this.adPodsReportQueue = []; // 请求队列
        this.isRequestProcessing = false; // 请求处理状态标志
        this.playStatusReportInterval = null; // 播放状态定时器
        this.playBufferReportInterval = null; // 播放buffer定时器
        this.reportCycleConfig = { // 数据采集上报频次
            vodEventCycle: 60,
            liveEventCycle: 60,
            bufferEventCycle: 60,
            maxBufferCount: 10,
            qualityEventCycle: 60,
            errorEventCycle: 60,
            adEventCount: 10
        }
        this.adImagesLoadedSuccess = {}; // 图片广告加载成功记录
        this.adImagesLoadedFailed = {}; // 图片广告加载失败记录
        this.state = {
            contentId: this.props.match.params.contentId, // 详情ID
            watermarkInfo: null, // 水印信息
            liveEventDetail: false, // 事件直播详情
            ageGrade: null, // 年龄等级信息
            currentSystemTime: null, // 当前系统时间
            isShowControls: true, // 是否显示控制栏
            isAdShow: false, // 广告是否显示
            isSupportAd: true, // 是否支持广告
            isShowContentGradePanel: false, // 是否显示年龄评级面板
            contentGradePannelCountingDown: 0, // 内容等级倒计时
            isShowContentGragePannelSkip: false, // 是否显示年龄评级跳过按钮
            gradeShowEnded: false, // 年龄评级面板是否有显示
            playInfo: null, // 播放信息
            currentPlaySource: null, // 当前播放的源信息
            customPlayerHeaders: {}, // 自定义license请求头
            liveEventStatus: GLOBAL.LIVE_EVENT.STATUS.UPCOMING,
            isShowPlayerTips: false, // 是否显示播放器提示
            playerTips: "", // 播放器提示语
            allImageAdPodsList: [], // 所有图片广告荚
            waitRequestImageAdPodsList: [], // 待请求图片广告荚
            startPlayTime: 0, // 开始播放事件
            buffers: [],
            bufferCount: 0, // 加载次数
            isShowTypeTag: true, // 是否显示类型标签
            user: null // 用户信息
        }
    }

    componentWillMount() {
        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
            this.setState({
                user: getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)
            })
            if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).isAD == 'N') {
                this.setState({
                    isSupportAd: false
                })
            }
        }
        if (getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG)) {
            let reportCycleConfig = getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG);
            Object.keys(this.reportCycleConfig).forEach(key => {
                this.reportCycleConfig[key] = reportCycleConfig[key];
            });
        }
        this.getLiveEventDetail();
        this.currentSystemInterval = setInterval(() => {
            this.setState({
                currentSystemTime: getSystemTime(true)
            })
        }, 1000);
    }

    componentDidMount() {
        this.handleControlKey();
        this.startAutoScroll();
    }


    componentWillUnmount() {
        if (this.currentSystemInterval) {
            clearInterval(this.currentSystemInterval);
            this.currentSystemInterval = null;
        }
        this.stopAutoScroll();
        if (this.startTimeUpdateTimer) {
            clearTimeout(this.startTimeUpdateTimer);
            this.startTimeUpdateTimer = null;
        }
        if (this.adCheckInterval) {
            clearInterval(this.adCheckInterval);
            this.adCheckInterval = null;
        }
    }

    startAutoScroll = () => {
        const scroll = () => {
            const container = this.contentGradecontainerRef.current;
            if (container && container.scrollTop >= container.scrollHeight - container.clientHeight) {
                container.scrollTop = 0; // 回到顶部，形成循环
            } else {
                if (container) {
                    container.scrollTop += this.scrollSpeed;
                }

            }
            // 使用 requestAnimationFrame 使滚动更加平滑
            this.scrollAnimationFrame = requestAnimationFrame(scroll);
        };

        this.scrollAnimationFrame = requestAnimationFrame(scroll);
    };

    stopAutoScroll = () => {
        cancelAnimationFrame(this.scrollAnimationFrame); // 清除动画帧
    };

    handleControlKey() {
        keycodeHandle({
            exit: this.handleExitKey,
            quit: this.handleExitKey,
            up: this.handleUpKey,
            down: this.handleDownKey,
            left: this.handleLeftKey,
            right: this.handleRightKey
        });
    }

    handleExitKey = () => {
        if (this.checkIsAdImageShow()) {
            this.hideImageAd();
            return
        }
        if (this.checkHeartInterval) {
            clearInterval(this.checkHeartInterval);
            this.checkHeartInterval = null;
        }
        if (this.playStatusReportInterval) {
            clearInterval(this.playStatusReportInterval);
        }
        if (this.playBufferReportInterval) {
            clearInterval(this.playBufferReportInterval);
        }
        this.playEndReportToServer();
        setTimeout(() => {
            window.history.back();
        }, 2000)
    }

    handleUpKey = () => {
        if (this.state.isShowControls == false) {
            this.setState({
                isShowControls: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isShowControls: false
                    })
                }, 10 * 1000)
            })
        }
    }

    handleDownKey = () => {
        if (this.state.isShowControls == false) {
            this.setState({
                isShowControls: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isShowControls: false
                    })
                }, 10 * 1000)
            })
        }
    }

    handleLeftKey = () => {
        if (this.state.isShowControls == false) {
            this.setState({
                isShowControls: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isShowControls: false
                    })
                }, 10 * 1000)
            })
        }
    }

    handleRightKey = () => {
        if (this.state.isShowControls == false) {
            this.setState({
                isShowControls: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isShowControls: false
                    })
                }, 10 * 1000)
            })
        }

    }

    handleListenerSkipContentGrade() {
        this.contentGradeSkipRef.current.addEventListener("click", () => {
            this.handleSkipContentGrade();
        })
        this.contentGradeSkipRef.current.addEventListener("left", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("right", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("up", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
        this.contentGradeSkipRef.current.addEventListener("down", () => {
            $tv.requestFocus(this.contentGradeSkipRef.current);
        })
    }

    /**
     * 获取事件直播详情
     */
    getLiveEventDetail() {
        $request.boApi.liveEvent.getLiveEventDetail({
            assetId: this.state.contentId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data && res.data.data) {

                    this.setState({
                        liveEventDetail: res.data.data
                    }, () => {
                        if (this.state.liveEventDetail.contentGradeDto && this.state.liveEventDetail.contentGradeDto.icon) {
                            this.setState({
                                ageGrade: this.state.liveEventDetail.contentGradeDto
                            })
                        }
                        if (this.state.liveEventDetail.gradeShow == "Y" && this.state.liveEventDetail.contentGradeDto && this.state.liveEventDetail.contentGradeDto.countingDown) {
                            this.checkContentGrade(() => {
                                this.getPlayAuth();
                            })
                        } else {
                            this.getPlayAuth();
                        }

                    });
                    if (res.data.data.watermarkId) {
                        this.getWatermarkInfo(res.data.data.watermarkId);
                    }
                }

            }
        })
    }

    /**
     * 播放中状态上报
     */
    playStatusReport() {
        const { playInfo, liveEventDetail, currentPlaySource } = this.state;
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_PLAY_EVENT,
            contentId: liveEventDetail.channelAssetId,
            type: GLOBAL.MEDIA_TYPE.VOD,
            quality: playInfo.playUrlList && playInfo.playUrlList.length ? playInfo.playUrlList[0].qualityName : 'auto',
            playCode: recordPlayInfo.playCode,
            duration: this.reportCycleConfig.liveEventCycle,
            drmType: currentPlaySource.drmAuthUrl ? 2 : 0,
            programId: liveEventDetail.assetId
        })
    }

    /**
     * 视频缓存情况上报
     */
    playBufferReport() {
        const { playInfo, bufferCount, buffers } = this.state;
        if (!buffers.length) {
            return
        }
        let recordPlayInfo = deepClone(playInfo);
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_BUFFER_EVENT,
            playBuffers: [{
                totalBufferCount: bufferCount,
                playCode: recordPlayInfo.playCode,
                buffers
            }]
        })
    }

    /**
     * 获取播放时长
     */
    getPlayDuration() {
        let nowTime = getUTCTime(); // 当前时间戳
        return parseInt((nowTime - this.state.liveEventDetail.startTime) / 1000); // 返回从开播到现在过去了多少秒
    }

    formatAdPods() {
        if (this.state.isSupportAd && this.state.liveEventDetail.adPods && this.state.liveEventDetail.adPods.length) {
            // 过滤出banner广告和轨迹广告
            let souresAdPodsList = deepClone(this.state.liveEventDetail.adPods).filter(item => {
                return (item.podsType == GLOBAL.AD_MATERIAL_TYPE.BANNER || item.podsType == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY)
            });
            souresAdPodsList = souresAdPodsList.map(item => {
                return {
                    ...item,
                    showEndDuration: amend(item.duration, item.displayDuration, "+")
                }
            });
            let nowDuration = this.getPlayDuration();
            let allImageAdPodsList = souresAdPodsList.filter(item => {
                // 计算出广告荚的播放结束时间
                item.endDuration = amend(item.duration, item.displayDuration, "+");
                return item.showEndDuration >= nowDuration
            })

            this.setState({
                allImageAdPodsList
            })

            let waitRequestImageAdPodsList = deepClone(allImageAdPodsList);
            this.setState({
                waitRequestImageAdPodsList
            }, () => {
                if (waitRequestImageAdPodsList.length) {
                    this.getAdPods({
                        podsId: waitRequestImageAdPodsList[0].podsId
                    })
                    this.adCheckInterval = setInterval(() => {
                        this.checkImageAdShow();
                    }, 1000)
                }
            })

        }
    }

    getAdPods(params) {
        let promise = new Promise((resolve, reject) => {
            resolve();
        });
        if (!sessionStorage.getItem(GLOBAL.STORAGE_KEYS.SIGN)) {
            promise = deviceSign();
        }
        params.memberId = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) ? getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).memberId : 0;
        promise.then(() => {
            $request.adApi.getAdPods(params).then(res => {
                if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                    if (res.data.body) {
                        let pods = rsaDecryptContent(res.data.body);
                        if (pods && pods.materials && pods.materials.length) {
                            let allImageAdPodsList = deepClone(this.state.allImageAdPodsList);
                            allImageAdPodsList.forEach(item => {
                                if (item.podsId == pods.podsId) {
                                    item.podsType = pods.podsType;
                                    item.materials = this.getMaterialDuration(item.duration, pods.materials, item);
                                }
                            })
                            this.setState({
                                allImageAdPodsList
                            })
                        }
                    }

                }
                this.getNextPods();
            })
        })
    }

    getNextPods() {
        let waitRequestImageAdPodsList = deepClone(this.state.waitRequestImageAdPodsList);
        waitRequestImageAdPodsList.shift(waitRequestImageAdPodsList[0]);
        this.setState({
            waitRequestImageAdPodsList
        }, () => {
            if (this.state.waitRequestImageAdPodsList.length) {
                setTimeout(() => {
                    this.getAdPods({
                        podsId: this.state.waitRequestImageAdPodsList[0].podsId
                    });
                }, 300)
            }
        })
    }

    /**
     * 计算广告素材显示的开始时间点和结束时间点
     * @param {*} startDuration 广告荚开始显示时间点
     * @param {*} materials 广告荚素材列表
     */
    getMaterialDuration(startDuration, materials, pods) {
        let materialList = deepClone(materials);
        materialList.forEach((item, index) => {
            item.isShow = false;
            item.podsId = pods.podsId;
            if (index == 0 || materialList.length == 1) {
                item.startDuration = parseInt(startDuration);
                item.endDuration = amend(parseInt(amend(item.startDuration, item.displayDuration, "+")), 1, '-');
            } else {
                item.startDuration = amend(parseInt(materialList[index - 1].endDuration), 1, '+');
                item.endDuration = amend(parseInt(amend(materialList[index - 1].endDuration, item.displayDuration, "+")), 1, '-');
            }
        })
        return materialList
    }

    /**
     * 检测图片广告是否显示
     * @param {*} duration 
     */
    checkImageAdShow() {
        let currentTime = this.getPlayDuration();
        const { allImageAdPodsList, playInfo } = this.state;
        allImageAdPodsList.forEach(item => {
            if (currentTime >= item.duration && currentTime <= item.endDuration) {
                if (item.materials) {
                    item.materials.forEach(materialItem => {
                        // console.log(materialItem)
                        let adMaterialElParent = this.adImageParentMapRef[`adImagesContainer${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                        let adMaterialEl = this.adImageMapRef[`adItem${materialItem.podsId}ID${materialItem.materialId}Duration${materialItem.startDuration}`];
                        if (currentTime >= materialItem.startDuration && currentTime < materialItem.endDuration && materialItem.isShow == false) {
                            // console.log(1111)
                            if (materialItem.isShow == false) {
                                materialItem.isShow = true;
                                // console.log(adMaterialElParent)
                                adMaterialElParent.style.display = 'block';
                                if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                    setTimeout(() => {
                                        Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                            adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                        });
                                        setTimeout(() => {
                                            Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                                adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                            });
                                        }, 300)
                                    }, 300)
                                }
                                this.adPodsImpressReport(item, materialItem);
                                if (this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]) {
                                    let adPodsBehavior = [];
                                    if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                        adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                        adPodsBehavior.push(this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    } else {
                                        adPodsBehavior.push(this.adImagesLoadedSuccess[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    }
                                    setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                                }
                                if (this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]) {
                                    let adPodsBehavior = [];
                                    if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                        adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                        adPodsBehavior.push(this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    } else {
                                        adPodsBehavior.push(this.adImagesLoadedFailed[`adImage${materialItem.podsId}And${materialItem.materialId}Duration${materialItem.startDuration}`]);
                                    }
                                    setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                                }
                            }
                        } else if (currentTime >= materialItem.endDuration) {
                            if (materialItem.isShow) {
                                if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                    Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                        adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                    });
                                    setTimeout(() => {
                                        Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                            adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                        });
                                    }, 300)
                                    setTimeout(() => {
                                        materialItem.isShow = false;
                                        adMaterialElParent.style.display = 'none';
                                    }, 600)
                                } else {
                                    materialItem.isShow = false;
                                    adMaterialElParent.style.display = 'none';
                                }
                                let collectionData = {
                                    eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_PLAY_RECORD,
                                    materialId: materialItem.materialId,
                                    materialUrl: materialItem.url,
                                    adPodId: materialItem.podsId,
                                    adType: GLOBAL.COLLECTION_AD_TYPE[materialItem.type],
                                    videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
                                    timeCode: materialItem.timeCode ? materialItem.timeCode : materialItem.startDuration,
                                    skipAd: 0,
                                    loadTime: 0,
                                    loadError: 0,
                                    loadErrorMessage: "",
                                    playDuration: materialItem.endDuration - materialItem.startDuration
                                }
                                if (collectionData.playDuration >= materialItem.displayDuration) {
                                    collectionData.fullPlay = 1;
                                } else {
                                    collectionData.fullPlay = 0;
                                }
                                if (playInfo) {
                                    collectionData.playCode = playInfo.playCode;
                                }
                                let adPodsBehavior = [];
                                if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                                    adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                                    adPodsBehavior.push(collectionData);
                                } else {
                                    adPodsBehavior.push(collectionData);
                                }
                                setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
                            }

                        } else if (currentTime < materialItem.startDuration) {
                            if (materialItem.type == GLOBAL.AD_MATERIAL_TYPE.TRAJECTORY) {
                                Object.keys(GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                    adMaterialEl.style[key] = GLOBAL.AD_SHOW_ANIMATE_CSS[materialItem.displayLocation][key]
                                });
                                setTimeout(() => {
                                    Object.keys(GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation]).forEach(key => {
                                        adMaterialEl.style[key] = GLOBAL.AD_HIDE_ANIMATE_CSS[materialItem.displayLocation][key]
                                    });
                                }, 300)
                                setTimeout(() => {
                                    materialItem.isShow = false;
                                    adMaterialElParent.style.display = 'none';
                                }, 600)
                            } else {
                                materialItem.isShow = false;
                                adMaterialElParent.style.display = 'none';
                            }
                            materialItem.isShow = false;
                            adMaterialElParent.style.display = 'none';
                        }
                    })
                }
            }
        })
    }

    getPlayAuth() {
        this.getLiveEventTime(this.state.liveEventDetail.assetId);
        $request.boApi.common.getPlayAuth({
            mac: getUUID(),
            contentId: this.state.liveEventDetail.channelAssetId,
            eventAssetId: this.state.liveEventDetail.assetId,
            type: GLOBAL.MEDIA_TYPE.LIVE_EVENT_CHANNEL
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                let playInfo = res.data;
                if (playInfo.streamKeeperDto) {
                    let source = {
                        drmAuthUrl: playInfo.streamKeeperDto.drmAuthUrl,
                        playUrl: ""
                    }
                    playInfo.streamKeeperDto.playUrlList.forEach(item => {
                        if (item.isDefault) {
                            source.playUrl = item.playUrl;
                        }
                    })
                    if (!source.playUrl) {
                        if (playInfo.streamKeeperDto.playUrlList.length) {
                            source.playUrl = playInfo.streamKeeperDto.playUrlList[0].playUrl;
                        }
                    }
                    this.setState({
                        gradeShowEnded: false,
                        currentPlaySource: source,
                        playInfo: {
                            ...playInfo.streamKeeperDto,
                            playCode: playInfo.playCode
                        },
                        customPlayerHeaders: {
                            "Authorization": playInfo.streamKeeperDto.drmToken
                        }
                    })
                } else {
                    let source = {
                        drmAuthUrl: playInfo.drmAuthUrl,
                        playUrl: ""
                    }
                    playInfo.playUrlList.forEach(item => {
                        if (item.isDefault) {
                            source.playUrl = item.playUrl;
                        }
                    })
                    this.setState({
                        currentPlaySource: source,
                        playInfo,
                        customPlayerHeaders: null
                    })
                }
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
                this.setState({
                    isShowPlayerTips: true,
                    playerTips: this.props.t("toast.deviceLimit")
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.DRM_TOKEN_ERROR) {
                this.setState({
                    isShowPlayerTips: true,
                    playerTips: this.props.t("toast.drmTokenError")
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.NOT_EXIST) {
                this.setState({
                    isShowPlayerTips: true,
                    playerTips: this.props.t("toast.addressExist")
                })
            }
        })
    }

    getLiveEventTime(assetId) {
        $request.boApi.liveEvent.getLiveEventTime({
            assetId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                let liveEventDetail = deepClone(this.state.liveEventDetail);
                liveEventDetail.startTime = res.data.data.startTime;
                liveEventDetail.endTime = res.data.data.endTime;
                this.setState({
                    liveEventDetail
                }, () => {
                    this.startTimeUpdateTimer = setTimeout(() => {
                        this.getLiveEventTime(assetId);
                    }, 5 * 60 * 1000)

                })
            }
        })
    }

    /**
     * 获取水印
     */
    getWatermarkInfo(watermarkId) {
        $request.boApi.system.getWatermarkInfo({
            watermarkId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    watermarkInfo: res.data.data
                })
            }
        })
    }

    /**
     * 获取DRM Token
     * @param {*} contentId 
     */
    getDrmToken(contentId) {
        $request.boApi.common.getDrmToken({
            contentId,
            contentType: GLOBAL.MEDIA_TYPE.VOD,
            drmType: GLOBAL.DRM_CODE.VMX
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    customPlayerHeaders: {
                        "Authorization": res.data.token
                    }
                })
            }
        })
    }

    checkContentGrade(callback) {
        const { liveEventDetail } = this.state;
        if (liveEventDetail.gradeShow == "Y" && liveEventDetail.contentGradeDto && liveEventDetail.contentGradeDto.countingDown) {
            this.contentGradeCallback = callback;
            this.setState({
                isShowContentGradePanel: true,
                isShowContentGragePannelSkip: false,
                contentGradePannelCountingDown: deepClone(liveEventDetail.contentGradeDto).countingDown
            }, () => {
                if (this.playerRef && this.playerRef.current) {
                    this.playerRef.current.pause();
                }
                if (this.contentGradeTimer) {
                    clearInterval(this.contentGradeTimer);
                    this.contentGradeTimer = null;
                }
                let countingDown = deepClone(liveEventDetail.contentGradeDto).countingDown;
                this.contentGradeTimer = setInterval(() => {
                    if (countingDown > 0) {
                        if (this.playerRef && this.playerRef.current) {
                            this.playerRef.current.pause();
                        }
                        countingDown--;
                        this.setState({
                            contentGradePannelCountingDown: countingDown
                        })
                        if (liveEventDetail.contentGradeDto.skip) {
                            if ((liveEventDetail.contentGradeDto.skipDuration > countingDown) || (liveEventDetail.contentGradeDto.countingDown - countingDown >= liveEventDetail.contentGradeDto.skipDuration)) {
                                this.setState({
                                    isShowContentGragePannelSkip: true
                                }, () => {
                                    $tv.requestFocus(this.contentGradeSkipRef.current);
                                    this.handleListenerSkipContentGrade();
                                })
                            }
                        }
                    } else {
                        clearInterval(this.contentGradeTimer);
                        this.contentGradeTimer = null;
                        this.handleSkipContentGrade();
                        // callback();
                    }
                }, 1000)
            })
        } else {
            this.handleSkipContentGrade();
        }
    }

    /**
    * 点击跳过内容评级
    */
    handleSkipContentGrade() {
        if (this.contentGradeTimer) {
            clearInterval(this.contentGradeTimer);
            this.contentGradeTimer = null;
        }
        this.setState({
            isShowContentGradePanel: false,
            isShowContentGragePannelSkip: false,
            gradeShowEnded: true
        }, () => {
            if (this.contentGradeCallback) {
                this.contentGradeCallback();
            }
        })
    }

    /**
     * 心跳检测
     */
    checkHeart() {
        $request.boApi.common.checkHeart({
            mac: getUUID()
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    isShowPlayerTips: false
                })
                if (this.state.liveEventStatus == GLOBAL.LIVE_EVENT.STATUS.ENDED) {
                    clearInterval(this.checkHeartInterval);
                    this.checkHeartInterval = null;
                } else {
                    this.checkHeartInterval = setTimeout(() => {
                        this.checkHeart();
                    }, 3 * 60 * 1000)
                }
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
                this.setState({
                    isShowPlayerTips: true,
                    playerTips: this.props.t("toast.deviceLimit")
                })
            }
        })
    }

    playerEvent(eventName, data) {
        let buffersStart = getUTCTime();
        switch (eventName) {
            case 'loading':
                buffersStart = getUTCTime();
                break;
            case 'loaded':
                this.checkHeart();
                setTimeout(() => {
                    this.setState({
                        isShowTypeTag: false
                    })
                }, 10 * 1000);
                if (this.state.isSupportAd) {
                    this.formatAdPods();
                }
                setTimeout(() => {
                    this.setState({
                        isShowControls: false
                    })
                }, 10 * 1000)
                this.setState({
                    startPlayTime: getUTCTime()
                })
                let buffers = deepClone(this.state.buffers);
                buffers.push({
                    vodStartTime: buffersStart,
                    bufferTime: parseInt((getUTCTime() - buffersStart) / 1000),
                    quality: this.state.playInfo.playUrlList && this.state.playInfo.playUrlList.length ? this.state.playInfo.playUrlList[0].qualityName : 'auto',
                    drmType: this.state.currentPlaySource.drmAuthUrl ? 2 : 0,
                });
                this.setState({
                    buffers
                })
                this.playBufferReportInterval = setInterval(() => {
                    this.playBufferReport();
                }, this.reportCycleConfig.bufferEventCycle * 1000);
                this.playStatusReportInterval = setInterval(() => {
                    this.playStatusReport();
                }, this.reportCycleConfig.liveEventCycle * 1000)
                this.eventPlayEndInterval = setInterval(() => {
                    if (getUTCTime() >= this.state.liveEventDetail.endTime) {
                        clearInterval(this.eventPlayEndInterval);
                        this.eventPlayEndInterval = null;
                        this.playerRef.current.pause();
                        this.setState({
                            liveEventStatus: GLOBAL.LIVE_EVENT.STATUS.ENDED,
                            isShowPlayerTips: true,
                            playerTips: this.props.t("liveEvent.liveEventEndTips")
                        })
                    }
                }, 1000)
                break;
            case 'adaptation':
                this.setState({
                    bufferCount: this.state.bufferCount + 1
                })
                break;
        }
    }

    /**
     * 广告隐藏
     */
    hideImageAd() {
        let adImageEls = document.querySelectorAll(".ad-images_container");
        adImageEls.forEach(item => {
            item.style.display = "none";
        })
    }

    /**
    * 图片广告展现上报
    */
    adPodsImpressReport(item, materialItem) {
        const { user, playInfo } = this.state;
        if (flag) {
            return
        }
        flag = true;
        $request.collectionApi.event({
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_IMPRESS_EVENT,
            materialName: materialItem.materialName,
            materialId: materialItem.materialId,
            adPodsName: item.podsName,
            adPodsId: item.podsId,
            type: materialItem.type
        }).then(() => {
            flag = false;
        });
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_VIEW_EVENT,
            materialId: materialItem.materialId,
            materialUrl: materialItem.url,
            adPodId: item.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[materialItem.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD
        }
        if (playInfo) {
            collectionData.playCode = playInfo.playCode;
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        let adPodsBehavior = [];
        if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
            adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            adPodsBehavior.push(collectionData);
        } else {
            adPodsBehavior.push(collectionData);
        }
        setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
        this.reportAdPodsBehavior();
    }

    /**
     * 检测是否有图片广告显示
     */
    checkIsAdImageShow() {
        let adImageEls = document.querySelectorAll(".ad-images_container");
        let hasAdShow = false;
        adImageEls.forEach(item => {
            if (item.style.display == 'block') {
                hasAdShow = true;
            }
        })
        return hasAdShow
    }

    /**
     * 广告图片加载错误
     * @param {*} data 
     */
    handleAdImageError(data) {
        const { user, playInfo } = this.state;
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materialId,
            materialUrl: data.url,
            adPodId: data.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            loadTime: 0,
            loadError: 0,
            loadError: 1,
            loadErrorMessage: "network error"
        }
        if (playInfo) {
            collectionData.playCode = playInfo.playCode
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        this.adImagesLoadedFailed[`adImage${data.podsId}And${data.materialId}Duration${data.startDuration}`] = collectionData;
    }

    /**
     * 广告图片加载成功
     * @param {*} data 
     */
    handleAdImageLoad(data) {
        const { user, playInfo } = this.state;
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materialId,
            materialUrl: data.url,
            adPodId: data.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.VOD,
            loadTime: 0,
            loadError: 0,
            loadErrorMessage: ""
        }
        if (playInfo) {
            collectionData.playCode = playInfo.playCode
        }
        if (user) {
            if (user.birthday) {
                collectionData.age = getAge(user.birthday);
            } else {
                collectionData.age = -1;
            }
        }
        this.adImagesLoadedSuccess[`adImage${data.podsId}And${data.materialId}Duration${data.startDuration}`] = collectionData;
    }

    /**
     * 渲染广告素材
     * @param {*} materialItem 
     */
    renderImageAdMaterial(materialInfo, index) {
        if (!materialInfo) {
            return
        }
        if (materialInfo.displayLocation == GLOBAL.AD_POSITION.TOP) {
            return (
                <div className='ad-images_container horizontal' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-top horizontal " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                                onLoad={() => {
                                    this.handleAdImageLoad(materialInfo)
                                }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.BOTTOM) {
            return (
                <div className='ad-images_container horizontal' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-bottom horizontal " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                                onLoad={() => {
                                    this.handleAdImageLoad(materialInfo)
                                }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.LEFT) {
            return (
                <div className='ad-images_container vertical' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-left vertical " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                                onLoad={() => {
                                    this.handleAdImageLoad(materialInfo)
                                }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.RIGHT) {
            return (
                <div className='ad-images_container vertical' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className={"ad-item ad-right vertical " + (materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "has-qr_code" : "")}>
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                                onLoad={() => {
                                    this.handleAdImageLoad(materialInfo)
                                }} />
                        </div>
                        <div className='qr-code' style={{ display: materialInfo.linkUrl && materialInfo.type == GLOBAL.AD_MATERIAL_TYPE.BANNER ? "block" : "none" }}>
                            <QRCode className="qr-code_img" value={materialInfo.linkUrl} />
                        </div>
                    </div>
                </div>
            )
        } else if (materialInfo.displayLocation == GLOBAL.AD_POSITION.CENTER) {
            return (
                <div className='ad-images_container center' style={{ display: materialInfo.isShow ? 'block' : 'none' }} ref={(el) => { this.adImageParentMapRef[`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adImagesContainer${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} key={index}>
                    <div ref={(el) => { this.adImageMapRef[`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`] = el }} id={`adItem${materialInfo.podsId}ID${materialInfo.materialId}Duration${materialInfo.startDuration}`} className="ad-item center">
                        <div className="ad-main" >
                            <img src={materialInfo.url} onError={() => {
                                this.handleAdImageError(materialInfo)
                            }}
                                onLoad={() => {
                                    this.handleAdImageLoad(materialInfo)
                                }} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    playEndReportToServer(isLiveEnd) {
        let { playInfo, currentPlaySource, startPlayTime, liveEventDetail, bufferCount } = this.state;
        if (!playInfo || !playInfo.playUrlList || !playInfo.playUrlList.length) {
            return
        }
        if (!currentPlaySource) {
            return
        }
        if (!this.playerRef || !this.playerRef.current) {
            return
        }
        if (!startPlayTime) {
            return
        }
        let startTime = startPlayTime;
        let endTime = getUTCTime();
        let durationData = {
            contentId: liveEventDetail.assetId,
            playCode: playInfo.playCode,
            type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
            playDuration: ((endTime - startTime) / 1000) > 0 ? (parseInt((endTime - startTime) / 1000)) : 0
        };
        // 播放记录数据
        let recordData = {
            assetId: liveEventDetail.assetId,
            playId: liveEventDetail.assetId,
            playName: liveEventDetail.name,
            playProgress: 10,
            type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
        }
        // DMS播放记录上报
        let collectionRecord = {
            eventId: GLOBAL.COLLECTION_EVENT.LIVE_EVENT_EVENT,
            startTime,
            endTime,
            playCode: playInfo.playCode,
            channelId: liveEventDetail.channelAssetId,
            playDuration: durationData.playDuration,
            programs: [
                {
                    programId: liveEventDetail.assetId,
                    programDuration: durationData.playDuration,
                    programStartTime: startTime,
                    programEndTime: endTime,
                    playUrl: currentPlaySource.playUrl,
                    programDate: formatDate(startTime, "yyyy-MM-dd")
                }
            ]
        }
        let collectionRecordData = {
            eventId: GLOBAL.COLLECTION_EVENT.PLAYER_PLAY_RECORD,
            contentId: liveEventDetail.assetId,
            type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
            startTime,
            endTime,
            duration: durationData.playDuration,
            quality: playInfo.playUrlList && playInfo.playUrlList.length ? playInfo.playUrlList[0].qualityName : 'auto',
            complete: 0,
            playCode: playInfo.playCode,
            streamType: getVideoStreamType(currentPlaySource.playUrl),
            bufferCount,
            drmType: currentPlaySource.drmAuthUrl ? 2 : 0,
        }
        if (durationData.playDuration > 0) {
            $request.boApi.common.playRecord(recordData);
            $request.boApi.common.playDuration(durationData);
            $request.collectionApi.event(collectionRecord);
            $request.collectionApi.event(collectionRecordData);
        }
    }

    /**
     * 广告荚数据上报
     */
    reportAdPodsBehavior() {
        // 将当前任务添加到队列
        this.adPodsReportQueue.push(this.processAdPodsBehavior);
        // 如果当前没有请求在处理，则开始处理队列
        if (!this.isRequestProcessing) {
            this.processQueue();
        }
    }

    processQueue() {
        this.isRequestProcessing = true; // 标记为正在处理请求

        // 处理队列中的请求
        if (this.adPodsReportQueue.length > 0) {
            const currentTask = this.adPodsReportQueue.shift(); // 获取队列中的第一个任务
            currentTask().then(() => {
                this.processQueue(); // 当前任务完成后继续处理下一个
            });
        } else {
            this.isRequestProcessing = false; // 标记为请求处理完成
        }
    }

    /**
    * 上报行为请求
    * @returns 
    */
    processAdPodsBehavior = () => {
        let _this = this;
        return new Promise((resolve) => {
            if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                let adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                console.log(this.reportCycleConfig.adEventCount, adPodsBehavior.length)
                if (adPodsBehavior.length >= (this.reportCycleConfig.adEventCount || 10)) {
                    adPodsBehavior.forEach(item => {
                        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) && getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).birthday) {
                            item.age = getAge(getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).birthday);
                        } else {
                            item.age = -1;
                        }
                        if (item.recordTime) {
                            delete item.recordTime; // 删除记录时间
                        }
                    });

                    $request.collectionApi.eventAdPods({ data: adPodsBehavior })
                        .then(res => {
                            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                                removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO); // 成功后清除存储
                            }
                            resolve(); // 请求完成，调用 resolve
                        })
                        .catch(error => {
                            console.error('报告广告行为失败:', error); // 错误处理
                            resolve(); // 失败时也要调用 resolve
                        });
                } else {
                    resolve(); // 如果不满足条件，直接调用 resolve
                }

                if (adPodsBehavior.length > GLOBAL.CONFIG.AD_REPORT_LIMIT_SIZE) {
                    removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO); // 超过限制时清除存储
                }
            } else {
                resolve(); // 如果没有数据，直接调用 resolve
            }
        });
    }

    render() {
        const { watermarkInfo, ageGrade, liveEventDetail, currentSystemTime, isShowControls, currentPlaySource, isShowTypeTag, customPlayerHeaders, isShowContentGragePannelSkip, contentGradePannelCountingDown, isShowContentGradePanel, isShowPlayerTips, playerTips, allImageAdPodsList } = this.state;
        let imageMaterialList = [];
        if (allImageAdPodsList.length) {
            deepClone(allImageAdPodsList).forEach(item => {
                if (item.materials && item.materials) {
                    item.materials.forEach(childItem => {
                        childItem.podsId = item.podsId;
                        childItem.podsType = item.podsType;
                        childItem.isShow = false;
                        imageMaterialList.push(childItem)
                    })
                }
            })
        }
        return (
            <div className='event-player'>
                {
                    watermarkInfo ? (<div className='watermark' style={{ ...GLOBAL.WATERMARK_POSITION_MAP[watermarkInfo.coordinateOrigin] }}>
                        <img src={watermarkInfo.filePath} />
                    </div>) : null
                }
                {
                    ageGrade ? (
                        <div className='age-grade'>
                            <img src={ageGrade.icon} />
                        </div>
                    ) : null
                }
                <div className='player-main'>
                    {
                        currentPlaySource ? <ShakaPlayer
                            src={currentPlaySource.playUrl}
                            ref={this.playerRef}
                            licenseServerUrl={currentPlaySource.drmAuthUrl}
                            customHeaders={customPlayerHeaders}
                            onEvent={(eventName, event) => {
                                this.playerEvent(eventName, event)
                            }}
                        /> : null
                    }

                </div>
                <div className='player-tips' style={{ display: isShowPlayerTips ? 'flex' : 'none' }}>{playerTips}</div>
                <div className='play-type_tag' style={{ display: isShowTypeTag ? 'flex' : 'none' }}>{this.props.t("tag.liveEvent").toLocaleUpperCase()}</div>
                <div className='controls' style={{ display: isShowControls ? 'flex' : 'none' }}>
                    <div className='event-name'>
                        {
                            liveEventDetail ? (<div>{liveEventDetail.name}</div>) : null
                        }
                    </div>
                    <div className='event-time_box'>
                        <div className='event-time_icon'>
                            <img src={require('../../assets/images/icon/atv_me_icon_playhistory.png').default} />
                        </div>
                        <div>
                            {currentSystemTime}
                        </div>
                    </div>
                </div>
                <div className='content-grade_pannel' style={{ display: isShowContentGradePanel ? 'block' : 'none' }}>
                    <div className='grade-pannel_wrapper'>
                        <div className='icon-wrapper'>
                            <div className='logo'>
                                <img src={require('../../assets/images/logo.png').default} />
                            </div>
                            <div className='line'></div>
                            <div className='age-icon' style={{ display: (liveEventDetail && liveEventDetail.contentGradeDto && liveEventDetail.contentGradeDto.icon) ? 'block' : 'none' }}>
                                <img src={(liveEventDetail && liveEventDetail.contentGradeDto && liveEventDetail.contentGradeDto.icon) ? liveEventDetail.contentGradeDto.icon : ''} />
                            </div>
                        </div>
                        {
                            liveEventDetail && liveEventDetail.contentGradeDto ? (
                                <div className='grade-pannel_main' ref={this.contentGradecontainerRef}>
                                    <div className='rating-desc'>{liveEventDetail.contentGradeDto.ratingDesc}</div>
                                    <div className='warning-desc'>{liveEventDetail.contentGradeDto.warningDesc}</div>
                                </div>
                            ) : null
                        }
                        <div className='grade-pannel_footer'>
                            <div className='grade-pannel_skip' ref={this.contentGradeSkipRef} style={{ display: isShowContentGragePannelSkip ? 'flex' : 'none' }} focusable>
                                <div className='skip-icon'>
                                    <img src={require('../../assets/images/icon/npvr_play.png').default} />
                                </div>
                                <div className='skip-text'>{this.props.t("button.skip")}</div>
                            </div>
                            <div className='down-time_text'>{this.props.t("player.contentGrageDownTimeTips")} {contentGradePannelCountingDown}</div>
                        </div>
                    </div>
                </div>
                <div className='ad-contenter'>
                    {
                        imageMaterialList.map((item, index) => (
                            this.renderImageAdMaterial(item, index)
                        ))
                    }
                </div>
            </div>
        )
    }

});