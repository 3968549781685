import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import CategorySelectorDialog from '../CategorySelector/CategorySelectorDialog';
import $request from '../../../../api';
import GLOBAL from "../../../../util/global"
import ColumnItem from '../../../../components/columnItem/ColumnItem';
import Empty from '../../../../components/empty/Empty';
import { deepClone, generateDays, keycodeHandle, isSameDay, getElementWidthWithMargin, getUTCTime, sortingAlgorithm, formatDate, getStorage, setStorage, removeStorage, scrollTextOnFocus, findChildNodeDeep } from "../../../../util/util"
import './style/LiveEvent.scss';

export default withTranslation()(class LiveEvent extends Component {
    constructor(props) {
        super(props);
        this.dayListRef = React.createRef();
        this.prevElRef = React.createRef();
        this.nextElRef = React.createRef();
        this.categoryBtnRef = React.createRef();
        this.categorySelectorRef = React.createRef();
        this.scrollHandler = null; // 滚动
        // this.categoryItemRef = [];
        this.state = {
            days: [],
            dayItemWidth: 0,
            curentPostionIndex: 7,
            currentDayIndex: 10,
            categoryList: [],
            isShowCategorySelector: false,
            categoryCheckedIds: [],
            categoryCheckedList: [],
            categoryCheckedStr: "", // 选中分类筛选的字符串
            liveEvents: [], // 事件直播列表
            selectedDayCategoryList: [], // 当天有事件的分类列表
            hasData: false, // 是否有数据
            // selectedDayCategoryStr: "", // 选择的分类
        }
    }
    componentWillMount() {
        this.setState({
            days: generateDays(21, 11, true)
        }, () => {
            this.getCategoryList();
        })

    }
    componentDidMount() {
        this.setState({
            dayItemWidth: getElementWidthWithMargin(document.getElementsByClassName("day-item")[0])
        }, () => {
            this.dayListRef.current.style.left = -(getElementWidthWithMargin(document.getElementsByClassName("day-item")[0]) * this.state.curentPostionIndex) + 'px';
            this.prevElRef.current.addEventListener('click', this.handleToPrevDay.bind(this));
            this.nextElRef.current.addEventListener('click', this.handleToNextDay.bind(this));
            this.prevElRef.current.addEventListener('mouseenter', () => {
                $tv.requestFocus(this.prevElRef.current);
            });
            this.nextElRef.current.addEventListener('mouseenter', () => {
                $tv.requestFocus(this.nextElRef.current);
            });
            this.categoryBtnRef.current.addEventListener('click', this.handleShowCategoryFilter.bind(this));
            this.categoryBtnRef.current.addEventListener('right', () => {
                $tv.requestFocus(this.categoryBtnRef.current);
            });
        });
        document.getElementById("root").style.backgroundImage = `none`;
        document.getElementsByClassName("home")[0].style.background = "#000";
        // keycodeHandle({
        //     exit: this.handleExit.bind(this),
        //     quit: this.handleExit.bind(this)
        // })
    }

    componentWillUnmount() {
        this.handleRemoveListenerCategoryItem();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showCategoryDialog !== this.state.isShowCategorySelector) {
            if (this.state.isShowCategorySelector) {
                this.setState({
                    isShowCategorySelector: false
                }, () => {
                    $tv.requestFocus(this.categoryBtnRef.current);
                })
            }
        }
    }

    handleExit() {
        if (this.state.isShowCategorySelector) {
            this.setState({
                isShowCategorySelector: false
            }, () => {
                $tv.requestFocus(this.categoryBtnRef.current);
            })
        }
    }


    getCategoryList() {
        const { data } = this.props;
        $request.boApi.liveEvent.getCategoryList({
            categoryId: data.categoryId ? data.categoryId : "",
            mediaType: GLOBAL.MEDIA_TYPE.LIVE_EVENT
        }).then((res) => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    categoryList: res.data.categorys ? res.data.categorys : []
                }, () => {
                    if (getStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY)) {
                        this.setState({
                            ...getStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY)
                        }, () => {
                            this.categorySelectorRef.current.listenerCategroy();
                        })
                    } else {
                        this.categorySelectorRef.current.listenerCategroy();
                    }

                })
            }
            this.getLiveEventList(this.state.days[this.state.currentDayIndex].formatDate);
        })
    }

    handleToPrevDay() {
        this.removeDayItemClass();
        if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)
        }
        this.setState({
            curentPostionIndex: this.state.curentPostionIndex > 0 ? this.state.curentPostionIndex - 1 : 0,
            currentDayIndex: this.state.currentDayIndex != 0 ? this.state.currentDayIndex - 1 : 0
        }, () => {
            if (this.state.curentPostionIndex >= 0) {
                this.dayListRef.current.style.left = -(getElementWidthWithMargin(document.getElementsByClassName("day-item")[0]) * this.state.curentPostionIndex) + 'px';
            }
            this.getLiveEventList(this.state.days[this.state.currentDayIndex].formatDate);
            document.getElementsByClassName("day-item")[this.state.currentDayIndex].classList.add("current");
        })
    }

    handleToNextDay() {
        this.removeDayItemClass();
        if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)
        }
        this.setState({
            curentPostionIndex: this.state.curentPostionIndex < 15 ? this.state.curentPostionIndex + 1 : 15,
            currentDayIndex: this.state.currentDayIndex != 20 ? this.state.currentDayIndex + 1 : 20
        }, () => {
            if (this.state.curentPostionIndex < 15) {
                this.dayListRef.current.style.left = -(getElementWidthWithMargin(document.getElementsByClassName("day-item")[0]) * this.state.curentPostionIndex) + 'px';
            }
            // console.log(this.state.days[this.state.currentDayIndex])
            this.getLiveEventList(this.state.days[this.state.currentDayIndex].formatDate);
            document.getElementsByClassName("day-item")[this.state.currentDayIndex].classList.add("current");
        })
    }

    removeDayItemClass() {
        let els = document.getElementsByClassName('current');
        for (let i = 0; i < els.length; i++) {
            els[i].classList.remove('current');
        }
    }

    scorllUp(propData, childData) {
        const { data } = this.props;
        if (this.props.scorllUp) {
            this.props.scorllUp(data, childData);
        }
    }

    scorllDown(propData, childData) {
        // console.log(propData)
        const { data } = this.props;
        if (this.props.scorllDown) {
            this.props.scorllDown(data, childData);
        }
    }

    clickItem(propData, childData) {
        const { data } = this.props;
        if (this.props.click) {
            this.props.click(data, childData);
        }
    }

    handleShowCategoryFilter() {
        this.setState({
            isShowCategorySelector: true
        }, () => {
            if (this.props.change) {
                this.props.change(true);
            }
        })
    }

    handleCategoryFilter(data, checkedIds) {
        let categoryCheckedStrArray = [];
        data.forEach(item => {
            categoryCheckedStrArray.push(item.categoryName)
        });
        $tv.requestFocus(this.categoryBtnRef.current);
        if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)
        }
        this.setState({
            isShowCategorySelector: false,
            categoryCheckedIds: checkedIds,
            categoryCheckedList: data,
            categoryCheckedStr: categoryCheckedStrArray.join(',')
        }, () => {
            if (this.props.change) {
                this.props.change(false);
            }
            setStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY, {
                categoryCheckedIds: this.state.categoryCheckedIds,
                categoryCheckedList: this.state.categoryCheckedList,
                categoryCheckedStr: this.state.categoryCheckedStr
            })
            $tv.requestFocus(this.categoryBtnRef.current);
            this.getLiveEventList(this.state.days[this.state.currentDayIndex].formatDate);
            // getLiveEventList
        })
    }

    getLiveEventList(date) {
        $request.boApi.liveEvent.getLiveEventCurrentList({
            categoryId: this.state.categoryCheckedIds.join(","),
            date
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                let currentLiveList = res.data.data || [];
                if (currentLiveList.length) {
                    this.setState({
                        hasData: true
                    })
                } else {
                    this.setState({
                        hasData: false
                    })
                }
                let selectedDayCategoryList = [];
                currentLiveList.forEach(item => {
                    selectedDayCategoryList.push({
                        categoryId: item.categoryId,
                        categoryName: item.categoryName,
                        categoryPoster: item.categoryPoster
                    });
                })
                let eventListByStatus = [
                    {
                        status: GLOBAL.LIVE_EVENT.STATUS.PLAYING,
                        statusName: this.props.t('liveEvent.live'),
                        liveEvents: []
                    },
                    {
                        status: GLOBAL.LIVE_EVENT.STATUS.UPCOMING,
                        statusName: this.props.t('liveEvent.upcomming'),
                        liveEvents: []
                    },
                    {
                        status: GLOBAL.LIVE_EVENT.STATUS.ENDED,
                        statusName: this.props.t('liveEvent.ended'),
                        liveEvents: []
                    }
                ];
                let eventList = [];
                // 展平事件列表
                currentLiveList.forEach(parent => {
                    parent.liveEvents.forEach(child => {
                        eventList.push({
                            ...child,
                            categoryId: parent.categoryId,
                            categoryName: parent.categoryName,
                            categoryPoster: parent.categoryPoster
                        })
                    })
                })
                // 按直播状态进行事件分类
                eventListByStatus.forEach(parent => {
                    eventList.forEach(child => {
                        if (child.startTime > getUTCTime()) {
                            child.status = GLOBAL.LIVE_EVENT.STATUS.UPCOMING;
                        } else if (child.startTime <= getUTCTime() && child.endTime > getUTCTime()) {
                            child.status = GLOBAL.LIVE_EVENT.STATUS.PLAYING;
                        } else {
                            child.status = GLOBAL.LIVE_EVENT.STATUS.ENDED;
                        }
                        if (child.status == parent.status) {
                            parent.liveEvents.push(deepClone(child));
                        }
                    })
                })
                // 进行排序
                eventListByStatus.forEach(item => {
                    if (item.status == GLOBAL.LIVE_EVENT.STATUS.PLAYING || item.status == GLOBAL.LIVE_EVENT.STATUS.UPCOMING) {
                        item.liveEvents = sortingAlgorithm(item.liveEvents, {
                            timestampKey: 'startTime',
                            nameKey: 'name'
                        })
                    } else if (item.status == GLOBAL.LIVE_EVENT.STATUS.ENDED) {
                        item.liveEvents = sortingAlgorithm(item.liveEvents, {
                            timestampKey: 'endTime',
                            nameKey: 'name'
                        })
                    }
                })
                eventListByStatus = eventListByStatus.filter(item => {
                    return item.liveEvents.length != 0
                })
                this.setState({
                    liveEvents: eventListByStatus,
                    selectedDayCategoryList
                }, () => {
                    this.listenerEventItem();
                    this.handleListenerCategoryItem();
                    // 还原记住的位置
                    if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
                        let foucsInfo = getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
                        if (foucsInfo.y) {
                            window.scrollTo(foucsInfo.y, 0);
                        }
                        if (foucsInfo.scorllEl) {
                            if (document.getElementById(foucsInfo.scorllEl)) {
                                document.getElementById(foucsInfo.scorllEl).style.left = foucsInfo.x + 'px' || 0;
                            }
                        }
                        if (foucsInfo.focusId) {
                            if (document.getElementById(foucsInfo.focusId)) {
                                $tv.requestFocus(document.getElementById(foucsInfo.focusId));
                            }
                        }
                        setTimeout(() => {
                            removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
                        }, 1000)
                    }
                })
            }
        })
    }

    renderEventStatus(data) {
        // console.log(data)
        if (data.status == GLOBAL.LIVE_EVENT.STATUS.PLAYING) {
            return (
                <div className='event-status living'>
                    <span className='event-status_icon'>
                        <img src={require('../../../../assets/images/icon/playing.gif').default} />
                    </span>
                    <span>{this.props.t('liveEvent.live')}</span>
                </div>
            )
        } else if (data.status == GLOBAL.LIVE_EVENT.STATUS.UPCOMING) {
            return (
                <div className='event-status'>
                    {this.props.t('liveEvent.upcomming')}
                </div>
            )
        } else {
            return (
                <div className='event-status'>
                    {this.props.t('liveEvent.ended')}
                </div>
            )
        }

    }

    listenerEventItem() {
        let events = document.querySelectorAll(".event-item");
        events.forEach((el, index) => {
            el.addEventListener("onFocus", () => {
                this.scrollHandler = scrollTextOnFocus(findChildNodeDeep(el, '.event-item_name'), {
                    duration: 2, // 自定义滚动持续时间
                    autoScroll: true // 聚焦时自动滚动
                });
                this.scrollHandler.startScroll();
            });
            el.addEventListener("onBlur", () => {
                this.scrollHandler.resetScroll();
            });
            el.addEventListener("left", this.handleEventLeft.bind(this, el));
            el.addEventListener("right", this.handleEventRight.bind(this, el));
            el.addEventListener("up", this.handleEventUp.bind(this, el));
            el.addEventListener("down", this.handleEventDown.bind(this, el));
            el.addEventListener("mouseenter", this.handleEventMouseEnter.bind(this, el));
            el.addEventListener("click", this.handleEventClick.bind(this, el));
        })
    }

    handleEventLeft(el) {
        if (!el) {
            return
        }
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let scrollEl = document.getElementById(`liveEventWrapper${parentIndex}`);
        let elWidth = getElementWidthWithMargin(el);
        if (Math.abs(scrollEl.offsetLeft) >= elWidth) {
            scrollEl.style.left = (parseInt(scrollEl.offsetLeft) + parseInt(elWidth)) + 'px';
        } else {
            scrollEl.style.left = "0px"
        }
        if (index == 0) {
            $tv.requestFocus(el);
            el.classList.add("levelShake");
            setTimeout(() => {
                el.classList.remove("levelShake");
            }, 1000)
        }
    }
    handleEventRight(el) {
        if (!el) {
            return
        }
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let scrollEl = document.getElementById(`liveEventWrapper${parentIndex}`);
        let elWidth = getElementWidthWithMargin(el);
        if (index < this.state.liveEvents[parentIndex].liveEvents.length - 1 && index >= 2) {
            scrollEl.style.left = -((index - 2) * elWidth) + 'px'
        } else {
            if (index == this.state.liveEvents[parentIndex].liveEvents.length - 1) {
                $tv.requestFocus(el);
                el.classList.add("levelShake");
                setTimeout(() => {
                    el.classList.remove("levelShake");
                }, 1000)
            }
        }

    }
    handleEventUp(el) { }
    handleEventDown(el) {
        let parentIndex = Number(el.getAttribute("parentindex"));
        if (parentIndex == this.state.liveEvents.length - 1) {
            $tv.requestFocus(el);
            el.classList.add("verticalShake");
            setTimeout(() => {
                el.classList.remove("verticalShake");
            }, 1000)
        }
    }
    handleEventMouseEnter(el) { }
    handleEventClick(el) {
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let scrollEl = document.getElementById(`liveEventWrapper${parentIndex}`);
        if (this.props.click) {
            setTimeout(() => {
                this.props.click({ ...this.state.liveEvents[parentIndex].liveEvents[index], x: scrollEl ? (scrollEl.offsetLeft > 0 ? 0 : scrollEl.offsetLeft) : 0, scrollEl: `liveEventWrapper${parentIndex}` });
            }, 300)
        }
        // console.log(this.state.liveEvents[parentIndex].liveEvents[index])
        // console.log(el.getAttribute("parentIndex"), el.getAttribute("index"))
    }

    /**
     * 分类tab事件监听
     */
    handleListenerCategoryItem() {
        let categorys = document.querySelectorAll(".category-list_item");
        categorys.forEach((el, index) => {
            el.addEventListener("click", () => {
                if (this.props.selected) {
                    this.props.selected({ ...this.state.selectedDayCategoryList[index], focusId: `liveEventSelectedCategoryItem${this.state.selectedDayCategoryList[index].categoryId}` });
                }
            });
        })
    }

    /**
     * 移除分类tab事件监听
     */
    handleRemoveListenerCategoryItem() {
        let categorys = document.querySelectorAll(".category-list_item");
        categorys.forEach((el, index) => {
            el.removeEventListener("click", () => { });
        })
    }

    render() {
        const { data } = this.props;
        const { days, hasData, categoryList, categoryCheckedStr, categoryCheckedIds, categoryCheckedList, liveEvents, selectedDayCategoryList } = this.state;
        const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return (
            <div className='live-event_main'>
                <div className='selector'>
                    <div className='day-selector'>
                        <div className="prev prev-day" ref={this.prevElRef} focusable id="dayPrev">
                            <div className="swiper-button-prev swiper-button-white"></div>
                        </div>
                        <div className="day-list" id="dayList" ref={this.dayListRef}>
                            {
                                days.map((item, index) => (
                                    <div key={index} className={isSameDay(new Date(), new Date(item.timestamp)) ? 'day-item current' : 'day-item'} id={`dayItem${index}`}>
                                        <div className='week'>{this.props.t(`date.${week[item.day]}`)}</div>
                                        <div className='date'>{isSameDay(new Date(), new Date(item.timestamp)) ? this.props.t('liveEvent.today') : item.date}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="next next-day" ref={this.nextElRef} focusable id="dayNext">
                            <div className="swiper-button-next swiper-button-white"></div>
                        </div>
                    </div>
                    <div className='category-selector'>
                        <div className='category-wrapper'>
                            {categoryCheckedList.length == 0 ? this.props.t("liveEvent.allCompetitions") : categoryCheckedStr}
                        </div>
                        <div className='category-btn' id="liveEventCategorySelectorBtn" ref={this.categoryBtnRef} focusable>

                            <div className='category-btn_img'>
                                <div className="swiper-button-next swiper-button-white"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='category-list'>
                    <div className='category-list_wrapper'>
                        {
                            selectedDayCategoryList.map((item, index) => (
                                <div className='category-list_item' key={index} id={`liveEventSelectedCategoryItem${item.categoryId}`} focusable>{item.categoryName}</div>
                            ))
                        }

                    </div>
                </div>
                <div className='live-event_list'>
                    <div className='live-event_child--list'>
                        {liveEvents.map((item, index) => (
                            <div key={index}>
                                <div className='live-event_status--title'>{item.statusName}</div>
                                <div className='live-event-child--wrapper' id={'liveEventWrapper' + index}>
                                    {
                                        item.liveEvents.map((eventItem, eventIndex) => (
                                            <div className='event-item' focusable parentindex={index} index={eventIndex} id={eventItem.assetId} key={eventIndex}>
                                                <div className='event-item_poster'>
                                                    {
                                                        eventItem.poster ? <img src={eventItem.poster} /> : null
                                                    }
                                                    {
                                                        this.renderEventStatus(eventItem)
                                                    }
                                                </div>
                                                <div className='event-item_name--wrapper'>
                                                    <div className='event-item_name'>
                                                        {eventItem.name}
                                                    </div>
                                                </div>
                                                <div className='event-item_info'>
                                                    <div className='event-time'>{formatDate(eventItem.startTime, "hh:mm")}</div>
                                                    <div className='event-iocn'>
                                                        {eventItem.categoryPoster ? <img src={eventItem.categoryPoster} /> : null}
                                                    </div>
                                                    <div className='event-category_name'>{eventItem.categoryName}</div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        ))}

                    </div>
                    <Empty show={!hasData} />
                    {/* <div className='empty' style={{display: hasData ? 'none' : 'flex'}}>
                        <div className='empty-wrapper'>
                            <img src={require('../../../../assets/images/icon/empty.png').default} />
                        </div>
                    </div> */}
                </div>
                <CategorySelectorDialog ref={this.categorySelectorRef} confirmText={this.props.t("button.sure")} title={this.props.t("liveEvent.allCompetitions")} show={this.state.isShowCategorySelector} data={categoryList} defaultChecked={categoryCheckedIds} confirm={(data, ids) => {
                    this.handleCategoryFilter(data, ids)
                }} />
            </div>
        )
    }
});