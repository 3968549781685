import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, getUUID, scrollTextOnFocus, findChildNodeDeep, keycodeHandle, isObject, formatDate, formatDecimals, getElementHeightWithMargin, getQueryObj } from '../../util/util';
import './style/Package.scss';
import $request from '../../api';
import GLOBAL from '../../util/global';

export default withTranslation()(class Package extends Component {
    constructor(props) {
        super(props);
        this.tabsRef = []; // tab节点
        this.packagesRef = []; // 包列表节点
        this.packagesWrapperRef = React.createRef();
        this.pageWheelTimer = null; // 鼠标滚轮监听延时器
        this.state = {
            userInfo: null, // 用户信息
            tabs: [{
                type: 1,
                name: this.props.t("purchase.basePackage")
            },
            // {
            //     type: 2,
            //     name: this.props.t("purchase.addPackage")
            // }
            ],
            currentSubscribes: [], // 当前订阅包
            packageList: [], // 包列表
            currentTabEl: null, // 当前Tab节点
            currentTab: null, // 当前tab
            currentPackage: null, // 当前包
            pathUrlQuery: null, // 路径参数
        }

    }

    componentWillMount() {
        if (getStorage("userInfo")) {
            this.setState({
                userInfo: getStorage("userInfo")
            })
        }
        let pathUrlQuery = getQueryObj(window.location.href);
        if (pathUrlQuery.contentId) {
            this.setState({
                pathUrlQuery
            })
        }
        this.getCurrentSubscribe();
    }

    componentDidMount() {
        // console.log(this.props.history)
        if (this.tabsRef.length) {
            $tv.requestFocus(this.tabsRef[0]);
            this.setState({
                currentTabEl: this.tabsRef[0],
                currentTab: this.state.tabs[0]
            })
        }
        this.handleListenerTab();
        keycodeHandle({
            exit: () => { window.history.back(-1) },
            quit: () => { window.history.back(-1) },
        })
    }

    componentWillUnmount() {
        this.handleRemoveListenerTab();
        clearTimeout(this.pageWheelTimer);
    }


    /**
     * 获取当前订阅包
     */
    getCurrentSubscribe() {
        $request.boApi.product.getCurrentSubscribe({
            mac: getUUID()
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.subscriptions) {
                    this.setState({
                        currentSubscribes: isObject(res.data.subscriptions) ? [res.data.subscriptions] : res.data.subscriptions
                    }, () => {
                        if (this.state.pathUrlQuery) {
                            this.getContentProductList();
                        } else {
                            this.getProductBaseList();
                        }

                    });
                }
            }
        })
    }

    /**
     * 获取产品基础包
     */
    getProductBaseList() {
        $request.boApi.product.getProductBaseList({
            mac: getUUID(),
            page: 0,
            pageSize: 999
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.products) {
                    let packageList = res.data.products || [];
                    packageList = packageList.filter(item => {
                        return item.purchaseType == 1
                    })
                    packageList.forEach((item, index) => {
                        this.state.currentSubscribes.forEach(childItem => {
                            if (item.productId == childItem.product.productId) {
                                packageList.splice(index, 1);
                            }
                        })
                    })
                    this.setState({
                        packageList
                    }, () => {
                        this.handleListenerPackage();
                    })
                }
            }
        })
    }

    /**
     * 获取增值包
     */
    getProductConsumeList() {
        $request.boApi.product.getProductConsumeList({
            mac: getUUID(),
            page: 0,
            pageSize: 999
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.products) {


                    // this.packagesRef = [];
                    this.setState({
                        packageList: res.data.products || []
                    }, () => {
                        this.handleListenerPackage();
                    })
                }
            }
        })
    }

    /**
     * 更加内容ID获取产品包
     */
    getContentProductList() {
        let type = this.state.pathUrlQuery.type
        let param = {
            contentId: this.state.pathUrlQuery.contentId,
            mac: getUUID(),
            type: type ? (type == GLOBAL.MEDIA_TYPE.LIVE_EVENT ? GLOBAL.MEDIA_TYPE.LIVE_EVENT_CHANNEL : type) : GLOBAL.MEDIA_TYPE.VOD
        }
        if (this.state.pathUrlQuery.eventAssetId) {
            param.eventAssetId = this.state.pathUrlQuery.eventAssetId
        }
        $request.boApi.product.getContentProductList(param).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.products) {
                    if (this.state.currentTab.type == 1) {
                        let packageList = res.data.products || [];
                        packageList = packageList.filter(item => {
                            return item.purchaseType == 1
                        })
                        packageList.forEach((item, index) => {
                            this.state.currentSubscribes.forEach(childItem => {
                                if (item.productId == childItem.product.productId) {
                                    packageList.splice(index, 1);
                                }
                            })
                        })
                        this.setState({
                            packageList
                        }, () => {
                            this.handleListenerPackage();
                        })
                    } else {
                        let packageList = res.data.products.filter(item => {
                            return item.purchaseType == 2
                        });
                        this.setState({
                            packageList
                        }, () => {
                            this.handleListenerPackage();
                        })
                    }
                }
            }
        })
    }

    /**
     * tab监听
     */
    handleListenerTab() {
        if (this.tabsRef.length) {
            this.tabsRef.forEach((el, index) => {
                el.addEventListener("onFocus", () => {
                    this.setState({
                        currentTabEl: el
                    });
                    if (this.state.currentTab && this.state.currentTab.type != this.state.tabs[index].type) {
                        this.handleRemoveListenerPackage();
                        setTimeout(() => {
                            this.setState({
                                currentTab: this.state.tabs[index]
                            }, () => {
                                if (this.state.currentTab.type == 1) {
                                    this.getCurrentSubscribe()
                                } else {
                                    this.getProductConsumeList();
                                }
                            })
                        }, 300)
                    }
                })
            })
        }
    }

    /**
     * tab移除监听
     */
    handleRemoveListenerTab() {
        if (this.tabsRef.length) {
            this.tabsRef.forEach(el => {
                el.removeEventListener("onFocus", () => { })
            })
        }
    }

    /**
     * 监听包事件
     */
    handleListenerPackage() {
        const packages = document.querySelectorAll(".package-item");
        if (packages.length) {
            let scorllEl = this.packagesWrapperRef.current;
            packages.forEach((el, index) => {
                el.addEventListener("onFocus", () => {
                    this.setState({
                        currentPackage: this.state.packageList[index]
                    });
                    let titleEl = findChildNodeDeep(el, '.name');
                    if (titleEl) {
                        this.scrollHandler = scrollTextOnFocus(titleEl, {
                            duration: 2, // 自定义滚动持续时间
                            autoScroll: true // 聚焦时自动滚动
                        });
                        this.scrollHandler.startScroll();
                    }
                })
                el.addEventListener("onBlur", () => {
                    this.scrollHandler.resetScroll();
                });
                el.addEventListener("click", () => {
                    this.setState({
                        currentPackage: this.state.packageList[index]
                    }, () => {
                        if (this.state.pathUrlQuery) {
                            this.props.history.push(`/packageDetail/${this.state.currentPackage.productId}?contentId=${this.state.pathUrlQuery.contentId}&type=${this.state.pathUrlQuery.type}`);
                        } else {
                            this.props.history.push(`/packageDetail/${this.state.currentPackage.productId}`);
                        }
                    })
                    // console.log(this.state.currentPackage)
                   
                    
                })
                el.addEventListener("up", () => {
                    if (index == 0) {
                        $tv.requestFocus(this.state.currentTabEl);
                        scorllEl.style.top = 0;
                    } else {
                        let elHeight = getElementHeightWithMargin(el);
                        if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                            scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
                        }
                    }
                })
                el.addEventListener("down", () => {
                    if (index != this.state.packageList.length - 1) {
                        if (el && index > 3) {
                            this.packagesWrapperRef.current.style.top = -(index * getElementHeightWithMargin(el)) + 'px';
                        }
                    }
                })
            })
        }
    }

    /**
     * 移除监听包事件
     */
    handleRemoveListenerPackage() {
        const packages = document.querySelectorAll(".package-item");
        if (packages.length) {
            packages.forEach((el, index) => {
                if (el) {
                    el.removeEventListener("onFocus", () => { })
                    el.removeEventListener("click", () => {

                    })
                    el.removeEventListener("up", () => { })
                    el.removeEventListener("down", () => { })
                }
            })
            // this.packagesRef = []
        }
    }

    /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
     pageWheel(e) {
        clearTimeout(this.pageWheelTimer);
        this.pageWheelTimer = setTimeout(() => {
            if (e.deltaY > 0) {
                $tv.findFocusType = 0;
                $tv.initDis = 10;
                $tv.setFocus('down');
            } else {
                $tv.findFocusType = 0;
                $tv.initDis = 80;
                $tv.setFocus('up');
            }
        }, 300);
    }

    render() {
        const { userInfo, tabs, packageList, currentPackage } = this.state;
        return (<div className='package-page'  onWheel={e => this.pageWheel(e)}>
            <div className='user-info'>
                <div className='current-user'>
                    <div className='avatar'>
                        <img src={require('../../assets/images/icon/atv_me_icon_userhead.png').default} />
                    </div>
                    <div className='nick-name'>
                        {this.props.t("purchase.currentAccout")}
                        {userInfo ? (userInfo.nickName || userInfo.name) : ""}
                    </div>
                </div>
            </div>
            <div className='tab'>
                {
                    tabs.map((item, index) => (
                        <div className='tab-item' key={item.type} focusable ref={(el) => { this.tabsRef[index] = el }}>
                            <div className='icon'>
                                <img className='icon-focused' src={require('../../assets/images/icon/payment_icon_sub_focus.png').default} />
                                <img className='icon-unblur' src={require('../../assets/images/icon/payment_icon_sub_normal.png').default} />
                            </div>
                            <div className='text'>{item.name}</div>
                        </div>
                    ))
                }

                <div className='line'></div>
            </div>

            <div className='package-wrapper'>
                <div className='wrapper-left'>
                    <div className='package-list' ref={this.packagesWrapperRef}>
                        {
                            packageList.map((item, index) => (
                                <div className='package-item' key={index} ref={(el) => { this.packagesRef[index] = el }} focusable>
                                    <div className='name-desc'>
                                        <div className='name'>{item.productName}</div>
                                        {/* <div className='desc'>{item.description}</div> */}
                                    </div>
                                    <div className='price'>
                                        {item.currency} {formatDecimals(item.price)}
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className='wrapper-right'>
                    <div className='poster'>
                        {
                            currentPackage && currentPackage.poster ? (<img src={currentPackage.poster || currentPackage.vPoster} />) : null
                        }
                    </div>
                </div>
            </div>

        </div>)
    }
}) 