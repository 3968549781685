import React, { Component } from 'react';
import Column from '../../../../components/column/Column';
import './style/FullScreenVideo.scss';


export default class FullScreenVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentContentItem: {
                title: "",
                description: ""
            },
            showContent: false
        }
    }

    componentDidMount() {

    }

    initBackgroundImage(data) {
        document.getElementById("root").style.backgroundImage = `url("${data.background}")`;
        document.getElementsByClassName("home")[0].style.background = "linear-gradient(180deg,rgba(15,15,15,0.8) 16%, rgba(15,15,15,0.00))";
        this.setState({
            showContent: true,
            currentContentItem: data
        })
    }

    focusedItem(data, index) {
        // console.log(data)
        this.setState({
            showContent: true,
            currentContentItem: data.data
        })
        if (this.props.focusedItem) {
            this.props.focusedItem(data, index);
        }
        document.getElementById("root").style.backgroundImage = `url("${data.data.background}")`;
        document.getElementsByClassName("home")[0].style.background = "linear-gradient(180deg,rgba(15,15,15,0.8) 16%, rgba(15,15,15,0.00))";
    }

    scorllUp() {
        // console.log(data)
        const { data } = this.props;
        if (this.props.scorllUp) {
            this.props.scorllUp(data);
            // console.log(index)
        }
    }

    scorllDown() {
        const { data } = this.props;
        if (this.props.scorllDown) {
            this.props.scorllDown(data);
        }
        this.setState({
            currentContentItem: {
                title: "",
                description: "",
                showContent: false
            }
        })
        document.getElementById("root").style.backgroundImage = `none`;
        document.getElementsByClassName("home")[0].style.background = "#000";
    }

    clickItem(data, childData) {
        if (this.props.click) {
            this.props.click(this.props, childData);
        }
    }


    render() {
        const { data } = this.props;
        const { currentContentItem, showContent } = this.state;
        return (
            <div className='full-screen_video'>
                <div className='recommend-content_title' style={{ display: showContent ? "block" : "none" }}>
                    {currentContentItem.title}
                </div>
                <div className='recommend-content_desc' style={{ display: showContent ? "block" : "none" }}>
                    {currentContentItem.description}
                </div>
                <div className='recommed-title' style={{ display: data.isDisplayTitle == "N" ? "none" : "block" }}>{data.title}</div>
                <Column data={data.rContent} click={(propData, childData) => {
                    this.clickItem(propData, childData)
                }} focusedItem={(propData, index) => {
                    this.focusedItem(propData, index)
                }} scorllUp={(propData, index) => { this.scorllUp(propData, index) }} scorllDown={(propData, index) => { this.scorllDown(propData, index) }} key={data.id} id={data.id} showTitle={data.isDisplayContentTitle} itemStyle={data.style} />
            </div>
        )
    }
}