import React, { Component } from 'react';
import $request from "../../../../api"
import { deepClone, getStorage, rsaDecryptContent, getAge, setStorage, removeStorage, getUTCTime } from '../../../../util/util';
// import { deviceSign } from '../../../../util/commonRequest';
import GLOBAL from '../../../../util/global';
import './style/Ad.scss';

export default class Ad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adData: {
                rContent: [],
                isVisible: false, // 是否可见
                retryCount: 0,
                maxRetries: 3, // 最大重试次数
            },
            defaultBg: require("../../../../assets/images/defaultImages/atv_default_1728x200.png")
        }
        this.observer = null; // 广告节点监听
        this.targetRef = React.createRef();
        this.user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) || null;
        this.reportCycleConfig = {
            vodEventCycle: 60,
            liveEventCycle: 60,
            bufferEventCycle: 60,
            maxBufferCount: 10,
            qualityEventCycle: 60,
            errorEventCycle: 60,
            adEventCount: 10
        }
    }


    componentDidMount() {
        this.setState({
            adData: this.props.data
        }, () => {
            if (getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG)) {
                let reportCycleConfig = getStorage(GLOBAL.STORAGE_KEYS.REPORT_CYCLE_CONFIG);
                Object.keys(this.reportCycleConfig).forEach(key => {
                    this.reportCycleConfig[key] = reportCycleConfig[key];
                });
            }
        })

        // let recommendData = (deepClone(this.props.data));
        // recommendData.rContent.forEach(item => {
        //     if (item.contentId) {
        //         setTimeout(() => {
        //             this.getAdPods(item.contentId)
        //         }, 300)
        //     }
        // })
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        // this.setState({
        //     adData: nextProps.data
        // })
        // console.log(nextProps, prevProps)
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        } else {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    getAdPods(contentId) {
        if (!sessionStorage.getItem(GLOBAL.STORAGE_KEYS.SIGN)) {
            // deviceSign() 
        }
        let promise = new Promise((resolve, reject) => {
            resolve();
        });
        $request.adApi.getAdPods({
            podsId: contentId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.body) {
                    let pods = rsaDecryptContent(res.data.body);
                    this.setState({
                        pods
                    }, () => {

                    })
                }

            }
        })
    }
    /**
     * 可见监听
     */
    handleAdVisible() {
        if ('IntersectionObserver' in window) {
            // 使用 IntersectionObserver
            this.observer = new IntersectionObserver(
                (entries) => {
                    const entry = entries[0];
                    if (entry.isIntersecting) {
                        this.setState({ isVisible: true });
                        this.adReportImpress();
                        this.observer.disconnect(); // 只触发一次，进入视口后停止观察
                    }
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.1,
                }
            );
            if (this.targetRef.current) {
                this.observer.observe(this.targetRef.current);
            }
        } else {
            // 如果浏览器不支持 IntersectionObserver，使用滚动事件作为回退
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        if (!this.targetRef.current) return;

        const rect = this.targetRef.current.getBoundingClientRect();
        const isVisible =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);

        if (isVisible) {
            this.setState({ isVisible: true });
            this.adReportImpress();
            window.removeEventListener('scroll', this.handleScroll); // 只触发一次，进入视口后停止监听
        }
    };

    focusedItem(data, index) {

    }

    scorllUp(data, index) {

    }

    scorllDown(data, index) {

    }

    renderItem(data) {
        // console.log(data, '---')
        if (data) {
            this.setState({
                adData: data
            }, () => {
                this.handleAdVisible();
            })
        }
        // const { pods } = this.state;
        // if (pods && pods.materials && pods.materials.length) {
        //     return
        // }
    }

    /**
     * 广告展现上报
     */
    adReportImpress() {
        const { adData } = this.state;
        if (adData && adData.rContent) {
            let adItem = adData.rContent[0];
            let materialInfo = adItem.materials;
            let collectionData = {
                eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_VIEW_EVENT,
                materialId: materialInfo.materialId,
                materialUrl: materialInfo.url,
                adPodId: materialInfo.podsId,
                adType: GLOBAL.COLLECTION_AD_TYPE[materialInfo.type],
                videoType: GLOBAL.COLLECTION_VIDEO_TYPE.PLAY_LIST
            }
            if (this.user) {
                collectionData.age = getAge(this.user.birthday);
            } else {
                collectionData.age = -1;
            }
            let adPodsBehavior = [];
            if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
                adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                adPodsBehavior.push(collectionData);
            } else {
                adPodsBehavior.push(collectionData);
            }
            setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
            this.reportAdPodsBehavior();
        }
    }

    /**
     * 广告行为上报
     */
    reportAdPodsBehavior() {
        if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
            let adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            if (adPodsBehavior.length >= this.reportCycleConfig.adEventCount) {
                adPodsBehavior.forEach(item => {
                    if (this.user && this.user.birthday) {
                        item.age = getAge(this.user.birthday);
                    } else {
                        item.age = -1;
                    }
                    if (item.recommendIndex) {
                        delete item.recommendIndex;
                    }
                    if (item.recordTime) {
                        delete item.recordTime;
                    }
                });
                $request.collectionApi.eventAdPods({
                    data: adPodsBehavior
                }).then(res => {
                    if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                        removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
                    }
                })
            }
            if (adPodsBehavior.length > GLOBAL.CONFIG.AD_REPORT_LIMIT_SIZE) {
                removeStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            }
        }
    }

    handleAdImageLoad(data) {
        // console.log('load', data);
        this.setState({ retryCount: 0 });
        if (data.materials) {
            this.adImagesLoadedSuccessReport(data);
        }
        
    }

    handleAdImageError(data) {
        const { retryCount, maxRetries } = this.state;
        if (retryCount < maxRetries && data.materials) {
            console.log(`Retrying image load... (${retryCount + 1}/${maxRetries})`);
            this.setState(
                (prevState) => ({
                    retryCount: prevState.retryCount + 1,
                }),
                () => {
                    // 在状态更新后，重新尝试加载图片
                    this.forceUpdate(); // 强制组件重新渲染以重新加载图片
                }
            );
        } else {
            if (data.materials) {
                // 在这里抛出错误或者执行其他失败逻辑
                this.adImagesLoadedFailedReport(data);
                if (this.targetRef && this.targetRef.current) {
                    this.targetRef.current.src = require("../../../../assets/images/defaultImages/atv_default_1728x200.png").default;
                }
                console.log(this.targetRef.current.src)
                console.log('error', data);
                console.log("Image failed to load after 3 retries.");
            }

        }

    }

    /**
     * 图片广告加载失败上报
     */
    adImagesLoadedFailedReport(data) {
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materials.materialId,
            materialUrl: data.materials.url,
            adPodId: data.materials.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.materials.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.PLAY_LIST,
            loadTime: 0,
            loadError: 0,
            loadError: 1,
			loadErrorMessage: "network error",
            recommendIndex: data.index
        }
        if (this.user && this.user.birthday) {
            collectionData.age = getAge(this.user.birthday);
        } else {
            collectionData.age = -1;
        }
        let adPodsBehavior = [];
        if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
            let isSameData = false;
            adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            adPodsBehavior.forEach(item => {
                if (item.adPodId == collectionData.adPodId && item.materialId == collectionData.materialId && item.recommendIndex == collectionData.recommendIndex) {
                    isSameData = true;
                    if (item.recordTime) {
                        if (collectionData.recordTime - item.recordTime >= 3000) {
                            isSameData = false;
                        }
                    }
                }
            })
            if (isSameData == false) {
                adPodsBehavior.push(collectionData);
            }
        } else {
            adPodsBehavior.push(collectionData);
        }
        setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
        // this.reportAdPodsBehavior();
    }

    /**
     * 图片广告加载成功上报
     */
    adImagesLoadedSuccessReport(data) {
        let collectionData = {
            eventId: GLOBAL.COLLECTION_EVENT.AD_PODS_LOAD_RECORD,
            materialId: data.materials.materialId,
            materialUrl: data.materials.url,
            adPodId: data.materials.podsId,
            adType: GLOBAL.COLLECTION_AD_TYPE[data.materials.type],
            videoType: GLOBAL.COLLECTION_VIDEO_TYPE.PLAY_LIST,
            loadTime: 0,
            loadError: 0,
            loadErrorMessage: "",
            recordTime: getUTCTime(),
            recommendIndex: data.index
        }
        if (this.user && this.user.birthday) {
            collectionData.age = getAge(this.user.birthday);
        } else {
            collectionData.age = -1;
        }
        let adPodsBehavior = [];
        if (getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO)) {
            let isSameData = false;
            adPodsBehavior = getStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO);
            adPodsBehavior.forEach(item => {
                if (item.adPodId == collectionData.adPodId && item.materialId == collectionData.materialId && item.recommendIndex == collectionData.recommendIndex) {
                    isSameData = true;
                    if (item.recordTime) {
                        if (collectionData.recordTime - item.recordTime >= 3000) {
                            isSameData = false;
                        }
                    } else {
                        isSameData = false;
                    }
                }
            })
            if (isSameData == false) {
                adPodsBehavior.push(collectionData);
            }
        } else {
            adPodsBehavior.push(collectionData);
        }
        setStorage(GLOBAL.STORAGE_KEYS.AD_PODS_COLLECTION_INFO, adPodsBehavior);
        // this.reportAdPodsBehavior();
    }

    render() {
        const { adData, retryCount } = this.state;
        return (
            <div className='ad-wrapper'>
                <div className='recommed-title' style={{ display: adData.isDisplayTitle == "N" ? "none" : "none" }}>{adData.title}</div>
                {
                    adData.rContent.map((item, index) => (
                        <div className='ad-item' key={index}>
                            <div className='ad-img_box' focusable id={item.id}>
                                <img onError={() => {
                                    this.handleAdImageError(item)
                                }} onLoad={() => {
                                    this.handleAdImageLoad(item)
                                }} ref={this.targetRef} src={(item.contentId ? (item.materials ? item.materials.url : "") : item.url) + `?retry=${retryCount}`} />
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}