/* eslint-disable */
import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { Link } from 'react-router-dom';
import { generateUrl } from '../../../../util/util';
import './style/Startup.scss';

export default class Startup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerSwiper: null,
            seconds: 10
        }
        this.countdown = this.countdown.bind(this);
    }

    componentDidMount() {
        this.renderItem();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {

        // this.renderItem()
    }


    countdown() {
        if (this.state.seconds > 0) {
          this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
        } else {
          clearInterval(this.intervalId);
          if (this.props.complete) {
            this.props.complete();
          }
        }
      }


    renderItem() {
        const { data } = this.props
        if (data) {
            let bannerSwiper = this.state.bannerSwiper;
            // return
            bannerSwiper = new Swiper(`#startupContainer`, {
                autoplay: data && data.length > 1 ? 2000 : false,
                // speed: 1000,
                autoplayDisableOnInteraction: false,
                loop: true,
                centeredSlides: true,
                slidesPerView: 1
            })
            this.setState({
                bannerSwiper
            }, () => {
                this.intervalId = setInterval(this.countdown, 1000);
            })
            let el = document.getElementById('downtime');
            setTimeout(() => {
                $tv.requestFocus(el);
            }, 500)
            el.addEventListener("right", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("left", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("up", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("down", () => {
                $tv.requestFocus(el);
            })
        }
    }


    render() {
        const { data } = this.props;
        return (<div className='startup'>
            <div className='swiper-container' id="startupContainer">
                <div className='swiper-wrapper'>
                    {
                        data.map((item, index) => (
                            <div className={`swiper-slide`} key={index}>
                                <div className='swiper-image'>
                                    <img src={item.url} />
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
            <div className='downtime' id='downtime' focusable>{this.state.seconds}</div>
        </div>)
    }
}