import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { $tv } from 'react-tv-focusable';
import ShakaPlayer from '../player/player';
import { withTranslation } from 'react-i18next';
import './style/adPlay.scss';

class AdPlayer extends Component {
    constructor(props) {
        super(props);
        this.adPlayerRef = React.createRef(); // 播放器节点
        this.adSkipRef = React.createRef(); // 跳转按钮节点
        this.state = {
            downTime: 0,
            isShowSkipButton: false,
            playUrl: ""
        }
    }

    componentDidMount() {
        this.handleSkipAd();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.src !== nextProps.src) {
            console.log(nextProps.src)
            this.setState({
                playUrl: nextProps.src
            })
            this.handleSkipAd();
        }
    }

    componentWillUnmount() {
        this.removeSkipAdListener();
    }
    /**
     * 事件监听
     * @param {*} eventName 
     * @param {*} data 
     */
    playerEvent(eventName, data) {
        const { showSkipDuration, isAllowSkip } = this.props;
        switch (eventName) {
            case 'timeupdate':
                setTimeout(() => {
                    if (this.adPlayerRef && this.adPlayerRef.current) {
                        let currentTime = this.adPlayerRef.current.currentTime ? this.adPlayerRef.current.currentTime() : 0;
                        let adDuration = this.adPlayerRef.current.duration ? parseInt(this.adPlayerRef.current.duration()) - parseInt(currentTime) : 0;
                        if (adDuration >= 0) {
                            this.setState({
                                downTime: adDuration
                            })
                        }

                        if (showSkipDuration != undefined && isAllowSkip) {
                            if (parseInt(currentTime) >= showSkipDuration) {
                                this.setState({
                                    isShowSkipButton: true
                                }, () => {
                                    $tv.requestFocus(this.adSkipRef.current);
                                })
                            }
                        }
                    }
                }, 300)
                break;
            case 'ended':
                if (this.props.onEvent) {
                    if (this.adPlayerRef && this.adPlayerRef.current) {
                        this.props.onEvent('completed', {
                            currentTime: parseInt(this.adPlayerRef.current.currentTime()),
                            duration: parseInt(this.adPlayerRef.current.duration())
                        });
                        this.adPlayerRef.current.destroyPlayer().then(() => {
                            if (this.props.destroy) {
                                this.props.destroy();
                            }
                        });
                    }
                }
                break;
            case 'error':
                if (this.props.onEvent) {
                    this.props.onEvent('error', data);
                    if (this.adPlayerRef && this.adPlayerRef.current) {
                        this.adPlayerRef.current.destroyPlayer().then(() => {
                            if (this.props.destroy) {
                                this.props.destroy();
                            }
                        });
                    } else {
                        this.props.onEvent('destroyed', data);
                    }
                }
                break;
            case 'loading':
                if (this.props.onEvent) {
                    this.props.onEvent(eventName, data);
                }
                break;
            case 'loaded':
                this.adPlayerRef.current.play();
                if (this.props.onEvent) {
                    this.props.onEvent(eventName, data);
                }
                break;
            case 'destroyed':
                console.log('AD destroyed')
                if (this.props.onEvent) {
                    this.props.onEvent(eventName, data);
                }
                break;
        }
    }
    /**
     * 跳过广告
     */
    handleSkipAd() {
        this.removeSkipAdListener();
        this.adSkipRef.current.addEventListener("click", () => {
            console.log(1111)
            if (this.props.onEvent) {
                if (this.adPlayerRef && this.adPlayerRef.current) {
                    this.props.onEvent('skiped', {
                        currentTime: parseInt(this.adPlayerRef.current.currentTime()),
                        duration: parseInt(this.adPlayerRef.current.duration())
                    });
                    this.adPlayerRef.current.destroyPlayer().then(() => {
                        if (this.props.destroy) {
                            this.props.destroy();
                        }
                    });
                }
            }
        });
        this.adSkipRef.current.addEventListener("click", () => { });
        this.adSkipRef.current.addEventListener("left", () => { });
        this.adSkipRef.current.addEventListener("right", () => { });
        this.adSkipRef.current.addEventListener("up", () => { });
        this.adSkipRef.current.addEventListener("down", () => { });
    }

    removeSkipAdListener() {
        this.adSkipRef.current.removeEventListener("click", () => { });
        this.adSkipRef.current.removeEventListener("click", () => { });
        this.adSkipRef.current.removeEventListener("left", () => { });
        this.adSkipRef.current.removeEventListener("right", () => { });
        this.adSkipRef.current.removeEventListener("up", () => { });
        this.adSkipRef.current.removeEventListener("down", () => { });
    }

    render() {
        const { src } = this.props;

        return (
            <div className='ad-player'>
                <ShakaPlayer
                   id={'adPlayer'}
                    src={src}
                    ref={this.adPlayerRef}
                    onEvent={(eventName, event) => {
                        this.playerEvent(eventName, event)
                    }}
                />
                <div className='downtime-box'>
                    {this.props.t("player.ad")}
                    <span> {this.state.downTime}</span>
                </div>
                <div className='ad-skip_btn' style={{display: this.state.isShowSkipButton ? 'flex' : 'none'}} ref={this.adSkipRef} focusable>
                    <div className='skip-icon'>
                        <img src={require('../../assets/images/icon/npvr_play.png').default} />
                    </div>
                    <div className='skip-text'>{this.props.t("button.skip")}</div>
                </div>
            </div>
        )
    }
}

// 定义 propTypes 进行参数类型检查
AdPlayer.propTypes = {
    src: PropTypes.string.isRequired,              // 必需的字符串类型，视频资源的 URL
    showSkipDuration: PropTypes.number.isRequired, // 显示跳过按钮的时间
    isAllowSkip: PropTypes.number || PropTypes.string, // 是否支持跳过
    licenseServerUrl: PropTypes.string,            // 可选的字符串类型，DRM 许可证服务器的 URL
    customHeaders: PropTypes.object,               // 可选的对象类型，自定义请求头
    onEvent: PropTypes.func                        // 可选的函数类型，事件回调函数
};

export default withTranslation()(AdPlayer);
