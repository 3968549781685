import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { getStorage, getUUID, isObject, keycodeHandle, formatDate, formatDecimals, decodeBase64, getElementHeightWithMargin, getQueryObj } from '../../util/util';
import './style/Payment.scss';
import $request from '../../api';
import GLOBAL from '../../util/global';
import Popup from '../../components/popup/popup';

export default withTranslation()(class Payment extends Component {
    constructor(props) {
        super(props);
        this.payGatewayWrapperRef = React.createRef(); // 网关盒子节点
        this.payGatewaysItems = []; // 网关节点列表
        this.paymentInterval = null; // 定时器
        this.paymentResultInterval = null; // 父母结果查询定时器
        this.state = {
            productId: this.props.match.params.productId, // 产品ID
            productDetail: null,  // 产品包详情
            payGateways: [], // 支付网关
            currentSelectGateway: null,
            pathUrlQuery: null, // 路径参数
            orderInfo: null, // 订单信息
            paymentTips: this.props.t("toast.pressPaymentTips"),
            countDownTime: "",
            isShowPaymentSuccessful: false, // 是否显示支付成功
        }
    }

    componentWillMount() {
        let pathUrlQuery = getQueryObj(window.location.href);
        if (pathUrlQuery.contentId) {
            this.setState({
                pathUrlQuery
            })
        }
        this.getProductDetail();
    }

    componentDidMount() {
        keycodeHandle({
            exit: () => { window.history.back(-1) },
            quit: () => { window.history.back(-1) },
        })
    }

    componentWillUnmount() {
        this.handleRemoveListenerGateway();
        if (this.paymentInterval) {
            clearInterval(this.paymentInterval);
            this.paymentInterval = null;
        }
        if (this.paymentResultInterval) {
            clearInterval(this.paymentResultInterval);
            this.paymentResultInterval = null;
        }
    }

    /**
     * 获取产品包详情
     */
    getProductDetail() {
        $request.boApi.product.getProductDetail({
            mac: getUUID(),
            productId: this.state.productId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.productInfo) {
                    this.setState({
                        productDetail: res.data.productInfo,
                        payGateways: res.data.productInfo.payGateways || []
                    }, () => {
                        if (this.payGatewaysItems.length) {
                            $tv.requestFocus(this.payGatewaysItems[0]);
                            this.handleListenerGateway();
                        }
                    })
                }
            }
        })
    }

    /**
     * 网关事件监听
     */
    handleListenerGateway() {
        if (this.payGatewaysItems.length) {
            this.payGatewaysItems.forEach((el, index) => {
                el.addEventListener("click", () => {
                    this.setState({
                        currentSelectGateway: this.state.payGateways[index]
                    }, () => {
                        this.handlePaymentOrderCheckout();
                    })
                })
                el.addEventListener("left", () => {

                })
                el.addEventListener("right", () => {

                })
                el.addEventListener("down", () => {

                })
            })
        }
    }

    /**
     * 移除网关事件监听
     */
    handleRemoveListenerGateway() {
        if (this.payGatewaysItems.length) {
            this.payGatewaysItems.forEach((el, index) => {
                el.removeEventListener("click", () => {

                })
                el.removeEventListener("left", () => {

                })
                el.removeEventListener("right", () => {

                })
                el.removeEventListener("down", () => {

                })
            })
        }
    }

    handlePaymentOrderCheckout() {
        let param = {
            packageId: this.state.productDetail.productId,
            payType: this.state.currentSelectGateway.gatewayCode
        }
        if (this.state.pathUrlQuery) {
            if (this.state.pathUrlQuery.contentId && this.state.pathUrlQuery.purchaseType && this.state.pathUrlQuery.purchaseType == 2) {
                param.assetId = this.state.pathUrlQuery.contentId;
                param.contentType = this.state.pathUrlQuery.type;
            }
        }
        $request.boApi.payment.paymentOrderCheckout(param).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.data) {
                    let orderInfo = res.data.data;
                    orderInfo.payUrl = decodeBase64(orderInfo.payUrl);
                    // console.log()
                    this.setState({
                        orderInfo,
                        paymentTips: this.props.t("toast.pressPaymentTips")
                    }, () => {
                        let date = Number(new Date().getTime() + (60000 * 10));
                        this.countDown(date);
                        this.getPaymentOrderCheck();
                        this.paymentResultInterval = setInterval(() => {
                            this.getPaymentOrderCheck();
                        }, 3000)
                    })
                }
            }
        })
    }

    countDown(date) {
        if (this.showTime(date)) {
            this.paymentInterval = setInterval(() => {
                if (this.showTime(date)) {
                    this.setState({
                        countDownTime: this.showTime(date)
                    })
                } else {
                    this.setState({
                        countDownTime: '',
                        paymentTips: this.props.t("toast.confirmPaymentAgainTips")
                    }, () => {
                        clearInterval(this.paymentInterval);
                        this.paymentInterval = null;
                        if (this.paymentResultInterval) {
                            clearInterval(this.paymentResultInterval);
                            this.paymentResultInterval = null;
                        }
                    })
                }
            }, 1000)
        } else {
            if (this.paymentInterval) {
                clearInterval(this.paymentInterval);
                this.paymentInterval = null;
            }
            if (this.paymentResultInterval) {
                clearInterval(this.paymentResultInterval);
                this.paymentResultInterval = null;
            }
        }
    }

    showTime(date) {
        let nowTime = Number(new Date().getTime()); //当前时间总的毫秒数
        let inputTime = date //用户输入时间总的毫秒数
        let times = (inputTime - nowTime) / 1000; //剩余时间的总的秒数
        let d = Math.floor(times / 60 / 60 / 24); //获取天数
        let D = d
        d = d < 10 ? "0" + d : d;
        let h = Math.floor((times / 60 / 60) % 24); //获取小时数
        let H = h
        h = h < 10 ? "0" + h : h;
        let m = Math.floor((times / 60) % 60); //获取分钟数
        let M = m
        m = m < 10 ? "0" + m : m;
        let s = Math.floor(times % 60); //获取秒数
        let S = s
        s = s < 10 ? "0" + s : s;
        if (D <= 0 && H <= 0 && M <= 0 && S <= 0) {
            return "";
        }
        return m + ":" + s;
    }

    /**
     * 获取订单结果
     */
    getPaymentOrderCheck() {
        $request.boApi.payment.paymentOrderCheck({
            orderId: this.state.orderInfo.orderId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    isShowPaymentSuccessful: true
                })
            }
            
        })
    }

    handleConfirmPaymentSuccess() {
        if (this.state.pathUrlQuery) {
            if (getStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD)) {
                let jumpPath = getStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD);
                window.location.replace(jumpPath);
            }
        } else {
            this.props.history.replace("/package");
        }
    }

    render() {
        const { payGateways, currentSelectGateway, productDetail, orderInfo, paymentTips, countDownTime, isShowPaymentSuccessful } = this.state;
        return (<div className='payment-page'>
            <div className='page-title'>
                <div className='title'>{this.props.t("purchase.paymentOption")}</div>
                <div className='page-title_right'>
                    <div className='icon'>
                        <img src={require('../../assets/images/icon/payment_icon_secure.png').default} />
                    </div>
                    <div>
                        <div className='secure'>{this.props.t("purchase.secure")}</div>
                        <div className='payment-text'>{this.props.t("purchase.payment")}</div>
                    </div>
                </div>
            </div>
            <div className='pay-gatways'>
                <div className='pay-gatways_wrapper' ref={this.payGatewayWrapperRef}>
                    {
                        payGateways.map((item, index) => (
                            <div className='pay-gatway_item' focusable ref={(el) => { this.payGatewaysItems[index] = el }} key={index}>
                                <img src={item.icon} />
                                <div className='checked' style={{ display: currentSelectGateway && currentSelectGateway.gatewayCode == item.gatewayCode ? 'block' : 'none' }}>
                                    <img src={require("../../assets/images/icon/multi_profile_check.png").default} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                currentSelectGateway ? (<div className='order-info'>
                    <div className='order-info_left'>
                        <div className='order-info_title'>{this.props.t("purchase.orderInfomation")}</div>
                        <div className='order-info_item'>
                            <div className='label'>{this.props.t("purchase.packageName")}</div>
                            <div className='name'>{productDetail.productName}</div>
                        </div>
                        <div className='order-info_item'>
                            <div className='label'>{this.props.t("purchase.total")}</div>
                            <div className='name'>{currentSelectGateway.currency} {currentSelectGateway.price ? formatDecimals(currentSelectGateway.price) : 0}</div>
                        </div>
                        <div className='order-info_item'>
                            <div className='label'>{this.props.t("purchase.orderStatus")}</div>
                            <div className='name waiting'>{this.props.t("purchase.orderStatusWaiting")}</div>
                        </div>
                        <div className='order-info_item'>
                            <div className='label'>{this.props.t("purchase.orderNumber")}</div>
                            <div className='name'>{orderInfo ? orderInfo.orderId : ""}</div>
                        </div>
                    </div>
                    <div className='order-info_right'>
                        <div className='qr-box'>
                            <div className='qr-wrapper'>
                                {
                                    orderInfo ? (<QRCode className="qr-code_img" size={400} value={orderInfo.payUrl} />) : null
                                }

                            </div>
                        </div>
                        <div className='downtime'>
                            <div className='icon'>
                                <img src={require('../../assets/images/icon/pre_icon_refresh.png').default} />
                            </div>
                            <div>{countDownTime}</div>
                        </div>
                    </div>
                </div>) : null
            }

            <div className='tips'>{paymentTips}</div>
            {
                productDetail ? (<Popup  show={isShowPaymentSuccessful} title={productDetail.productName} text={this.props.t("toast.paymentSuccess") } buttons={[
                    {
                        type: 'ok',
                        text: this.props.t("button.confirm")
                    }
                ]} click={ (data) => { this.handleConfirmPaymentSuccess(data) }}  />) : null
            }
            
        </div>)
    }
})