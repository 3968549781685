import boApi from "../api/boApi";
import adApi from "../api/adApi";
import collectionApi from "../api/collectionApi";

const request = {
    boApi,
    adApi,
    collectionApi
}

export default request;