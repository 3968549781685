import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getSystemTime, getStorage, getElementWidthWithMargin, deepClone, throttle } from "../../../../util/util";
import GLOBAL from "../../../../util/global"
import $request from '../../../../api';
import './style/Header.scss';
let focusedFlag = true;
export default withTranslation()(class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.sysTimeEl = React.createRef();
        this.searchRef = React.createRef();
        this.settingRef = React.createRef();
        this.menuWrapperRef = React.createRef();
        this.notificationNumRef = React.createRef();
        this.menuListRef = [];
        this.state = {
            logo: require('../../../../assets/images/logo.png'),
            arrowUp: require('../../../../assets/images/icon/home_menu_icon_up.png'),
            menuList: [],
            currentMenu: null
        };
        this.lastFocusTime = 0;  // To track last focus event
        // 使用 throttle 对 navigationFocus 进行限制
        this.throttledNavigationFocus = throttle(this.navigationFocus.bind(this), 300);
    }
    componentDidMount() {
        // console.log($request)
        this.sysTimeEl.current.innerText = getSystemTime();
        this.getMenuList();
        this.listenerSearchBtn();
        this.listenerSettingBtn();
        this.handleListenerNotificationNumBtn();
        $tv.requestFocus(this.searchRef.current);
        // console.log(22222)
        // el.addEventListener('onFocus', this.navigationFocus.bind(this));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        if (nextProps.showNavbar) {
            // console.log(22222)
            if (!getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
                if (document.getElementById("startupContainer")) {
                    return
                }
                if (this.state.currentMenu) {
                    $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
                } else {
                    if (this.state.menuList && this.state.menuList.length) {
                        $tv.requestFocus(document.getElementById(`menuItem${this.state.menuList[0].menuId}`));
                    }

                }
            }

        }
    }

    getMenuList() {
        $request.boApi.menu.getMenuList({
            isRequiredChildNode: true,
            language: getStorage(GLOBAL.STORAGE_KEYS.LANG),
            mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
            menuId: 0
        }).then(res => {
            // console.log(res)
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.setState({
                    menuList: res.data.menus.filter(item => {
                        return item.type != GLOBAL.MEDIA_TYPE.APPSTORE && item.type != GLOBAL.MEDIA_TYPE.YOUTUBE
                    })
                }, () => {
                    if (this.state.menuList && this.state.menuList.length) {

                        if (this.props.change) {
                            if (getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU)) {
                                // 查询本地存储的菜单是否存在，如果存在，就切换到对应菜单
                                let hasMenu = false;
                                this.state.menuList.forEach(item => {
                                    if (item.menuId == getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU).menuId) {
                                        hasMenu = true;
                                        $tv.requestFocus(document.getElementById(`menuItem${getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU).menuId}`));
                                        this.props.change(getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU));
                                        this.menuWrapperRef.current.style.left = getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU).x + 'px';
                                    }
                                })
                                if (hasMenu == false) {
                                    this.props.change(this.state.menuList[0]);
                                    $tv.requestFocus(document.getElementById(`menuItem${this.state.menuList[0].menuId}`));
                                }
                            } else {
                                this.props.change(this.state.menuList[0]);
                                $tv.requestFocus(document.getElementById(`menuItem${this.state.menuList[0].menuId}`));
                            }

                        }
                        // this.removeListerMenu();
                        this.listenerMenu();
                    }
                })


            }
        })
    }

    listenerMenu() {
        //  this.removeListerMenu();
        const menus = document.querySelectorAll('.menu-item');
        menus.forEach((el, index) => {
            el.addEventListener('onFocus', this.navigationFocus.bind(this, index));
            // el.addEventListener('onBlur', this.navigationBlur.bind(this));
            el.addEventListener('right', this.navigationRight.bind(this, index));
            el.addEventListener('left', this.navigationLeft.bind(this, index));
            // el.addEventListener('up', this.navigationUp.bind(this));
            el.addEventListener('down', this.navigationDown.bind(this));
            el.addEventListener('click', this.navigationClick.bind(this, index));
            el.addEventListener('mouseenter', this.navigationMouseEnter.bind(this, index));
            // el.addEventListener('mouseleave', this.navigationMouseleave.bind(this));
        })
    }

    navigationRight(index) {
        const now = Date.now();
        if (now - this.lastFocusTime < 500) {
            $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
            return;  // Ignore if the focus change is too rapid
        }
        if (this.props.isRequest) {
            $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
            return
        }
        this.lastFocusTime = now;
        const menus = document.querySelectorAll('.menu-item');
        let scorllEl = this.menuWrapperRef.current;
        let x = 0;
        menus.forEach((item, elIndex) => {
            if (elIndex <= index && elIndex >= 5) {
                x += getElementWidthWithMargin(menus[elIndex]);
            }
        })
        if (index >= 2 && index < menus.length - 1) {
            scorllEl.style.left = -x + 'px'
        } else {
            if (index == menus.length - 1) {
                $tv.requestFocus(menus[index]);
            }
        }
    }

    navigationLeft(index) {
        const now = Date.now();
        if (now - this.lastFocusTime < 500) {
            $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
            return;  // Ignore if the focus change is too rapid
        }
        if (this.props.isRequest) {
            $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
            return
        }
        this.lastFocusTime = now;
        const menus = document.querySelectorAll('.menu-item');
        let scorllEl = this.menuWrapperRef.current;
        let elWidth = getElementWidthWithMargin(menus[index]);
        if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
            scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
        } else {
            scorllEl.style.left = "0px"
        }
    }

    removeListerMenu() {
        const menus = document.querySelectorAll('.menu-item');
        if (!menus || !menus.length) {
            return
        }
        menus.forEach((el, index) => {
            el.removeEventListener('onFocus', this.navigationFocus.bind(this, index));
            // el.removeEventListener('onBlur', this.navigationBlur.bind(this));
            el.removeEventListener('right', this.navigationRight.bind(this));
            el.removeEventListener('left', this.navigationLeft.bind(this));
            // el.removeEventListener('up', this.navigationUp.bind(this));
            el.removeEventListener('down', this.navigationDown.bind(this));
            el.removeEventListener('click', this.navigationClick.bind(this, index));
            el.removeEventListener('mouseenter', this.navigationMouseEnter.bind(this, index));
            // el.removeEventListener('mouseleave', this.navigationMouseleave.bind(this));
        })
    }

    listenerSearchBtn() {
        this.searchRef.current.addEventListener('click', this.handleSearch.bind(this))
        this.searchRef.current.addEventListener('down', this.headerBtnPressDown.bind(this))
        this.searchRef.current.addEventListener('left', () => {
            $tv.requestFocus(this.searchRef.current);
        })
    }

    handleSearch() {
        if (this.props.searchClick) {
            this.props.searchClick();
        }
        // this.props.history.push(`/search`)
    }

    listenerSettingBtn() {
        this.settingRef.current.addEventListener('click', () => {
            if (this.props.settingClick) {
                this.props.settingClick();
            }
        })
        this.settingRef.current.addEventListener('down', this.headerBtnPressDown.bind(this))
        this.settingRef.current.addEventListener('right', () => {
            $tv.requestFocus(this.settingRef.current);
        })
    }

    handleListenerNotificationNumBtn() {
        this.notificationNumRef.current.addEventListener('click', () => {
            if (this.props.notificatinNumberClick) {
                this.props.notificatinNumberClick();
            }
        })
    }

    headerBtnPressDown() {
        if (this.props.pressDown) {
            this.props.pressDown();
        }
    }

    /**
     * 监听点击
     * @param {*} index 
     */
    navigationClick(index) {
        if (this.props.click) {
            this.setState({
                currentMenu: this.state.menuList[index]
            }, () => {
                let x = this.menuWrapperRef.current.offsetLeft;
                let currentMenu = deepClone(this.state.currentMenu);
                currentMenu.x = x;
                if (this.props.change) {
                    this.props.change(currentMenu);
                }
            })
        }

    }

    /**
     * 监听聚焦
     */
    navigationFocus(index) {
        // console.log('navigationFocus')

        if (this.props.change) {
            this.setState({
                currentMenu: this.state.menuList[index]
            }, () => {
                let x = this.menuWrapperRef.current.offsetLeft;
                let currentMenu = deepClone(this.state.currentMenu);
                currentMenu.x = x;
                // if (!focusedFlag) {
                //     return false;
                // }
                // focusedFlag = false;
                // setTimeout(() => {
                if (this.props.change) {
                    this.props.change(currentMenu);
                    focusedFlag = true;
                }
                // }, 300);

            })
        }
    }

    /**
     * 监听鼠标hover
     */
    navigationMouseEnter(index) {
        // console.log(this.state.menuList[index].menuId)
        $tv.requestFocus(document.getElementById(`menuItem${this.state.menuList[index].menuId}`));
        if (this.props.change) {
            this.setState({
                currentMenu: this.state.menuList[index]
            }, () => {

                this.props.change(this.state.currentMenu);
            })
        }

    }

    /**
     * 监听遥控下键
     */
    navigationDown() {
        if (this.props.pressDown) {
            this.props.pressDown();
        }

    }

    // updateHeaderStatus() {

    // }

    render() {
        const { showNavbar, name, notificationNum } = this.props;
        const { menuList } = this.state;
        // console.log(showNavbar)
        return (<div className='header'>
            <div className='nav-header' style={{ "display": showNavbar ? "flex" : "none" }}>
                <div className='header-left'>
                    <div className='search-icon' ref={this.searchRef} id="headerSearchIcon" focusable>
                        <img src={require('../../../../assets/images/icon/atv_search_icon_search_40x40.png').default} alt="search" />
                    </div>
                    <div className='menu-list'>
                        <div className='menu-list_wrapper' ref={this.menuWrapperRef}>
                            {
                                menuList.map((item, index) => (
                                    <div className='menu-item' id={`menuItem${item.menuId}`} index={index} key={item.menuId} focusable>{item.menuName}</div>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <div className='header-right'>
                    <div className='notification-number' id="headerNotificationIcon" style={{display: notificationNum ? 'flex' : 'none'}} ref={this.notificationNumRef} focusable>
                        <div className='notification-number_wrapper'>{notificationNum >= 9 ? 9 : notificationNum}</div>
                    </div>
                    <div className='setting-icon' id="headerSettingIcon" ref={this.settingRef} focusable>
                        <img src={require('../../../../assets/images/icon/head_icon_setting_focus.png').default} alt="search" />
                    </div>
                </div>
            </div>
            <div className='logo-header' style={{ "display": showNavbar ? "none" : "flex" }}>
                <div className='logo'>
                    <img src={this.state.logo.default} alt="logo" />
                </div>
                <div className='menu-name_box'>
                    <div className='arrow-up'>
                        <img src={this.state.arrowUp.default} alt="arrow-up" />
                    </div>
                    <div className='menu-name'>{name}</div>
                </div>
                <div className='time' ref={this.sysTimeEl}></div>
            </div>
        </div>)
    }
})