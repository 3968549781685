import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, setStorage, removeStorage, keycodeHandle } from "../../util/util";
import ColumnItem from '../../components/columnItem/ColumnItem';
import GLOBAL from "../../util/global"
import $request from '../../api';
import Empty from '../../components/empty/Empty';
import './style/WatchHistory.scss';

export default withTranslation()(class NewWatchHistory extends Component {

  constructor(props) {
    super(props);
    this.tabList = React.createRef();
    this.operateDelete = React.createRef();
    this.allDelete = React.createRef();
    this.singleDelete = React.createRef();
    this.state = {
      playHistory: require("../../assets/images/icon/atv_me_icon_playhistory.png"),
      imgSelectblueIcon: require("../../assets/images/icon/atv_setting_icon_select_blue.png"),
      imgSelectIcon: require("../../assets/images/icon/atv_setting_icon_select.png"),
      dataList: [],
      contentIdList: [],
      currentTab: 0,
      isDelete: false,
      isSelectAll: false,
      falg: true,
      currentType: GLOBAL.MEDIA_TYPE.VOD,
      itemStyle: GLOBAL.RECOMMEND_STYLE.HORIZONTAL,
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    if (getStorage("currentType")) {
      this.setState({
        currentType: getStorage("currentType")
      }, () => {
        this.getPlayHistory();
        removeStorage("currentType");
      });
    } else {
      this.getPlayHistory();
    }
    if (getStorage("currentTab")) {
      this.setState({
        currentTab: getStorage("currentTab") - 0,
      });
      removeStorage("currentTab");
    }
    this.getTabTtemEls();
    keycodeHandle({ exit: this.seriesKeyDown, quit: this.seriesKeyDown });
    this.operateDelete.current.addEventListener('left', this.operateDeleteLeft.bind(this));
    this.operateDelete.current.addEventListener('down', this.operateDeleteDown.bind(this));
    this.operateDelete.current.addEventListener('click', this.operateDeleteClick.bind(this));
    this.operateDelete.current.addEventListener("mouseenter", this.operateDeleteMouseEnter.bind(this));

  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    clearTimeout(this.pageWheelTime);
    $tv.distanceToCenter = true;
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  seriesKeyDown = () => {
    removeStorage("watchHistoryFocalElements");
    if (this.state.isDelete) {
      this.setState({
        isDelete: false,
      });
      $tv.requestFocus(this.tabList.current.childNodes[0]);
    } else {
      window.history.back();
    }
  }
  // 删除方法添加
  getDeleteEls() {
    this.singleDelete.current.addEventListener('down', this.singleDeleteDown.bind(this));
    this.singleDelete.current.addEventListener('click', this.singleDeleteClick.bind(this));
    this.singleDelete.current.addEventListener("mouseenter", this.singleDeleteMouseEnter.bind(this));
    this.allDelete.current.addEventListener('down', this.allDeleteDown.bind(this));
    this.allDelete.current.addEventListener('left', this.allDeleteLeft.bind(this));
    this.allDelete.current.addEventListener('click', this.allDeleteClick.bind(this));
    this.allDelete.current.addEventListener("mouseenter", this.allDeleteMouseEnter.bind(this));
    this.setState({
      falg: false,
    })
  }
  // 标签方法添加
  getTabTtemEls() {
    // 标签按钮
    this.tabList.current.childNodes.forEach((item, index) => {
      item.addEventListener('onFocus', this.tabTtemFocus.bind(this, index));
      item.addEventListener('up', this.tabTtemUp.bind(this));
      item.addEventListener("mouseenter", this.tabTtemMouseEnter.bind(this, index));
    });
    if (!getStorage("watchHistoryFocalElements") || getStorage("watchHistoryFocalElements") == undefined || getStorage("watchHistoryFocalElements") == 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0); // 滚动条返回顶部
        
        $tv.requestFocus(this.tabList.current.childNodes[0]);
      }, 100);
    }
  }
  //播放历史记录
  getPlayHistory() {
    $request.boApi.user.getPlayList({
      type: this.state.currentType,
      page: 0,
      pageSize: 500
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let dataList = res.data.contents;
        if (dataList && dataList.length) {
          dataList.forEach((item) => {
            item.isSelect = false;
          })
          this.setState({
            dataList: dataList,
          });
        } else {
          this.setState({
            dataList: [],
          });
        }
      }
      this.focusMemory();
    })
  };

  // 焦点记忆
  focusMemory() {
    if (getStorage("watchHistoryFocalElements") && document.getElementById(getStorage("watchHistoryFocalElements"))) {
      $tv.requestFocus(document.getElementById(getStorage("watchHistoryFocalElements")));
      removeStorage("watchHistoryFocalElements");
    }
  }
  tabTtemFocus(params) {
    if (this.state.currentTab !== params) {
      this.setState({
        currentTab: params,
      });
      let currentType = ""
      if (params === 0) {
        currentType = GLOBAL.MEDIA_TYPE.VOD;
      } else if (params === 1) {
        currentType = GLOBAL.MEDIA_TYPE.LIVE_EVENT;
      } else if (params === 2) {
        currentType = GLOBAL.MEDIA_TYPE.LIVE;
      }
      this.setState({
        currentType: currentType,
        dataList: []
      }, () => {
        this.getPlayHistory();
      });
    }
  }
  tabTtemUp() {
    if (!this.state.isDelete) {
      $tv.requestFocus(this.operateDelete.current);
    } else {
      $tv.requestFocus(this.allDelete.current);
    }
  }
  tabTtemMouseEnter(params) {
    $tv.requestFocus(this.tabList.current.childNodes[params]);
  }
  // 选择删除
  operateDeleteLeft() {
    $tv.requestFocus(this.operateDelete.current);
  }
  operateDeleteClick() {
    this.setState({
      isDelete: true,
    }, () => {
      if (this.state.falg) {
        this.getDeleteEls();
      }
      $tv.requestFocus(this.allDelete.current);
    });
  }
  operateDeleteDown() {
    $tv.requestFocus(this.tabList.current.childNodes[this.state.currentTab]);
  }
  operateDeleteMouseEnter() {
    $tv.requestFocus(this.operateDelete.current);
  }
  // 全选择删除
  allDeleteClick() {
    this.setState({
      isSelectAll: !this.state.isSelectAll,
    });
    let dataList = this.state.dataList;
    let contentIdList = [];
    dataList.forEach((item) => {
      if (this.state.isSelectAll) {
        if (this.state.currentTab == 0) {
          contentIdList.push(item.contentId);
        }
        if (this.state.currentTab == 1) {
          contentIdList.push(item.assetId);
        }
        item.isSelect = true;
      } else {
        contentIdList = [];
        item.isSelect = false;
      }
    })
    this.setState({
      contentIdList: contentIdList,
      dataList: dataList,
    });
  }
  allDeleteDown() {
    if (!this.state.isDelete) {
      $tv.requestFocus(this.tabList.current.childNodes[this.state.currentTab]);
    }
  }
  allDeleteLeft() {
    $tv.requestFocus(this.allDelete.current);
  }
  allDeleteMouseEnter() {
    $tv.requestFocus(this.allDelete.current);
  }

  // 确定删除
  singleDeleteClick() {
    if (this.state.contentIdList.length) {
      this.clickDelete()
    } else {
      this.setState({
        isDelete: false,
      }, () => {
        $tv.requestFocus(this.operateDelete.current);
      });
    }
  }
  singleDeleteDown() {
    if (!this.state.isDelete) {
      $tv.requestFocus(this.tabList.current.childNodes[this.state.currentTab]);
    }
  }
  singleDeleteMouseEnter() {
    $tv.requestFocus(this.singleDelete.current);
  }

  //删除提交
  clickDelete() {
    $request.boApi.user.deleteWatchHistory({
      assetId: this.state.contentIdList.join(','),
      type: this.state.currentType
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        this.setState({
          isDelete: false,
        }, () => {
          this.getPlayHistory();
          $tv.requestFocus(this.tabList.current.childNodes[this.state.currentTab]);
          // $tv.requestFocus(this.tabList.current.childNodes[0]);
        });
      }
    })
  }
  columnItemLeft(propData) {
    let index = propData.index;
    if (index % 4 === 0) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("levelShake");
        setTimeout(() => {
          document.getElementById(propData.id).classList.remove("levelShake");
        }, 1000)
      }
    }
  }

  columnItemRight(propData) {
    let index = propData.index;
    let condition = (index + 1) % 4 === 0;;
    if (condition || index === (this.state.dataList.length - 1)) {
      if (document.getElementById(propData.id)) {
        $tv.requestFocus(document.getElementById(propData.id));
        document.getElementById(propData.id).classList.add("levelShake");
        setTimeout(() => {
          document.getElementById(propData.id).classList.remove("levelShake");
        }, 1000)
      }
    }
  }
  columnItemUp(propData) {
    let index = propData.index;
    if (!this.state.isDelete) {
      if (index < 4) {
        $tv.requestFocus(this.tabList.current.childNodes[this.state.currentTab]);
      }
    } else {
      if (index < 4) {
        $tv.requestFocus(this.allDelete.current);
      }
      
    }
    if (index < 8) {
      window.scrollTo(0, 0); // 滚动条返回顶部
    }
  }
  columnItemClick(propData) {
    let index = propData.index;
    if (this.state.isDelete) {
      const currentDataList = this.state.dataList;
      let contentIdList = this.state.contentIdList;
      console.log(this.state.currentTab)
      if (currentDataList[index].isSelect === true) {
        if (this.state.currentTab == 0) {
          contentIdList = contentIdList.filter(item => item !== currentDataList[index].contentId);
        }
        if (this.state.currentTab == 1) {
          contentIdList = contentIdList.filter(item => item !== currentDataList[index].assetId);
        }
        currentDataList[index].isSelect = false;
        this.setState({
          dataList: currentDataList,
          contentIdList: contentIdList,
        });
      } else {
        if (this.state.currentTab == 0) {
          contentIdList.push(currentDataList[index].contentId);
        }
        if (this.state.currentTab == 1) {
          contentIdList.push(currentDataList[index].assetId);
        }
        currentDataList[index].isSelect = true;
        this.setState({
          dataList: currentDataList,
          contentIdList: contentIdList,
        });
      }
    } else {
      if (propData) {
        setStorage("currentType", this.state.currentType);
        setStorage("currentTab", this.state.currentTab);
        switch (this.state.currentType) {
          case GLOBAL.MEDIA_TYPE.VOD:
            setStorage("watchHistoryFocalElements", propData.data.contentId);
            this.props.history.push(`/vod/${propData.data.contentId}`);
            break;
          case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
            setStorage("watchHistoryFocalElements", propData.data.assetId);
            this.props.history.push(`/liveEventDetail/${propData.data.assetId}`);
            break;
          default:
            break;
        }
      }
    }

  }

  renderRecommend(data, index, style) {
    return <ColumnItem itemStyle={style} showTitle={"Y"} name={data.title ? data.title : data.name} key={index} data={data} index={index} id={data.contentId || data.assetId} scorllLeft={(propData) => {
      this.columnItemLeft(propData)
    }} scorllRight={(propData) => {
      this.columnItemRight(propData);
    }} scorllUp={(propData) => { this.columnItemUp(propData) }} click={(propData) => {
      this.columnItemClick(propData)
    }} />
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }
  render() {
    const { dataList, itemStyle, imgSelectblueIcon, imgSelectIcon } = this.state;
    return (
      <div className="history_contenter_page" onWheel={e => this.pageWheel(e)}>
        <div className="history_title_Box">
          <div className="history_title_box_left">
            <img src={this.state.playHistory.default} alt="" />
            <span className="f_title">{this.props.t('user.watchHistory')}</span>
          </div>

          <div className="del_box" >
            <span className="del_text" ref={this.allDelete} style={{ display: this.state.isDelete ? 'block' : 'none' }} focusable >{this.props.t('user.all')}</span>
            <div className="del_text" ref={this.singleDelete} style={{ display: this.state.isDelete ? 'block' : 'none' }} focusable >{this.props.t('user.Delete')}</div>
          </div>
          <span className="del_text" ref={this.operateDelete} style={{ display: !this.state.isDelete && dataList.length ? 'block' : 'none' }} focusable>{this.props.t('user.Delete')}</span>
        </div>
        <div className="tab-list" style={{ display: !this.state.isDelete ? 'flex' : 'none' }} ref={this.tabList}>
          <div className={`tab-item ${this.state.currentTab === 0 ? 'current' : ''}`} focusable>VOD</div>
          <div className={`tab-item ${this.state.currentTab === 1 ? 'current' : ''}`} focusable>Live Events</div>
          {/* <div className={`tab-item ${this.state.currentTab===2 ? 'current' : ''}`} focusable>Live</div> */}
        </div>
        <div className="favorite_wrap">
          <div className="f_list_wrap">
            <div className="f_list-container">
              {
                dataList.map((item, index) => (
                  <div className='history_list_item' key={index}>
                    <img className='img_select_icon' style={{ display: this.state.isDelete ? 'block' : 'none' }} src={item.isSelect ? imgSelectblueIcon.default : imgSelectIcon.default} alt="" />
                    {this.renderRecommend(item, index, itemStyle)}
                  </div>
                ))
              }
            </div>
            <Empty show={dataList.length == 0} />
          </div>
        </div>
      </div>
    );
  }
});
