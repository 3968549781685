import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './style/SettingPopup.scss';
import GLOBAL from '../../../../util/global';
import projectConfig from '../../../../util/config'
import { getStorage, getElementHeightWithMargin } from '../../../../util/util'

class SettingPopup extends Component {
    constructor(props) {
        super(props);
        this.buttonsRef = [];
        this.scrollWrapperRef = React.createRef();
        this.state = {
            allSkip: this.props.t("setting.off"),
            language: ""
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.handleButtonListenr();
        this.setState({
            language: getStorage(GLOBAL.STORAGE_KEYS.LANG)
        })
    }

    componentWillUnmount() {
        this.handleRemoveButtonListenr();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.show) {
            let settingList = document.querySelectorAll(".setting-item");
            if (nextProps.value) {
                settingList.forEach(item => {
                    if (item.getAttribute("type") == nextProps.value) {
                        $tv.requestFocus(item);
                    }
                })
            } else {
                if (settingList.length) {
                    $tv.requestFocus(settingList[0]);
                }
            }
            if (getStorage(GLOBAL.STORAGE_KEYS.LANG)) {
                this.setState({
                    language: getStorage(GLOBAL.STORAGE_KEYS.LANG)
                })
            }
            if (Number(getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP))) {
                this.setState({
                    allSkip: this.props.t("setting.on")
                })
            } else {
                this.setState({
                    allSkip: this.props.t("setting.off")
                })
            }

        }

    }

    handleButtonListenr() {
        let settingList = document.querySelectorAll(".setting-item");
        let scorllEl = this.scrollWrapperRef.current;
        settingList.forEach((el, index) => {
            el.addEventListener("click", () => {
                if (this.props.click) {
                    this.props.click(el.getAttribute("type"));
                }
            });
            el.addEventListener("left", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("right", () => {
                $tv.requestFocus(el);
            })
            el.addEventListener("up", () => {
                if (index == 0) {
                    $tv.requestFocus(el);
                    scorllEl.style.top = 0;
                } else {
                    let elHeight = getElementHeightWithMargin(el);
                    if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                        scorllEl.style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
                    }
                }
            })
            el.addEventListener("down", () => {
                if (index == settingList.length - 1) {
                    $tv.requestFocus(el);
                } else {
                    $tv.requestFocus(settingList[index + 1]);
                }
                if (index >= 3) {
                    if (el) {
                        let elHeight = getElementHeightWithMargin(el);
                        scorllEl.style.top = -(index * elHeight) + 'px';
                    }
                }
            })
        })
    }

    handleRemoveButtonListenr() {
        let settingList = document.querySelectorAll(".setting-item");
        settingList.forEach(el => {
            el.removeEventListener("click", () => { });
            el.removeEventListener("left", () => { })
            el.removeEventListener("right", () => { })
            el.removeEventListener("up", () => { })
            el.removeEventListener("down", () => { })
        })
    }


    render() {
        const { show } = this.props;
        const { language, allSkip } = this.state;
        return (
            <div className='setting-popup' style={{ display: show ? 'flex' : 'none' }}>
                <div className='popup-left'>

                </div>
                <div className='popup-right'>
                    <div className='title'>{this.props.t("setting.setting")}</div>
                    <div className='setting-box'>
                        <div className='setting-wrapper' ref={this.scrollWrapperRef}>
                            {
                                getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) ? (
                                    <div className='setting-item' id='setParentalControl' type="parentalControl" focusable>
                                        <div className='icon'>
                                            <img src={require('../../../../assets/images/icon/atv_setting_icon_lock.png').default} />
                                        </div>
                                        <div className='item-info'>
                                            <div className='item-name'>{this.props.t("setting.parentControl")}</div>
                                            <div className='item-value'>{this.props.t("setting.on")}</div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            <div className='setting-item' id='setAutoSkip' type="autoSkip" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_icon_skip.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.introAndOutro")}</div>
                                    <div className='item-value'>{allSkip}</div>
                                </div>
                            </div>
                            <div className='setting-item' id='setLanguage' type="language" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_icon_language.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.language")}</div>
                                    {/* <div className='item-value'>{language}</div> */}
                                </div>
                            </div>
                            <div className='setting-item' id='setVersion' type="version" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_icon_version.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.version")}</div>
                                    <div className='item-value'>{projectConfig.version}</div>
                                </div>
                            </div>
                            <div className='setting-item' id='setDeviceMangement' type="deviceMangement" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_icon_mac.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.deviceManagement")}</div>
                                    <div className='item-value'></div>
                                </div>
                            </div>
                            <div className='setting-item' id='setTermsOfService' type="termsOfService" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_user_terms.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.termsOfService")}</div>
                                    <div className='item-value'></div>
                                </div>
                            </div>
                            <div className='setting-item' id='setPrivacyPolicy' type="privacyPolicy" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_user_privacy.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.privacyPolicy")}</div>
                                    <div className='item-value'></div>
                                </div>
                            </div>
                            <div className='setting-item' id='setFaq' type="faq" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_user_terms.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("setting.faq")}</div>
                                    <div className='item-value'></div>
                                </div>
                            </div>
                            <div className='setting-item' id='setContactUS' type="contactUS" focusable>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_setting_user_terms.png').default} />
                                </div>
                                <div className='item-info'>
                                    <div className='item-name'>{this.props.t("connectUs.connectUs")}</div>
                                    <div className='item-value'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SettingPopup.propTypes = {
    click: PropTypes.func // 点击事件
}

export default withTranslation()(SettingPopup);