import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { $tv } from 'react-tv-focusable';
import $request from '../../api';
import './style/PinBox.scss';
import GLOBAL from "../../util/global";
import projectConfig from '../../util/config';
import { getStorage } from '../../util/util'

export default withTranslation()(class PinBox extends Component {
    constructor(props) {
        super(props);
        this.numKeyBoard = React.createRef();
        this.state = {
            isShow: false,  //是否显示弹框 
            numKey: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'clear', 0, 'del'], // 键盘数字
            iconClearImg: require("../../assets/images/icon/atv_search_icon_clear.png"),
            iconDeleteImg: require("../../assets/images/icon/atv_search_icon_delete.png"),
            psw1: "",
            psw2: "",
            psw3: "",
            psw4: "",
            focusInput: "",
            prompt: "",
            pinErrorTips: false, // 错误提示
            pinSameErrorTips: false,
            pinBoxStatus: "",// 1 验证 2 更新
            user: null
        };
    }

    componentDidMount() {
        this.selectNum();
        this.keyDownHandle();
    }
    // 监听传值
    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        const isPinBox = nextProps.isPinBox;
        const pinBoxStatus = nextProps.pinBoxStatus;
        if (isPinBox !== this.props.isPinBox) {
            if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
                this.setState({
                    user: getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)
                })
            }
            this.setState({
                pinBoxStatus: pinBoxStatus,
                isShow: isPinBox,
            });
            if (isPinBox) {
                setTimeout(() => {
                    $tv.requestFocus(this.numKeyBoard.current.childNodes[0]);
                }, 100);
            } else {
                this.setState({
                    psw1: "",
                    psw2: "",
                    psw3: "",
                    psw4: "",
                    focusInput: "",
                    pinErrorTips: false,
                    pinSameErrorTips: false,
                });
            }
        }
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    // 数字按钮绑定事件
    selectNum() {
        this.numKeyBoard.current.childNodes.forEach((item, index) => {
            item.addEventListener('click', this.numKeyBoardClick.bind(this, index));
            item.addEventListener('right', this.numKeyBoardRight.bind(this, index));
            item.addEventListener('left', this.numKeyBoardLeft.bind(this, index));
            item.addEventListener('up', this.numKeyBoardUp.bind(this, index));
            item.addEventListener('down', this.numKeyBoardDown.bind(this, index));
            item.addEventListener("mouseenter", this.numKeyBoardMouseEnter.bind(this, index));
        });
    }
    // 数字按钮点击
    numKeyBoardClick(params) {
        let psw1 = "";
        let psw2 = "";
        let psw3 = "";
        let psw4 = "";
        if (params !== 9 && params !== 11) {
            if (params === 10) {
                params = -1;
            }
            if (this.state.psw1.length < 1) {
                psw1 = params + 1;
                this.setState({
                    psw1: psw1,
                    focusInput: "psw1"
                });
            } else if (this.state.psw2.length < 1) {
                psw2 = params + 1;
                this.setState({
                    psw2: psw2,
                    focusInput: "psw2"
                });
            } else if (this.state.psw3.length < 1) {
                psw3 = params + 1;
                this.setState({
                    psw3: psw3,
                    focusInput: "psw3"
                });
            } else if (this.state.psw4.length < 1) {
                psw4 = params + 1;
                this.setState({
                    psw4: psw4,
                    focusInput: "psw4"
                }, () => {
                    let pinNum = `${this.state.psw1}${this.state.psw2}${this.state.psw3}${this.state.psw4}`
                    if (this.state.pinBoxStatus == 1) {
                        if (this.verifyPin(pinNum)) {
                            this.setState({
                                psw1: "",
                                psw2: "",
                                psw3: "",
                                psw4: "",
                                focusInput: "",
                                pinErrorTips: false,
                                pinSameErrorTips: false,
                                pinBoxStatus: "",
                            });
                            this.props.verifyPinGetMsg({ verifyPinPass: this.verifyPin(pinNum), pinBoxStatus: 1, isShow: false });
                        } else {
                            this.setState({
                                pinErrorTips: true,
                                pinSameErrorTips: false,
                            });
                        }
                    } else {
                        if (pinNum == GLOBAL.CONFIG.DEFAULT_PIN) {
                            this.setState({
                                pinErrorTips: false,
                                pinSameErrorTips: true,
                            });
                            return
                        }
                        this.updatePinNum(pinNum);
                        this.setState({
                            psw1: "",
                            psw2: "",
                            psw3: "",
                            psw4: "",
                            focusInput: "",
                            pinErrorTips: false,
                            pinSameErrorTips: false,
                            pinBoxStatus: "",
                        });
                    }
                });
            }
        } else {
            if (params === 9) {
                this.setState({
                    psw1: "",
                    psw2: "",
                    psw3: "",
                    psw4: "",
                    focusInput: "",
                    pinErrorTips: false,
                    pinSameErrorTips: false,
                });
            } else if (params === 11) {
                if (this.state.psw4 !== "") {
                    this.setState({
                        psw4: "",
                        focusInput: "psw4"
                    });
                    return
                } else if (this.state.psw3 !== "") {
                    this.setState({
                        psw3: "",
                        focusInput: "psw3"
                    });
                    return
                } else if (this.state.psw2 !== "") {
                    this.setState({
                        psw2: "",
                        focusInput: "psw2"
                    });
                    return
                } else if (this.state.psw1 !== "") {
                    this.setState({
                        psw1: "",
                        focusInput: "",
                        pinErrorTips: false,
                        pinSameErrorTips: false,
                    });
                    return
                } else {
                    this.setState({
                        focusInput: "",
                    });
                }
            }
        }

    }
    numKeyBoardMouseEnter(params) {
        $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
    }
    numKeyBoardRight(params) {
        if (params % 3 == 2) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 1]);
        }
    }
    numKeyBoardLeft(params) {
        if (params % 3 == 0) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 1]);
        }
    }
    numKeyBoardUp(params) {
        if (params < 3) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params - 3]);
        }
    }
    numKeyBoardDown(params) {
        if (params > 8) {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params]);
        } else {
            $tv.requestFocus(this.numKeyBoard.current.childNodes[params + 3]);
        }
    }
    /**
     * 监听遥控数字按键
     */
     keyDownHandle() {
        document.addEventListener('keydown', (e) => {
            if (this.props.isPinBox) {
                if (document.getElementById(`pin${GLOBAL.KEY_CODE[e.keyCode]}`)) {
                    $tv.requestFocus(document.getElementById(`pin${GLOBAL.KEY_CODE[e.keyCode]}`));
                    document.getElementById(`pin${GLOBAL.KEY_CODE[e.keyCode]}`).click();
                }
            }
        }, true)
    }
    //校验pin码
    verifyPin(pinNum) {
        var userInfo = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) ? getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) : {};
        var parent = userInfo.parentControlPin;
        // var parent = "1111";
        if (pinNum === parent) {
            return true
        } else {
            return false
        }
    };
    // 更新pin码
    updatePinNum(pinNum) {
        $request.boApi.user.updateUserInfo({
            parentControlPin: pinNum
        }).then(res => {
            if (res.data.resultCode === 0) {
                this.props.verifyPinGetMsg({ verifyPinPass: false, pinBoxStatus: 2, pinNum: pinNum, isShow: false, updateState: "success" });
            } else {
                //warning
                this.props.verifyPinGetMsg({ verifyPinPass: false, pinBoxStatus: 2, pinNum: pinNum, isShow: false, updateState: "failure" });
            }
        })
    };

    render() {
        const { showDefaultPinCodeTips } = this.props;
        return (
            <div className="pin_content_box" style={{ display: this.state.isShow ? 'flex' : 'none' }}>
                <div className="pin_content">
                    <div className="pin_left">
                        <span className="input_ttitle">{this.state.pinBoxStatus == 2 ? this.props.t("vod.enterNewPin") : this.props.t("vod.enterPin")}</span>
                        <div className="input_box">
                            <input className={`${this.state.focusInput === "psw1" ? 'input_item_focus' : ''} input_item`} type="password" value={this.state.psw1} disabled placeholder="*" />
                            <input className={`${this.state.focusInput === "psw2" ? 'input_item_focus' : ''} input_item`} type="password" value={this.state.psw2} disabled placeholder="*" />
                            <input className={`${this.state.focusInput === "psw3" ? 'input_item_focus' : ''} input_item`} type="password" value={this.state.psw3} disabled placeholder="*" />
                            <input className={`${this.state.focusInput === "psw4" ? 'input_item_focus' : ''} input_item`} type="password" value={this.state.psw4} disabled placeholder="*" />
                        </div>
                        <div style={{ display: this.state.pinErrorTips ? 'block' : 'none' }} className="errorTips">{this.props.t("toast.parentControlPinFail")}</div>
                        <div style={{ display: (this.state.pinSameErrorTips || ((this.state.user && this.state.user.parentControlPin) == GLOBAL.CONFIG.DEFAULT_PIN  && this.state.pinBoxStatus == 1) || showDefaultPinCodeTips) ? 'block' : 'none' }} className={((this.state.user && this.state.user.parentControlPin == GLOBAL.CONFIG.DEFAULT_PIN  && this.state.pinBoxStatus == 1) || showDefaultPinCodeTips) ? "errorTips init-code_tips" : "errorTips"}>{this.props.t("toast.parentControlPinSetError")}</div>
                    </div>
                    <div className='pin_div'>
                        <div className="pin_right" ref={this.numKeyBoard}>
                            {this.state.numKey.map((item) => (
                                <div key={item} id={`pin${item}`} focusable>
                                    {item <= 9 ? item : ''}
                                    <img style={{ display: item === "clear" ? 'block' : 'none' }} src={this.state.iconClearImg.default} alt="" />
                                    <img style={{ display: item === "del" ? 'block' : 'none' }} src={this.state.iconDeleteImg.default} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="hintTextPin">{this.props.t("pin.hintTextPin")}</div>
                </div>
            </div>
        )
    }
})