import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Column from '../../components/column/Column';
import BasicRecommed from './components/BasicRecommed/BasicRecommed';
import FullScreenVideo from './components/FullScreenVideo/FullScreenVideo';
import Ad from './components/Ad/Ad';
import Carousel from './components/Carousel/Carousel';
import ScrollBanner from './components/ScrollBanner/ScrollBanner'
import LiveEvent from './components/LiveEvent/LiveEvent';
// import Popup from '../../components/popup/popup';
import Popup from '../../components/popup/popup';
import SettingPopup from './components/SettingPopup/SettingPopup'
import SelectorPopup from './components/SelectorPopup/SelectorPopup';
import SendCodeOfEmail from '../../components/sendCodeOfEmail/SendCodeOfEmail';
import UpdatePin from '../../components/updatePin/UpdatePin';
import PinBox from '../../components/pinBox/PinBox';
import Me from './components/Me/Me';
import GLOBAL from '../../util/global';
import { getStorage, setStorage, deepClone, getFocusedElement, rsaDecryptContent, keycodeHandle, getUUID, removeStorage } from '../../util/util';
import projectConfig from '../../util/config';
import { deviceSign } from '../../util/commonRequest';
import $request from "../../api"
import DeviceMangement from './components/DeviceManagement/DeviceMangement';
import Startup from './components/Startup/Startup';
import './style/Home.scss';

export default withTranslation()(class Home extends Component {
  constructor(props) {
    super(props);
    this.adChildRefs = {}; // 手动管理子组件的引用
    this.fullScreenVideoRef = React.createRef();
    this.deviceMangementPopupRef = null; // 设备管理弹窗节点
    this.requestFlag = true; // 请求限制开关
    this.pageWheelTimer = null; // 鼠标监听
    this.isLoadingRecommed = false; // 是否正在加载推荐位
    this.isMountedComponent = false;
    this.state = {
      showNavbar: true, // 是否显示菜单
      currentMenu: null, // 当前菜单信息
      recommends: [], // 推荐列表
      chunkRecommends: [],
      isShowLiveEventCategoryDialog: false, // 是否显示事件直播分类弹窗
      isShowSignOut: false, // 是否显示退出登录
      isShowSetting: false, // 是否显示设置弹窗
      isShowParentalControl: false, // 是否显示父母控制设置弹窗
      userInfo: null, // 用户信息
      isShowPin: false, // 是否显示PIN码输入框
      pinBoxType: 1, // PIN弹窗类型
      isShowSendEmailCode: false, // 是否显示发送邮件弹窗
      // isShowPinTips: false, // 是否显示PIN码修改成功弹窗
      parentControlPin: "", // 父母控制PIN码
      isShowUpdatePinSuccess: false, // 是否显示父母控制修改成功提示
      isShowAutoSkipSetting: false, // 是否显示自动跳过片头片尾设置弹窗
      allowSkip: 0, // 总是跳过片头片尾
      settingSelectedValue: "", // 设置选择项
      language: getStorage(GLOBAL.STORAGE_KEYS.LANG) || projectConfig.defaultLanguage, // 语言
      isShowChangeLang: false, // 是否显示语言修改弹窗
      isShowDeviceMangement: false, // 是否显示设备管理
      isShowDeleteDeviceConfirm: false, // 是否显示确认删除弹窗
      currentSelectDevice: "", // 当前选中设备
      isShowExitApp: false, // 是否显示退出应用
      exitAppFocusedEl: false, // 退出应用弹窗前获焦的节点
      notificationNum: 0, // 消息数量
      startupImages: [], // 启动图
      showStartup: false, // 是否显示启动图
    };
  }
  componentWillMount() {
    if (!getStorage(GLOBAL.STORAGE_KEYS.SHOW_STARTUP_IMAGE)) {
      this.getStartupImage();
    }
    this.getDeviceInfo();
    this.getSystemInfo();
    this.getSysLinkInfo();
    this.getUnreadMessageCount();
    this.getMemberInfo();
    clearTimeout(this.pageWheelTimer);
  }
  componentDidMount() {
    this.isMountedComponent = true;
    if (!getStorage(GLOBAL.STORAGE_KEYS.LANG)) {
      setStorage(GLOBAL.STORAGE_KEYS.LANG, projectConfig.defaultLanguage);
    }
    if (getStorage("userInfo")) {
      this.setState({
        userInfo: getStorage("userInfo")
      })
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP)) {
      this.setState({
        allowSkip: getStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP)
      })
    }
    keycodeHandle({
      exit: this.handleExit.bind(this),
      quit: this.handleExit.bind(this)
    });
    // $tv.setOnFocusChangeListener((element, focus)=>{
    //   console.log("焦点状态改变的元素：" + element, "聚焦或失去焦点：" + focus)
    // })
  }

  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    document.getElementById("root").style.backgroundImage = `none`;
    document.getElementsByClassName("home")[0].style.background = "#000";
    this.isMountedComponent = false;
  }

  handleExit() {
    const { showStartup, showNavbar, isShowSendEmailCode, isShowSetting, isShowSignOut, isShowParentalControl, isShowPin, isShowAutoSkipSetting, isShowChangeLang, isShowDeviceMangement, isShowDeleteDeviceConfirm, isShowLiveEventCategoryDialog } = this.state;
    if (showStartup) {
      this.handleConfirmExitApp();
      return
    }
    if (isShowPin) {
      this.setState({
        isShowPin: false,
        pinBoxType: 1,
        isShowParentalControl: true
      })
      return
    }
    if (isShowSignOut) {
      this.setState({
        isShowSignOut: false
      }, () => {
        $tv.requestFocus(document.getElementById("signOut"));
      })
      return
    }
    if (isShowSendEmailCode) {
      this.setState({
        isShowSendEmailCode: false,
        isShowParentalControl: true
      })
      return
    }
    if (isShowParentalControl) {
      this.setState({
        isShowParentalControl: false,
        isShowSetting: true
      })
      return
    }
    if (isShowAutoSkipSetting) {
      this.setState({
        isShowAutoSkipSetting: false,
        isShowSetting: true
      })
      return
    }
    if (isShowChangeLang) {
      this.setState({
        isShowChangeLang: false,
        isShowSetting: true
      })
      return
    }
    if (isShowDeleteDeviceConfirm) {
      this.setState({
        isShowDeleteDeviceConfirm: false,
        isShowDeviceMangement: true
      })
      return
    }
    if (isShowDeviceMangement) {
      this.setState({
        isShowDeviceMangement: false,
        isShowSetting: true
      })
      return
    }
    if (isShowSetting) {
      this.setState({
        isShowSetting: false
      }, () => {
        $tv.requestFocus(document.getElementsByClassName('setting-icon')[0]);
      })
      return
    }
    if (isShowLiveEventCategoryDialog) {
      this.setState({
        isShowLiveEventCategoryDialog: false
      });
      return
    }

    if (!showNavbar) {

      this.setState({
        showNavbar: true
      }, () => {
        window.scrollTo(0, 0);
        $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
        let items = this.state.recommends;
        items.forEach((item, index) => {
          if (item.style == GLOBAL.RECOMMEND_STYLE.FULL_SCREEN_VIDEO && index == 0) {
            if (item.rContent && this.fullScreenVideoRef && this.fullScreenVideoRef.current && !getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
              this.fullScreenVideoRef.current.initBackgroundImage(item.rContent[0]);
            }
          }
        })
      })
      return
    }

    this.setState({
      exitAppFocusedEl: getFocusedElement()
    }, () => {
      this.setState({
        isShowExitApp: true
      })
    })

  }

  /**
   * 获取未读消息数量
   */
  getUnreadMessageCount() {
    $request.boApi.user.getUnreadMessageCount({
      mac: getUUID()
    }).then(res => {
      if (res.data.data) {
        this.setState({
          notificationNum: res.data.data
        });
      }
    })
  }


  getDeviceInfo() {
    try {
      // eslint-disable-next-line
      // eslint-disable-next-line
      if (webapis && webapis.productinfo.getDuid()) {
        setStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE, GLOBAL.DEVICE_TYPE.SAMSUNG_TV);
        // eslint-disable-next-line
        setStorage(GLOBAL.STORAGE_KEYS.UUID, webapis.productinfo.getDuid());
      }

    } catch (err) {
      try {
        // eslint-disable-next-line
        webOS.service.request("luna://com.webos.service.sm", {
          method: "deviceid/getIDs",
          parameters: {
            "idType": ["LGUDID"]
          },
          onSuccess: (inResponse) => {
            console.log("Result: " + JSON.stringify(inResponse));
            // To-Do something
            // mac = inResponse.idList[0].idValue;
            // localStorage.setItem('deviceId', inResponse.idList[0].idValue)
            setStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE, GLOBAL.DEVICE_TYPE.LG_TV);
            setStorage(GLOBAL.STORAGE_KEYS.UUID, inResponse.idList[0].idValue);
            // localStorage.setItem("uuid", mac);
          },
          onFailure: (inError) => {
            console.log("Failed to get system ID information");
            console.log("[" + inError.errorCode + "]: " + inError.errorText);
            setStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE, GLOBAL.DEVICE_TYPE.LG_TV);
            // To-Do something
            // localStorage.setItem('deviceId', mac)
            return;
          }
        });
      } catch (err) {
        // setStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE, GLOBAL.DEVICE_TYPE.LG_TV);
        setStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE, GLOBAL.DEVICE_TYPE.LG_TV);
      }
    }
  }

  /**
   * 获取系统信息
   */
  getSystemInfo() {
    $request.boApi.system.getSystemInfo({
      mac: getStorage(GLOBAL.STORAGE_KEYS.UUID)
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        setStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO, res.data.sysInfo);
      }
    })
  }

  /**
   * 获取启动图
   */
  getStartupImage() {
    $request.boApi.common.startup({
      mac: getUUID(),
      type: "startupLogo"
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data.animation && res.data.animation.pictures) {
          this.setState({
            startupImages: res.data.animation.pictures,
            showStartup: true
          })
        }
      }
    })
  }

  /**
   * 获取系统链接信息
   */
  getSysLinkInfo() {
    $request.boApi.system.getSysLinkInfo({
      mac: getUUID()
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        setStorage(GLOBAL.STORAGE_KEYS.SYSTEM_LINK_INFO, res.data.linkInfo);
      }
    })
  }

  /**
   * 获取推荐位
   * @param {*} menuId 
   */
  getRecommend(menuId) {
    this.isLoadingRecommed = true;

    document.getElementById("root").style.backgroundImage = `none`;
    document.getElementsByClassName("home")[0].style.background = "#000";
    this.setState({
      recommends: []
    })
    $request.boApi.recommend.getRecommend({
      mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
      menuId
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (!this.isMountedComponent) {
          return
        }
        // 过滤推荐位
        if (res.data && res.data.menus.length && res.data.menus[0].recommend) {
          let recommends = res.data.menus[0].recommend.filter((item, index) => {
            item.realIndex = index;
            if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
              if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO).isAD == 'N') {
                return item.rContent && item.rContent.length && item.type != "favority" && item.type != "playhistory" && item.style != 'ad'
              }
              return item.rContent && item.rContent.length && item.type != "favority" && item.type != "playhistory"
            } else {
              return item.rContent && item.rContent.length
            }

          })
          if (res.data.menus[0].type == GLOBAL.MEDIA_TYPE.LIVE_EVENT) {
            if (recommends.length) {
              recommends = [recommends[0]];
              recommends.forEach(item => {
                item.style = GLOBAL.RECOMMEND_STYLE.STYLE_BANNER
              })
            }

          }
          if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
            recommends.forEach(item => {
              if (item.type == "favority") {
                this.getFavoriteList();
              }
              if (item.type == "playhistory") {
                this.getPlayList();
              }
            })
          }
          recommends.forEach((item, parentIndex) => {
            item.index = parentIndex;
            item.rContent.forEach(childItem => {
              childItem.index = parentIndex;
              childItem.id = `recommendItem${item.id}Content${childItem.id}`;
              if (item.style == GLOBAL.RECOMMEND_STYLE.AD) {
                this.getAdPods(childItem, parentIndex);
              }
            })
          })
          this.setState({
            recommends: recommends
          })
        } else {
          this.setState({
            recommends: []
          })
        }

        this.index = 0;
        this.chunkSize = 1;
        this.setState({
          chunkRecommends: []
        })
        this.renderChunk();

      }
      this.isLoadingRecommed = false;
    })
  }

  /**
   * 获取广告荚
   * @param {*} data 
   * @param {*} parentIndex 
   */
  getAdPods(data, parentIndex) {
    if (!sessionStorage.getItem(GLOBAL.STORAGE_KEYS.SIGN)) {
      deviceSign()
    }
    let promise = new Promise((resolve, reject) => {
      resolve();
    });
    $request.adApi.getAdPods({
      podsId: data.contentId
    }).then(res => {
      if (!this.isMountedComponent) {
        return
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data.body) {
          let pods = rsaDecryptContent(res.data.body);
          if (pods.materials && pods.materials.length) {
            let recommeds = deepClone(this.state.recommends);
            recommeds.forEach((item, index) => {
              if (index == parentIndex && item.style == GLOBAL.RECOMMEND_STYLE.AD) {
                item.rContent.forEach(childItem => {
                  if (childItem.contentId == data.contentId) {
                    childItem.pods = pods;
                    childItem.materials = pods.materials[0];
                    childItem.materials.podsId = pods.podsId;
                    setTimeout(() => {
                      if (this.adChildRefs[item.id]) {
                        this.adChildRefs[item.id].renderItem(item);
                      }
                    }, 2000)

                  }
                })
              }
            })


            this.setState({
              recommeds
            })
          }

        }

      }
    })
  }

  /**
   * 获取收藏记录
   */
  getFavoriteList() {
    $request.boApi.user.getFavoriteList({
      page: 0,
      pageSize: 50,
      type: GLOBAL.MEDIA_TYPE.VOD
    }).then(res => {
      if (!this.isMountedComponent) {
        return
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data.contents && res.data.contents.length) {
          let recommends = deepClone(this.state.recommends);
          recommends.forEach(item => {
            if (item.type == "favority") {
              item.rContent = res.data.contents;
            }
          })
          recommends.forEach(item => {
            item.rContent.forEach(childItem => {
              childItem.id = `recommendItem${item.id}Content${childItem.id}`
            })
          })
          this.setState({
            recommends: recommends
          })
        }
      }
    })
  }

  /**
   * 获取观看记录
   */
  getPlayList() {
    $request.boApi.user.getPlayList({
      page: 0,
      pageSize: 50,
      type: GLOBAL.MEDIA_TYPE.VOD
    }).then(res => {
      if (!this.isMountedComponent) {
        return
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data.contents && res.data.contents.length) {
          let recommends = deepClone(this.state.recommends);
          recommends.forEach(item => {
            if (item.type == "playhistory") {
              item.rContent = res.data.contents;
            }
          })
          recommends.forEach(item => {
            item.rContent.forEach(childItem => {
              childItem.id = `recommendItem${item.id}Content${childItem.id}`
            })
          })
          this.setState({
            recommends: recommends
          })
        }


      }
    })
  }

  /**
   * 切换菜单
   * @param {*} data 
   */
  changeMenu(data) {
    document.getElementById("root").style.backgroundImage = `none`;
    document.getElementsByClassName("home")[0].style.background = "#000";
    if (this.state.currentMenu && this.state.currentMenu.menuId != data.menuId) {
      this.setState({
        currentMenu: data
      }, () => {
        setTimeout(() => {
          if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
          }
          if (getStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY)) {
            removeStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY);
          }
          if (this.state.currentMenu.type != GLOBAL.MENU_TYPE.USER && this.state.currentMenu.type != GLOBAL.MENU_TYPE.LIVE) {
            this.getRecommend(this.state.currentMenu.menuId);
          } else {
            this.setState({
              recommeds: [],
              chunkRecommends: []
            })
          }
        }, 300)
      })

    } else if (this.state.currentMenu == null) {
      this.setState({
        currentMenu: data
      }, () => {
        if (this.state.currentMenu.type != GLOBAL.MENU_TYPE.USER && this.state.currentMenu.type != GLOBAL.MENU_TYPE.LIVE) {
          this.getRecommend(this.state.currentMenu.menuId);
        } else {
          this.setState({
            recommeds: [],
            chunkRecommends: []
          })
        }
      })

    }

  }

  /**
   * 獲取用戶信息
   */
  getMemberInfo() {
    if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
      $request.boApi.user.getMemberInfo({
        mac: getUUID()
      }).then(res => {
        if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
          if (res.data) {
            setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, res.data.userInfo);
          }
        }
      })
    }
  }

  /**
   * 隐藏菜单
   */
  hideMenu() {
    if (this.isLoadingRecommed) {
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
      return
    }
    this.setState({
      showNavbar: false
    })
    if (this.state.recommends.length) {
      if (this.state.recommends[0].rContent && this.state.recommends[0].rContent.length) {
        if (document.getElementById(this.state.recommends[0].rContent[0].id)) {
          $tv.requestFocus(document.getElementById(this.state.recommends[0].rContent[0].id));
        }

      }
    }
  }

  renderChunk = () => {
    let items = this.state.recommends;
    if (!this.isMountedComponent) {
      return
    }
    if (this.index < items.length) {
      this.setState((prevState) => ({
        chunkRecommends: [...prevState.chunkRecommends, ...items.slice(this.index, this.index + this.chunkSize)]
      }));
      this.index += this.chunkSize;

      setTimeout(this.renderChunk, 16.6);
    } else {
      items.forEach((item, index) => {
        if (item.style == GLOBAL.RECOMMEND_STYLE.FULL_SCREEN_VIDEO && index == 0) {
          if (item.rContent && this.fullScreenVideoRef && this.fullScreenVideoRef.current && !getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            this.fullScreenVideoRef.current.initBackgroundImage(item.rContent[0]);
          }
        }
      })
      // 如果渲染完成后，发现没有聚焦节点；强制聚焦到当前页面的第一个推荐位内容
      if (!getFocusedElement()) {
        if (this.state.recommends.length) {
          if (this.state.recommends[0].rContent && this.state.recommends[0].rContent.length) {
            if (document.getElementById(this.state.recommends[0].rContent[0].id)) {
              $tv.requestFocus(document.getElementById(this.state.recommends[0].rContent[0].id));
            }

          }
        }
      }

      if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
        let foucsInfo = getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
        if (foucsInfo.y) {
          window.scrollTo(foucsInfo.y, 0);
        }
        if (foucsInfo.scorllEl) {
          if (document.getElementById(foucsInfo.scorllEl)) {
            document.getElementById(foucsInfo.scorllEl).style.left = foucsInfo.x + 'px' || 0;
          }
        }
        if (foucsInfo.focusId) {
          if (document.getElementById(foucsInfo.focusId)) {
            $tv.requestFocus(document.getElementById(foucsInfo.focusId));
            this.setState({
              showNavbar: false
            });
          }

        }
      }

    }
  };

  renderRecommend(data) {

    if (data.style == GLOBAL.RECOMMEND_STYLE.AD) {
      return <Ad data={data} key={data.id} ref={(el) => (this.adChildRefs[data.id] = el)} />
    } else if (data.style == GLOBAL.RECOMMEND_STYLE.FULL_SCREEN_VIDEO) {
      return <FullScreenVideo ref={this.fullScreenVideoRef} click={(parent, child) => {
        this.handleClickItem(parent, child)
      }} scorllDown={(propData) => {
        this.fullScreenVideoScorllDown(propData)
      }} scorllUp={(propData) => {
        this.fullScreenVideoScorllUp(propData)
      }} focusedItem={(propData) => {
        this.fullScreenContentFocused(propData)
      }} key={data.id} data={data} />
    } else if (data.style == GLOBAL.RECOMMEND_STYLE.SCROLL_BANNER) {
      return <ScrollBanner data={data} key={data.id} click={(parent, child) => {
        this.handleClickItem(parent, child)
      }} swiperDown={(propData) => {
        this.scrollBannerDown(propData)
      }} swiperUp={(propData) => {
        this.scrollBannerUp(propData)
      }} />
    } else if (data.style == GLOBAL.RECOMMEND_STYLE.STYLE_BANNER) {
      return <Carousel data={data} key={data.id} click={(parent, child) => {
        this.handleClickItem(parent, child)
      }} carouselDown={(propData) => {
        this.swiperDown(propData)
      }} carouselUp={(propData) => {
        this.swiperUp(propData)
      }}
      />
    } else {
      let moreRecommedItem = {
        type: 'more',
        isDisplayTitle: "N",
        isDisplayContentTitle: "N",
        style: data.style,
        id: `recommendItem${data.id}Content${data.index}`,
        poster: require("../../assets/images/defaultImages/more_h.png").default,
        vPoster: require("../../assets/images/defaultImages/more_v.png").default
      }
      let concatMoreData = deepClone(data);
      if (data.rContent) {
        // if (data.rContent[0].type == GLOBAL.MEDIA_TYPE.CATEGORY) {
        //   concatMoreData.rContent = concatMoreData.rContent;
        // } else {
        concatMoreData.rContent = concatMoreData.rContent.concat(moreRecommedItem);
        // }
      }

      return <BasicRecommed data={concatMoreData} click={(parent, child) => {
        this.handleClickItem(parent, child)
      }} scorllUp={(propData, index) => {
        this.basicRecommendScorllUp(propData, index)
      }}
        scorllDown={(propData, index) => {
          this.basicRecommendScorllDown(propData, index)
        }}
        key={data.id} />
    }
  }

  fullScreenContentFocused(data) {
    if (data && data.data) {
      this.fullScreenVideoRef.current.initBackgroundImage(data.data);
    }

    // console.log(data)
  }

  basicRecommendScorllDown(data, childData) {
    // console.log(data, index)
    let index = deepClone(data).index;
    if (index == this.state.recommends[this.state.recommends.length - 1].index) {
      if (document.getElementById(childData.id)) {
        $tv.requestFocus(document.getElementById(childData.id));
        document.getElementById(childData.id).classList.add("verticalShake");
        setTimeout(() => {
          document.getElementById(childData.id).classList.remove("verticalShake");
        }, 1000)
      }
    } else {
      if (index < this.state.recommends.length - 1) {
        if (this.state.recommends[index + 1].rContent[0].id) {
          // if (this.state.recommends[index + 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
          //   return
          // }
          if (document.getElementById(this.state.recommends[index + 1].rContent[0].id)) {
            $tv.requestFocus(document.getElementById(this.state.recommends[index + 1].rContent[0].id));
          }

        }

      }
    }
    // if (index != this.state.recommends.length - 1) {
    // if (this.state.recommends[index + 1].rContent.length) {
    // $tv.requestFocus(document.getElementById(this.state.recommends[index].rContent[0].id));
    // }

    // }
  }

  basicRecommendScorllUp(data) {
    let index = deepClone(data).index;
    if (index == 0) {
      this.setState({
        showNavbar: true
      })
      window.scrollTo(0, 0);
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
    } else {
      if (index > 0) {
        if (this.state.recommends[index - 1] && this.state.recommends[index - 1].rContent[0].id) {
          // if (this.state.recommends[index - 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
          //   return
          // }
          if (document.getElementById(this.state.recommends[index - 1].rContent[0].id)) {
            $tv.requestFocus(document.getElementById(this.state.recommends[index - 1].rContent[0].id));
          }
        }
      }
    }
  }

  fullScreenVideoScorllUp(data) {
    let index = deepClone(data).index;
    if (index == 0) {
      this.setState({
        showNavbar: true
      })
      window.scrollTo(0, 0);
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
    } else {
      if (index > 0) {
        if (this.state.recommends[index - 1] && this.state.recommends[index - 1].rContent[0].id) {
          // if (this.state.recommends[index - 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
          //   return
          // }
          if (document.getElementById(this.state.recommends[index - 1].rContent[0].id)) {
            $tv.requestFocus(document.getElementById(this.state.recommends[index - 1].rContent[0].id));
          }
        }
      }
    }
    // console.log(data)
  }

  fullScreenVideoScorllDown(data) {
    let index = deepClone(data).index;
    if (index < this.state.recommends.length - 1) {
      if (this.state.recommends[index + 1] && this.state.recommends[index + 1].rContent[0].id) {
        // if (this.state.recommends[index + 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
        //   return
        // }
        if (document.getElementById(this.state.recommends[index + 1].rContent[0].id)) {
          $tv.requestFocus(document.getElementById(this.state.recommends[index + 1].rContent[0].id));
        }

      }

    }
  }

  fullScreenVideoFocused(data) {

  }

  /**
   * 三个的banner
   * @param {*} data 
   */
  swiperUp(data) {
    // console.log(data)
    let index = deepClone(data).index;
    if (index == 0) {
      this.setState({
        showNavbar: true
      })
      window.scrollTo(0, 0);
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
    } else {
      if (index > 0) {
        if (this.state.recommends[index - 1] && this.state.recommends[index - 1].rContent[0].id) {
          // if (this.state.recommends[index - 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
          //   return
          // }
          if (document.getElementById(this.state.recommends[index - 1].rContent[0].id)) {
            $tv.requestFocus(document.getElementById(this.state.recommends[index - 1].rContent[0].id));
          }
        }
      }
    }
  }

  /**
   * 3个的banner
   * @param {*} data 
   */
  swiperDown(data) {
    let index = deepClone(data).index;
    if (index < this.state.recommends.length - 1) {
      if (this.state.recommends[index + 1] && this.state.recommends[index + 1].rContent[0].id) {
        // if (this.state.recommends[index + 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
        //   return
        // }
        if (document.getElementById(this.state.recommends[index + 1].rContent[0].id)) {
          $tv.requestFocus(document.getElementById(this.state.recommends[index + 1].rContent[0].id));
        }

      }

    }
  }

  /**
   * 大banner
   * @param {*} data 
   */
  scrollBannerUp(data) {
    let index = deepClone(data).index;
    if (index == 0) {
      this.setState({
        showNavbar: true
      })
      window.scrollTo(0, 0);
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
    } else {
      if (index > 0) {
        if (this.state.recommends[index - 1] && this.state.recommends[index - 1].rContent[0].id) {
          // if (this.state.recommends[index - 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
          //   return
          // }
          // 上移聚焦到第一个内容
          if (document.getElementById(this.state.recommends[index - 1].rContent[0].id)) {
            $tv.requestFocus(document.getElementById(this.state.recommends[index - 1].rContent[0].id));
          }
        }
      }
    }
  }

  /**
   * 大banner
   * @param {*} data 
   */
  scrollBannerDown(data) {
    let index = deepClone(data).index;
    if (index < this.state.recommends.length - 1) {
      if (this.state.recommends[index + 1] && this.state.recommends[index + 1].rContent[0].id) {
        // if (this.state.recommends[index + 1].rContent[0].type == GLOBAL.RECOMMEND_STYLE.AD) {
        //   return
        // }
        // 下移聚焦到下一个的第一个内容
        if (document.getElementById(this.state.recommends[index + 1].rContent[0].id)) {
          $tv.requestFocus(document.getElementById(this.state.recommends[index + 1].rContent[0].id));
        }

      }

    }
  }

  handleClickItem(parent, data) {
    console.log(data)
    if (data) {
      setStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO, {
        x: data.x ? data.x : 0,
        y: window.scrollY,
        scorllEl: data.scorllEl || null,
        focusId: data.id || null
      });
      // console.log(this.state.currentMenu)
      setStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU, this.state.currentMenu);
      // return
      switch (data.type) {
        case GLOBAL.MEDIA_TYPE.VOD:
          this.props.history.push(`/vod/${data.url}`);
          break;
        case GLOBAL.MEDIA_TYPE.EPISODE:
          this.getSeasonsDetail(data);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE_EVENT:
          this.props.history.push(`/liveEventDetail/${data.url || data.contentId}`);
          break;
        case GLOBAL.MEDIA_TYPE.CATEGORY:
          this.props.history.push(`/category/${data.url || data.contentId}/${data.resAssetId}/${data.categoryType}/${parent.isDisplayContentTitle}/${data.title}`);
          break;
        case GLOBAL.MEDIA_TYPE.LIVE:
          break;
        case 'more':
          this.props.history.push(`/allRecommend/${parent.id}/${parent.style == 'default' ? GLOBAL.RECOMMEND_STYLE.HORIZONTAL : parent.style}/${parent.title}/${parent.isDisplayContentTitle}`)
          // console.log(parent)
          break;
        default:
          break;

      }
    }

    // console.log(parent)
    // this.props.history.push(`/vod/${data.data.url}`)
    // console.log(this.props)
  }

  handleClickLiveEventItem(data) {
    if (data) {
      setStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO, {
        x: data.x ? data.x : 0,
        y: window.scrollY,
        scorllEl: data.scrollEl || null,
        focusId: data.assetId || null
      });
      setStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU, this.state.currentMenu);
      this.props.history.push(`/liveEventDetail/${data.assetId}`)
    }
  }

  /**
   * 事件直播分类tab点击
   */
  handleCategoryTabClick(data) {
    if (data) {
      setStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO, {
        x: data.x ? data.x : 0,
        y: window.scrollY,
        scorllEl: data.scorllEl || null,
        focusId: data.focusId || null
      });
      setStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU, this.state.currentMenu);
      this.props.history.push(`/liveEventAll/${data.categoryId}/${data.categoryName}`);
    }
  }

  /**
   * 事件分类面板显示隐藏
   * @param {*} value 
   */
  handleLiveEventCategoryDialogStatus(value) {
    this.setState({
      isShowLiveEventCategoryDialog: value
    })
  }


  getSeasonsDetail(data) {
    if (this.requestFlag) {
      this.requestFlag = false;
      $request.boApi.vod.getSeasonsDetail({
        mac: getUUID(),
        seasonsAssetId: data.url || data.contentId
      }).then(res => {
        if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
          let seasonsDetail = res.data.seasonsDetail;
          if (seasonsDetail.contents) {
            if (seasonsDetail.contents.length > 1) {
              this.props.history.push(`/series/${data.url || data.contentId}`);
            } else {
              this.props.history.push(`/vod/${seasonsDetail.contents[0].contentId}`);
            }
          }
        }
        this.requestFlag = true;
      }).catch(err => {
        this.requestFlag = true;
      })
    }
  }

  handleLoginUp() {
    this.setState({
      showNavbar: true
    })
  }
  handleMeMenuItem(data) {
    console.log(data)
    if (data.type != "signOut") {
      setStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO, {
        x: data.x ? data.x : 0,
        y: window.scrollY,
        scorllEl: data.scorllEl || null,
        focusId: data.type || null
      });
      setStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU, this.state.currentMenu);
    }
    switch (data.type) {
      case 'subscription':
        this.props.history.push(`/subscription`);
        break;
      case 'transactionHistory':
        this.props.history.push(`/transactionHistory`);
        break;
      case 'myFavorites':
        this.props.history.push(`/favorite`);
        break;
      case 'watchHistory':
        this.props.history.push(`/watchHistory`);
        break;
      case 'claimTheVoucherCode':
        this.props.history.push(`/voucherCode`);
        break;
      case 'messages':
        this.props.history.push(`/notification`);
        break;
      case 'signOut':
        this.setState({
          isShowSignOut: true
        })
        break;

    }
  }

  /**
   * 隐藏启动图
   */
  hideStartup() {
    this.setState({
      showStartup: false
    }, () => {
      setStorage(GLOBAL.STORAGE_KEYS.SHOW_STARTUP_IMAGE, 1);
      $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
    })
  }

  /**
   * 点击跳转登录
   */
  handleToLogin() {
    this.props.history.push(`/qrLogin`)
  }

  /**
   * 退出登录按钮点击
   * @param {*} data 
   */
  handleSignOutBtn(data) {
    switch (data.type) {
      case 'ok':
        this.handleConfirmSignOut();
        break;
      case 'cancel':
        this.setState({
          isShowSignOut: false
        }, () => {
          $tv.requestFocus(document.getElementById('signOut'))
        })
        break;
    }
  }


  /**
   * 退出登录
   */
  handleConfirmSignOut() {
    $request.boApi.user.logout({
      mac: getUUID(),
      verison: projectConfig.version
    }).then(res => {
      removeStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
      removeStorage(GLOBAL.STORAGE_KEYS.TOKEN);
      window.location.reload();
    })
  }

  /**
   * 退出应用
   * @param {*} data 
   */
  handleExitApp(data) {
    switch (data.type) {
      case 'ok':
        this.handleConfirmExitApp();
        break;
      case 'cancel':
        this.setState({
          isShowExitApp: false
        }, () => {
          if (this.state.exitAppFocusedEl) {
            $tv.requestFocus(this.state.exitAppFocusedEl);
          } else {
            $tv.requestFocus(document.getElementById(`menuItem${this.state.currentMenu.menuId}`));
          }

        })
        break
    }
  }

  /**
   * 确认退出应用程序
   */
  handleConfirmExitApp() {
    const deviceType = getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE);
    if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
      removeStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU)) {
      removeStorage(GLOBAL.STORAGE_KEYS.HOME_CURRENT_MENU);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY)) {
      removeStorage(GLOBAL.STORAGE_KEYS.LIVE_EVENT_CATEGORY);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO)) {
      removeStorage(GLOBAL.STORAGE_KEYS.ALL_RECOMMEND_FOCUS_IFNO);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO)) {
      removeStorage(GLOBAL.STORAGE_KEYS.CATEGORY_PAGE_FOCUS_INFO);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD)) {
      removeStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD);
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.SHOW_STARTUP_IMAGE)) {
      removeStorage(GLOBAL.STORAGE_KEYS.SHOW_STARTUP_IMAGE);
    }
    try {

      if (deviceType == GLOBAL.DEVICE_TYPE.SAMSUNG_TV) {
        this.closeWindow();
        // eslint-disable-next-line
        // try {
        //   // eslint-disable-next-line
        //   var app = tizen.application.getCurrentApplication();
        //   app.exit();
        // } catch(err) {
        //   if (window.parent && window.parent.exitApp) {
        //     window.parent.exitApp();
        //   }
        //   this.closeWindow();
        // }
      } else {
        try {
          // eslint-disable-next-line
          webOS.platformBack();
        } catch (err) {
          this.closeWindow();
        }
      }

    } catch (err) {
      this.closeWindow();
    }
  }

  /**
   * 关闭窗口
   */
  closeWindow() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      // IE
      if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
        // IE6
        window.opener = null;
        window.close();
      } else {
        // IE6+
        window.open('', '_top');
        window.top.close();
      }
    } else {
      window.location.href = 'about:blank';
      window.opener = null;
      window.open('', '_self', '');
      window.close();
    }
  }

  /**
   * 设置点击
   */
  handleSetting() {
    this.setState({
      isShowSetting: true
    })
  }

  /**
   * 设置项点击
   * @param {*} type 
   */
  handleSettingItemClick(type) {
    switch (type) {
      case 'parentalControl':
        this.setState({
          isShowParentalControl: true,
          isShowSetting: false,
          settingSelectedValue: type
        })
        break;
      case 'autoSkip':
        this.setState({
          isShowAutoSkipSetting: true,
          isShowSetting: false,
          settingSelectedValue: type
        })
        break;
      case 'language':
        this.setState({
          isShowChangeLang: true,
          isShowSetting: false,
          settingSelectedValue: type
        })
        break;
      case 'deviceMangement':
        this.setState({
          isShowDeviceMangement: true,
          isShowSetting: false,
          settingSelectedValue: type
        })
        break;
      case 'termsOfService':
        this.props.history.push(`/termsOfService`);
        break;
      case 'privacyPolicy':
        this.props.history.push(`/privacyPolicy`);
        break;
      case 'faq':
        this.props.history.push(`/faq`)
        break;
      case 'contactUS':
        this.props.history.push(`/contactUS`);
        break;
    }
  }

  /**
   * 父母控制选择
   * @param {*} data 
   */
  handleSelectParentalControlItem(data) {
    switch (data.value) {
      case 'edit':
        this.setState({
          isShowSendEmailCode: true,
          isShowParentalControl: false
        })
        break;
      case 'forgot':
        break;
    }
  }

  verifyPinGetMsg(data) {
    // console.log(data, '-------------')
    if (data.verifyPinPass) {
      if (this.state.pinBoxType == 1) {
        this.setState({
          isShowPin: false
        }, () => {
          this.setState({
            pinBoxType: 2,
            isShowPin: true
          })
        })
      }
    } else {
      if (this.state.pinBoxType == 2 && data.updateState == 'success') {
        if (data.pinNum) {
          let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
          user.parentControlPin = data.pinNum;
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, user);
          this.setState({
            isShowPin: false,
            parentControlPin: data.pinNum,
            isShowUpdatePinSuccess: true
          }, () => {
            setTimeout(() => {
              this.setState({
                isShowUpdatePinSuccess: false
              })
            }, 3000)
          })
        }
      }
    }
  }

  /**
   * 验证码校验
   * @param {*} data 
   */
  getEmailMsg(data) {
    this.setState({
      isShowSendEmailCode: data.isShow,
      isShowPin: data.isShow ? false : true,
      pinBoxType: 1
    })
  }

  /**
   * 片头片尾跳过设置
   */
  handleAutoSkipSetting(data) {
    if (data) {
      setStorage(GLOBAL.STORAGE_KEYS.ALLOW_SKIP, data.value);
      this.setState({
        isShowAutoSkipSetting: false,
        allowSkip: data.value,
        isShowSetting: true
      }, () => {

      })
    }
  }

  /**
   * 语言切换
   */
  handleSelectLang(data) {
    console.log(data)
    if (data) {
      this.setState({
        language: data.value,
        isShowChangeLang: false
      }, () => {
        setStorage(GLOBAL.STORAGE_KEYS.LANG, data.value);
        setStorage(GLOBAL.STORAGE_KEYS.LANG_KEY, data.key);
        window.location.reload();
      })
    }
  }

  /**
   * 点击删除设备
   * @param {*} data 
   */
  handleDeleteDevice(data) {
    // deviceMangementPopupRef
    this.setState({
      isShowDeviceMangement: false,
      isShowDeleteDeviceConfirm: true,
      currentSelectDevice: data.mac
    })
  }

  /**
   * 删除确认弹窗
   * @param {*} data 
   */
  handleConfirmDeleteDevice(data) {
    switch (data.type) {
      case 'ok':
        $request.boApi.user.unbindDevice({
          unbindMac: this.state.currentSelectDevice
        }).then(res => {
          if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
            // this.deviceMangementPopupRef.current.getDeviceList();
          }
          this.setState({
            isShowDeviceMangement: true,
            isShowDeleteDeviceConfirm: false
          })
        })
        break;
      case 'cancel':
        this.setState({
          isShowDeviceMangement: true,
          isShowDeleteDeviceConfirm: false
        })
        break;
    }
  }

  /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
  pageWheel(e) {
    clearTimeout(this.pageWheelTimer);
    this.pageWheelTimer = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.findFocusType = 0;
        $tv.initDis = 10;
        $tv.setFocus('down');
      } else {
        $tv.findFocusType = 0;
        $tv.initDis = 80;
        $tv.setFocus('up');
      }
    }, 300);
  }

  /**
   * 跳转消息通知页面
   */
  handleLinkToNotification() {
    this.props.history.push(`/notification`)
  }

  render() {
    // console.log(this.state.chunkRecommends)
    const { currentMenu, isLoadingRecommed, userInfo, isShowSignOut, isShowSetting, notificationNum, isShowParentalControl } = this.state;
    // console.log(currentMenu)
    return (
      <div className='home' onWheel={e => this.pageWheel(e)}>
        <Header showNavbar={this.state.showNavbar} isRequest={isLoadingRecommed} notificationNum={notificationNum}
          notificatinNumberClick={() => {
            this.handleLinkToNotification()
          }}
          settingClick={() => {
            this.handleSetting()
          }} searchClick={() => {
            this.props.history.push(`/search`);
          }} change={(data) => {
            this.changeMenu(data)
          }} pressDown={() => {
            this.hideMenu()
          }} name={this.state.currentMenu ? this.state.currentMenu.menuName : ""} />
        <div className='main'>

          {/* <ScrollBanner data={testList} /> */}

          {
            this.state.chunkRecommends.map(item => (
              this.renderRecommend(item)
            ))
          }
          {currentMenu && currentMenu.type == GLOBAL.MENU_TYPE.LIVE_EVENT ? <LiveEvent showCategoryDialog={this.state.isShowLiveEventCategoryDialog} click={(data) => {
            this.handleClickLiveEventItem(data)
          }} selected={(data) => {
            this.handleCategoryTabClick(data)
          }}
            change={(value) => {
              this.handleLiveEventCategoryDialogStatus(value)
            }}
            data={this.state.currentMenu} /> : null}
          {currentMenu && currentMenu.type == GLOBAL.MENU_TYPE.USER ? <Me loginClick={() => {
            this.handleToLogin()
          }} click={(data) => {
            this.handleMeMenuItem(data)
          }} up={(el) => {
            this.handleLoginUp(el)
          }}

          /> : null}
        </div>
        {
          userInfo ? (
            <Popup show={isShowSignOut} title={userInfo.nickName || userInfo.name} text={this.props.t("toast.signOutTips")} click={(data) => {
              this.handleSignOutBtn(data)
            }} buttons={
              [
                {
                  type: 'ok',
                  text: this.props.t("button.sure")
                },
                {
                  type: 'cancel',
                  text: this.props.t("button.cancel")
                }
              ]
            } />
          ) : null
        }
        <Popup show={this.state.isShowExitApp} title={this.props.t("setting.exitApp")} text={this.props.t("toast.exitAppTips")} click={(data) => {
          this.handleExitApp(data)
        }} buttons={
          [
            {
              type: 'ok',
              text: this.props.t("button.sure")
            },
            {
              type: 'cancel',
              text: this.props.t("button.cancel")
            }
          ]
        } />
        <SettingPopup show={isShowSetting} value={this.state.settingSelectedValue} click={(type) => {
          this.handleSettingItemClick(type)
        }} title={this.props.t("setting.setting")} />
        <SelectorPopup value={""} click={(data) => {
          this.handleSelectParentalControlItem(data)
        }} show={isShowParentalControl} title={this.props.t("setting.parentControl")} data={[{ label: this.props.t("setting.edit"), value: 'edit' }]} isSelected={false} />
        {
          userInfo ? <SendCodeOfEmail isSendCodeOfEmail={this.state.isShowSendEmailCode} email={userInfo.email} SendCodeOfEmailGetMsg={(value) => { this.getEmailMsg(value) }} /> : null
        }

        {
          userInfo ? <PinBox isPinBox={this.state.isShowPin} pinBoxStatus={this.state.pinBoxType} verifyPinGetMsg={(value) => { this.verifyPinGetMsg(value) }} /> : null
        }
        <UpdatePin isUpdatePin={this.state.isShowUpdatePinSuccess} updateState={'success'} parentControlPin={this.state.parentControlPin} />
        <SelectorPopup click={(data) => { this.handleAutoSkipSetting(data) }} show={this.state.isShowAutoSkipSetting} isSelected={true} title={this.props.t("setting.introAndOutro")} value={this.state.allowSkip} data={[{ label: this.props.t("setting.on"), value: 1 }, { label: this.props.t("setting.off"), value: 0 }]} />
        <SelectorPopup click={(data) => { this.handleSelectLang(data) }} show={this.state.isShowChangeLang} isSelected={true} title={this.props.t("setting.language")} value={this.state.language} data={projectConfig.lang} />
        <DeviceMangement show={this.state.isShowDeviceMangement} value={this.state.currentSelectDevice} click={(data) => {
          this.handleDeleteDevice(data)
        }} />
        <Popup show={this.state.isShowDeleteDeviceConfirm} click={(data) => { this.handleConfirmDeleteDevice(data) }} title={this.props.t("setting.unbind")} text={this.props.t("toast.unbindTips")} buttons={
          [
            {
              type: 'ok',
              text: this.props.t("button.sure")
            },
            {
              type: 'cancel',
              text: this.props.t("button.cancel")
            }
          ]
        } />
        {
          this.state.showStartup ? (<Startup data={this.state.startupImages} complete={() => {
            this.hideStartup()
          }} />) : null
        }

      </div>
    );
  }
});
