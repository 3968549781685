import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, setStorage, removeStorage, formatDate, keycodeHandle, downTime, calculateAge, getUUID, getFocusedElement } from "../../util/util";
import Column from '../../components/column/Column';
import SignIn from '../../components/signIn/SignIn';
import SendCodeOfBirthday from '../../components/sendCodeOfBirthday/SendCodeOfBirthday';
import SendCodeOfEmail from '../../components/sendCodeOfEmail/SendCodeOfEmail';
import ToolTipBox from '../../components/toolTipBox/ToolTipBox';
import UpdatePin from '../../components/updatePin/UpdatePin';
import PinBox from '../../components/pinBox/PinBox';
import GLOBAL from "../../util/global"
import Popup from '../../components/popup/popup';
import Dialog from '../../components/dialog/Dialog';
import $request from '../../api';
import './style/LiveEventDetail.scss';

export default withTranslation()(class NewLiveEventDetail extends Component {
  signInGetMsg = (value) => {
    this.setState({
      isSignIn: value,
    });
    $tv.requestFocus(this.liveCollect.current);
  };
  // 生日验证
  SendCodeOfBirthdayGetMsg = (params) => {
    this.setState({
      birthday: params.birthday,
      isSendCodeOfBirthday: params.isShow,
    }, () => {
      if (this.verifyAge(params.birthday, this.state.liveEventDetail)) {
        if (this.state.isVerifyParent_play) {
          if (this.state.playInfo) {
            localStorage.setItem('playInfo', JSON.stringify(
              this.state.playInfo));
            this.props.history.push(`/eventPlayer/${this.state.liveEventDetail.assetId}/`);
          }
        }
      }
    });
  };
  // 发送邮箱验证码
  SendCodeOfEmailGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      isSendCodeOfEmail: params.isShow,
    }, () => {
      this.setState({
        isPinBox: true
      });
    });
  };
  // 18+  PIN 提示
  toolTipBoxGetMsg = (value, isBack) => {
    this.setState({
      isToolTipBox: value,
    }, () => {
      if (this.state.toolTipType == "pin") {
        this.setState({
          isSendCodeOfEmail: true,
        });
      } else {
        if (isBack) {
          window.history.back();
        }
        
      }
    });
  };
  // 验证pin码
  verifyPinGetMsg = (params) => {
    this.setState({
      pinBoxStatus: params.pinBoxStatus,
      verifyPinPass: params.verifyPinPass,
      isPinBox: params.isShow,
    }, () => {
      if (params.pinBoxStatus == 1) {
        if (this.state.verifyPinPass) {
          if (this.state.isVerifyParent_play) {
            if (this.state.playInfo) {
              this.props.history.push(`/eventPlayer/${this.state.liveEventDetail.assetId}`);
            }
          } else {
            // location.href = "package.html";
          }
        }
      } else {
        if (params.updateState == "success") {
          this.setState({
            isUpdatePin: true,
            parentControlPin: params.pinNum,
            updateState: params.updateState,
          });
          let userInfo = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO) || {};
          userInfo.parentControlPin = params.pinNum;
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, userInfo);
          setTimeout(() => {
            this.setState({
              isVerifyParent_play: true,
              isUpdatePin: false,
              parentControlPin: "",
              isPinBox: true
            });
          }, 1500)
        } else {
          //warning
          this.setState({
            isUpdatePin: true,
            parentControlPin: "",
            updateState: params.updateState,
          });
          setTimeout(() => {
            this.setState({
              isUpdatePin: false,
            });
          }, 1500)
        }
        this.setState({
          pinBoxStatus: 1,
        });
      }
    });
  };
  constructor(props) {
    super(props);
    this.buyBtn = React.createRef();
    this.livePlay = React.createRef();
    this.liveCollect = React.createRef();
    this.reminder = React.createRef();
    this.unReminder = React.createRef();
    this.recordFocusEl = null; // 记录聚焦节点
    this.state = {
      liveContentBgImg: require("../../assets/images/icon/home_bgvideo_shadow_bg.png"),
      livePlay: require("../../assets/images/icon/vod_play.png"),
      reminder: require("../../assets/images/icon/icon_reminder_normal.svg"),
      unReminder: require("../../assets/images/icon/player_icon_reminder_normal.svg"),
      liveCollect: require("../../assets/images/icon/vod_save.png"),
      flag: 1,
      assetId: this.props.match.params.contentId, // 请求参数
      liveEventDetail: {},
      liveEventStartTime: "",
      liveEventStatus: "",
      liveEventTime: "",
      isShowBuy: false,
      isLivePlay: false, // 是否显示播放
      isReminder: false,
      isUnReminder: false,
      isLiveCollect: false,
      liveIntroHtml: "",
      contentGradeDto: [],
      recommondData: [],
      isSignIn: false,
      isToolTipBox: false,
      isSendCodeOfBirthday: false,
      isPinBox: false,
      verifyPinPass: false, //校验pin码是否通过
      birthday: "",
      toolTipType: "",
      isUpdatePin: false,
      updateState: "success",
      parentControlPin: "",
      age: "",
      isShowSignOut: false,
      popupText: "",
      isVerifyParent_play: false,
      playInfo: null,
      pinBoxStatus: 1,
      isSendCodeOfEmail: false,
      email: "",
      isShowPurchase: false, // 是否显示订阅
      isShowPurchaseTipDialog: false, //是否显示购买提示弹窗 
    };
  }
  componentWillMount() {

  }
  componentDidMount() {
    $tv.distanceToCenter = false;
    this.getLiveEventDetail();
    // 播放按钮
    this.livePlay.current.addEventListener('onFocus', this.livePlayFocus.bind(this));
    this.livePlay.current.addEventListener('click', this.livePlayClick.bind(this));
    this.livePlay.current.addEventListener("mouseenter", this.livePlayMouseEnter.bind(this));
    // 订阅按钮
    this.reminder.current.addEventListener('onFocus', this.reminderFocus.bind(this));
    this.reminder.current.addEventListener('click', this.reminderClick.bind(this));
    this.reminder.current.addEventListener("mouseenter", this.reminderMouseEnter.bind(this));
    // 取消订阅按钮
    this.unReminder.current.addEventListener('onFocus', this.unReminderFocus.bind(this));
    this.unReminder.current.addEventListener('click', this.unReminderClick.bind(this));
    this.unReminder.current.addEventListener("mouseenter", this.unReminderMouseEnter.bind(this));
    // 收藏按钮
    this.liveCollect.current.addEventListener('onFocus', this.liveCollectFocus.bind(this));
    this.liveCollect.current.addEventListener('click', this.liveCollectClick.bind(this));
    this.liveCollect.current.addEventListener("mouseenter", this.liveCollectMouseEnter.bind(this));
    this.buyBtn.current.addEventListener('click', this.handleBuyClick.bind(this))
    keycodeHandle({ exit: this.vodKeyDown, quit: this.vodKeyDown });
  }
  // 监听传值
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.match.params.contentId !== this.state.assetId) {
      this.setState({
        assetId: newProps.match.params.contentId
      }, () => {
        window.location.reload()
      });
    }
  }
  // 在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    if (this.startTimeUpdateTimer) {
      clearInterval(this.startTimeUpdateTimer)
    }
    if (this.startCountDown) {
      clearInterval(this.startCountDown)
    }
    clearTimeout(this.pageWheelTime);
    $tv.distanceToCenter = true;
    this.setState = () => false;
  }
  // 监听esc和Backspace事件
  vodKeyDown = () => {
    if (this.state.isVerifyParent_play) {
      this.setState({
        isVerifyParent_play: false,
      })
    } else if (this.state.isPinBox) {
      this.setState({
        isPinBox: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else if (this.state.isSignIn) {
      this.setState({
        isSignIn: false,
      }, () => {
        if (this.state.isLivePlay) {
          $tv.requestFocus(this.livePlay.current);
        } else {
          $tv.requestFocus(this.liveCollect.current);
        }
      });
    } else if (this.state.isSendCodeOfBirthday) {
      this.setState({
        isSendCodeOfBirthday: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else if (this.state.isSendCodeOfEmail) {
      this.setState({
        isSendCodeOfEmail: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else if (this.state.isToolTipBox) {
      this.setState({
        isToolTipBox: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else if (this.state.isUpdatePin) {
      this.setState({
        isUpdatePin: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else if (this.state.isShowSignOut) {
      this.setState({
        isShowSignOut: false,
      }, () => {
        $tv.requestFocus(this.livePlay.current);
      });
    } else {
      window.history.back();
    }

  }
  // 获取直播详情
  getLiveEventDetail() {
    $request.boApi.liveEvent.getLiveEventDetail({
      assetId: this.state.assetId
    }).then(res => {
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let liveEventDetail = res.data.data;
        let liveEventStatus = liveEventDetail.status;
        this.setState({
          isUnReminder: true,
          isLiveCollect: true,
          liveEventStatus: liveEventDetail.status,
          liveEventDetail: liveEventDetail,
          liveEventStartTime: liveEventDetail.startTime,
          contentGradeDto: liveEventDetail.contentGradeDto.name.split(",")
        });
        //判断是否收藏
        if (liveEventDetail.favoriteState) {
          this.setState({
            flag: 0,
            liveCollect: require("../../assets/images/icon/vod_saved.png"),
          });
        } else {
          this.setState({
            flag: 1,
            liveCollect: require("../../assets/images/icon/vod_save.png"),
          });
        }
        // 如果当前时间已结束，显示直播播放结束提示
        if (liveEventStatus == GLOBAL.LIVE_EVENT.STATUS.ENDED) {
          this.setState({
            isLivePlay: false,
            isReminder: false,
            isUnReminder: false,
            isLiveCollect: true,
            liveIntroHtml: "liveEvent.liveEventEndTips",
            liveEventTime: formatDate(liveEventDetail.startTime, "hh:mm"),
          });
          $tv.requestFocus(this.liveCollect.current);
          if (this.startTimeUpdateTimer) {
            clearInterval(this.startTimeUpdateTimer)
          }
        } else {
          this.getPlayAuth(liveEventDetail.channelAssetId, this.state.assetId);
          if (liveEventStatus == GLOBAL.LIVE_EVENT.STATUS.UPCOMING) {
            // 判断是否预约通知
            this.getReservationList();
            this.setState({
              isLivePlay: false,
              isLiveCollect: true,
              liveIntroHtml: "liveEvent.staringSoon",
              liveEventTime: formatDate(liveEventDetail.startTime, "hh:mm"),
            });
            this.startCountDown = setInterval(() => {
              if (!downTime(liveEventDetail.startTime)) {
                this.setState({
                  isReminder: false,
                  isUnReminder: false,
                  liveIntroHtml: "",
                  isLiveCollect: true,
                  liveEventTime: "",
                });
                if (!this.state.isShowBuy) {
                  this.setState({
                    isLivePlay: true,
                  });
                  $tv.requestFocus(this.livePlay.current);
                }
                clearInterval(this.startCountDown);
              } else {
                this.setState({
                  liveEventTime: downTime(liveEventDetail.startTime),
                });
              }
            }, 1000)
          } else {
            this.setState({
              isReminder: false,
              isUnReminder: false,
              liveIntroHtml: "",
            });
            if (!this.state.isShowBuy) {
              this.setState({
                isLivePlay: true,
              });
              $tv.requestFocus(this.livePlay.current);
            }
          }
          // 定时请求获取直播开始时间及结束时间
          this.startTimeUpdateTimer = setInterval(() => {
            this.getLiveEventTime();
          }, 5 * 60 * 1000)
        }
        this.getLiveEventRecommendList(liveEventDetail.categoryId);
      }
    })
  }
  // 焦点记忆
  focusMemory() {
    if (getStorage("liveEventFocalElements") && document.getElementById(getStorage("liveEventFocalElements"))) {
      $tv.requestFocus(document.getElementById(getStorage("liveEventFocalElements")));
      removeStorage("liveEventFocalElements");
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0); // 滚动条返回顶部
        if (this.state.isLivePlay && this.livePlay && this.livePlay.current) {
          $tv.requestFocus(this.livePlay.current);
        } else {
          if (this.liveCollect && this.liveCollect.current) {
            $tv.requestFocus(this.liveCollect.current);
          }
        }
      }, 100);
    }
  }
  //  定时更新直播开始时间及结束时间
  getLiveEventTime() {
    $request.boApi.liveEvent.getLiveEventTime({
      assetId: this.state.assetId
    }).then(res => {
      if (res.data.resultCode == 0) {
        this.setState({
          liveEventStartTime: res.data.data.startTime
        });
        this.playLiveEvent(res.data.data.startTime, res.data.data.endTime);
      }
    })
  }
  //  开始时间及结束时间更新状态
  playLiveEvent(startTime, endTime) {
    if (this.startCountDown) {
      clearInterval(this.startCountDown);
    }
    let currentTime = new Date().getTime();
    // 如果当前时间大于等于开始时间且小于结束时间且时间直播状态是待播状态，到播放时间，请求播放地址进行播放
    if (currentTime >= startTime && currentTime < endTime && this.state.liveEventStatus == GLOBAL.LIVE_EVENT.STATUS.UPCOMING) {
      if (!this.state.isShowBuy) {
        this.setState({
          isLivePlay: true,
        });
        $tv.requestFocus(this.livePlay.current);
      }
      this.setState({
        isReminder: false,
        isUnReminder: false,
        isLiveCollect: true,
        liveIntroHtml: "",
        liveEventTime: "",
        liveEventStatus: GLOBAL.LIVE_EVENT.STATUS.PLAYING,
      });
    } else {
      // 如果当前时间大于结束时间
      if (currentTime >= endTime) {
        this.setState({
          isReminder: false,
          isUnReminder: false,
          isLivePlay: false,
          isLiveCollect: true,
          liveEventStatus: GLOBAL.LIVE_EVENT.STATUS.ENDED,
          liveIntroHtml: "liveEvent.liveEventEndTips",
          liveEventTime: formatDate(startTime, "hh:mm"),
        });
        if (this.startTimeUpdateTimer) {
          clearInterval(this.startTimeUpdateTimer)
        }
      } else {
        // 倒计时为0，显示播放按钮
        if (!downTime(startTime)) {
          if (!this.state.isShowBuy) {
            this.setState({
              isLivePlay: true,
            });
            $tv.requestFocus(this.livePlay.current);
          }
          this.setState({
            isReminder: false,
            isUnReminder: false,
            isLiveCollect: true,
            liveIntroHtml: "",
            liveEventTime: "",
          });
        } else {
          this.setState({
            isLivePlay: false,
            isLiveCollect: true,
            liveIntroHtml: "liveEvent.staringSoon",
          });
          // 判断是否预约通知
          this.getReservationList();
          this.startCountDown = setInterval(() => {
            // 如播放倒计时存在，显示播放倒计时；
            this.setState({
              liveEventTime: downTime(startTime),
            });
          }, 1000)
        }

      }
    }
  }

  //  订阅提醒
  handleSubscriptionReminder() {
    $request.boApi.commont.subscriptionReminder({
      contentId: this.state.assetId,
      assetId: this.state.assetId,
      type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
      action: "reserve",
      reservationTime: formatDate(parseInt(this.state.liveEventStartTime))
    }).then(res => {
      console.log(res.data)
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        this.setState({
          isReminder: true,
          isUnReminder: false,
        });
        $tv.requestFocus(this.reminder.current);
        setTimeout(() => {
          this.getReservationList('reserve', this.state.assetId);
        }, 300)
      }
    })
  }

  //  取消订阅提醒
  handleCancelSubscriptionReminder() {
    $request.boApi.commont.subscriptionReminder({
      contentId: this.state.assetId,
      assetId: this.state.assetId,
      type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
      action: "cancel",
      reservationTime: formatDate(parseInt(this.state.liveEventStartTime))
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        this.setState({
          isReminder: false,
          isUnReminder: true,
        });
        $tv.requestFocus(this.unReminder.current);
        setTimeout(() => {
          this.getReservationList('cancel', this.state.assetId);
        }, 300)
      }
    })
  }
  // 获取点播推荐
  getLiveEventRecommendList(categoryId) {
    $request.boApi.liveEvent.getLiveEventRecommendList({
      page: 1,
      pageSize: -1,
      categoryId: categoryId,
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        let liveEventsList = res.data.data.liveEvents;
        liveEventsList = liveEventsList.filter(item => item.assetId != this.state.assetId)
        this.setState({
          recommondData: liveEventsList,
        })
      }
      this.focusMemory()
    })
  }
  //  获取节目单预约列表
  getReservationList(type, assetId) {
    $request.boApi.user.getReservationList({
      page: 1,
      pageSize: -1,
      type: "liveevent",
    }).then(res => {
      // if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
      let liveEventsNotice = res.data.contents || [];
      if (liveEventsNotice.length) {
        for (var i = 0; i < liveEventsNotice.length; i++) {
          if (liveEventsNotice[i].assetId == this.state.assetId) {
            this.setState({
              isReminder: true,
              isUnReminder: false,
            });
          }
        }
      }
      if (assetId && type) {
        let reservationItem = '';
        liveEventsNotice.forEach(item => {
          if (item.assetId == assetId) {
            reservationItem = item;
          }
        })
        console.log(liveEventsNotice)
        if (type == "cancel" && assetId) {
          let playList = getStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST) || [];
          playList.forEach((item, index) => {
            if (item.assetId == assetId) {
              playList.splice(index, 1);
            }
          })
          setStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST, playList);
        }
        if (type == "reserve" && reservationItem) {
          let playList = getStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST) || [];
          let hasEvent = false;


          playList.forEach(item => {
            if (item.assetId == reservationItem.assetId) {
              hasEvent = true;
            }
          })
          if (!hasEvent) {
            playList.push(reservationItem);
          }
          setStorage(GLOBAL.STORAGE_KEYS.RESERVATION_LIST, playList);
        }
      }
      // }
    })
  }
  // 收藏
  clickFavorites() {
    // 1 收藏  0 取消收藏
    $request.boApi.user.updataFavorites({
      assetId: this.state.assetId,
      type: GLOBAL.MEDIA_TYPE.LIVE_EVENT,
      action: this.state.flag
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.USER_NOT_LOGIN) {
        this.setState({
          isSignIn: true,
        });
        return;
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (this.state.flag == 1) {
          this.setState({
            flag: 0,
            liveCollect: require("../../assets/images/icon/vod_saved.png"),
          });
        } else {
          this.setState({
            flag: 1,
            liveCollect: require("../../assets/images/icon/vod_save.png"),
          });
        }
      }
    })
  };

  //播放鉴权
  getPlayAuth(contentId_params, assetId,) {
    this.setState({
      isShowSignOut: false,
      popupText: "",
    });
    $request.boApi.common.getPlayAuth({
      mac: getStorage(GLOBAL.STORAGE_KEYS.UUID),
      contentId: contentId_params,
      eventAssetId: assetId,
      type: GLOBAL.MEDIA_TYPE.LIVE_EVENT_CHANNEL,
      playType: "",
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.USER_NOT_LOGIN) {
        this.setState({
          isSignIn: true,
        });
        return;
      }
      // 播放地区限制
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_AREA_LIMIT) {
        this.setState({
          isShowSignOut: true,
          popupText: "toast.areaFail",
        });
        return;
      }
      // 播放设备限制
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
        this.setState({
          isShowSignOut: true,
          popupText: "toast.dedviceLimit",
        });
        return;
      }
      // 播放地址不存在
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.NOT_EXIST) {
        this.setState({
          isShowSignOut: true,
          popupText: "toast.addressExist",
        });
        return;
      }
      //需要购买
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.NEED_BY) {
        // 如果当前时间已结束，隐藏购买
        if (this.state.liveEventStatus == GLOBAL.LIVE_EVENT.STATUS.ENDED) {
          this.setState({
            isShowBuy: false,
          });
        } else {
          this.setState({
            isShowBuy: true,
          });
          $tv.requestFocus(this.buyBtn.current);
        }
        this.setState({
          isLivePlay: false,
        });
      }
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.DRM_TOKEN_ERROR) {
        this.setState({
          isShowSignOut: true,
          playerTips: "toast.drmTokenError"
        })
      }
      if (res.data.resultCode === GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        this.setState({
          isShowBuy: false,
          playInfo: res.data
        });
        // localStorage.setItem('playInfo', JSON.stringify(res.data));
      }
    });
  };

  /**
 * 校验年龄方法
*/
  verifyAge(time, contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!contentDetail.contentGradeDto) {
      return
    }
    if (calculateAge(time) < contentDetail.contentGradeDto.age) {
      this.setState({
        isToolTipBox: true,
        toolTipType: "mature"
      });
      return
    }
    if (contentDetail.contentGradeDto.parentControl == "Y") {
      this.setState({
        email: user.email,
      });
      if (user.parentControlPin == GLOBAL.CONFIG.DEFAULT_PIN) {
        this.setState({
          isToolTipBox: true,
          toolTipType: "pin"
        });
      } else {
        this.setState({
          isVerifyParent_play: true,
          isToolTipBox: false,
          isPinBox: true
        });
      }
      return
    }
    this.setState({
      isVerifyParent_play: true,
    });
    return true;
  }

  verfiyContentGradeDto(contentDetail) {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (contentDetail.loginLimit == "N") {
      return true;
    }
    if (contentDetail.loginLimit == "Y" && !user) {
      this.setState({
        isSignIn: true,
      });
      return
    }
    if (contentDetail.contentGradeDto) {
      if (user.birthday) {
        if (this.verifyAge(user.birthday, contentDetail)) {
          return true
        }
        return false
      }
      this.recordFocusEl = getFocusedElement();
      this.setState({
        isSendCodeOfBirthday: true,
        // showBirthdaySetDialog: true,
        age: contentDetail.contentGradeDto.age,
      });
      return false
    }
    return true
  }

  /**
   * 设置生日
   */
   handleSetBirthday() {
    $request.boApi.user.getMemberInfo({
      mac: getUUID()
    }).then(res => {
      if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
        if (res.data) {
          setStorage(GLOBAL.STORAGE_KEYS.USER_INFO, res.data.userInfo);
          this.setState({
            showBirthdaySetDialog: false
          }, () => {
            if (this.recordFocusEl) {
              $tv.requestFocus(this.recordFocusEl);
            }
          })
        }
      }
    })
  }


  // 播放获焦
  livePlayFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  livePlayMouseEnter() {
    $tv.requestFocus(this.livePlay.current);
  }

  livePlayClick() {
    if (!this.state.isVerifyParent_play) {
      if (!this.verfiyContentGradeDto(this.state.liveEventDetail)) {
        return
      }
    }
    this.props.history.push(`/eventPlayer/${this.state.liveEventDetail.assetId}/`);
  }

  // 订阅获焦
  reminderFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  reminderMouseEnter() {
    $tv.requestFocus(this.reminder.current);
  }

  reminderClick() {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!user) {
      this.setState({
        isSignIn: true,
      });
      return;
    }
    this.handleCancelSubscriptionReminder()
  }

  // 取消订阅获焦
  unReminderFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  unReminderMouseEnter() {
    $tv.requestFocus(this.unReminder.current);
  }

  unReminderClick() {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!user) {
      this.setState({
        isSignIn: true,
      });
      return;
    }
    this.handleSubscriptionReminder()
  }



  // 收藏获焦
  liveCollectFocus() {
    window.scrollTo(0, 0); // 滚动条返回顶部
  }
  liveCollectMouseEnter() {
    $tv.requestFocus(this.liveCollect.current);
  }
  liveCollectClick() {
    // 收藏
    this.clickFavorites();
  }

  /**
   * 订阅点击
   * @returns 
   */
  handleBuyClick() {
    let user = getStorage(GLOBAL.STORAGE_KEYS.USER_INFO);
    if (!user) {
      this.setState({
        isSignIn: true,
      });
      return;
    }
    if (getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO)) {
      if (getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) == GLOBAL.DEVICE_TYPE.LG_TV) {
        this.setState({
          isShowPurchase: getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).lgTVBuySwitch,
          isShowPurchaseTipDialog: !getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).lgTVBuySwitch
        }, () => {
          if (this.state.isShowPurchase) {
            setStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD, window.location.href);
            this.props.history.push(`/package?contentId=${this.state.liveEventDetail.channelAssetId}&eventAssetId=${this.state.liveEventDetail.assetId}&type=${GLOBAL.MEDIA_TYPE.LIVE_EVENT}`)
          }
          return
        })
      } else if (getStorage(GLOBAL.STORAGE_KEYS.DEVICE_TYPE) == GLOBAL.DEVICE_TYPE.SAMSUNG_TV) {
        this.setState({
          isShowPurchase: getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).samsungTVBuySwitch,
          isShowPurchaseTipDialog: !getStorage(GLOBAL.STORAGE_KEYS.SYSTEM_INFO).samsungTVBuySwitch
        }, () => {
          if (this.state.isShowPurchase) {
            setStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD, window.location.href);
            this.props.history.push(`/package?contentId=${this.state.liveEventDetail.channelAssetId}&eventAssetId=${this.state.liveEventDetail.assetId}&type=${GLOBAL.MEDIA_TYPE.LIVE_EVENT}`)
          }
          return
        })
      }
      if (this.state.isShowPurchase) {
        setStorage(GLOBAL.STORAGE_KEYS.CURRENT_PATH_RECORD, window.location.href);
        this.props.history.push(`/package?contentId=${this.state.liveEventDetail.channelAssetId}&eventAssetId=${this.state.liveEventDetail.assetId}&type=${GLOBAL.MEDIA_TYPE.LIVE_EVENT}`)
      }
    }
  }

  handlePurchaseDialogCancel(data) {
    this.setState({
      isShowPurchaseTipDialog: false
    }, () => {
      $tv.requestFocus(this.buyBtn.current)
      
    })
  }

  handleSignOut(data) {
    switch (data.type) {
      case 'cancel':
        this.setState({
          isShowSignOut: false
        }, () => {
          window.history.back();
          // $tv.requestFocus(this.liveCollect.current);
        })
        break
    }
  }

  columnClick(propData, childData) {
    setStorage("liveEventFocalElements", childData.assetId);
    this.props.history.push(`/liveEventDetail/${childData.assetId}`);
  }

  renderRecommend(data, id, style) {
    return <Column data={data} key={id} id={data.assetId} showTitle={true} itemStyle={style} click={(propData, childData) => {
      this.columnClick(propData, childData)
    }} />
  }
  // 适配鼠标滑轮
  pageWheel(e) {
    clearTimeout(this.pageWheelTime);
    this.pageWheelTime = setTimeout(() => {
      if (e.deltaY > 0) {
        $tv.setFocus('down');
      } else {
        $tv.setFocus('up');
      }
    }, 300);
  }

  render() {
    const { liveEventDetail, contentGradeDto, recommondData } = this.state;
    return (
      <div className="live_content" onWheel={e => this.pageWheel(e)}>
        <img className="root_bg_img" src={liveEventDetail.poster} alt="" />
        <img className="live_content_bg" src={this.state.liveContentBgImg.default} alt="" />
        <div className="live_container">
          <div className="live_title">{liveEventDetail.name}</div>
          <div className="tag-list">
            {
              contentGradeDto.map((item, index) => (
                <div className="age-tag" key={index}>{item}</div>
              ))
            }
          </div>
          <div className="live_intro" style={{ display: this.state.liveIntroHtml ? '-webkit-box' : 'none' }} > {this.props.t(this.state.liveIntroHtml)} </div>
          <div className="countdown">{this.state.liveEventTime}</div>
          <div className="live_control">
            <div ref={this.buyBtn} id="buyBtn" style={{ display: this.state.isShowBuy ? 'flex' : 'none' }} className="buy-btn" focusable>{this.props.t('vod.Rental')}</div>
            <div ref={this.reminder} id="reminderBtn" style={{ display: this.state.isReminder ? 'flex' : 'none' }} className="live_notice" focusable>
              <img src={this.state.reminder.default} alt="" className="live_play_icon" />
            </div>
            <div ref={this.unReminder} style={{ display: this.state.isUnReminder ? 'flex' : 'none' }} className="live_notice" focusable>
              <img src={this.state.unReminder.default} alt="" className="live_play_icon" />
            </div>
            <div ref={this.livePlay} className="live_play" id='livePlayBtn' style={{ display: this.state.isLivePlay ? 'flex' : 'none' }} focusable>
              <img src={this.state.livePlay.default} alt="" className="live_play_icon" />
              <div className="live_play_text">{this.props.t('vod.Play')}</div>
            </div>
            <div ref={this.liveCollect} className="live_collect" id='liveCollectBtn' style={{ display: this.state.isLiveCollect ? 'flex' : 'none' }} focusable>
              <img src={this.state.liveCollect.default} alt="" className="live_play_icon" />
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="content detail-list_content" style={{ display: recommondData.length ? 'block' : 'none' }}>
            <div className='category_title'> {this.props.t('vod.Recommend')}</div>
            {
              this.renderRecommend(recommondData, 'liveEvent', GLOBAL.RECOMMEND_STYLE.HORIZONTAL)
            }
          </div>

        </div>
        <SignIn isSignIn={this.state.isSignIn} signInGetMsg={this.signInGetMsg} />
        <SendCodeOfBirthday isSendCodeOfBirthday={this.state.isSendCodeOfBirthday} age={this.state.age} SendCodeOfBirthdayGetMsg={this.SendCodeOfBirthdayGetMsg} />
        <SendCodeOfEmail isSendCodeOfEmail={this.state.isSendCodeOfEmail} email={this.state.email} SendCodeOfEmailGetMsg={this.SendCodeOfEmailGetMsg} />
        <ToolTipBox isToolTipBox={this.state.isToolTipBox} toolTipType={this.state.toolTipType} toolTipBoxGetMsg={this.toolTipBoxGetMsg} />
        <UpdatePin isUpdatePin={this.state.isUpdatePin} updateState={this.state.updateState} parentControlPin={this.state.parentControlPin} />
        <PinBox isPinBox={this.state.isPinBox} pinBoxStatus={this.state.pinBoxStatus} verifyPinGetMsg={this.verifyPinGetMsg} />
        <Popup show={this.state.isShowPurchaseTipDialog} 
        click={(data) => {
          this.handlePurchaseDialogCancel(data)
        }}
        title={this.state.liveEventDetail.name} text={this.props.t("purchase.purchaseTipsToApp")} buttons={
          [
            {
              type: 'cancel',
              text: this.props.t("button.cancel")
            }
          ]
        }/>
        <Popup show={this.state.isShowSignOut} title={this.props.t("user.Notifications")} text={this.props.t(this.state.popupText)} click={(data) => {
          this.handleSignOut(data)
        }} buttons={
          [
            {
              type: 'cancel',
              text: this.props.t("button.cancel")
            }
          ]
        } />
        <Dialog show={ this.state.showBirthdaySetDialog } age={Number(this.state.age)} confirm={() => {
          this.handleSetBirthday()
        }} />
      </div>
    );
  }
});
