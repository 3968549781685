import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ColumnItem from '../columnItem/ColumnItem';
import { getElementWidthWithMargin } from '../../util/util'
import { $tv } from 'react-tv-focusable';
import PropTypes from 'prop-types';
import GLOBAL from '../../util/global';
import './style/Column.scss';

class Column extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

        // $tv.requestFocus(this.testEl.current);
    }
    /**
     * 推荐位内容聚焦
     * @param {*} data 
     */
    focusedItem(data) {
        if (this.props.focusedItem) {
            this.props.focusedItem(data);
        }
    }
    /**
     * 推荐位内容失去焦点
     * @param {*} data 
     */
    blurItem(data) {
        if (this.props.blurItem) {
            this.props.blurItem(data);
        }
    }
    /**
     * 右移
     * @param {*} data 
     */
    scorllRight(data) {
        let index = data.index;
        const { id } = this.props;
        let scorllEl = document.getElementById("column" + id);
        let elWidth = getElementWidthWithMargin(document.getElementById(data.id));
        if (index >= 2 && index < this.props.data.length - 1) {
            scorllEl.style.left = -((index - 2) * elWidth) + 'px'
        } else {
            if (index == this.props.data.length - 1 && document.getElementById(data.id)) {
                $tv.requestFocus(document.getElementById(data.id));
                document.getElementById(data.id).classList.add("levelShake");
                setTimeout(() => {
                    if (document.getElementById(data.id)) {
                        document.getElementById(data.id).classList.remove("levelShake");
                    }
                }, 1000)
            }
            // console.log(index)
        }
    }
    /**
     * 左移
     * @param {*} data 
     */
    scorllLeft(data) {
        const { id } = this.props;
        let index = data.index;
        let scorllEl = document.getElementById("column" + id);
        let elWidth = getElementWidthWithMargin(document.getElementById(data.id));
        if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
            scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
        } else {
            scorllEl.style.left = "0px"
        }
        if (index == 0 && document.getElementById(data.id)) {
            $tv.requestFocus(document.getElementById(data.id));
            document.getElementById(data.id).classList.add("levelShake");
            setTimeout(() => {
                if (document.getElementById(data.id)) {
                    document.getElementById(data.id).classList.remove("levelShake");
                }
            }, 1000)
        }
    }
    /**
     * 下移
     * @param {*} data 
     * @param {*} index 
     */
    scorllUp(data, index) {
        const { id } = this.props;
        let scorllEl = document.getElementById("column" + id);
        scorllEl.style.left = "0px"
        if (this.props.scorllUp) {
            this.props.scorllUp(this.props, data, index);
        }
    }
    /**
     * 下移
     * @param {*} data 
     * @param {*} index 
     */
    scorllDown(data, index) {
        const { id } = this.props;
        let scorllEl = document.getElementById("column" + id);
        scorllEl.style.left = "0px"
        if (this.props.scorllDown) {
            this.props.scorllDown(this.props, data, index);
        }
    }
    /**
     * 推荐位内容点击事件
     * @param {*} data 
     * @param {*} index 
     */
    clickItem(data, index) {
        const { id } = this.props;
        let scorllEl = document.getElementById("column" + id);
        // console.log(parseInt(scorllEl.offsetLeft))
        if (this.props.click) {
            this.props.click({...this.props, x: parseInt(scorllEl.offsetLeft), scorllEl: `column${id}` }, data.data ? {...data.data, x: parseInt(scorllEl.offsetLeft), scorllEl: `column${id}`} : {...data, x: parseInt(scorllEl.offsetLeft), scorllEl: `column${id}`}, index);
        }
    }

    render() {
        const { id, showTitle, itemStyle, pathConfig } = this.props;
        return (<div className='column'>
            <div className='column-main' id={'column' + id}>
                {
                    this.props.data.map((item, index) => (
                        <ColumnItem focused={(propData) => {
                            this.focusedItem(propData)
                        }} 
                        blur={(propData) => {
                            this.blurItem(propData)
                        }}
                        itemStyle={itemStyle} showTitle={showTitle} key={index} name={item.title || item.name || item.seasonsName} scorllLeft={(propData) => {
                            this.scorllLeft(propData)
                        }} scorllRight={(propData) => {
                            this.scorllRight(propData);
                        }} scorllUp={(propData, index) => { this.scorllUp(propData, index) }} scorllDown={(propData, index) => { this.scorllDown(propData, index) }} click={(propData, index) => {
                            this.clickItem(propData, index)
                        }} data={item} pathConfig={pathConfig} markIcon={item.markUrl} index={index} markPosition={item.markPosition || ""} id={item.id || item.url || item.contentId || item.assetId || item.seasonsAssetId} />
                    ))
                }
            </div>

        </div>)
    }
}

Column.propTypes = {
    id: PropTypes.any, // 推荐位唯一ID
    showTitle: PropTypes.string, // 是否显示标题，Y/N
    itemStyle: PropTypes.string, // 行样式
}

export default withTranslation()(Column);