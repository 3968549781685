import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getStorage, scrollTextOnFocus, findChildNodeDeep, getUUID, isObject, formatDate, getElementWidthWithMargin, keycodeHandle } from '../../util/util';
import './style/Subscription.scss';
import $request from '../../api';
import GLOBAL from '../../util/global';
import Popup from '../../components/popup/popup';

export default withTranslation()(class Subscription extends Component {
    constructor(props) {
        super(props);
        this.renewBtnRef = React.createRef();
        this.packageWrapperRef = React.createRef();
        this.packagesRef = []; // 订阅包节点列表
        this.pageWheelTimer = null; // 鼠标滚轮监听延时器
        this.state = {
            userInfo: null, // 用户信息
            subscriptions: [], // 订阅包列表
            isShowUnsubscribe: false, // 是否显示取消订阅
            currentSelectPackage: null, // 当前选中订阅包
        }
    }

    componentWillMount() {
        if (getStorage("userInfo")) {
            this.setState({
                userInfo: getStorage("userInfo")
            })
        }
        this.getCurrentSubscribe();
    }

    componentDidMount() {
        $tv.requestFocus(this.renewBtnRef.current);
        this.handleRenewListener();
        keycodeHandle({
            exit: this.handleExit.bind(this),
            quit: this.handleExit.bind(this),
        });
    }

    componentWillUnmount() {
        this.handlePackageRemoveListener();
        this.handleRenewRemoveListener();
        clearTimeout(this.pageWheelTimer);
    }

    handleExit() {
        window.history.back(-1);
    }

    /**
     * 获取当前订阅包
     */
    getCurrentSubscribe() {
        $request.boApi.product.getCurrentSubscribe({
            mac: getUUID()
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.subscriptions) {
                    // console.log(res.data, isObject(res.data.subscriptions))
                    this.setState({
                        subscriptions: isObject(res.data.subscriptions) ? [res.data.subscriptions] : res.data.subscriptions
                    }, () => {
                        this.handlePackageListener();
                    });
                }
            }
        })
    }

    /**
     * 更多包事件监听
     */
    handleRenewListener() {
        this.renewBtnRef.current.addEventListener("click", () => {
            this.props.history.push(`/package`);
         });
        this.renewBtnRef.current.addEventListener("down", () => {
            if (this.packagesRef && this.packagesRef.length) {
                $tv.requestFocus(this.packagesRef[0]);
            }
        })
    }

    /**
     * 移除更多包事件监听
     */
    handleRenewRemoveListener() {
        this.renewBtnRef.current.removeEventListener("click", () => { });
        this.renewBtnRef.current.removeEventListener("down", () => { });
    }

    /**
     * 当前包事件监听
     */
    handlePackageListener() {
        if (this.packagesRef && this.packagesRef.length) {
            let scorllEl = this.packageWrapperRef.current;
            this.packagesRef.forEach((el, index) => {
                el.addEventListener("click", () => {
                    if (this.state.subscriptions[index].productTime.isCancel == 'N' && this.state.subscriptions[index].product.productCode != 'FREE') {
                        this.setState({
                            currentSelectPackage: {
                                ...this.state.subscriptions[index],
                                index
                            },
                            isShowUnsubscribe: true
                        });
                    }
                })
                el.addEventListener("onFocus", () => {
                    // console.log(findChildNodeDeep(el, '.package-name'))
                    let titleEl = findChildNodeDeep(el, '.package-name');
                    if (titleEl) {
                        this.scrollHandler = scrollTextOnFocus(titleEl, {
                            duration: 2, // 自定义滚动持续时间
                            autoScroll: true // 聚焦时自动滚动
                        });
                        this.scrollHandler.startScroll();
                    }
                });
                el.addEventListener("onBlur", () => {
                    this.scrollHandler.resetScroll();
                });
                el.addEventListener("left", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
                        scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
                    } else {
                        scorllEl.style.left = "0px"
                    }
                    if (index == 0 && el) {
                        $tv.requestFocus(el);
                        el.classList.add("levelShake");
                        setTimeout(() => {
                            if (el) {
                                el.classList.remove("levelShake");
                            }
                        }, 1000)
                    }
                })
                el.addEventListener("right", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    if (index >= 1 && index < this.state.subscriptions.length - 1) {
                        scorllEl.style.left = -(((index - 1) || 1) * elWidth) + 'px';
                    } else {
                        if (index == this.state.subscriptions.length - 1 && el) {
                            $tv.requestFocus(el);
                            el.classList.add("levelShake");
                            setTimeout(() => {
                                if (el) {
                                    el.classList.remove("levelShake");
                                }
                            }, 1000)
                        }
                    }
                })
                el.addEventListener("up", () => {
                    $tv.requestFocus(this.renewBtnRef.current);
                })
                el.addEventListener("down", () => {
                    $tv.requestFocus(el);
                    el.classList.add("verticalShake");
                    setTimeout(() => {
                        if (el) {
                            el.classList.remove("verticalShake");
                        }
                    }, 1000)
                })
            })
        }
    }

    /**
    * 当前包移除事件监听
    */
    handlePackageRemoveListener() {
        if (this.packagesRef && this.packagesRef.length) {
            this.packagesRef.forEach((el, index) => {
                el.removeEventListener("click", () => { });
                el.removeEventListener("left", () => { });
                el.removeEventListener("right", () => { });
                el.removeEventListener("up", () => { });
                el.removeEventListener("down", () => { });
            })
        }
    }

    /**
     * 取消自定续订
     * @param {*} data 
     */
    handleUnsubscribe(data) {
        switch (data.type) {
            case 'ok':
                this.handleConfirmUnsubscribe();
                break;
            case 'cancel':
                this.setState({
                    isShowUnsubscribe: false,
                }, () => {
                    $tv.requestFocus(this.packagesRef[this.state.currentSelectPackage.index]);
                })
                break
        }
    }

    /**
    * 确认取消自定续订
    */
    handleConfirmUnsubscribe() {
        const { currentSelectPackage } = this.state;
        console.log(currentSelectPackage)
        $request.boApi.payment.paymentUnsubPackage({
            orderId: currentSelectPackage.productTime.orderNo,
            payType: currentSelectPackage.productTime.paymentMethod,
            subscribeId: currentSelectPackage.productTime.subscriptionId
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                this.handlePackageRemoveListener();
                this.getCurrentSubscribe();
                this.setState({
                    isShowUnsubscribe: false
                }, () => {
                    $tv.requestFocus(this.renewBtnRef.current);
                })
            }
        })
    }

    /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
     pageWheel(e) {
        clearTimeout(this.pageWheelTimer);
        this.pageWheelTimer = setTimeout(() => {
            if (e.deltaY > 0) {
                $tv.findFocusType = 0;
                $tv.initDis = 10;
                $tv.setFocus('down');
            } else {
                $tv.findFocusType = 0;
                $tv.initDis = 80;
                $tv.setFocus('up');
            }
        }, 300);
    }

    render() {
        const { userInfo, subscriptions, isShowUnsubscribe, currentSelectPackage } = this.state;
        return (<div className='subscription-page' onWheel={e => this.pageWheel(e)}>
            <div className='page-header'>
                <div className='page-icon'>
                    <img src={require('../../assets/images/icon/payment_subscription.png').default} />
                </div>
                <div className='page-title'>{this.props.t("purchase.subscription")}</div>
            </div>
            <div className='subscription-wrapper'>

                <div className='subscription-info_btn'>
                    <div className='user-info'>
                        <div className='user-avater'>
                            <img src={require('../../assets/images/icon/atv_me_icon_userhead.png').default} />
                        </div>
                        <div className='nick-name'>
                            {this.props.t("purchase.currentAccout")}
                            {userInfo ? (userInfo.nickName || userInfo.name) : ""}
                        </div>
                        <div className='vip-icon'>
                            <img src={require('../../assets/images/icon/package_icon_vip.png').default} />
                        </div>
                    </div>
                    <div className='renew-btn' ref={this.renewBtnRef} focusable>{this.props.t("purchase.morePackage")}</div>
                </div>
                <div className='package-list'>
                    <div className='package-wrapper' ref={this.packageWrapperRef}>
                        {
                            subscriptions.map((item, index) => (
                                <div className='package-item' key={index} ref={(el) => { this.packagesRef[index] = el }} focusable>
                                    <div className='package-icon'>
                                        {
                                            (item.product.poster || item.product.vPoster) ? <img src={item.product.poster || item.product.vPoster} /> : null
                                        }

                                    </div>
                                    <div className='package-info'>
                                        <div className='package-name'>{item.product.productName}</div>
                                        <div className='expire-on'>{this.props.t("purchase.expireOn")} {item.productTime.expiredTimeUTC ? formatDate(item.productTime.expiredTimeUTC, 'yyyy-MM-dd') : ''}</div>
                                        <div className='unsubscribe-btn' style={{ display: item.productTime.isCancel == "N" && item.product.productCode != "FREE" ? 'block' : 'none' }}>{this.props.t("purchase.unsubscribe")}</div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
            <Popup show={isShowUnsubscribe} click={(data) => {
                this.handleUnsubscribe(data)
            }} title={currentSelectPackage ? currentSelectPackage.product.productName : ""} text={this.props.t("toast.unSubscriptionTips")} buttons={
                [
                    {
                        type: 'ok',
                        text: this.props.t("button.sure")
                    },
                    {
                        type: 'cancel',
                        text: this.props.t("button.cancel")
                    }
                ]
            } />
        </div>)
    }
})