import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { addZero, getUUID, setStorage, keycodeHandle } from '../../util/util';
import projectConfig from '../../util/config';
import Alert from '../../components/alert/alert';
import $request from '../../api';
import QRCode from 'qrcode.react';
import GLOBAL from '../../util/global';
import './style/QRLogin.scss';

function StringBuffer() {
    this.__strings__ = [];
}

StringBuffer.prototype.append = function (str) {
    this.__strings__.push(str);
};
StringBuffer.prototype.toString = function () {
    return this.__strings__.join('');
};

export default withTranslation()(class QRLogin extends Component {
    constructor(props) {
        super(props);
        this.cancelBtnRef = React.createRef(); // 取消登录按钮节点
        this.updateQRCodeTimer = null; // 更新二维码定时器
        this.loginResultTimer = null; // 轮询登录结果定时器
        this.state = {
            qrCodeUrl: '',
            resultCount: 1, // 获取结果次数
            alertText: "", // 提醒弹窗文字
            isShowAlert: false // 是否显示alert
        }
    }

    componentWillMount() {
        this.setState({
            qrCodeUrl: this.QRencode(this.strToUtf8Bytes(JSON.stringify({
                mac: getUUID(),
                timestamp: this.getFormatDate(),
                version: projectConfig.version,
                tenantCode: projectConfig.tenantCode,
                action: 0
            })))
        })
        this.updateQRCodeTimer = setInterval(() => {
            this.setState({
                qrCodeUrl: this.QRencode(this.strToUtf8Bytes(JSON.stringify({
                    mac: getUUID(),
                    timestamp: this.getFormatDate(),
                    version: projectConfig.version,
                    tenantCode: projectConfig.tenantCode,
                    action: 0
                })))
            })
        }, 1 * 60 * 1000)
    }

    componentDidMount() {
        $tv.requestFocus(this.cancelBtnRef.current);
        if (this.loginResultTimer) {
            clearInterval(this.loginResultTimer);
        }
        this.loginResultTimer = setInterval(() => {
            if (this.state.resultCount >= 50) {
                clearInterval(this.loginResultTimer);
                this.loginResultTimer = null;
                return
            }
            let resultCount = this.state.resultCount
            this.setState({
                resultCount: resultCount +  1
            }, () => {
                this.getInteractiveResult();
            })
        }, 2000);
        this.handleClickCancel();
        keycodeHandle({
            exit: this.handleExit.bind(this),
            quit: this.handleExit.bind(this),
        });
    }

    componentWillUnmount() {
        if (this.updateQRCodeTimer) {
            clearInterval(this.updateQRCodeTimer);
            this.updateQRCodeTimer = null;
        }
        if (this.loginResultTimer) {
            clearInterval(this.loginResultTimer);
            this.loginResultTimer = null;
        }
    }

    handleClickCancel() {
        if (this.cancelBtnRef && this.cancelBtnRef.current) {
            this.cancelBtnRef.current.addEventListener('click', () => {
                this.handleExit();
            })
        }
    }

    handleExit() {
        window.history.back(-1);
    }

    strToUtf8Bytes(str) {
        const utf8 = [];
        for (let ii = 0; ii < str.length; ii++) {
            let charCode = str.charCodeAt(ii);
            if (charCode < 0x80) utf8.push(charCode);
            else if (charCode < 0x800) {
                utf8.push(0xc0 | (charCode >> 6), 0x80 | (charCode & 0x3f));
            } else if (charCode < 0xd800 || charCode >= 0xe000) {
                utf8.push(0xe0 | (charCode >> 12), 0x80 | ((charCode >> 6) & 0x3f), 0x80 | (charCode & 0x3f));
            } else {
                ii++;
                // Surrogate pair:
                // UTF-16 encodes 0x10000-0x10FFFF by subtracting 0x10000 and
                // splitting the 20 bits of 0x0-0xFFFFF into two halves
                charCode = 0x10000 + (((charCode & 0x3ff) << 10) | (str.charCodeAt(ii) & 0x3ff));
                utf8.push(
                    0xf0 | (charCode >> 18),
                    0x80 | ((charCode >> 12) & 0x3f),
                    0x80 | ((charCode >> 6) & 0x3f),
                    0x80 | (charCode & 0x3f),
                );
            }
        }
        //兼容汉字，ASCII码表最大的值为127，大于127的值为特殊字符
        for (let jj = 0; jj < utf8.length; jj++) {
            var code = utf8[jj];
            if (code > 127) {
                utf8[jj] = code - 256;
            }
        }
        return utf8;
    }

    /**
     * 获取年月日时分秒格式化
     * @returns 
     */
    getFormatDate() {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hh = date.getHours();
        var mm = date.getMinutes();
        var ss = date.getSeconds();
        month = addZero(month);
        day = addZero(day);
        hh = addZero(hh);
        mm = addZero(mm);
        ss = addZero(ss);
        return year + '-' + month + '-' + day + ' ' + hh + ':' + mm + ':' + ss
    }

    QRencode(data) {
        let base64EncodeChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
            'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
            'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6',
            '7', '8', '9', '+', '/'
        ];
        let sb = new StringBuffer();
        let len = data.length;
        let i = 0;

        while (i < len) {
            let b1 = data[i++] & 255;
            if (i == len) {
                sb.append(base64EncodeChars[b1 >>> 2]);
                sb.append(base64EncodeChars[(b1 & 3) << 4]);
                sb.append("==");
                break;
            }

            let b2 = data[i++] & 255;
            if (i == len) {
                sb.append(base64EncodeChars[b1 >>> 2]);
                sb.append(base64EncodeChars[(b1 & 3) << 4 | (b2 & 240) >>> 4]);
                sb.append(base64EncodeChars[(b2 & 15) << 2]);
                sb.append("=");
                break;
            }

            let b3 = data[i++] & 255;
            sb.append(base64EncodeChars[b1 >>> 2]);
            sb.append(base64EncodeChars[(b1 & 3) << 4 | (b2 & 240) >>> 4]);
            sb.append(base64EncodeChars[(b2 & 15) << 2 | (b3 & 192) >>> 6]);
            sb.append(base64EncodeChars[b3 & 63]);
        }

        return sb.toString();
    }

    /**
     * 获取二维码扫码结果
     */
    getInteractiveResult() {
        $request.boApi.user.getInteractiveResult({
            mac: getUUID(),
            action: 0
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                setStorage("token", res.data.token);
                setStorage("userInfo", res.data.userInfo);
                window.history.back();
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.PLAY_DEVICE_LIMIT) {
                this.setState({
                    alertText: "",
                    isShowAlert: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false,
                            alertText: this.props.t("toast.loginDeviceLimit")
                        })
                    }, 3000)
                })
            } else if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.FIREBASE_LOGIN_ERROR) {
                this.setState({
                    alertText: "",
                    isShowAlert: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            isShowAlert: false,
                            alertText: this.props.t("toast.firebaseError")
                        })
                    }, 3000)
                })
            }
        })
    }

    render() {
        return (<div className='qr-login_page'>
            <div className='login-left_wrapper'>
                <img src={require('../../assets/images/icon/login_scan_phone.png').default} />
            </div>
            <div className='login-right_wrapper'>
                <div className='scan-qr_title'>
                    {this.props.t("login.signInByQR")}
                </div>
                <div className='scan-qr_step'>
                    <div className='step-item'>{this.props.t("login.scanQRStep1")}</div>
                    <div className='step-item'>{this.props.t("login.scanQRStep2")}</div>
                    <div className='step-item'>{this.props.t("login.scanQRStep3")}</div>
                </div>
                <div className='qr-code'>
                    <QRCode className="qr-code_img" size={200} value={this.state.qrCodeUrl} />
                </div>
                <div className='cancel-btn' ref={this.cancelBtnRef} focusable>{this.props.t("button.cancel")}</div>
            </div>
            <Alert text={this.state.alertText} type={'error'} show={this.state.isShowAlert} />
        </div>)
    }
})