import React from 'react';

const ModalContext = React.createContext();

class ModalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modals: [],
    };
  }

  addModal = (id, title, description) => {
    const exists = this.state.modals.some(modal => modal.id === id);
    if (!exists) {
      this.setState((prevState) => {
        const updatedModals = [...prevState.modals, { id, title, description }];
        console.log('Adding modal:', updatedModals); // 调试输出
        return { modals: updatedModals };
      });
    }
  };

  removeModal = (id) => {
    this.setState((prevState) => {
      const updatedModals = prevState.modals.filter(modal => modal.id !== id);
      console.log('Removing modal:', updatedModals); // 调试输出
      return { modals: updatedModals };
    });
  };

  render() {
    return (
      <ModalContext.Provider
        value={{
          modals: this.state.modals,
          addModal: this.addModal,
          removeModal: this.removeModal,
        }}
      >
        {this.props.children}
      </ModalContext.Provider>
    );
  }
}

export { ModalProvider, ModalContext };
