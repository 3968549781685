import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { roundShift, generateDays, isSameDay, getElementHeightWithMargin, deepClone } from "../../../../util/util"
import './style/CategorySelectorDialog.scss';
let flag = true;
export default class CategorySelectorDialog extends Component {
    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.state = {
            categoryList: [],
            checkedIds: [],
            show: false
        }
    }
    componentWillMount() {

    }
    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { data, defaultChecked, show } = nextProps;
        let categorys = deepClone(data);
        categorys.forEach(item => {
            item.checked = false;
            if (defaultChecked.indexOf(item.categoryId) > -1) {
                item.checked = true;
            }
        })
        if (data) {

            this.setState({
                categoryList: categorys,
                checkedIds: defaultChecked,
                show: show
            }, () => {
                if (show) {
                    if (this.state.categoryList.length && flag) {
                        flag = false;
                        $tv.requestFocus(document.getElementById(`categoryItem0`));
                    }
                } else {
                    flag = true;
                    // this.removeListenerCategory();
                }
            })
        }
    }

    listenerCategroy() {
        const categorys = document.querySelectorAll('.category-item');
        categorys.forEach((el, index) => {
            el.addEventListener('down', this.handleCategoryDown.bind(this, index));
            el.addEventListener('up', this.handleCategoryUp.bind(this, index));
            el.addEventListener('click', this.handleCategoryClick.bind(this, index));
            el.addEventListener('right', () => {
                $tv.requestFocus(document.getElementById(`categoryItem${index}`));
            });
            el.addEventListener('left', () => {
                $tv.requestFocus(document.getElementById(`categoryItem${index}`));
            });
        })
        this.confirmBtnRef.current.addEventListener("down", () => {
            $tv.requestFocus(this.confirmBtnRef.current);
        })
        this.confirmBtnRef.current.addEventListener("left", () => {
            $tv.requestFocus(this.confirmBtnRef.current);
        })
        this.confirmBtnRef.current.addEventListener("right", () => {
            $tv.requestFocus(this.confirmBtnRef.current);
        })
        this.confirmBtnRef.current.addEventListener("up", () => {
            console.log(this.state.categoryList.length - 1)
            $tv.requestFocus(document.getElementById(`categoryItem${this.state.categoryList.length - 1}`));
        })
        this.confirmBtnRef.current.addEventListener("click", () => {
            $tv.requestFocus(this.confirmBtnRef.current);
            let checkedItemList = [];
            this.state.categoryList.forEach(item => {
                if (item.checked) {
                    checkedItemList.push(item)
                }
            })
            if (this.props.confirm) {
                this.props.confirm(checkedItemList, this.state.checkedIds);
                document.getElementById("categoryWrapper").style.top = 0;
            }
        })
    }

    removeListenerCategory() {
        const categorys = document.querySelectorAll('.category-item');
        categorys.forEach((el, index) => {
            el.removeEventListener('down', () => { });
            el.removeEventListener('up', () => { });
            el.removeEventListener('click', () => { });
            el.removeEventListener('right', () => { });
            el.removeEventListener('left', () => { });
        })
    }

    handleCategoryDown(index) {
        if (index != this.state.categoryList.length - 1) {
            $tv.requestFocus(document.getElementById(`categoryItem${index + 1}`));
            if (document.getElementById(`categoryItem0`)) {
                if (index > 3) {
                    document.getElementById("categoryWrapper").style.top = -(index * getElementHeightWithMargin(document.getElementById(`categoryItem0`))) + 'px';
                }
            }
        } else {
            $tv.requestFocus(this.confirmBtnRef.current);
        }
    }

    handleCategoryUp(index) {
        let scorllEl = document.getElementById("categoryWrapper");
        let elHeight = getElementHeightWithMargin(document.getElementById(`categoryItem0`));
        if (index == 0) {
            $tv.requestFocus(document.getElementById(`categoryItem0`));
        } else {
            if (Math.abs(scorllEl.offsetTop) >= elHeight) {
                document.getElementById("categoryWrapper").style.top = (parseInt(scorllEl.offsetTop) + parseInt(elHeight)) + 'px';
            }

        }
    }

    handleCategoryClick(index) {
        const { categoryList } = this.state;
        let categorys = deepClone(categoryList);
        let checkedIds = [];
        if (categorys[index].checked) {
            categorys[index].checked = false;
        } else {
            categorys[index].checked = true;
        }
        categorys.forEach(item => {
            if (item.checked) {
                checkedIds.push(item.categoryId);
            }
        })
        this.setState({
            categoryList: categorys,
            checkedIds
        }, () => {
            $tv.requestFocus(document.getElementById(`categoryItem${index}`));
        })

    }

    render() {
        const { categoryList, checkedIds, show } = this.state;
        const { title, confirmText } = this.props;
        return (
            <div className='category-selector_dialog' style={{ display: show ? 'flex' : 'none' }}>
                <div className='category-selector_wrapper'>
                    <div className='category-selector_dialog--title'>
                        {title}
                    </div>
                    <div className='category-selector_list'>
                        <div className='category-selector_list--wrapper' id='categoryWrapper'>
                            {
                                categoryList.map((item, index) => (
                                    <div className='category-item' id={'categoryItem' + index} key={index} focusable>
                                        <div className='category-item_name'>{item.categoryName}</div>
                                        <div className='category-checkbox'>
                                            {
                                                checkedIds.indexOf(item.categoryId) > -1 ? <img src={require('../../../../assets/images/icon/atv_setting_icon_select_blue.png').default} /> : <img src={require('../../../../assets/images/icon/atv_setting_icon_select.png').default} />
                                            }

                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                    <div className='category-selector_confirm' focusable ref={this.confirmBtnRef}>
                        {confirmText}
                    </div>
                </div>
            </div>
        )
    }
};