import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import PropTypes from 'react'
import './style/ColumnItem.scss';
import LazyLoad from 'react-lazy-load';
import GLOBAL from '../../util/global';
import PropTypes from 'prop-types';
import { deepClone, generateUrl, scrollTextOnFocus } from '../../util/util';
import { $tv } from 'react-tv-focusable';

class ColumnItem extends Component {
    constructor(props) {
        super(props);
        this.contentItemRef = React.createRef();
        this.titleElRef = React.createRef();
        this.scrollHandler = null; // 滚动
    }

    componentDidMount() {
        // $tv.requestFocus(this.testEl.current);
        this.listenerContentScorll();
    }
    /**
     * 图片加载错误，加载默认图
     * @param {*} e 
     */
    loadImageError(e) {
        const { itemStyle, data } = this.props;
        if (itemStyle == GLOBAL.RECOMMEND_STYLE.VERTICAL) {
            e.target.src = require("../../assets/images/defaultImages/atv_default_274x365.png").default;
        } else if (itemStyle == GLOBAL.RECOMMEND_STYLE.BIG_VERTICAL) {
            e.target.src = require("../../assets/images/defaultImages/atv_default_313x417.png").default;
        } else {
            e.target.src = require("../../assets/images/defaultImages/atv_default_274x168.png").default;
        }
        if (this.props.loadError) {
            this.props.loadError(data);
        }
    }
    /**
     * 事件监听
     */
    listenerContentScorll() {
        this.contentItemRef.current.addEventListener("onFocus", this.handleFocusItem.bind(this));
        this.contentItemRef.current.addEventListener("onBlur", this.handleOnBlurItem.bind(this));
        this.contentItemRef.current.addEventListener("click", this.handleClickItem.bind(this));
        this.contentItemRef.current.addEventListener("right", this.handleRight.bind(this));
        this.contentItemRef.current.addEventListener("left", this.handleLeft.bind(this));
        this.contentItemRef.current.addEventListener("down", this.handleDown.bind(this));
        this.contentItemRef.current.addEventListener("up", this.handleUp.bind(this));
        this.contentItemRef.current.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    }
    /**
     * 聚焦
     */
    handleFocusItem() {
        this.scrollHandler = scrollTextOnFocus(this.titleElRef.current, {
            duration: 2, // 自定义滚动持续时间
            autoScroll: true // 聚焦时自动滚动
        });
        this.scrollHandler.startScroll();
        // let focusedTitleEl = this.titleElRef.current;
        // // 确保文字不换行
        // focusedTitleEl.style.whiteSpace = "nowrap";
        // // 设置横向滚动
        // focusedTitleEl.scrollLeft = 0;
        // focusedTitleEl.scrollIntoView({ behavior: "smooth", inline: "start" });
        if (this.props.focused) {
            this.props.focused(this.props);
        }
    }
    /**
     * 失去焦点
     */
    handleOnBlurItem() {
        this.scrollHandler.resetScroll();
        if (this.props.blur) {
            this.props.blur(this.props);
        }
        // let focusedTitleEl = this.titleElRef.current;
        // // 取消滚动，恢复到初始状态
        // focusedTitleEl.scrollLeft = 0;
    }
    /**
     * 鼠标点击
     */
    handleMouseEnter() {
        if (this.props.focused) {
            const { data, index } = this.props;
            this.props.focused(this.props, index);
        }
        $tv.requestFocus(this.contentItemRef.current);
    }
    /**
     * 点击
     */
    handleClickItem() {
        if (this.props.click) {
            const { data, index } = this.props;
            this.props.click(this.props, index);
        }
    }
    /**
     * 下移
     */
    handleDown() {
        if (this.props.scorllDown) {
            const { data, index } = this.props;
            this.props.scorllDown(this.props, index);
        }
    }
    /**
     * 上移
     */
    handleUp() {
        if (this.props.scorllUp) {
            const { data, index } = this.props;
            this.props.scorllUp(this.props, index);
        }
    }
    /**
     * 右移
     */
    handleRight() {
        if (this.props.scorllRight) {
            const { data, index } = this.props;
            this.props.scorllRight(this.props, index);
        }
    }

    /**
     * 左移
     */
    handleLeft() {
        if (this.props.scorllLeft) {
            const { data, index } = this.props;
            this.props.scorllLeft(this.props, index);
        }
    }

    renderContent() {
        const { children, markIcon, markPosition, itemStyle, id, name, showTitle, data, url, pathConfig } = this.props;
        let pathData = {};
        if (pathConfig) {
            pathData = {
                data,
                ...pathConfig
            }
        } else {
            pathData = null;
        }
        if (itemStyle == GLOBAL.RECOMMEND_STYLE.VERTICAL) {
            return (<div className='vertical-style recommend-item' ref={this.contentItemRef} id={id} focusable>
                {/* <Link key={id} to={generateUrl(pathData)}> */}
                    <LazyLoad threshold={0.45} offset={10} height={'100%'} width={'100%'}>
                        <img src={data.vPoster || data.vposter || data.poster || data.url} onError={(e) => {
                            this.loadImageError(e)
                        }} />
                    </LazyLoad>
                    {
                        markIcon ? (<div className={`mark-icon ${markPosition}`}>
                            <img src={markIcon} />
                        </div>) : ''
                    }
                    {
                        data.type && data.type == "more" ? (<div className='recommend-more_text'>
                            <div className='more-text_box'>
                                <div className='more-text1'>{this.props.t('home.see')}</div>
                                <div className='more-text2'>{this.props.t('home.more')}</div>
                            </div>
                        </div>) : ""
                    }
                {/* </Link> */}
                <div className='title-section'  style={{ display: (showTitle == "N" || (data.type && data.type == "more")) ? 'none' : "block" }}>
                    <div className='title-main' ref={this.titleElRef}>{name}</div>
                </div>
                {children}
            </div>)
        } else if (itemStyle == GLOBAL.RECOMMEND_STYLE.BIG_VERTICAL) {
            return (<div className='big-vertical recommend-item' ref={this.contentItemRef} id={id} focusable>
                {/* <Link key={id} to={generateUrl(pathData)}> */}
                    <LazyLoad threshold={0.2} offset={10} height={'100%'} width={'100%'}>
                        <img src={data.vPoster || data.vposter || data.poster || data.url} onError={(e) => {
                            this.loadImageError(e)
                        }} />
                    </LazyLoad>
                    {
                        markIcon ? (<div className={`mark-icon ${markPosition}`}>
                            <img src={markIcon} />
                        </div>) : ''
                    }
                    {
                        data.type && data.type == "more" ? (<div className='recommend-more_text'>
                            <div className='more-text_box'>
                                <div className='more-text1'>{this.props.t('home.see')}</div>
                                <div className='more-text2'>{this.props.t('home.more')}</div>
                            </div>
                        </div>) : ""
                    }
                {/* </Link> */}
                <div className='title-section' style={{ display: (showTitle == "N" || (data.type && data.type == "more")) ? 'none' : "block" }}>
                    <div className='title-main' ref={this.titleElRef}>{name}</div>
                </div>
                {children}
            </div>)
        } else {
            return (<div className='horizontal-style recommend-item' ref={this.contentItemRef} id={id} focusable>
                {/* <Link key={id} to={generateUrl(pathData)}> */}
                    <LazyLoad threshold={0.2} offset={10} height={'100%'} width={'100%'}>
                        <img src={data.poster || data.url} onError={(e) => {
                            this.loadImageError(e)
                        }} />
                    </LazyLoad>

                    {
                        markIcon ? (<div className={`mark-icon ${markPosition}`}>
                            <img src={markIcon} />
                        </div>) : ''
                    }
                {/* </Link> */}
                {
                    data.type && data.type == "more" ? (<div className='recommend-more_text'>
                        <div className='more-text_box'>
                            <div className='more-text1'>{this.props.t('home.see')}</div>
                            <div className='more-text2'>{this.props.t('home.more')}</div>
                        </div>
                    </div>) : ""
                }
                <div className='title-section' style={{ display: (showTitle == "N" || (data.type && data.type == "more")) ? 'none' : "block" }}>
                    <div className='title-main' ref={this.titleElRef}>{name}</div>

                </div>
                {children}
            </div>)
        }

    }

    render() {

        return (<div className='list-item'>
            {this.renderContent()}
        </div>)
    }
}


ColumnItem.propTypes = {
    children: PropTypes.element, // 节点插槽
    id: PropTypes.any, // 唯一ID
    data: PropTypes.object, // 数据体
    itemStyle: PropTypes.string, // 样式
    showTitle: PropTypes.string, // 是否显示标题 默认为Y
    name: PropTypes.string, // 标题
    markIcon: PropTypes.string, // 图标
    markPosition:  PropTypes.string, // 图标位置
}
export default withTranslation()(ColumnItem);