import React from 'react';
import { ModalContext } from './ModalProvider';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { getFocusedElement, getCanFocusElement, setStorage, getStorage, removeStorage } from './util/util';
import GLOBAL from './util/global';
class ModalContainer extends React.Component {
  static contextType = ModalContext;

  state = {
    hasRendered: false,
  };

  handleRemove = (id) => {
    this.context.removeModal(id);
    this.logRemainingModals();
  };


  logRemainingModals = () => {
    const remainingCount = this.context.modals.length;
    if (remainingCount == 0) {
      if (getStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM)) {
        let el = document.getElementById(getStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM));
        if (el) {
          $tv.requestFocus(el);
        }
        removeStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM);
      }
    }
    console.log(`还有 ${remainingCount} 个弹窗显示`);
  };


  componentDidMount() {
    if (this.context.modals.length > 0 && !this.state.hasRendered) {
      this.setState({ hasRendered: true }, () => {
        console.log("Modal has been initially rendered.");
      });
    }
    // const currentModal = modals.length > 0 ? modals[0] : null;
    // setTimeout(() => {
    //   $tv.requestFocus(document.getElementById(currentModal.id))
    // }, 2000)
  }

  componentDidUpdate(prevProps, prevState) {
    const { modals } = this.context;
    const currentModal = modals.length > 0 ? modals[0] : null;
    if (currentModal) {
      let el = document.getElementById(currentModal.id);
      $tv.requestFocus(el);
      el.addEventListener("click", () => {
        this.handleRemove(currentModal.id);
      })
      el.addEventListener("left", () => {
        $tv.requestFocus(el);
      })
      el.addEventListener("right", () => {
        $tv.requestFocus(el);
      })
      el.addEventListener("down", () => {
        $tv.requestFocus(el);
      })
      el.addEventListener("up", () => {
        $tv.requestFocus(el);
      })
    }


  }




  render() {
    const { modals } = this.context;
    console.log('All modals in ModalContainer:', modals); // 调试输出
    const currentModal = modals.length > 0 ? modals[0] : null;
    if (!getStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM)) {
      if (getFocusedElement()) {
        setStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM, getFocusedElement().id);
      } else {
        if (getCanFocusElement() && getCanFocusElement().id) {
          setStorage(GLOBAL.STORAGE_KEYS.RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM, getCanFocusElement().id);
        }
        
      }
    }
    return (
      <div className="modal-container">
        {currentModal && (
          <div
            className="modal-layer"
          >
            <div className="modal-content">
              <div className='icon'>
                <img src={require('./assets/images/logo.png').default} />
              </div>
              <h2>{currentModal.title}</h2>
              <p>{currentModal.description}</p>
              <div className='btn' focusable id={currentModal.id}>
                {this.props.t("toast.sure")}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ModalContainer);
